import { get, intersectionBy, sortBy } from 'lodash';
import { Moment } from 'moment';
import { PropertyProps } from 'dwell/store/property/action-types';
import { TaskProps } from 'dwell/store/task/action-types';
import { UserProps } from 'dwell/store/user/action-types';
import { FOLLOWUPS, TOUR_TYPES_LIST } from 'dwell/views/tasks/utils';

export enum GroupCategory {
  All = 'All',
  Tours = 'Tours',
  CallBack = 'Call Back',
  FirstContact = 'Needs First Contact',
  ReplyBack = 'Reply Back',
  Followup = 'Followups',
}

export enum OwnerCategory {
  User = 'My Tasks',
  All = 'All Tasks',
  VirtualTeam = 'Virtual Team',
  OnSiteTeam = 'On-Site Team',
}

export enum PageCategory {
  Today = 'Today',
  Upcoming = 'Upcoming',
  Completed = 'Completed',
}

export interface CompletedState {
  [type: string]: {
    [owner: string]: {
      before?: Moment;
      disabled?: boolean;
    };
  };
}

export interface WeekInfo {
  offset?: number,
  setOffset?: (offset: number) => void,
  week?: {
    day: Moment,
    disabled: boolean,
    isToday: boolean,
  }[],
}

export interface CommonProps {
  handleSelect: (row: TaskProps, isSelect: boolean) => void;
  handleSelectAll: (isSelect: boolean, rows: TaskProps[]) => void;
  owner?: OwnerCategory;
  selected: number[];
  tasks: TaskProps[];
  type?: GroupCategory;
  upcomingDate?: Moment;
  setCompletedState?: (state: CompletedState) => void;
  setUpcomingDate?: (m: Moment) => void;
  users?: UserProps[];
  weekInfo?: WeekInfo,
  loadMore?: () => void;
  disabledLoadMore?: boolean;
}

export const groupFilterToValue = (group: GroupCategory): string => {
  switch (group) {
    case GroupCategory.CallBack:
      return 'CALL_BACK';
    case GroupCategory.FirstContact:
      return 'FIRST_CONTACT';
    case GroupCategory.Followup:
      return 'FOLLOWUPS';
    case GroupCategory.ReplyBack:
      return 'REPLY_BACK';
    case GroupCategory.Tours:
      return 'TOURS';
    default:
      return 'ALL';
  }
};

export const mapUsers = (property: PropertyProps, properties: PropertyProps[]): { label: string, value: number }[] => {
  const mapper = (user) => {
    if (user.first_name || user.last_name) return { label: `${user.first_name} ${user.last_name}`, value: user.id };
    return { label: user.email, value: user.id };
  };

  if (properties.length === 1 && property && property.users) {
    return sortBy(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      get<any, 'users', any[]>(property, 'users', []).map(mapper),
      [i => i.label.toLowerCase()],
    );
  }
  return sortBy(
    intersectionBy(...properties.map(p => get(p, 'users', [])), 'id').map(mapper),
    [i => i.label.toLowerCase()],
  );
};

export const ownerFilterToValue = (owner: OwnerCategory, currentUser: UserProps): number | string => {
  if (typeof owner === 'number') return owner;
  switch (owner) {
    case OwnerCategory.User:
      return currentUser.id;
    case OwnerCategory.VirtualTeam:
      return 'VIRTUAL_TEAM';
    case OwnerCategory.OnSiteTeam:
      return 'ON_SITE_TEAM';
    default:
      return 'ALL';
  }
};

export const splitTasks = (tasks: TaskProps[]): { [key: string]: TaskProps[] } => {
  const callbacks = tasks.filter(task => task.type === 'CALL_BACK');
  const firstContact = tasks.filter(task => task.type === 'FIRST_CONTACT');
  const replyBack = tasks.filter(task => task.type === 'REPLY_BACK');
  const followups = tasks.filter(task => FOLLOWUPS.includes(task.type));
  const tours = tasks.filter(task => TOUR_TYPES_LIST.includes(task.type));
  return { callbacks, firstContact, followups, replyBack, tours };
};
