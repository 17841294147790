import React, { FC, useState, useMemo } from 'react';
import { UserPlus } from 'react-feather';
import cn from 'classnames';
import { alphabet } from 'dwell/constants';
import { UserProps } from 'dwell/store/user/action-types';
import { CustomSearchIcon, FormSearchDiv, LetterLabel } from 'dwell/components/ordered_dropdown/styles';
import {
  StageDropdown, DropdownLink, OwnerDropdownMenu, CustomFormSearch,
  CustomDropdownItem, UnassignedItem,
} from 'dwell/views/lead/layout/key_info/style';

interface OrderedOwnerDropdownProps {
  availableOwners: UserProps[];
  selectedOwner: number;
  onChange: (field: string, value: string | number) => void;
}

const OrderedOwnerDropdown: FC<OrderedOwnerDropdownProps> = ({ availableOwners = [], selectedOwner, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [focus, setFocus] = useState(false);

  const filteredAvailableOwners = useMemo(() => {
    if (!availableOwners.length) return availableOwners;
    if (!keyword) return availableOwners;

    return availableOwners.filter(o => `${o.first_name} ${o.last_name}`.toUpperCase().includes(keyword.toUpperCase()));
  }, [availableOwners, keyword]);

  const orderedAvailableOwners = useMemo(() => {
    if (!filteredAvailableOwners.length) return {};

    const temp = {} as { [key: string]: UserProps[] };
    alphabet.forEach((letter) => {
      const filteredOrderedOwners = filteredAvailableOwners.filter(o => `${o.first_name} ${o.last_name}`.toUpperCase().startsWith(letter));
      if (filteredOrderedOwners.length) temp[letter] = filteredOrderedOwners;
    });
    return temp;
  }, [filteredAvailableOwners]);

  const selectedOwnerName = useMemo(() => {
    if (!selectedOwner || !availableOwners.length) return '';
    const owner = availableOwners.find(o => o.id === selectedOwner);
    return `${owner?.first_name} ${owner?.last_name}`;
  }, [selectedOwner, availableOwners]);

  const onSearch = ({ target: { value } }) => {
    setKeyword(value);
  };

  const handleChange = (field: string, value: string | number) => {
    onChange(field, value);
    setKeyword('');
  };

  return (
    <StageDropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownLink caret>
        {selectedOwnerName || <React.Fragment><UserPlus size="16" /> <UnassignedItem>Unassigned</UnassignedItem></React.Fragment>}
      </DropdownLink>
      <OwnerDropdownMenu>
        <FormSearchDiv className={cn('position-relative mr-10', { focus })} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
          <CustomSearchIcon />
          <CustomFormSearch
            name="search"
            value={keyword ?? ''}
            onChange={onSearch}
            placeholder="Search"
          />
        </FormSearchDiv>
        {
          Object.keys(orderedAvailableOwners).length ? Object.keys(orderedAvailableOwners).map((letter, index) => (
            <div key={index}>
              <LetterLabel header>{letter}</LetterLabel>
              {
                orderedAvailableOwners[letter].map((current_owner, owner_index) => (
                  <CustomDropdownItem
                    key={owner_index}
                    onClick={() => handleChange('owner', current_owner.id)}
                    className={current_owner.id === selectedOwner ? 'selected' : ''}
                  >
                    {current_owner.first_name} {current_owner.last_name}
                  </CustomDropdownItem>
                ))
              }
            </div>
          )) : <CustomDropdownItem disabled>No results found</CustomDropdownItem>
        }
      </OwnerDropdownMenu>
    </StageDropdown>
  );
};

export default OrderedOwnerDropdown;
