import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { startCase } from 'lodash';
import { formatUrl } from 'src/utils';
import { InsiderInfoDetails } from 'dwell/store/property_profile/action-types';
import { Link, Label, InfoContainer } from './styles';
import { Card, CardBody, Content } from '../styles';

const Neighborhood: FC = () => {
  const insiderInfo = useSelector(state => state.propertyProfile.insiderInfoDetails) as InsiderInfoDetails[];
  const { locations } = useSelector(state => state.propertyProfile.neighborhood);

  return locations && (
    <Content>
      {Object.keys(locations).map((topic, index) => {
        const header = startCase(topic.replace('_', ' '));
        const insiderInfoPlaces = insiderInfo?.find(({ category, subcategory }) => category === 'Neighborhood' && subcategory.toLowerCase() === header.toLowerCase())?.details;

        return (
          <section key={index}>
            <h6 className={cn('tx-16', 'text-dark', { 'mg-t-15': index !== 0 })}># {header}</h6>
            {locations[topic].map((item, ind) => (
              <Card className={cn({ 'mg-t-15': ind !== 0 })}>
                <CardBody>
                  <div className="d-flex align-items-center mg-b-5">
                    <i className="ri-road-map-fill tx-18 mg-r-5 lh-1" />
                    <h6 className="mg-b-0">{item.name}</h6>
                    <Link className="d-flex mg-l-auto tx-16 lh-1" href={formatUrl(item.website)} target="_blank">
                      <i className="ri-external-link-fill" />
                    </Link>
                  </div>
                  <Label className="tx-12" style={{ marginBottom: 10 }}>{item.address}</Label>
                  <InfoContainer className="d-flex align-items-center tx-12">
                    <div className="d-flex align-items-center">
                      <i className="ri-cellphone-fill mr-1" /> {item.phone}
                    </div>
                    <div className="d-flex align-items-center mg-l-20">
                      <i className="ri-pin-distance-fill mr-1" /> {item.distance} from property
                    </div>
                  </InfoContainer>
                </CardBody>
              </Card>
            ))}
            {insiderInfoPlaces?.length > 0 && (
              <>
                <p><strong>Insider Info:</strong></p>
                {insiderInfoPlaces.map(li => (
                  <div>- {li}</div>
                ))}
              </>
            )}
          </section>
        );
      })}
    </Content>
  );
};

export default Neighborhood;
