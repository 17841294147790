import Pusher from 'pusher-js';
import actions from 'dwell/actions';
import * as PusherTypes from 'pusher-js';
import store from './store';

const { pusher:
  {
    pusherCreateRecord,
    pusherUpdateRecord,
    pusherDeleteRecord,
    pusherDesktopPhoneCall,
    pusherDesktopPhoneCallCompleted,
    pusherBrowserPhoneCallAnswered,
    pusherOutboundCallStatus,
    pusherConferenceStarted,
  },
} = actions;

export default class PusherManager {
  pusher: PusherTypes.Pusher;

  constructor(pusherKey: string, pusherCluster: string) {
    this.pusher = new Pusher(pusherKey, { cluster: pusherCluster });
  }

  // --------------------------------------------------------------------------- PUSHER ACTIONS
  enablePusher = (pusherModel: string, id = null): void => {
    let channelName = 'private-property-properties';
    if (id) {
      channelName = `private-user-${id}`;
    } else if (['chatprospect', 'chatconversation'].includes(pusherModel)) {
      channelName = 'private-chat';
    }
    let channel = this.pusher.channels.find(channelName);
    if (!channel) {
      channel = this.pusher.subscribe(channelName);
    }
    if (pusherModel) {
      channel.bind(`${pusherModel}_created`, row => store.dispatch(pusherCreateRecord(pusherModel, row)));
      channel.bind(`${pusherModel}_changed`, row => store.dispatch(pusherUpdateRecord(pusherModel, row)));
      channel.bind(`${pusherModel}_deleted`, row => store.dispatch(pusherDeleteRecord(pusherModel, row)));
      channel.bind('user_typing', row => store.dispatch(pusherUpdateRecord('typing', row)));
      channel.bind('desktop_phone_call_answered', row => store.dispatch(pusherDesktopPhoneCall('voice', row)));
      channel.bind('desktop_phone_call_completed', () => store.dispatch(pusherDesktopPhoneCallCompleted('voice')));
      channel.bind('browser_phone_call_answered', () => store.dispatch(pusherBrowserPhoneCallAnswered('voice')));
      channel.bind('outbound_call_status', row => store.dispatch(pusherOutboundCallStatus('voice', row)));
      channel.bind('conference_started', row => store.dispatch(pusherConferenceStarted('voice', row)));
    }
  };

  initializePusher(pusherModels = [], id = null): void {
    pusherModels.forEach((model) => {
      this.enablePusher(model, id);
    });
  }
}
