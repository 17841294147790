import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'dwell/actions';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { UserActivityType } from 'dwell/store/user_activity/action-types';

export const useActivityTracker = (activityThreshold = 10 * 1000, idleThreshold = 1 * 30 * 1000): void => {
  const { pingUserActivity } = actions.userActivity;
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);

  const firstRequest = useRef(true);
  const activityType = useRef(UserActivityType.ACTIVE);
  const activityId = useRef<number>(null);
  const idleId = useRef<number>(null);
  const lastActivity = useRef<moment.Moment>(moment());

  const activityTimer = (start = false) => {
    const time = start ? 0 : activityThreshold + 10;
    const delta = moment().diff(lastActivity.current);
    console.log('track', {
      activityThreshold,
      delta,
      type: activityType.current,
      isActive: (delta < activityThreshold && activityType.current === UserActivityType.ACTIVE),
      isOther: activityType.current !== UserActivityType.ACTIVE,
    });
    if ((delta < activityThreshold && activityType.current === UserActivityType.ACTIVE) || activityType.current !== UserActivityType.ACTIVE) {
      activityId.current = setTimeout(() => {
        dispatch(pingUserActivity(user.id, activityType.current)).then(() => activityTimer());
      }, time);
    }
  };

  const inactivityTimer = () => {
    clearTimeout(idleId.current);
    activityType.current = UserActivityType.ACTIVE;
    lastActivity.current = moment();

    idleId.current = setTimeout(() => {
      clearTimeout(activityId.current);
      activityId.current = null;
      dispatch(pingUserActivity(user.id, UserActivityType.IDLE)).then(() => {
        activityType.current = UserActivityType.IDLE;
        activityTimer();
      });
    }, idleThreshold);
    console.log('reset idle', { activityId: activityId.current, type: activityType.current });

    if (activityId.current === null || activityType.current !== UserActivityType.ACTIVE) {
      console.log('restart timer');
      activityType.current = UserActivityType.ACTIVE;
      activityTimer(true);
    }
  };

  useEffect(() => {
    if (!user || isEmpty(user)) return () => null;

    if (firstRequest.current) {
      activityTimer(true);
      firstRequest.current = false;
    }

    const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
    events.forEach(event => window.addEventListener(event, inactivityTimer));

    inactivityTimer();

    return () => {
      clearTimeout(activityId.current);
      clearTimeout(idleId.current);
      events.forEach(event => window.removeEventListener(event, inactivityTimer));
    };
  }, [user]);
};

export default useActivityTracker;
