import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { fieldChoices } from 'dwell/constants';
import { InsiderInfoDetails } from 'dwell/store/property_profile/action-types';
import { startCase } from 'lodash';
import { Content } from '../styles';

const Amenities: FC = () => {
  const insiderInfo = useSelector(state => state.propertyProfile.insiderInfoDetails) as InsiderInfoDetails[];
  const amenities = useSelector(state => state.propertyProfile.amenities);
  const formatTimeStr = str => (str.includes('AM') || str.includes('PM') ? str.replace(/ /g, '').toLowerCase() : str);
  const utilities = amenities.utilities.map(i => startCase(fieldChoices.PROPERTY_POLICY_FILED_CHOICES.utilities[i])).join(', ');

  const insiderInfoCommunityFeatures = insiderInfo?.find(({ category, subcategory }) => category === 'Amenities' && subcategory === 'Community Features')?.details;
  const insiderInfoApartmentFeatures = insiderInfo?.find(({ category, subcategory }) => category === 'Amenities' && subcategory === 'Apartment Features')?.details;
  const insiderInfoAmenityHours = insiderInfo?.find(({ category, subcategory }) => category === 'Amenities' && subcategory === 'Amenity Hours')?.details;
  const insiderInfoParking = insiderInfo?.find(({ category, subcategory }) => category === 'Amenities' && subcategory === 'Parking')?.details;
  const insiderInfoUtilities = insiderInfo?.find(({ category, subcategory }) => category === 'Amenities' && subcategory === 'Utilities')?.details;
  const insiderInfoPoolAndSpa = insiderInfo?.find(({ category, subcategory }) => category === 'Amenities' && subcategory === 'Pool and Spa')?.details;
  const insiderInfoFitness = insiderInfo?.find(({ category, subcategory }) => category === 'Amenities' && subcategory === 'Fitness')?.details;
  const insiderInfoClubhouse = insiderInfo?.find(({ category, subcategory }) => category === 'Amenities' && subcategory === 'Clubhouse')?.details;
  const insiderInfoBusinessCenter = insiderInfo?.find(({ category, subcategory }) => category === 'Amenities' && subcategory === 'Business center')?.details;

  return (
    <Content>
      <h6 className="tx-16 text-dark"># Community Features</h6>
      {amenities.community.map((item, index) => (
        <p key={index}><strong>{item.category}:</strong> {item.name}</p>
      ))}
      {insiderInfoCommunityFeatures?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoCommunityFeatures?.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}

      <hr />

      <h6 className="tx-16 text-dark"># Apartment Features</h6>
      {amenities.apartment.map((item, index) => (
        <p key={index}><strong>{item.category}:</strong> {item.name}</p>
      ))}
      {insiderInfoApartmentFeatures?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoApartmentFeatures?.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}

      <hr />

      <h6 className="tx-16 text-dark"># Amenity Hours</h6>
      <p><strong>Clubhouse:</strong> {formatTimeStr(amenities.hours.clubhouse)}</p>
      <p><strong>Fitness Center:</strong> {formatTimeStr(amenities.hours.fitness_center)}</p>
      <p><strong>Pool:</strong> {formatTimeStr(amenities.hours.pool)}</p>
      <p><strong>Community Quiet Hours:</strong> {formatTimeStr(amenities.hours.community_quiet_hours)}</p>
      <p><strong>Moving &amp; Transfer:</strong> {formatTimeStr(amenities.hours.moving_and_transfer)}</p>
      {insiderInfoAmenityHours?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoAmenityHours?.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}

      <hr />

      <h6 className="tx-16 text-dark"># Parking</h6>
      <p><strong>Residents:</strong> {amenities.parking.residents}</p>
      <p><strong>Guests:</strong> {amenities.parking.guests}</p>
      {insiderInfoParking?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoParking?.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}

      <hr />

      <h6 className="tx-16 text-dark"># Utilities</h6>
      <p>
        <strong>Utility Billing Policy:</strong> Resident is responsible for {utilities}
      </p>
      {insiderInfoUtilities?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoUtilities?.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}

      {insiderInfoPoolAndSpa?.length > 0 ?
        <>
          <hr />
          <h6 className="tx-16 text-dark"># Pool and Spa</h6>
          {insiderInfoPoolAndSpa?.map(li => (
            <div>- {li}</div>
          ))}
        </> : null
      }

      {insiderInfoFitness?.length > 0 ?
        <>
          <hr />
          <h6 className="tx-16 text-dark"># Fitness</h6>
          {insiderInfoFitness?.map(li => (
            <div>- {li}</div>
          ))}
        </> : null
      }

      {insiderInfoClubhouse?.length > 0 ?
        <>
          <hr />
          <h6 className="tx-16 text-dark"># Clubhouse</h6>
          {insiderInfoClubhouse?.map(li => (
            <div>- {li}</div>
          ))}
        </> : null
      }

      {insiderInfoBusinessCenter?.length > 0 ?
        <>
          <hr />
          <h6 className="tx-16 text-dark"># Business Center</h6>
          {insiderInfoBusinessCenter?.map(li => (
            <div>- {li}</div>
          ))}
        </> : null
      }
    </Content>
  );
};

export default Amenities;
