import produce from 'immer';
import { get, unionBy, orderBy } from 'lodash';
import { Communication } from 'src/interfaces';
import { COMMUNICATION_FILTER } from 'dwell/constants';
import { PusherAction, ActionType as PusherActionType, PusherRow } from 'dwell/store/pusher/action-types';
import { ActionType as TaskActionType } from '../task/action-types';
import { ActionType as ChatActionType } from '../chat/action-types';
import { ActionType as SMSActionType, SMSMessage } from '../sms/action-types';
import { RoommateProps } from '../roommate/action-types';
import { Action, ActionType, LeadData, LeadNamesProps, LeadAction, Lead } from './action-types';
import { CommonStateType } from '../types';

const mergeCommunications = (communications, model, row, filterType, keyword) => {
  let data;
  const object = {
    type: undefined,
    date: undefined,
  };
  if (model === 'notification') return communications;
  if (model === 'emailmessage') {
    ['id', 'attachments', 'body', 'date', 'is_unread', 'sender_name', 'sender_email', 'receiver_name', 'receiver_email', 'formatted_sender_name', 'formatted_receiver_name', 'subject'].forEach((field) => {
      object[field] = row[field];
    });
    data = { date: row.created, is_property_communication: row.is_property_communication, type: 'EMAIL', object };
  } else if (model === 'smscontent') {
    ['id', 'date', 'user_name', 'message', 'agent_name'].forEach((field) => {
      object[field] = row[field];
    });
    data = { date: row.created, is_property_communication: row.is_team_message, type: 'SMS', object };
  } else if (model === 'call') {
    ['id', 'date', 'lead_name', 'message'].forEach((field) => {
      object[field] = row[field];
    });
    data = { date: row.created, is_property_communication: null, type: 'CALL', object: row };
  } else if (model === 'chatconversation') {
    ['id', 'agent_name', 'date', 'type', 'message'].forEach((field) => {
      object[field] = row[field];
    });
    data = { date: row.created, is_property_communication: !(row.type === 'PROSPECT' && row.to_agent), type: 'CHATS', object: [object] };
  } else if (model === 'notedelete') {
    const removeId = row.id;
    return communications.filter((el) => {
      if (get(el, 'type') === 'NOTE') {
        return Number(get(el, 'object.id')) !== removeId;
      }
      return true;
    });
  } else if (model === 'note') {
    ['id', 'text', 'is_auto_generated', 'updated', 'created', 'actor', 'source_name'].forEach((field) => {
      object[field] = row[field];
    });
    data = { date: row.updated, is_property_communication: null, type: 'NOTE', object };
  } else {
    const { last_activity: activity } = row;
    if (!activity) return communications;
    ['id', 'tour', 'type', 'transformed_content', 'creator', 'created'].forEach((field) => {
      object[field] = activity[field];
    });
    data = { date: activity.created, is_property_communication: null, type: 'ACTIVITY', object };
  }

  // filter the data by filter type
  if (filterType === 'note' && data.type !== 'NOTE') return communications;
  if (filterType === 'update' && !['LEAD_UPDATED', 'TOUR_UPDATED', 'TASK_UPDATED', 'ROOMMATE_UPDATED', 'LEASE_UPDATED'].includes(get(data, 'object.type'))) return communications;
  if (filterType === 'email' && data.type !== 'EMAIL') return communications;
  if (filterType === 'sms' && data.type !== 'SMS') return communications;
  if (filterType === 'chat' && data.type !== 'CHATS') return communications;
  if (filterType === 'call' && data.type !== 'CALL') return communications;

  // filter the data with search keyword
  if (
    keyword &&
    !Object.values(object).filter((item: string) =>
      (item || '')
        .toString()
        .toLowerCase()
        .includes(keyword.toLowerCase())).length
  ) {
    return communications;
  }

  data = { ...data, uniqueId: `${data.type}-${data.object.id}` };
  let newCommunications = communications.map((item) => {
    if (item.type === 'CHATS') return item;
    return { ...item, uniqueId: `${item.type}-${item.object.id}` };
  });
  let nearestCommIndex = newCommunications.findIndex(comm => comm.type === 'CHATS');
  if (nearestCommIndex < 0) {
    nearestCommIndex = 0;
  }

  if (model === 'chatconversation') {
    const previous_communication = newCommunications[nearestCommIndex];
    if (previous_communication && previous_communication.type === 'CHATS' && object.type !== 'GREETING') {
      previous_communication.date = object.date;
      previous_communication.object = unionBy([object], previous_communication.object, 'id');
      if (previous_communication.is_property_communication && !data.is_property_communication) {
        previous_communication.is_property_communication = false;
      }
    } else {
      newCommunications = unionBy([data], newCommunications, 'uniqueId');
    }
  } else {
    newCommunications = unionBy([data], newCommunications, 'uniqueId');
  }
  return orderBy(newCommunications, 'date', 'desc');
};

export interface LeadState extends CommonStateType {
  areLeadNamesLoaded: boolean;
  leads: LeadData[];
  lead: LeadData;
  leadProspect: LeadData;
  leadNames: LeadNamesProps[];
  count: number;
  filteredCount: number;
  previewLeadBulkEmail: Lead;
  pmsSyncData: {
    pms_sync_status: string;
    pms_sync_date: string;
    pms_sync_condition_lack_reason: string;
  };
  totalLeadsCount: number;
  activeLeadsCount: number;
  myLeadsCount: number;
  isCommunicationLoaded: boolean;
  isRemainingCommunicationsLoaded: boolean;
  communications: Communication[];
  communicationCount: number;
  isCommunicationUpdate: boolean;
  isChatPusher: boolean;
  communicationSearchKeyword: string;
  communicationFilterType: string;
  isLeadProspectLoaded: boolean;
}

const communicationFilter = localStorage.getItem(COMMUNICATION_FILTER);

export const initialState: LeadState = {
  isSubmitting: false,
  isLoaded: true,
  areLeadNamesLoaded: true,
  errorMessage: null,
  leads: [],
  lead: {} as LeadData,
  leadProspect: {} as LeadData,
  leadNames: [],
  count: 0,
  filteredCount: 0,
  previewLeadBulkEmail: {} as Lead,
  pmsSyncData: {
    pms_sync_status: 'NOT_STARTED',
    pms_sync_date: null,
    pms_sync_condition_lack_reason: '',
  },
  totalLeadsCount: 0,
  activeLeadsCount: 0,
  myLeadsCount: 0,
  isCommunicationLoaded: false,
  isRemainingCommunicationsLoaded: false,
  communications: [],
  communicationCount: 0,
  isCommunicationUpdate: false,
  isChatPusher: false,
  communicationSearchKeyword: '',
  communicationFilterType: communicationFilter || '',
  isLeadProspectLoaded: false,
};

const objectCreatedSignals = [
  ActionType.CREATE_NOTE_SUCCESS,
  ActionType.UPDATE_NOTE_SUCCESS,
  ActionType.DELETE_NOTE_SUCCESS,
  TaskActionType.CREATE_TASK_SUCCESS,
  TaskActionType.UPDATE_TASK_SUCCESS,
  TaskActionType.DELETE_TASK_SUCCESS,
  TaskActionType.COMPLETE_TASK_SUCCESS,
  TaskActionType.MARK_NO_SHOW_TASK_SUCCESS,
  ActionType.SEND_MESSAGE_SUCCESS,
  SMSActionType.SEND_SMS_SUCCESS,
  ChatActionType.SEND_CHAT_MESSAGE_SUCCESS,
  ActionType.UPDATE_LEAD_SUCCESS,
  ActionType.SHARE_LEAD_SUCCESS,
  ActionType.DELETE_ROOMMATE_SUCCESS,
  ActionType.UPDATE_ROOMMATES_SUCCESS,
];

const getModelNameByActionType = (type) => {
  if ([ActionType.CREATE_NOTE_SUCCESS, ActionType.UPDATE_NOTE_SUCCESS].includes(type)) return 'note';
  if ([TaskActionType.CREATE_TASK_SUCCESS, TaskActionType.UPDATE_TASK_SUCCESS, TaskActionType.DELETE_TASK_SUCCESS, TaskActionType.COMPLETE_TASK_SUCCESS].includes(type)) return 'task';
  if ([ActionType.SEND_MESSAGE_SUCCESS].includes(type)) return 'emailmessage';
  if ([SMSActionType.SEND_SMS_SUCCESS].includes(type)) return 'smscontent';
  if ([ChatActionType.SEND_CHAT_MESSAGE_SUCCESS].includes(type)) return 'chatconversation';
  if ([ActionType.UPDATE_LEAD_SUCCESS, ActionType.SHARE_LEAD_SUCCESS].includes(type)) return 'lead';
  if ([ActionType.UPDATE_ROOMMATES_SUCCESS].includes(type)) return 'roommates';
  if ([ActionType.DELETE_NOTE_SUCCESS].includes(type)) return 'notedelete';
  return '';
};

export const selectLead = (state: {lead:LeadState}): LeadData => state.lead.lead;
export const selectLeadNames = (state: { lead: LeadState }): LeadNamesProps[] => state.lead.leadNames || [];
export const selectActiveLeadsCount = (state: { lead: LeadState }): number => state.lead.activeLeadsCount;
export const selectLeadIsLoaded = (state: { lead: LeadState }): boolean => state.lead.isLoaded;
export const selectLeads = (state: { lead: LeadState }): LeadData[] => state.lead.leads;
export const selectLeadsCount = (state: { lead: LeadState }): number => state.lead.count;
export const selectMyLeadsCount = (state: { lead: LeadState }): number => state.lead.myLeadsCount;
export const selectTotalLeadsCount = (state: { lead: LeadState }): number => state.lead.totalLeadsCount;

export default produce((state: LeadState = initialState, action: Action): LeadState => {
  const leadAction = action as LeadAction;
  if (objectCreatedSignals.includes(leadAction.type)) {
    const {
      result: { data },
      type,
    } = leadAction;
    const model = getModelNameByActionType(type);

    if (model === 'chatconversation') {
      const isCurrentProspect = (state.lead.chat_prospects || []).find(el => el.id === data.prospect);
      if (!isCurrentProspect) return state;
    }

    if (model === 'smscontent' && (data as SMSMessage).lead !== state.lead.id) return state;

    if (model === 'roommates') {
      let communicationList = state.communications;
      (data as RoommateProps[]).forEach((el) => {
        communicationList = mergeCommunications(communicationList, model, el, state.communicationFilterType, state.communicationSearchKeyword);
      });
      state.communications = communicationList;
      state.isCommunicationUpdate = !state.isCommunicationUpdate;
      return state;
    }

    if (model === 'lead') {
      state.isSubmitting = false;
      state.lead = data;
      state.pmsSyncData = { pms_sync_status: data.pms_sync_status, pms_sync_date: data.pms_sync_date, pms_sync_condition_lack_reason: data.pms_sync_condition_lack_reason };
      state.totalLeadsCount = data.all_leads_count;
      state.activeLeadsCount = data.active_leads_count;
      state.myLeadsCount = data.my_leads_count;
      state.leads = [];
    }
    let newCommunications = state.communications;
    if (type === ActionType.SEND_MESSAGE_SUCCESS && get(state, 'lead.id') === get(data, 'lead', '')) {
      newCommunications = mergeCommunications(state.communications, model, data, state.communicationFilterType, state.communicationSearchKeyword);
    }
    const isAdded = model !== 'notedelete' ? newCommunications.length !== state.communications.length : false;
    state.communications = newCommunications;
    state.isCommunicationUpdate = isAdded ? !state.isCommunicationUpdate : state.isCommunicationUpdate;
    return state;
  }

  const pusherAction = action as PusherAction;
  if (pusherAction.type.startsWith('PUSHER_') && pusherAction.type !== 'PUSHER_CREATE_RECORD' && !['lead', 'note'].includes(pusherAction.pusherModel)) {
    return state;
  }

  switch (action.type) {
    case ActionType.GET_LEAD_REQUEST:
      state.isLoaded = false;
      state.lead = {} as LeadData;
      break;
    case ActionType.GET_LEAD_SUCCESS:
      state.isLoaded = true;
      state.leads = action.result.data.results;
      state.count = action.result.data.count;
      state.totalLeadsCount = action.result.data.all_leads_count;
      state.activeLeadsCount = action.result.data.active_leads_count;
      state.myLeadsCount = action.result.data.my_leads_count;
      break;
    case ActionType.GET_LEAD_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isLoaded = true;
      break;

    case ActionType.GET_LEAD_BY_ID_REQUEST:
      state.isLoaded = false;
      break;
    case ActionType.GET_LEAD_BY_ID_SUCCESS:
      state.isLoaded = true;
      state.lead = action.result.data;
      state.pmsSyncData = { pms_sync_status: action.result.data.pms_sync_status, pms_sync_date: action.result.data.pms_sync_date, pms_sync_condition_lack_reason: action.result.data.pms_sync_condition_lack_reason };
      break;
    case ActionType.GET_LEAD_BY_ID_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isLoaded = true;
      break;

    case ActionType.UPDATE_LEAD_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_LEAD_SUCCESS:
      state.isSubmitting = false;
      state.lead = action.result.data;
      state.pmsSyncData = { pms_sync_status: action.result.data.pms_sync_status, pms_sync_date: action.result.data.pms_sync_date, pms_sync_condition_lack_reason: action.result.data.pms_sync_condition_lack_reason };
      state.totalLeadsCount = action.result.data.all_leads_count;
      state.activeLeadsCount = action.result.data.active_leads_count;
      state.myLeadsCount = action.result.data.my_leads_count;
      state.leads = [];
      break;
    case ActionType.UPDATE_LEAD_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.DELETE_LEADS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.DELETE_LEADS_SUCCESS:
      state.isSubmitting = false;
      state.leads = [];
      break;
    case ActionType.DELETE_LEADS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.UPDATE_LEADS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_LEADS_SUCCESS:
      state.isSubmitting = false;
      state.leads = [];
      break;
    case ActionType.UPDATE_LEADS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.CREATE_LEAD_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.CREATE_LEAD_SUCCESS:
      state.isSubmitting = false;
      state.lead = action.result.data;
      state.pmsSyncData = { pms_sync_status: action.result.data.pms_sync_status, pms_sync_date: action.result.data.pms_sync_date, pms_sync_condition_lack_reason: action.result.data.pms_sync_condition_lack_reason };
      state.totalLeadsCount = action.result.data.all_leads_count;
      state.activeLeadsCount = action.result.data.active_leads_count;
      state.myLeadsCount = action.result.data.my_leads_count;
      state.leads = [];
      break;
    case ActionType.CREATE_LEAD_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.GET_LEAD_NAMES_REQUEST:
      state.areLeadNamesLoaded = false;
      break;
    case ActionType.GET_LEAD_NAMES_SUCCESS:
      state.areLeadNamesLoaded = true;
      state.leadNames = action.result.data;
      break;
    case ActionType.GET_LEAD_NAMES_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.areLeadNamesLoaded = true;
      break;

    case ActionType.CLEAR_LEADS:
      state.leads = [];
      break;

    case ActionType.GET_FILTERED_LEADS_COUNT_REQUEST:
      state.isLoaded = false;
      state.previewLeadBulkEmail = {} as Lead;
      break;
    case ActionType.GET_FILTERED_LEADS_COUNT_SUCCESS:
      state.isLoaded = true;
      state.filteredCount = action.result.data.count;
      state.previewLeadBulkEmail = action.result.data.first_lead || {} as Lead;
      break;
    case ActionType.GET_FILTERED_LEADS_COUNT_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isLoaded = true;
      break;

    case PusherActionType.PUSHER_DELETE_RECORD: {
      const row = action.row as Lead;
      state.lead = state.lead.id && state.lead.id.toString() === row.id.toString() ? ((row as unknown) as LeadData) : state.lead;
      break;
    }

    case ActionType.GET_PMS_SYNC_STATUS_BY_ID_REQUEST:
      state.isLoaded = false;
      break;
    case ActionType.GET_PMS_SYNC_STATUS_BY_ID_SUCCESS:
      state.isLoaded = true;
      state.pmsSyncData = { pms_sync_status: action.result.data.pms_sync_status, pms_sync_date: action.result.data.pms_sync_date, pms_sync_condition_lack_reason: action.result.data.pms_sync_condition_lack_reason };
      break;
    case ActionType.GET_PMS_SYNC_STATUS_BY_ID_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isLoaded = true;
      break;

    case PusherActionType.PUSHER_CREATE_RECORD: {
      const row = action.row as PusherRow;
      let isCurrentLead = true;
      if (action.pusherModel === 'chatconversation') {
        const currentLead = (state.lead.chat_prospects || []).find(el => el.id === row.prospect);
        isCurrentLead = !!currentLead;
      } else if (action.pusherModel === 'smscontent') {
        isCurrentLead = state.lead.id === row.lead;
      } else if (action.pusherModel === 'lead') {
        isCurrentLead = get(state, 'lead.id') === get(row, 'id', '');
      } else if (['emailmessage', 'note', 'task'].includes(action.pusherModel)) {
        isCurrentLead = get(state, 'lead.id') === get(row, 'lead', '');
      }
      if (!isCurrentLead) break;

      const newCommunications = mergeCommunications(state.communications, action.pusherModel, action.row, state.communicationFilterType, state.communicationSearchKeyword);
      const isAdded = newCommunications.length !== state.communications.length;

      state.communications = newCommunications;
      state.isCommunicationUpdate = isAdded ? !state.isCommunicationUpdate : state.isCommunicationUpdate;
      break;
    }

    case PusherActionType.PUSHER_UPDATE_RECORD: {
      if (get(action.row, 'id', '') === get(state, 'lead.id')) {
        const row = action.row as PusherRow;
        const newCommunications = mergeCommunications(state.communications, action.pusherModel, row, state.communicationFilterType, state.communicationSearchKeyword);
        const isAdded = newCommunications.length !== state.communications.length;
        state.communications = newCommunications;
        state.lead = action.pusherModel === 'lead' && state.lead.id === row.id ? ((row as unknown) as LeadData) : state.lead;
        state.isCommunicationUpdate = isAdded ? !state.isCommunicationUpdate : state.isCommunicationUpdate;
      }
      break;
    }

    case ActionType.GET_LEAD_FOR_PROSPECT_REQUEST:
      state.isLeadProspectLoaded = false;
      break;
    case ActionType.GET_LEAD_FOR_PROSPECT_SUCCESS:
      state.isLeadProspectLoaded = true;
      state.leadProspect = action.result.data;
      break;
    case ActionType.GET_LEAD_FOR_PROSPECT_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isLeadProspectLoaded = true;
      break;

    case ActionType.UNENROLL_LEAD_SUCCESS:
      state.lead = { ...state.lead, active_nurture: action.result.data };
      break;

    case ActionType.SHARE_LEAD_REQUEST:
    case ActionType.TRANSFER_LEAD_REQUEST:
      state.isSubmitting = true;
      break;

    case ActionType.SHARE_LEAD_SUCCESS:
    case ActionType.TRANSFER_LEAD_SUCCESS:
      state.isSubmitting = false;
      break;

    case ActionType.SHARE_LEAD_FAILURE:
    case ActionType.TRANSFER_LEAD_FAILURE:
      state.isSubmitting = false;
      break;
  }

  return state;
});
