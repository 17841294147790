import { PromiseActionType } from 'dwell/store/types';
import { PetProps } from 'leasing/store/pet/action-types';
import { VehicleProps } from 'leasing/store/vehicle/action-types';
import { GarageProps } from 'leasing/store/garage/action-types';
import { StorageProps } from 'leasing/store/storage/action-types';
import { GateKeyProps } from 'leasing/store/gate_key/action-types';
import { ParkingProps } from 'leasing/store/parking/action-types';
import { OtherRentItemProps } from 'leasing/store/other_rent_item/action-types';
import { ScreeningReportProps } from 'leasing/store/screening_report/action-types';
import { PaymentReceivedProps } from 'leasing/store/payment/action-types';
import { EmployerProps } from 'leasing/store/employer/action-types';
import { OtherIncomeProps } from 'leasing/store/other_income/action-types';
import { EmergencyContactProps } from 'leasing/store/applicant/action-types';
import { DependentProps } from 'leasing/store/dependent/action-types';
import { ActionType as DocumentActionType } from 'leasing/store/document/action-types';
import { LeadLeaseDetails } from 'src/interfaces';

export enum ActionType {
  GET_APPLICATION_DETAILS_REQUEST = 'GET_APPLICATION_DETAILS_REQUEST',
  GET_APPLICATION_DETAILS_SUCCESS = 'GET_APPLICATION_DETAILS_SUCCESS',
  GET_APPLICATION_DETAILS_FAILURE = 'GET_APPLICATION_DETAILS_FAILURE',

  UPDATE_APPLICATION_REQUEST = 'UPDATE_APPLICATION_REQUEST',
  UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS',
  UPDATE_APPLICATION_FAILURE = 'UPDATE_APPLICATION_FAILURE',

  UPDATE_APPLICATION_DECISION_REQUEST = 'UPDATE_APPLICATION_DECISION_REQUEST',
  UPDATE_APPLICATION_DECISION_SUCCESS = 'UPDATE_APPLICATION_DECISION_SUCCESS',
  UPDATE_APPLICATION_DECISION_FAILURE = 'UPDATE_APPLICATION_DECISION_FAILURE',

  SET_APPLICANT = 'SET_APPLICANT',
  SET_RELOAD_STATE = 'SET_RELOAD_STATE',
  TOGGLE_EXPAND_MODE = 'TOGGLE_EXPAND_MODE',
}

export interface UpdateDataType {
  application_status?: string,
  owner?: number,
  lost_reason?: number,
  is_single_field_save?: boolean,
}

export interface UpdateApplicationDecisionType {
  status: string;
  require_guarantor: boolean;
  require_larger_security_deposit: boolean;
  decline_reason?: string;
}

export interface ApplicantActivityProps {
  step: string,
  date: string,
}

export interface LeaseActionStep {
  name: string;
  step_number: number;
  action: string;
  leasing_user: number;
  completed_date: string;
}

export interface ApplicantProps {
  id: number;
  type: string;
  first_name: string;
  last_name: string;
  birth_date: string;
  account_type: string;
  status: string;
  email: string;
  phone_number: string;
  phone_type: string;
  identification_type: string;
  identification_id: string;
  driver_licence_number: string;
  driver_licence_state: string;
  driver_licence_photo_front: string;
  driver_licence_photo_back: string;
  is_currently_employed: boolean;
  employers: EmployerProps[];
  other_incomes: OtherIncomeProps[];
  emergency_contact: EmergencyContactProps;
  dependents: DependentProps[];
  screening: ScreeningReportProps;
  subscription_status: string;
  next_billing_date: string;
  refund_required: boolean;
  country: string;
  first_street_address: string;
  second_street_address: string;
  zip_or_postal_code: string;
  city_name: string;
  state: string;
  current_residence_move_in_date: string;
  is_residence_owned: boolean;
  landlord_name: string;
  landlord_phone: string;
  has_vehicles: boolean;
  has_pets: boolean;
  has_dependents: boolean;
  person_type: string;
  monthly_payment: number;
  background_check: ScreeningReportProps;
  renter_insurance: {
    insurer_name: string;
    insurance_policy_number: string;
    insurance_liability_amount: string;
    insurance_effective_date: string;
  };
  household_status: string;
  social_security_number: string;
  driver_license_number: string;
  sms_opt_in_status: string;
  best_contact_method: string;
  best_contact_time: string;
  main_contact_for_residency: string;
  total_income: number;
  is_application_completed: boolean;
  current_step: string;
  is_main_contact_for_group: boolean;
  is_account_created: boolean;
  activities?: ApplicantActivityProps[];
  income_report_created_date: string;
  income_report_refreshed_date: string;
  rent_payments_reported?: number;
}

export interface FactorProps {
  total_income: number,
  rent_ratio: number,
  income_criteria: string,
  credit_score: number,
  warning_indicators_found: string,
  credit_criteria: string,
  criminal_records: string,
  criminal_criteria: string,
  eviction_records: string,
  eviction_criteria: string,
  is_screening_pending: boolean,
}

export interface ApplicationDetailsProps {
  id?: number,
  floor_plan?: string;
  unit?: string;
  base_rent?: number;
  lease_term?: number;
  type?: string;
  lease_start_date?: string;
  lease_end_date?: string;
  move_in_date?: string;
  move_out_date?: string;
  deposit_agreement_signed_date?: string;
  lease_signed_date?: string;
  building_number?: string;
  security_deposit_type?: string;
  security_deposit?: number;
  deposit_alternative_fee?: number;
  e_premium_payment_methods?: string[];
  pets?: PetProps[];
  vehicles?: VehicleProps[];
  garages?: GarageProps[];
  storages?: StorageProps[];
  parking?: ParkingProps[];
  other_rent_items?: OtherRentItemProps[];
  gate_keys?: GateKeyProps[];
  payment_received?: PaymentReceivedProps;
  application_fee?: number;
  non_refundable_administration_fee?: number;
  non_refundable_fee?: number;
  status?: string;
  status_update_date?: string;
  status_update_by?: string;
  utilities?: string[];
  application_completed_date?: string;
  application_submitted_date?: string;
  screening?: ScreeningReportProps;
  application_steps?: { [step: string]: string };
  ach_account_type?: string;
  ach_depository_name?: string;
  ach_depository_branch?: string;
  ach_depository_city?: string;
  ach_depository_state?: string;
  ach_depository_zip_code?: number;
  ach_routing_number?: string;
  ach_account_number?: string;
  applicants_group: ApplicantProps[];
  resident_group?: ApplicantProps[];
  factors: FactorProps;
  require_guarantor: boolean;
  require_larger_security_deposit: boolean;
  deposit_agreement_addenda: number[];
  addenda: number[];
  is_generated_agreement_documents?: boolean;
  lease_details?: LeadLeaseDetails,
  owner?: number,
  remove_people_names?: string,
  new_people_names?: string,
}

export interface GetApplicationAction {
  type: ActionType.GET_APPLICATION_DETAILS_REQUEST | ActionType.GET_APPLICATION_DETAILS_SUCCESS | ActionType.GET_APPLICATION_DETAILS_FAILURE;

  result: {
    data: ApplicationDetailsProps;
  };
  error: {
    response: { status: string };
  };
}

export interface UpdateApplicationAction {
  type: ActionType.UPDATE_APPLICATION_REQUEST | ActionType.UPDATE_APPLICATION_SUCCESS | ActionType.UPDATE_APPLICATION_FAILURE;

  result: {
    data: ApplicationDetailsProps;
  };
  error: {
    response: { status: string };
  };
}

export interface UpdateApplicationDecisionAction {
  type: ActionType.UPDATE_APPLICATION_DECISION_REQUEST | ActionType.UPDATE_APPLICATION_DECISION_SUCCESS | ActionType.UPDATE_APPLICATION_DECISION_FAILURE;

  result: {
    data: ApplicationDetailsProps;
  };
  error: {
    response: { status: string };
  };
}

export interface SetApplicantAction {
  type: ActionType.SET_APPLICANT;
  applicant: ApplicantProps;
}

export interface SetReloadStateAction {
  type: ActionType.SET_RELOAD_STATE;
  shouldReload: boolean;
}

export interface ToggleExpandModeAction {
  type: ActionType.TOGGLE_EXPAND_MODE;
}

interface GenerateAgreementDocumentAction {
  type: DocumentActionType.GENERATE_AGREEMENT_DOCUMENT_SUCCESS;
}

export type Action =
  | PromiseActionType
  | GetApplicationAction
  | UpdateApplicationAction
  | UpdateApplicationDecisionAction
  | SetApplicantAction
  | SetReloadStateAction
  | GenerateAgreementDocumentAction
  | ToggleExpandModeAction;
