import produce from 'immer';
import { get, unionBy, cloneDeep, set } from 'lodash';
import { FloorPlanProps, UnitProps } from 'src/interfaces';
import { TemplatePlaceholders } from 'dwell/components/settings/templates/_utils';
import { ActionType as LeasingActionType } from 'leasing/store/lease/action-types';
import { ActionType as ScoredCallsActionType } from 'dwell/store/scored_calls/action-types';
import { platformTypes } from 'site/constants';
import { Action, ActionType, PropertyProps } from './action-types';
import { CommonStateType } from '../types';

export interface PropertyState extends CommonStateType {
  isSubmittingLazy: boolean;
  properties: PropertyProps[];
  isPropertiesDataLoaded: boolean,
  menuProperties: PropertyProps[];
  selectedProperties: PropertyProps[];
  selectedProperty: PropertyProps;
  selectedPropertyXname: PropertyProps;
  selectedPropertySettings: PropertyProps;
  menuPropertiesClean: boolean;
  property: PropertyProps;
  isUpdatingStatus: boolean;
  allPropertiesScored: boolean;
  isPropertyDataLoaded: boolean;
  propertiesDataLoaded: boolean;
  propertiesFloorPlansLoaded: boolean;
  totalCount: number;
  propertyLazyTotalCount: number;
  returnedEmpty: boolean;
  allPropertiesLoaded?: boolean;

  isPaginationDataLoaded: boolean;
  isPaginationSubmitting: boolean;
  pagination: PropertyProps[];
  paginationTotalCount: number;
  paginationTotalCountActive: number;
  paginationTotalCountAll: number;
  paginationTotalCountInactive: number;
  customerProperties: PropertyProps[];
  isPollingEmailSync: boolean;
  isTokenObtained: boolean;
  isTokenSetted: boolean;
  shouldReloadProperties: boolean;
}

export const initialState: PropertyState = {
  isSubmitting: false,
  isSubmittingLazy: false,
  errorMessage: null,
  properties: [],
  isPropertiesDataLoaded: false,
  menuProperties: [],
  selectedProperties: [],
  selectedProperty: {},
  selectedPropertyXname: {},
  selectedPropertySettings: {},
  property: {},
  menuPropertiesClean: false,
  isUpdatingStatus: false,
  allPropertiesScored: false,
  isPropertyDataLoaded: false,
  propertiesDataLoaded: false,
  propertiesFloorPlansLoaded: false,
  totalCount: 0,
  propertyLazyTotalCount: 0,
  returnedEmpty: false,

  isPaginationDataLoaded: false,
  isPaginationSubmitting: false,
  pagination: [],
  paginationTotalCount: 0,
  paginationTotalCountActive: 0,
  paginationTotalCountAll: 0,
  paginationTotalCountInactive: 0,
  customerProperties: [],
  isPollingEmailSync: false,
  isTokenObtained: false,
  isTokenSetted: false,
  shouldReloadProperties: false,
};

export const selectActiveProperties = (state: { property: PropertyState }): PropertyProps[] =>
  state.property.properties.filter(p => p.status === 'ACTIVE');
export const selectCurrentProperty = (state: {property: PropertyState}): PropertyProps =>
  (state.property.selectedProperties.length === 1 ? state.property.selectedProperties[0] : state.property.property);
export const selectIsPropertyDataLoaded = (state: {property: PropertyState}): boolean => state.property.isPropertyDataLoaded;
export const selectMenuProperties = (state: {property: PropertyState}): PropertyProps[] => state.property.menuProperties;
export const selectMenuPropertiesClean = (state: {property: PropertyState}): boolean => state.property.menuPropertiesClean;
export const selectProperties = (state: {property: PropertyState}): PropertyProps[] => state.property.properties;
export const selectAllPropertiesLoaded = (state: {property: PropertyState}): boolean => state.property.allPropertiesLoaded;
export const selectSelectedProperty = (state: {property: PropertyState}): PropertyProps => state.property.selectedProperty;
export const selectSelectedPropertyXname = (state: {property: PropertyState}): PropertyProps => state.property.selectedPropertyXname;
export const selectSelectedPropertySettings = (state: {property: PropertyState}): PropertyProps => state.property.selectedPropertySettings;
export const selectSelectedProperties = (state: {property: PropertyState}): PropertyProps[] => state.property.selectedProperties;
export const selectPropertiesFloorPlansLoaded = (state: {property: PropertyState}): boolean => state.property.propertiesFloorPlansLoaded;
export const selectSelectedPropertiesFloorPlans = (state: {property: PropertyState}): FloorPlanProps[] =>
  state.property.selectedProperties
    .map(p => (p.floor_plans || []))
    .reduce((prev, current) => prev.concat(current), [])
    .filter(fp => fp.available > 0);

export const selectPropertyLazyTotalCount = (state: {property: PropertyState}): number => state.property.propertyLazyTotalCount;
export const selectReturnedEmpty = (state: {property: PropertyState}): boolean => state.property.returnedEmpty;

export const selectPagination = (state: {property: PropertyState}): PropertyProps[] => state.property.pagination;
export const selectPaginationTotalCount = (state: {property: PropertyState}): number => state.property.paginationTotalCount;
export const selectPaginationTotalCountAll = (state: {property: PropertyState}): number => state.property.paginationTotalCountAll;
export const selectPaginationTotalCountActive = (state: {property: PropertyState}): number => state.property.paginationTotalCountActive;
export const selectPaginationTotalCountInactive = (state: {property: PropertyState}): number => state.property.paginationTotalCountInactive;
export const selectIsPaginationDataLoaded = (state: {property: PropertyState}): boolean => state.property.isPaginationDataLoaded;

export const selectProperty = (state : { property: PropertyState }) : PropertyProps => state.property.property;
export const selectPropertyUserChoices = (state : { property: PropertyState }) : { [key: number]: string } =>
  (state.property.property.users.reduce((acc, row) => ({ ...acc, [row.id]: `${row.first_name} ${row.last_name}` }), {})) as { [key: number]: string };

export const selectFloorPlans = (state : { property: PropertyState }) : FloorPlanProps[] => state.property.property.floor_plans;
export const selectAllFloorPlans = (state : { property: PropertyState }) : FloorPlanProps[] =>
  state.property.selectedProperties.reduce((prev, curr) => prev.concat(curr.floor_plans || []), []);

export const selectUnits = (state : { property: PropertyState }) : UnitProps[] => state.property.property.units;

export const selectTemplatePlaceholders = (state: { property: PropertyState }) : TemplatePlaceholders => state.property.property.template_placeholders;

export const selectAllSelectedPropertiesHaveIVRSetup = (state: { property: PropertyState }) : boolean => state.property.selectedProperties.every(p => p.has_ivr_setup);
export const selectAllSelectedPropertiesHaveVoiceSetup = (state: { property: PropertyState }) : boolean => state.property.selectedProperties.every(p => p.has_voice_setup);
export const selectSomeSelectedPropertiesHaveVoiceSetup = (state: { property: PropertyState }) : boolean => state.property.selectedProperties.some(p => p.has_voice_setup);
export const selectAllSelectedPropertiesHaveSomeVoiceFeature = (state: { property: PropertyState }) : boolean => state.property.selectedProperties.every(p => p.has_voice_setup || p.has_ivr_setup);
export const selectSelectedPropertyHasVoiceSetup = (state: { property: PropertyState }) : boolean => state.property.selectedProperty.has_voice_setup;
export const selectisPollingEmailSync = (state: { property: PropertyState }) : boolean => state.property.isPollingEmailSync;
export const selectIsTheFirstPropertyCBPlatform = (state: { property: PropertyState }) : boolean => (state.property.selectedProperties.length && state.property.selectedProperties[0].platforms.includes(platformTypes.CREDIT_BUILDER));

export const selectSelectedPropertiesUnitTypes = (state : { property: PropertyState }) : FloorPlanProps[] =>
  state.property.selectedProperties.reduce((prev, curr) => [...prev, ...curr.bedroom_types?.filter(bt => !prev.includes(bt))], []);

export default produce((state: PropertyState = initialState, action: Action): PropertyState => {
  switch (action.type) {
    case ActionType.GET_PROPERTY_REQUEST:
      state.isSubmitting = true;
      state.isPropertiesDataLoaded = get(action, 'payload.paginated', false) && get(action, 'payload.page', 1) > 1;
      break;
    case ActionType.GET_PROPERTY_SUCCESS:
      state.isSubmitting = false;
      state.properties = action.result.data.previous ? [...state.properties, ...action.result.data.results] : action.result.data.results;
      state.isPropertiesDataLoaded = true;
      state.totalCount = action.result.data.count;
      state.shouldReloadProperties = false;
      state.allPropertiesLoaded = state.totalCount === state.properties?.length;
      break;
    case ActionType.GET_PROPERTY_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      state.isPropertiesDataLoaded = false;
      state.shouldReloadProperties = false;
      break;

    case ActionType.GET_PROPERTY_LAZY_REQUEST:
      state.isSubmittingLazy = true;
      break;
    case ActionType.GET_PROPERTY_LAZY_SUCCESS: {
      const { menuProperties } = state;
      const newMenuProperties = action.result.data.count > menuProperties.length ? menuProperties.concat(action.result.data.results) : menuProperties;
      state.menuProperties = newMenuProperties;
      state.propertyLazyTotalCount = action.result.data.count;
      state.menuPropertiesClean = false;
      state.isSubmittingLazy = false;
      state.returnedEmpty = action.result.data.results.length === 0;
      break;
    }
    case ActionType.GET_PROPERTY_LAZY_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmittingLazy = false;
      break;

    case ActionType.GET_PROPERTY_PAGINATION_REQUEST:
      state.isPaginationSubmitting = true;
      state.isPaginationDataLoaded = false;
      break;
    case ActionType.GET_PROPERTY_PAGINATION_SUCCESS:
      state.isPaginationSubmitting = false;
      state.isPaginationDataLoaded = true;
      state.pagination = action.result.data.results;
      state.paginationTotalCount = action.result.data.count;
      state.paginationTotalCountAll = action.result.data.count_all;
      state.paginationTotalCountActive = action.result.data.count_active;
      state.paginationTotalCountInactive = action.result.data.count_inactive;
      break;
    case ActionType.GET_PROPERTY_PAGINATION_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isPaginationSubmitting = false;
      state.isPaginationDataLoaded = false;
      break;

    case ActionType.CLEAR_PROPERTY_LAZY:
      state.menuProperties = [];
      state.menuPropertiesClean = true;
      break;

    case ActionType.GET_CURRENT_PROPERTY_REQUEST:
      state.isSubmitting = true;
      state.isPropertyDataLoaded = false;
      break;
    case ActionType.GET_CURRENT_PROPERTY_SUCCESS:
      state.isSubmitting = false;
      state.isPropertyDataLoaded = true;
      state.property = { ...action.result.data };
      break;
    case ActionType.GET_CURRENT_PROPERTY_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      state.isPropertyDataLoaded = true;
      break;

    case ActionType.GET_PROPERTY_DETAILS_REQUEST:
      state.isPropertyDataLoaded = false;
      break;
    case ActionType.GET_PROPERTY_DETAILS_SUCCESS:
      state.isPropertyDataLoaded = true;
      state.property = { ...action.result.data };
      break;
    case ActionType.GET_PROPERTY_DETAILS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isPropertyDataLoaded = true;
      break;

    case ActionType.SET_SELECTED_PROPERTIES:
      state.propertiesDataLoaded = true;
      state.selectedProperties = action.properties;
      break;

    case ActionType.SET_SELECTED_PROPERTY:
      state.selectedProperty = action.property;
      break;

    case ActionType.SET_SELECTED_PROPERTY_XNAME:
      state.selectedPropertyXname = { external_id: action.property.external_id, id: action.property.id, name: action.property.name };
      break;

    case ActionType.SET_SELECTED_PROPERTY_SETTINGS:
      state.selectedPropertySettings = { external_id: action.property.external_id, id: action.property.id, name: action.property.name, is_by_appointment_only: action.property.is_by_appointment_only };
      break;

    case ActionType.GET_SELECTED_PROPERTIES_REQUEST:
      state.propertiesDataLoaded = false;
      state.propertiesFloorPlansLoaded = false;
      state.isSubmitting = true;
      break;
    case ActionType.GET_SELECTED_PROPERTIES_SUCCESS:
      state.isSubmitting = false;
      state.propertiesDataLoaded = true;
      state.selectedProperties = action.result.data;
      break;
    case ActionType.GET_SELECTED_PROPERTIES_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.GET_SELECTED_PROPERTIES_FLOOR_PLANS_REQUEST:
      state.propertiesFloorPlansLoaded = false;
      state.isSubmitting = true;
      break;
    case ActionType.GET_SELECTED_PROPERTIES_FLOOR_PLANS_SUCCESS:
      state.isSubmitting = false;
      state.propertiesDataLoaded = true;
      state.propertiesFloorPlansLoaded = true;
      state.selectedProperties = action.result.data;
      break;
    case ActionType.GET_SELECTED_PROPERTIES_FLOOR_PLANS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.UPDATE_PROPERTY_SYNC_SETTINGS_REQUEST:
      state.isUpdatingStatus = true;
      break;
    case ActionType.UPDATE_PROPERTY_SYNC_SETTINGS_SUCCESS:
      state.isUpdatingStatus = false;
      state.property = action.result.data;
      break;
    case ActionType.UPDATE_PROPERTY_SYNC_SETTINGS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isUpdatingStatus = false;
      break;

    case ScoredCallsActionType.REQUIRE_RESCORE_CALL_SUCCESS:
      state.property.is_call_rescore_required_today = true;
      break;

    case ActionType.CLEAR_ALL_PROPERTIES_SCORED:
      state.allPropertiesScored = false;
      break;

    case ActionType.SET_SCORED_PROPERTY: {
      const resultProperties = state.properties.map((property) => {
        const result = { ...property };
        if (action.id === property.external_id) {
          result.has_scored_calls_today = true;
        }
        return result;
      });
      state.properties = resultProperties;
      state.allPropertiesScored = !resultProperties.some(item => !item.has_scored_calls_today && item.not_scored_calls_count > 0);
      break;
    }

    case ActionType.UPDATE_PROPERTY_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_PROPERTY_SUCCESS:
      state.isSubmitting = false;
      state.property = action.result.data.id === state.property.id ? action.result.data : state.property;
      state.properties = unionBy([action.result.data], state.properties, 'id');
      break;
    case ActionType.UPDATE_PROPERTY_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.UPDATE_TOUR_OPTIONS_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_TOUR_OPTIONS_SUCCESS:
      state.isSubmitting = false;
      state.property = action.result.data.id === state.property.id ? { ...state.property, ...action.result.data } : state.property;
      state.properties = unionBy([action.result.data], state.properties, 'id');
      break;
    case ActionType.UPDATE_TOUR_OPTIONS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.PROPERTY_CREATE_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.PROPERTY_CREATE_SUCCESS:
      state.isSubmitting = false;
      break;
    case ActionType.PROPERTY_CREATE_FAILURE:
      state.isSubmitting = false;
      break;

    case ActionType.SUBMIT_CALLS_SCORE_STATE_SUCCESS:
      state.isSubmitting = false;
      state.properties = state.properties.map(p => ({ ...p, is_call_scoring_submitted_today: true }));
      break;

    case LeasingActionType.UPDATE_UNIT_RELEASE_HOLD_SUCCESS: {
      const propertyClone = cloneDeep(state.property);
      let unitIndex = propertyClone.units.findIndex(unit => unit.id === action.id);
      set(propertyClone, `units.[${unitIndex}].is_hold`, false);
      if (action.newHoldId) {
        unitIndex = propertyClone.units.findIndex(unit => unit.id === Number(action.newHoldId));
        set(propertyClone, `units.[${unitIndex}].is_hold`, true);
      }
      state.property = propertyClone;
      break;
    }

    case ActionType.RESET_NYLAS_SETTINGS_REQUEST:
      state.isUpdatingStatus = true;
      break;
    case ActionType.RESET_NYLAS_SETTINGS_SUCCESS:
      state.isUpdatingStatus = false;
      state.property = action.result.data;
      break;
    case ActionType.RESET_NYLAS_SETTINGS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isUpdatingStatus = false;
      break;

    case ActionType.GET_CUSTOMER_PROPERTIES_REQUEST:
      state.isLoaded = false;
      state.isSubmitting = true;
      break;
    case ActionType.GET_CUSTOMER_PROPERTIES_SUCCESS:
      state.isLoaded = true;
      state.isSubmitting = false;
      state.customerProperties = action.result.data.results;
      break;
    case ActionType.GET_CUSTOMER_PROPERTIES_FAILURE:
      state.isLoaded = true;
      state.isSubmitting = false;
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.SET_POLLING_EMAIL_SYNC:
      state.isPollingEmailSync = action.status;
      break;

    case ActionType.SET_IS_TOKEN_OBTAINED:
      state.isTokenObtained = action.status;
      break;

    case ActionType.SET_IS_TOKEN_SETTED:
      state.isTokenSetted = action.status;
      break;

    case ActionType.RELOAD_PROPERTIES:
      state.shouldReloadProperties = true;
      break;

    case ActionType.UPDATE_COMMUNITY_DETAILS_AMENITY_SUCCESS: {
      const community_details = action.result.data;
      const propertyIndex = state.selectedProperties.findIndex(property => property.community_details?.id === community_details.id);

      if (propertyIndex !== -1) {
        state.selectedProperties[propertyIndex].community_details = community_details;
      }

      break;
    }
  }

  return state;
});
