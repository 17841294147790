import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action, UserActivityType } from './action-types';

export default {
  pingUserActivity: (userId: number, type: UserActivityType): Action => ({
    CALL_API: {
      types: [
        ActionType.PING_USER_ACTIVITY_REQUEST,
        ActionType.PING_USER_ACTIVITY_SUCCESS,
        ActionType.PING_USER_ACTIVITY_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.USER_ACTIVITY, userId), { type }),
    },
  }),
};
