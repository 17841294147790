import produce from 'immer';
import { Call as TwilioCall, Device as TwilioDevice } from '@twilio/voice-sdk';
import { ActionType as PusherActionType } from 'dwell/store/pusher/action-types';
import { PropertyProps } from 'dwell/store/property/action-types';
import { LeadResidentSearch } from 'dwell/store/pipeline/action-types';
import { Action,
  ActionType,
  VoiceState,
  DesktopPhoneCall,
  AgentPhoneType,
  CallContact,
  InProgressCall,
  Agent,
  PropertyTeam,
  CallWindowBodyComponent,
  OutboundCallSatus,
  TransferReasonType,
  PusherOutboundCallStatus,
  PusherConferenceStarted,
} from './action-types';
import BackgroundAudioProcessor from './audio-processor';

const defaultValues = {
  device: null,
  transfering: false,
  callTransferred: false,
  transferReason: null,
  desktopPhoneCallCompleted: false,
  inProgressCall: null,
  incomingCall: null,
  callFinished: false,
  callTime: 0,
  callConnected: false,
  notes: '',
  bodyComponent: CallWindowBodyComponent.NOTES,
  muted: false,
  outboundCallStatus: OutboundCallSatus.NO_ANSWER,
  timeShowingIncomingCallNotification: 0,
  transferEventId: null,
  audioProcessor: null,
  openDialWindow: false,
  dialedPhoneNumber: '',
  dialedProperty: {},
  dialedContact: {
    id: 0,
    name: '',
    property: 0,
    phone_number: '',
    lease_lead_id: 0,
    secondary_phone_number: '',
  },
  callSid: null,
  conferenceName: null,
  conferenceSid: null,
  onHold: false,
  onHoldLoading: false,
};

const initialState: VoiceState = {
  ...defaultValues,
  callContacts: [],
  allContactsCount: 0,
  downloadedCount: 0,
  loading: false,
  availableAgents: [],
  propertyTeam: null,
  loadingAvailableAgents: false,
  errorMessage: '',
  voiceToken: '',
  showingIncomingCallNotification: false,
  desktopPhoneCallStarted: false, // handling request start
  callingFromDesktopProspectName: '',
  hasMicrophoneAccess: true, // if user has not granted microphone then we show a message to ask for it
  showAskForMicrophoneAccess: true,
};

export const selectCallContacts = (state: { voice: VoiceState }): CallContact[] => state.voice.callContacts;
export const selectAllContactsCount = (state: { voice: VoiceState }): number => state.voice.allContactsCount;
export const selectDownloadedCount = (state: { voice: VoiceState }): number => state.voice.downloadedCount;
export const selectCallContactsLoading = (state: { voice: VoiceState }): boolean => state.voice.loading;
export const selectAvailableAgents = (state: { voice: VoiceState }): Agent[] => state.voice.availableAgents;
export const selectPropertyTeam = (state: { voice: VoiceState }): PropertyTeam => state.voice.propertyTeam;
export const selectAvailableAgentsLoading = (state: { voice: VoiceState }): boolean => state.voice.loadingAvailableAgents;
export const selectTransferingCall = (state: { voice: VoiceState }): boolean => state.voice.transfering;
export const selectCallTransferred = (state: { voice: VoiceState }): boolean => state.voice.callTransferred;
export const selectTransferReason = (state: { voice: VoiceState }): TransferReasonType => state.voice.transferReason;
export const selectTransferEventId = (state: { voice: VoiceState }): number => state.voice.transferEventId;
export const selectDesktopPhoneCallCompleted = (state: { voice: VoiceState }): boolean => state.voice.desktopPhoneCallCompleted;
export const selectDesktopPhoneCallStarted = (state: { voice: VoiceState }): boolean => state.voice.desktopPhoneCallStarted;
export const selectCallingFromDesktopProspectName = (state: { voice: VoiceState }): string => state.voice.callingFromDesktopProspectName;
export const selectIncomingCall = (state: { voice: VoiceState }): TwilioCall => state.voice.incomingCall;
export const selectCallInProgress = (state: { voice: VoiceState }): InProgressCall => state.voice.inProgressCall;
export const selectDialWindow = (state: { voice: VoiceState }): boolean => state.voice.openDialWindow;
export const selectShowingIncomingCallNotification = (state: { voice: VoiceState }): boolean => state.voice.showingIncomingCallNotification;
export const selectTimeShowingIncomingCallNotification = (state: { voice: VoiceState }): number => state.voice.timeShowingIncomingCallNotification;
export const selectDevice = (state: { voice: VoiceState }): TwilioDevice => state.voice.device;
export const selectAudioProcessor = (state: { voice: VoiceState }): BackgroundAudioProcessor => state.voice.audioProcessor;
export const selectCallWindowBody = (state: { voice: VoiceState }): CallWindowBodyComponent => state.voice.bodyComponent;
export const selectCallTime = (state: { voice: VoiceState }): number => state.voice.callTime;
export const selectCallConnected = (state: { voice: VoiceState }): boolean => state.voice.callConnected;
export const selectCallNotes = (state: { voice: VoiceState }): string => state.voice.notes;
export const selectMuted = (state: { voice: VoiceState }): boolean => state.voice.muted;
export const selectCallFinished = (state: { voice: VoiceState }): boolean => state.voice.callFinished;
export const selectOutboundCallStatus = (state: { voice: VoiceState }): OutboundCallSatus => state.voice.outboundCallStatus;
export const selectHasMicrophoneAccess = (state: { voice: VoiceState }): boolean => state.voice.hasMicrophoneAccess;
export const selectShowAskForMicrophoneAccess = (state: { voice: VoiceState }): boolean => state.voice.showAskForMicrophoneAccess;
export const selectDialedPhone = (state: { voice: VoiceState }): string => state.voice.dialedPhoneNumber;
export const selectDialedProperty = (state: { voice: VoiceState }): PropertyProps => state.voice.dialedProperty;
export const selectDialedContact = (state: { voice: VoiceState }): LeadResidentSearch => state.voice.dialedContact;
export const selectConferenceName = (state: { voice: VoiceState }): string => state.voice.conferenceName;
export const selectCallSid = (state: { voice: VoiceState }): number => state.voice.callSid;
export const selectConferenceSid = (state: { voice: VoiceState }): string => state.voice.conferenceSid;
export const selectOnHold = (state: { voice: VoiceState }): boolean => state.voice.onHold;
export const selectOnHoldLoading = (state: { voice: VoiceState }): boolean => state.voice.onHoldLoading;

export default produce((state: VoiceState = initialState, action: Action): VoiceState => {
  switch (action.type) {
    case ActionType.GET_CALL_CONTACTS_REQUEST:
      state.loading = true;
      break;
    case ActionType.GET_CALL_CONTACTS_SUCCESS: {
      const { results, count } = action.result.data;
      const { callContacts } = state;
      const newContacts = results.filter(i => !callContacts.find(j => j.id === i.id));
      state.loading = false;
      state.callContacts = state.callContacts.concat(newContacts);
      state.allContactsCount = count;
      state.downloadedCount += results.length;
      break;
    }
    case ActionType.GET_CALL_CONTACTS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.loading = false;
      break;

    case ActionType.CLEAR_CALL_CONTACTS:
      state.callContacts = [];
      state.downloadedCount = 0;
      state.allContactsCount = 0;
      break;

    case ActionType.GET_AVAILABLE_AGENTS_REQUEST:
      state.loadingAvailableAgents = true;
      break;
    case ActionType.GET_AVAILABLE_AGENTS_SUCCESS:
      state.loadingAvailableAgents = false;
      state.availableAgents = action.result.data.agents;
      state.propertyTeam = action.result.data.property_team;
      break;
    case ActionType.GET_AVAILABLE_AGENTS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.loadingAvailableAgents = false;
      break;

    case ActionType.GET_VOICE_TOKEN_SUCCESS:
      state.voiceToken = action.result.data.voice_token;
      break;
    case ActionType.GET_VOICE_TOKEN_FAILURE:
      state.errorMessage = action.error.response?.status;
      break;

    case ActionType.CREATE_DEVICE: {
      const { voiceToken } = state;
      const device = new TwilioDevice(voiceToken, { closeProtection: true });
      const processor = new BackgroundAudioProcessor();
      device.audio.addProcessor(processor);
      device.register();
      state.voiceToken = '';
      state.device = device;
      state.audioProcessor = processor;
      break;
    }

    case ActionType.CLEAR_DEVICE: {
      const { device } = state;
      if (device) device.destroy();
      state.device = null;
      break;
    }

    case ActionType.REFRESH_DEVICE_TOKEN: {
      const { device, voiceToken } = state;
      if (device && voiceToken) {
        try {
          device.updateToken(voiceToken);
          state.voiceToken = '';
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error updating token', error);
        }
      }
      break;
    }

    case ActionType.SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_SUCCESS:
      state = { ...state, ...defaultValues };
      break;

    case ActionType.TRANSFER_CALL_TO_AGENT_REQUEST:
    case ActionType.TRANSFER_CALL_TO_PROPERTY_TEAM_REQUEST:
      state.transfering = true;
      break;
    case ActionType.TRANSFER_CALL_TO_AGENT_SUCCESS:
    case ActionType.TRANSFER_CALL_TO_PROPERTY_TEAM_SUCCESS:
      state.callTransferred = true;
      state.transfering = false;
      state.transferEventId = action.result.data.transfer_event_id;
      break;
    case ActionType.TRANSFER_CALL_TO_AGENT_FAILURE:
    case ActionType.TRANSFER_CALL_TO_PROPERTY_TEAM_FAILURE:
      state.callTransferred = false;
      state.transfering = false;
      break;

    case ActionType.SET_TRANSFER_REASON:
      state.transferReason = action.reason;
      break;

    case ActionType.CALL_CONTACT_FROM_DESKTOP_PHONE_REQUEST:
    case ActionType.CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_REQUEST:
      state.desktopPhoneCallStarted = true;
      break;
    case ActionType.CALL_CONTACT_FROM_DESKTOP_PHONE_FAILURE:
    case ActionType.CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_FAILURE:
      state.desktopPhoneCallStarted = false;
      state.callingFromDesktopProspectName = '';
      break;

    case ActionType.SET_CALLING_FROM_DESKTOP_PROSPECT_NAME:
      state.callingFromDesktopProspectName = action.prospectName;
      break;

    case ActionType.SET_INCOMING_CALL:
      state.incomingCall = action.call;
      break;

    case ActionType.SHOW_INCOMING_CALL_NOTIFICATION:
      state.showingIncomingCallNotification = true;
      break;

    case ActionType.CLOSE_CALL_NOTIFICATION:
      state.showingIncomingCallNotification = false;
      state.desktopPhoneCallStarted = false;
      state.timeShowingIncomingCallNotification = 0;
      break;

    case ActionType.TIME_SHOWING_INCOMING_CALL_NOTIFICATION:
      state.timeShowingIncomingCallNotification = action.time;
      break;

    case ActionType.COLLAPSE_CALL_WINDOW:
      state.inProgressCall.collapsed = true;
      break;

    case ActionType.SHOW_CALL_WINDOW:
      state.inProgressCall.collapsed = false;
      break;

    case ActionType.SHOW_DIAL_WINDOW:
      state.openDialWindow = true;
      break;

    case ActionType.HIDE_DIAL_WINDOW:
      state.openDialWindow = false;
      break;

    case ActionType.INCOMING_CALL_ACCEPTED:
      state.inProgressCall = action.payload;
      state.callFinished = false;
      state.desktopPhoneCallCompleted = false;
      state.callTransferred = false;
      state.transfering = false;
      break;

    case ActionType.CLEAR_IN_PROGRESS_CALL: {
      // We have to destroy and recreate device due to transfer call process.
      // After agent A transfers call to agent B, agent A device keeps with
      // busy status even if we invoke "device.disconnectAll()".
      let newDevice = null;
      let newProcessor = null;
      const { device } = state;
      if (device) {
        const { token } = device;
        device.destroy();
        newDevice = new TwilioDevice(token, { closeProtection: true });
        newProcessor = new BackgroundAudioProcessor();
        newDevice.audio.addProcessor(newProcessor);
        newDevice.register();
      }
      state = { ...state, ...defaultValues, device: newDevice, audioProcessor: newProcessor };
      break;
    }

    // TODO: Remove or refactor after validation
    case ActionType.CREATE_DEVICE_WITHOUT_NOISE_CANCELLING: {
      const { voiceToken } = state;
      const device = new TwilioDevice(voiceToken, { closeProtection: true });
      device.register();
      state.voiceToken = '';
      state.device = device;
      break;
    }

    // TODO: Remove or refactor after validation
    case ActionType.CLEAR_IN_PROGRESS_CALL_WITHOUT_NOISE_CANCELLING: {
      let newDevice = null;
      const { device } = state;
      if (device) {
        const { token } = device;
        device.destroy();
        newDevice = new TwilioDevice(token, { closeProtection: true });
        newDevice.register();
      }
      state = { ...state, ...defaultValues, device: newDevice, audioProcessor: null };
      break;
    }

    case ActionType.CALL_CONTACT_FROM_BROWSER_PHONE:
      state.inProgressCall = action.payload;
      state.callTransferred = false;
      state.transfering = false;
      state.outboundCallStatus = OutboundCallSatus.NO_ANSWER;
      break;

    case ActionType.UPDATE_IN_PROGRESS_CALL:
      state.inProgressCall = { ...state.inProgressCall, ...action.leadInfo };
      break;

    case ActionType.SET_CALL_WINDOW_BODY_COMPONENT:
      state.bodyComponent = action.body;
      break;

    case ActionType.TOGGLE_MUTE:
      state.muted = action.muted;
      break;

    case ActionType.SET_CALL_FINISHED:
      state.callFinished = true;
      break;

    case ActionType.SET_CALL_TIME:
      state.callTime = action.time;
      break;

    case ActionType.SET_CALL_NOTES:
      state.notes = action.notes;
      break;

    case ActionType.SET_OUTBOUND_CALL_STATUS:
      state.outboundCallStatus = action.status;
      break;

    case ActionType.SET_HAS_MICROPHONE_ACCESS:
      state.hasMicrophoneAccess = action.enabled;
      break;

    case ActionType.SET_SHOW_ASK_FOR_MICROPHONE_ACCESS:
      state.showAskForMicrophoneAccess = action.show;
      break;

    case ActionType.SET_DIALED_PHONE_NUMBER:
      state.dialedPhoneNumber = action.phone;
      break;

    case ActionType.SET_DIALED_PROPERTY:
      state.dialedProperty = action.property;
      break;

    case ActionType.SET_DIALED_CONTACT:
      state.dialedContact = action.contact;
      break;

    case PusherActionType.PUSHER_DESKTOP_PHONE_CALL_ANSWERED: {
      const payload = action.row as DesktopPhoneCall;
      state.callTransferred = false;
      state.transfering = false;
      state.desktopPhoneCallStarted = false;
      state.desktopPhoneCallCompleted = false;
      state.callConnected = true;
      state.conferenceSid = payload.conference_sid;

      state.inProgressCall = {
        direction: payload.direction,
        collapsed: false,
        prospectName: payload.caller_name || state.inProgressCall?.prospectName || '',
        prospectType: payload.caller_type,
        leadId: payload.lead_id,
        leaseId: payload.lease_id,
        applicationId: payload.application_id,
        leasingUserId: payload.leasing_user_id,
        propertyId: payload.property_id,
        propertyExternalId: payload.property_external_id,
        propertyName: payload.property_name,
        sourceName: payload.source_name,
        topic: payload.topic,
        phoneType: AgentPhoneType.DESKTOP_PHONE,
        callSid: payload.original_call_sid,
        participantLabel: payload?.participant_label,
        call: null,
        conferenceName: null,
        callId: null,
      };
      break;
    }

    case PusherActionType.PUSHER_DESKTOP_PHONE_CALL_COMPLETED:
      state.desktopPhoneCallCompleted = true;
      state.desktopPhoneCallStarted = false;
      break;

    case PusherActionType.PUSHER_BROWSER_PHONE_CALL_ANSWERED:
      state.outboundCallStatus = OutboundCallSatus.ANSWERED;
      state.callConnected = true;
      break;

    case PusherActionType.PUSHER_OUTBOUND_CALL_STATUS: {
      const { status } = action.row as PusherOutboundCallStatus;
      state.callConnected = true;
      state.outboundCallStatus = OutboundCallSatus[status];
      break;
    }

    case PusherActionType.PUSHER_CONFERENCE_STARTED: {
      const {
        conference_name: conferenceName,
        call_sid: callSid,
        conference_sid: conferenceSid,
      } = action.row as PusherConferenceStarted;

      state.conferenceName = conferenceName;
      state.callSid = callSid;
      state.conferenceSid = conferenceSid;
      break;
    }
    case ActionType.ON_HOLD_REQUEST:
      state.onHoldLoading = true;
      break;
    case ActionType.ON_HOLD_FAILURE:
    case ActionType.ON_HOLD_SUCCESS:
      state.onHoldLoading = false;
      state.onHold = action.result.data.on_hold;

      // If we remove the customer of on hold, and the agent is muted we remove the mute.
      if (!state.onHold && state.muted) {
        state.muted = false;
        state.inProgressCall.call?.mute(false);
      }
      break;
  }
  return state;
});
