import React, { FC, useState, useMemo } from 'react';
import { ButtonDropdown, DropdownItem } from 'reactstrap';
import cn from 'classnames';
import { alphabet } from 'dwell/constants';
import { LetterLabel, FormSearchDiv, CustomSearchIcon, DropdownLink, CustomFormSearch, CustomDropdownMenu } from './styles';

interface DropdownProps {
  selectedValue?: string | number | null,
  toggleLabel: string,
  onChange: (name: string | number, value: string | number) => void,
  className?: string,
  choices?: { name: string | number, value: string | number }[],
  needFocus?: boolean,
  invalid?: boolean,
  disabled?: boolean,
}

const Dropdown: FC<DropdownProps> = ({ selectedValue, toggleLabel, className, choices, onChange, invalid, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [focus, setFocus] = useState(false);

  const filteredChoices = useMemo(() => {
    if (!choices.length) return choices;
    if (!keyword) return choices;

    return choices.filter(choice => choice.name.toString().toUpperCase().includes(keyword.toUpperCase()));
  }, [choices, keyword]);

  const orderedChoices = useMemo(() => {
    if (!filteredChoices.length) return {};

    const temp = {} as { [key: string]: { name: string | number, value: string | number }[] };
    alphabet.forEach((letter) => {
      const filteredOrderedChoices = filteredChoices.filter(choice => choice.name.toString().toUpperCase().startsWith(letter));
      if (filteredOrderedChoices.length) temp[letter] = filteredOrderedChoices;
    });
    return temp;
  }, [filteredChoices]);

  const onSearch = ({ target: { value } }) => {
    setKeyword(value);
  };

  const handleChange = (name: string | number, value: string | number) => {
    onChange(name, value);
    setKeyword('');
  };

  return (
    <ButtonDropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} invalid={invalid} className={className} disabled={disabled}>
      <DropdownLink caret disabled={disabled} className="bg-white">
        {toggleLabel}
      </DropdownLink>
      <CustomDropdownMenu>
        <FormSearchDiv className={cn('position-relative mr-10', { focus })} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
          <CustomSearchIcon />
          <CustomFormSearch
            name="search"
            value={keyword ?? ''}
            onChange={onSearch}
            placeholder="Search"
          />
        </FormSearchDiv>
        {
          Object.keys(orderedChoices).length ? Object.keys(orderedChoices).map((letter, index) => (
            <div key={index}>
              <LetterLabel header>{letter}</LetterLabel>
              {
                orderedChoices[letter].map((choice, choice_index) => (
                  <DropdownItem
                    key={choice_index}
                    onClick={() => handleChange(choice.name, choice.value)}
                    className={choice.value === selectedValue ? 'selected' : ''}
                  >
                    {choice.name}
                  </DropdownItem>
                ))
              }
            </div>
          )) : <DropdownItem disabled>No results found</DropdownItem>
        }
      </CustomDropdownMenu>
    </ButtonDropdown>
  );
};

export default Dropdown;
