import React, { FC, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { isEmpty } from 'lodash';
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import voiceActions from 'dwell/store/voice/action-creator';
import {
  selectAvailableAgents,
  selectAvailableAgentsLoading,
  selectPropertyTeam,
  selectTransferingCall,
  selectCallTransferred,
  selectCallInProgress,
  selectTransferReason,
} from 'dwell/store/voice/reducers';
import { TRANSFER_REASONS, Agent as IAgent } from 'dwell/store/voice/action-types';
import { selectCurrentUser, selectUserRole } from 'dwell/store/user/reducers';
import { ChatSearch as AgentsFilter, EmptyContent } from 'dwell/views/chat/single_chat/contact/styles';
import { getCallParams, getOutboundCallParams, isIonAgent } from 'dwell/views/calls/communications/voice_centre/utils';
import Agent from './agent';
import {
  AvailableAgentsContainer,
  TransferButton,
  TransferLoading,
  TransferSuccess,
  TransferReasonDropdown,
} from './styles';
import CallBodyItem from '../call_body_item';
import { CallBodyItemBaseProps } from '..';

interface TransferCallProps extends CallBodyItemBaseProps {
  prospectName: string,
  hangingUp: boolean,
  onTransferClick: (agent: IAgent) => void,
}

const TransferCall: FC<TransferCallProps> = ({ prospectName, hide, hangingUp, onClose, onTransferClick }) => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [keyword, setKeyword] = useState('');

  const dispatch = useDispatch();
  const { getAvailableAgents, setTransferReason } = voiceActions;

  const agents = useSelector(selectAvailableAgents);
  const propertyTeam = useSelector(selectPropertyTeam);
  const inProgressCall = useSelector(selectCallInProgress);
  const transfering = useSelector(selectTransferingCall);
  const callTransferred = useSelector(selectCallTransferred);
  const transferReason = useSelector(selectTransferReason);
  const loadingAgents = useSelector(selectAvailableAgentsLoading);
  const currentUser = useSelector(selectCurrentUser);
  const userRole = useSelector(selectUserRole);

  const { propertyId } = inProgressCall;

  useEffect(() => {
    let callParams;
    if (inProgressCall?.direction === 'inbound') callParams = getCallParams(inProgressCall?.call);
    else callParams = getOutboundCallParams(inProgressCall?.call);
    dispatch(getAvailableAgents(propertyId, inProgressCall.topic, inProgressCall.direction.toUpperCase(), callParams?.to));
  }, []);

  const transferCall = () => {
    onTransferClick(selectedAgent);
  };

  const emptyContent = (
    <EmptyContent>
      <i className="ri-phone-fill" />
      <h5><span>{keyword ? 'No Agents Found' : 'There are no available agents'}</span></h5>
      <p>{keyword ? 'Try adjusting your search to find what you’re looking for.' : 'There are no available agents'}</p>
    </EmptyContent>
  );

  const filteredAgents = useMemo(() => {
    let results = (agents || []).filter(agent => agent.id !== currentUser.id);
    // On-Site agents won't see the Property Team, because they are part of the Property Team (unless they are a property manager that has browser phone)
    if (isIonAgent(userRole, currentUser?.browser_phone) && propertyTeam && inProgressCall.phoneType === 'browser') {
      results = [propertyTeam, ...results];
    }
    if (keyword) results = results.filter(agent => agent.name.toLowerCase().includes(keyword.toLocaleLowerCase()));
    return results;
  }, [keyword, currentUser.id, agents]);

  return (
    <CallBodyItem itemTitle="Transfer Call" hide={hide} onClose={callTransferred ? null : onClose}>
      {!transfering && !callTransferred && (<>
        <AgentsFilter className="form-search">
          <FontAwesomeIcon icon={faSearch} />
          <input
            type="text"
            className="form-control"
            style={{ marginLeft: 8 }}
            placeholder="Search team members"
            value={keyword}
            onChange={({ target: { value } }) => setKeyword(value)}
          />
        </AgentsFilter>
        <AvailableAgentsContainer>
          {!loadingAgents && filteredAgents.length ? filteredAgents.map((agent, idx) => (
            <Agent
              key={idx}
              agent={agent}
              selected={selectedAgent && selectedAgent.name === agent.name}
              onAgentSelected={agentSelected => setSelectedAgent(agentSelected)}
            />
          )) : emptyContent}
        </AvailableAgentsContainer>
        <TransferButton
          onClick={transferCall}
          disabled={isEmpty(selectedAgent) || isEmpty(filteredAgents) || !filteredAgents.find(a => a.id === selectedAgent.id) || hangingUp}
        >
          Transfer Now
        </TransferButton>
      </>)}

      {transfering && !callTransferred &&
        <TransferLoading>
          <i className="ri-refresh-line lh-1" />
          <p>Transferring call...</p>
        </TransferLoading>}

      {!transfering && callTransferred &&
        <TransferSuccess>
          <i className="ri-checkbox-circle-fill" />
          <p>{`${prospectName} has been transferred to ${selectedAgent.name}`}</p>
          <TransferReasonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
            <DropdownToggle>{transferReason ? transferReason.label : 'Select Transfer Reason'}</DropdownToggle>
            <DropdownMenu>
              {TRANSFER_REASONS.map((reason, index) => (
                <React.Fragment key={index}>
                  <DropdownItem onClick={() => dispatch(setTransferReason(reason))}>
                    {reason.label}
                  </DropdownItem>
                </React.Fragment>))}
            </DropdownMenu>
          </TransferReasonDropdown>
        </TransferSuccess>
      }
    </CallBodyItem>
  );
};

export default TransferCall;
