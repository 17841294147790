import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import actions from 'dwell/actions';
import applicationActions from 'application/store/actions';
import residentActions from 'resident/store/actions';
import leaseActions from 'leasing/store/actions';
import { selectSelectedProperties, selectProperty, selectProperties } from 'dwell/store/property/reducers';
import { selectCurrentUser } from 'dwell/store/user/reducers';
import { selectLeaseDetails } from 'resident/store/lease/reducers';
import { selectApplication } from 'application/store/application/reducers';
import { paths } from 'dwell/constants';
import { getPropertyId } from 'src/utils';
import 'src/scss/pages/_lead_details.scss';
import { getLeaseId } from './utils';

const Loader: FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const selectedProperties = useSelector(selectSelectedProperties);
  const properties = useSelector(selectProperties);
  const propertyDetails = useSelector(selectProperty);
  const currentUser = useSelector(selectCurrentUser);
  const lease = useSelector(selectLeaseDetails);
  const { shouldReload } = useSelector(selectApplication);
  const { getPropertyDetails, setSelectedProperty } = actions.property;
  const { updateUserAccessedProperties } = actions.user;
  const { getLeaseDetails, getRenewLeaseDetails } = residentActions.leaseDetails;
  const { getObjectTasks } = actions.task;
  const { resetLease } = leaseActions.lease;
  const { setReloadState } = applicationActions.application;
  const { getAddendaDocumentsChoices } = leaseActions.addenda;
  const { getNotices } = actions.notice;
  const { getRenewalManagers } = actions.renewalOffer;
  const { getOtherRentItems } = actions.settings;

  const load = () => {
    const leaseId = getLeaseId(pathname);
    if (Number.isNaN(leaseId)) return;
    if (!lease.id || (lease.id !== leaseId) || shouldReload) {
      dispatch(getLeaseDetails(leaseId)).then((resp) => {
        if (resp.result.data.renew_lease) {
          dispatch(getRenewLeaseDetails(resp.result.data.renew_lease));
        }
      }).catch(() => {
        push(paths.client.PIPELINE);
      });
      dispatch(getObjectTasks(leaseId, 'lease'));
      dispatch(resetLease());
      dispatch(getAddendaDocumentsChoices());
      dispatch(getNotices(leaseId));
      dispatch(getRenewalManagers(leaseId));
      dispatch(getOtherRentItems());
    }
  };

  useEffect(() => {
    if (propertyDetails.timezone) {
      localStorage.setItem('timezone', propertyDetails.timezone);
    }
  }, [propertyDetails]);

  useEffect(() => {
    const propertyId = getPropertyId();
    if (pathname.split('/')[1] === propertyId) {
      if (!isEmpty(selectedProperties)) {
        if (selectedProperties.map(p => p.external_id).includes(propertyId)) {
          const selectedProperty = selectedProperties.find(p => p.external_id === propertyId);
          dispatch(setSelectedProperty(selectedProperty));
          load();
          if (propertyDetails.external_id === propertyId) return;
          dispatch(getPropertyDetails(selectedProperty.id));
        } else if (properties.length) {
          const nonSelected = properties.find(p => p.external_id === propertyId);
          if (nonSelected) {
            dispatch(updateUserAccessedProperties(currentUser.id, { accessed_properties: [...selectedProperties, nonSelected] }));
          } else {
            push('/');
          }
        }
      }
    }
  }, [pathname, selectedProperties, properties]);

  useEffect(() => {
    if (shouldReload) {
      load();
      dispatch(setReloadState(false));
    }
  }, [shouldReload]);

  return <></>;
};

export default Loader;
