import axios from 'axios';
import { get } from 'lodash';
import { Dispatch } from 'redux';
import moment from 'moment';

import {
  actions,
  LAST_ACTIVITY_DATE,
  NOTIFICATION_NOT_REQUIRED_REQUESTS,
} from 'dwell/constants';
import { toastOptions, toastError } from 'site/constants';
import authentication from 'dwell/store/authentication/action-creators';
import { toast, ToastOptions } from 'react-toastify';
import { buildHeaders } from 'src/client';

const sendToastNotification = (toastType: string, requestType: string, errorMessage?: string): void => {
  if (![
    'UPDATE', 'CREATE', 'DELETE', 'SEND', 'ARCHIVE', 'UNARCHIVE', 'MERGE', 'SHARE', 'TRANSFER', 'SAVE', 'COMPLETE', 'RESEND', 'REMOVE', 'REQUEST', 'RUN', 'ADD', 'PUBLISH',
  ].includes(requestType.split('_')[0]) || requestType.split('_')[1] === 'NOTIFICATION') return;
  if ([
    'CHANGE_EVALUATION_REPORT_SUCCESS',
    'CREATE_IDLE_EVENT_SUCCESS',
    'UPDATE_CHAT_REPORT_EVALUATION_BY_ID_SUCCESS',
    'UPDATE_CHAT_REPORT_MESSAGE_BY_ID_SUCCESS',
    'UPDATE_EMPLOYERS_SUCCESS',
    'UPDATE_IDLE_EVENT_SUCCESS',
    'PING_USER_ACTIVITY_SUCCESS',
  ].includes(requestType)) return;
  if (toastType === 'success') {
    const substring = requestType.substr(0, requestType.lastIndexOf('_')).toLowerCase();
    const action = substring.split('_')[0];
    let model = substring.substr(action.length + 1, substring.length - action.length - 1).replace(/_/g, ' ');
    if (model === 'leads filter') model = 'filter';
    let message = `${model.charAt(0).toUpperCase()}${model.slice(1)} ${action}`;
    switch (requestType.split('_')[0]) {
      case 'UPDATE': {
        if (model === 'columns') message = 'Pipeline table updated';
        else message = message.replace('update', 'updated');
        break;
      }
      case 'CREATE': {
        if (model === 'columns') message = 'Pipeline table updated';
        else {
          message = message.replace('create', 'created');
          message = `New ${message.toLowerCase()}`;
        }
        break;
      }
      case 'DELETE': {
        message = message.replace('delete', 'deleted').replace('!', '');
        break;
      }
      case 'SEND': {
        message = message.replace('send', 'sent');
        break;
      }
      case 'RESEND': {
        message = message.replace('resend', 'resent');
        break;
      }
      case 'ARCHIVE': {
        if (model === 'email') message = 'Message archived';
        else message = message.replace('archive', 'archived');
        break;
      }
      case 'UNARCHIVE': {
        message = message.replace('unarchive', 'unarchived');
        break;
      }
      case 'MERGE': {
        message = message.replace('merge', 'merged');
        break;
      }
      case 'SHARE': {
        message = message.replace('share', 'shared');
        break;
      }
      case 'TRANSFER': {
        message = message.replace('transfer', 'transferred');
        break;
      }
      case 'SAVE': {
        message = message.replace('save', 'saved');
        break;
      }
      case 'COMPLETE': {
        message = message.replace('complete', 'completed');
        break;
      }
      case 'REQUEST': {
        message = message.replace('request', 'requested');
        break;
      }
      case 'PUBLISH': {
        message = message.replace('publish', 'published');
        break;
      }
      case 'ADD': {
        message = message.replace('add', 'added');
        break;
      }
      default: break;
    }
    toast.success(message, toastOptions as ToastOptions);
  }
  if (toastType === 'failure') {
    toast.error(errorMessage, toastError as ToastOptions);
  }
};

const getMethod = (promise: Promise<unknown>): string => {
  const source = promise.toString();
  const match = source.match(/\.([a-z]{3,6})\(/);
  return get(match, '[1]', '').toUpperCase();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middleware = (action: any, dispatch: any, getState: any, next: Dispatch<void>) => {
  if (typeof action === 'function') {
    return action(dispatch, getState);
  }
  const callAPIAction = action[actions.CALL_API];
  if (typeof callAPIAction === 'undefined' || !callAPIAction.promise) {
    return next(action);
  }

  const { promise, types, successCB, failureCB, config, ...rest } = callAPIAction;
  const method = getMethod(promise);
  const [REQUEST, SUCCESS, FAILURE] = types;
  const headers = buildHeaders(REQUEST, getState(), config);

  next({ ...rest, method, type: REQUEST });
  localStorage.setItem(LAST_ACTIVITY_DATE, moment().toDate().toString());

  return promise(axios.create({ headers, baseURL: window.crmApp.config.crmHost }), dispatch)
    .then(
      (result) => {
        if (successCB) successCB(result);
        if (!NOTIFICATION_NOT_REQUIRED_REQUESTS.includes(REQUEST) && !successCB) sendToastNotification('success', SUCCESS);
        setTimeout(() => Promise.resolve(), 500);
        return next({ ...rest, method, result, type: SUCCESS });
      },
      (error) => {
        if (!NOTIFICATION_NOT_REQUIRED_REQUESTS.includes(REQUEST) && error.response && error.response.status !== 401 && !axios.isCancel(error)) {
          const errorMessage = typeof error.response.data === 'string'
            ? error.message
            : Object.values(error.response.data).reduce<string[]>((acc: string[], val: string[]) => acc.concat(val), []).pop();
          sendToastNotification('failure', FAILURE, errorMessage);
        }
        if (failureCB) failureCB(error.response.data);
        if (error.message.includes('Operation canceled due to new request')) {
          Object.assign(error, { response: { status: 429 } });
          next({ ...rest, error, method, type: FAILURE });
        }
        next({ ...rest, method, error, type: FAILURE });
        const authError = error.response && error.response.status === 401;
        if (authError && REQUEST !== actions.LOGIN_REQUEST) {
          return dispatch(authentication.sessionTimeout());
        }
        return authError ? null : Promise.reject(error);
      },
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const promiseMiddleware = (action: any, dispatch: any, next: Dispatch<void>) => action
  .then((resolved) => {
    const callAPIAction = resolved[actions.CALL_API];
    if (typeof callAPIAction === 'undefined') {
      return next(action);
    }

    const { types, result, error, successCB, failureCB, ...rest } = callAPIAction;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [REQUEST, SUCCESS, FAILURE] = types;

    localStorage.setItem(LAST_ACTIVITY_DATE, moment().toDate().toString());

    if (result) {
      if (successCB) successCB(result);
      if (!NOTIFICATION_NOT_REQUIRED_REQUESTS.includes(REQUEST) && !successCB) sendToastNotification('success', SUCCESS);
      return next({ ...rest, type: SUCCESS, result });
    }

    if (!NOTIFICATION_NOT_REQUIRED_REQUESTS.includes(REQUEST) && error.response && error.response.status !== 401 && !axios.isCancel(error)) {
      const errorMessage = typeof error.response.data === 'string'
        ? error.message
        : Object.values(error.response.data).reduce<string[]>((acc: string[], val: string[]) => acc.concat(val), []).pop();
      sendToastNotification('failure', FAILURE, errorMessage);
    }
    if (failureCB) failureCB(error.response.data);
    if (error.message.includes('Operation canceled due to new request')) {
      Object.assign(error, { response: { status: 429 } });
      next({ ...rest, error, type: FAILURE });
    }
    next({ ...rest, error, type: FAILURE });
    const authError = error.response && error.response.status === 401;
    if (authError && REQUEST !== actions.LOGIN_REQUEST) {
      return dispatch(authentication.sessionTimeout());
    }
    return authError ? null : Promise.reject(error);
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export default () => ({ dispatch, getState }: any) => (next: Dispatch<void>) => (action: any): any => {
  if (action && (typeof action.then === 'function')) {
    return promiseMiddleware(action, dispatch, next);
  }
  return middleware(action, dispatch, getState, next);
};
