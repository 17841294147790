import axios, { AxiosRequestConfig, Method, CancelTokenSource } from 'axios';
import { get } from 'lodash';

import {
  actions,
  LOGGED_ACCOUNT,
  RECENT_PROPERTY_HYPHENS,
} from 'dwell/constants';
import { ActionType as TaskActionType } from 'dwell/store/task/action-types';
import { PromiseActionType } from 'src/interfaces';
import { getCookie, getPropertyId } from 'src/utils';
import { SITE_TYPES } from 'corporate/views/edit_site/edit_panel/component/constants';

export const requestException = [actions.LOGIN_REQUEST];

export const chatRequests = [
  actions.SMS_CONTACTS_REQUEST,
  actions.GET_PROSPECTS_REQUEST,
  actions.GET_LEAD_NAMES_REQUEST,
];

export const leadsRequest = [
  actions.GET_LEAD_FOR_PROSPECT_REQUEST,
  actions.GET_CONVERSATION_BY_ID_REQUEST,
];

const taskRequests = [
  TaskActionType.GET_TASKS_REQUEST,
  TaskActionType.GET_OBJECT_TASKS_REQUEST,
  TaskActionType.CREATE_TASK_REQUEST,
  TaskActionType.BULK_SAVE_TASKS_REQUEST,
  TaskActionType.UPDATE_TASK_REQUEST,
  TaskActionType.DELETE_TASK_REQUEST,
] as string[];

const multiPropertyRequests = [
  'GET_FOCUS_BAR_LEADS_REQUEST',
];

const singlePropertyPages = ['apartment-availability', 'automation'];

interface RequestConfig extends AxiosRequestConfig {
  types: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

const client = axios.create({ baseURL: get(window, 'crmApp.config.crmHost', '/') });

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const buildHeaders = (requestType: string, state: any, config: any = {}): any => {
  const token = JSON.parse(localStorage.getItem(LOGGED_ACCOUNT)) || {};
  const csrftoken = getCookie('csrftoken');
  const headers = token.access ? { Authorization: `Bearer ${token.access}` } : {};
  const propertyId = getPropertyId();
  const secondParameterInUrl = getPropertyId('', 2);
  const {
    property: { selectedPropertyXname = {}, selectedPropertySettings = {}, selectedProperties = [] },
    corporateCustomer: { itemSelected = {} },
  } = state;

  const bannedPages = ['settings'];

  let xName = '';
  let xSettingsName = '';
  let xHyphens = '';
  let xSelectedProperties = '';
  const recentPropertyId = localStorage.getItem(RECENT_PROPERTY_HYPHENS);
  const forceSingleProperty = !chatRequests.includes(requestType) && !leadsRequest.includes(requestType) && !multiPropertyRequests.includes(requestType) && !bannedPages.includes(secondParameterInUrl);
  const isSinglePropertyPage = recentPropertyId !== 'multiproperty' && (secondParameterInUrl.includes('site') || singlePropertyPages.includes(secondParameterInUrl));

  if (config.property_external_id) {
    xName = config.property_external_id;
  } else if (propertyId === 'compete' || (propertyId && recentPropertyId && propertyId !== recentPropertyId && isSinglePropertyPage)) {
    xName = recentPropertyId;
  } else if (selectedPropertyXname.external_id && forceSingleProperty) {
    xName = selectedPropertyXname.external_id;
  } else if (propertyId === 'properties' || multiPropertyRequests.includes(requestType) || bannedPages.includes(secondParameterInUrl)) {
    xName = 'multiproperty';
    if (secondParameterInUrl === 'settings') xSettingsName = selectedPropertySettings.external_id;
  } else if (propertyId === 'credit-builder') {
    xName = recentPropertyId;
  } else {
    xName = propertyId || recentPropertyId;
  }

  if (itemSelected && window.location.pathname === '/corporate' && itemSelected.siteType !== SITE_TYPES.CORPORATE) {
    xHyphens = itemSelected.site.external_id;
  }

  if (selectedProperties.length && taskRequests.includes(requestType)) {
    xSelectedProperties = selectedProperties.map((p: { external_id: string }) => p.external_id).join(',');
  }

  if (headers) {
    headers['X-CSRFToken'] = csrftoken;
    if (xName && !requestException.includes(requestType)) headers['X-Name'] = xName;
    if (xSettingsName && !requestException.includes(requestType)) headers['X-Settings'] = xSettingsName;
    if (xHyphens && !requestException.includes(requestType)) headers['X-Hyphens'] = xHyphens;
    // This header is used to clean up the task cache when a user switches properties
    if (xSelectedProperties && !requestException.includes(requestType)) headers['X-SelectedProperties'] = xSelectedProperties;
  }

  return headers;
};

class Client {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  private static cancelTokens: Record<string, CancelTokenSource> = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  static dispatch(method: Method, url: string, config: RequestConfig, data?: any): Promise<PromiseActionType> {
    const source = axios.CancelToken.source();
    this.cancelTokens[url] = source;

    return import('src/store').then(({ default: store }) => {
      const { types, ...rest } = config;
      store.dispatch({ ...rest, method, type: types[0] });

      return client({
        ...rest,
        data,
        headers: buildHeaders(types[0], store.getState()),
        method,
        url,
        cancelToken: source.token,
      })
        .then((result) => {
          delete this.cancelTokens[url];
          return { CALL_API: { ...rest, method, types, result } };
        })
        .catch((error) => {
          delete this.cancelTokens[url];
          const isCanceled = axios.isCancel(error);
          return { CALL_API: { ...rest, method, types, error, isCanceled } };
        });
    });
  }

  static cancelRequest(url: string): void {
    if (this.cancelTokens[url]) {
      this.cancelTokens[url].cancel('Operation canceled by the user.');
      delete this.cancelTokens[url];
    }
  }

  static delete(url: string, config: RequestConfig): Promise<PromiseActionType> {
    return Client.dispatch('DELETE', url, config);
  }

  static get(url: string, config: RequestConfig): Promise<PromiseActionType> {
    return Client.dispatch('GET', url, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  static patch(url: string, data: any, config: RequestConfig): Promise<PromiseActionType> {
    return Client.dispatch('PATCH', url, config, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  static post(url: string, data: any, config: RequestConfig): Promise<PromiseActionType> {
    return Client.dispatch('POST', url, config, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  static put(url: string, data: any, config: RequestConfig): Promise<PromiseActionType> {
    return Client.dispatch('PUT', url, config, data);
  }
}

export default Client;
