import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action, RequestProps, FilterType, ColumnType } from './action-types';

export default {
  getProspects: (param: RequestProps, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PROSPECTS_REQUEST,
        ActionType.GET_PROSPECTS_SUCCESS,
        ActionType.GET_PROSPECTS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PIPELINE.PROSPECTS, { params: param, cancelToken: token }),
    },
  }),

  getApplications: (param: RequestProps, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_APPLICATIONS_REQUEST,
        ActionType.GET_APPLICATIONS_SUCCESS,
        ActionType.GET_APPLICATIONS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PIPELINE.APPLICATIONS, { params: param, cancelToken: token }),
    },
  }),

  getLeases: (param: RequestProps, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEASES_REQUEST,
        ActionType.GET_LEASES_SUCCESS,
        ActionType.GET_LEASES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PIPELINE.LEASES, { params: param, cancelToken: token }),
    },
  }),

  allSearch: (param: RequestProps, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.ALL_SEARCH_REQUEST,
        ActionType.ALL_SEARCH_SUCCESS,
        ActionType.ALL_SEARCH_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PIPELINE.ALL_SEARCH, { params: param, cancelToken: token }),
    },
  }),

  getFilters: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_FILTERS_REQUEST,
        ActionType.GET_FILTERS_SUCCESS,
        ActionType.GET_FILTERS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PIPELINE.FILTERS),
    },
  }),

  createFilter: (data: FilterType): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_FILTER_REQUEST,
        ActionType.CREATE_FILTER_SUCCESS,
        ActionType.CREATE_FILTER_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.PIPELINE.FILTERS, data),
    },
  }),

  updateFilter: (id: number, data: FilterType): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_FILTER_REQUEST,
        ActionType.UPDATE_FILTER_SUCCESS,
        ActionType.UPDATE_FILTER_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.PIPELINE.FILTERS_DETAILS, id), data),
    },
  }),

  deleteFilter: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_FILTER_REQUEST,
        ActionType.DELETE_FILTER_SUCCESS,
        ActionType.DELETE_FILTER_FAILURE,
      ],
      promise: client => client.delete(build(paths.api.v1.PIPELINE.FILTERS_DETAILS, id)),
    },
  }),

  getColumns: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_COLUMNS_REQUEST,
        ActionType.GET_COLUMNS_SUCCESS,
        ActionType.GET_COLUMNS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PIPELINE.COLUMNS),
    },
  }),

  updateColumns: (id: number, data: ColumnType): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_COLUMNS_REQUEST,
        ActionType.UPDATE_COLUMNS_SUCCESS,
        ActionType.UPDATE_COLUMNS_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.PIPELINE.COLUMNS_DETAILS, id), data),
    },
  }),

  searchObject: (name: string, all_assigned_properties = false, include_leads = true, include_residents = true, include_leases = true, include_applications = true, limit = 200): Action => ({
    CALL_API: {
      types: [
        ActionType.SEARCH_OBJECT_REQUEST,
        ActionType.SEARCH_OBJECT_SUCCESS,
        ActionType.SEARCH_OBJECT_FAILURE,
      ],
      promise: client => client.get(
        paths.api.v1.PIPELINE.SEARCH_OBJECT,
        {
          params: {
            name,
            all_assigned_properties,
            include_leads,
            include_residents,
            include_leases,
            include_applications,
            limit,
          },
        }),
    },
  }),

  setContextMenuInfo: (info: null | [number, number, { [key: string]: string }]): Action => ({
    type: ActionType.SET_CONTEXT_MENU_INFO, info,
  }),

  setPage: (page: { general: number, combined: number }): Action => ({
    type: ActionType.SET_PAGE, page,
  }),

  setSizePerPage: (sizePerPage: { general: number, combined: number }): Action => ({
    type: ActionType.SET_SIZE_PER_PAGE, sizePerPage,
  }),

  setSelected: (selected: number[]): Action => ({
    type: ActionType.SET_SELECTED, selected,
  }),

  setSortField: (sortField: { general: string, combined: string }): Action => ({
    type: ActionType.SET_SORT_FIELD, sortField,
  }),

  setSortOrder: (sortOrder: { general: string, combined: string }): Action => ({
    type: ActionType.SET_SORT_ORDER, sortOrder,
  }),

  setFilter: (filter: string | number): Action => ({
    type: ActionType.SET_FILTER, filter,
  }),

  setCategory: (category: string): Action => ({
    type: ActionType.SET_CATEGORY, category,
  }),

  setKeyword: (keyword: string): Action => ({
    type: ActionType.SET_KEYWORD, keyword,
  }),

  setReloadState: (shouldReload: boolean): Action => ({
    type: ActionType.SET_RELOAD_STATE, shouldReload,
  }),

  setFilterFormData: (formData: FilterType): Action => ({
    type: ActionType.SET_FILTER_FORM_DATA, formData,
  }),

  setActiveTable: (activeTable: string): Action => ({
    type: ActionType.SET_ACTIVE_TABLE, activeTable,
  }),

  resetPagination: (): Action => ({
    type: ActionType.RESET_PAGINATION,
  }),
};
