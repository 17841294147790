import React, { FC } from 'react';
import { Avatar as AvatarStyle } from 'dwell/views/pipeline/styles';
import { getShortName } from 'dwell/constants';
import { getColorFromString } from 'site/common/getColor';

interface AvatarProps {
  name: string;
  color?: number;
  className?: string;
  url?: string;
}

const Avatar: FC<AvatarProps> = ({ name, className, url }) => (
  <AvatarStyle style={{ backgroundColor: getColorFromString(name) }} className={`avatar ${className ?? ''}`}>
    {url ? <img src={url} alt={name} /> : <span>{getShortName(name)}</span>}
  </AvatarStyle>
);

export default Avatar;
