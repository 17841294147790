import { get, isEmpty } from 'lodash';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { InsiderInfoDetails, PropertyInfoTypes } from 'dwell/store/property_profile/action-types';
import { Content } from '../styles';

const PropertyInfo: FC = () => {
  const insiderInfo = useSelector(state => state.propertyProfile.insiderInfoDetails) as InsiderInfoDetails[];
  const propertyInfo = useSelector(state => state.propertyProfile.propertyInfo) as PropertyInfoTypes;

  const formatString = str => (str && str.includes('Closed') ? str : (str || '').replace(/ /g, '').toLowerCase());
  const year = get(propertyInfo, 'about.year_built', null);
  const formatYear = useMemo(() => {
    if (!year) return null;
    try {
      return moment().diff(`${year}-01-01`, 'years');
    } catch (e) {
      return null;
    }
  }, [year]);

  const insiderInfoOfficeHours = insiderInfo?.find(({ category, subcategory }) => category === 'Property Info' && subcategory === 'Office Hours')?.details;
  const insiderInfoContact = insiderInfo?.find(({ category, subcategory }) => category === 'Property Info' && subcategory === 'Contact')?.details;
  const insiderInfoLocation = insiderInfo?.find(({ category, subcategory }) => category === 'Property Info' && subcategory === 'Location')?.details;
  const insiderInfoAbout = insiderInfo?.find(({ category, subcategory }) => category === 'Property Info' && subcategory === 'About')?.details;
  const insiderInfoOther = insiderInfo?.find(({ category, subcategory }) => category === 'Property Info' && subcategory === 'Other Property Info')?.details;

  return !isEmpty(propertyInfo) && (
    <Content>
      <h6 className="tx-16 text-dark"># Office Hours</h6>
      <p><strong>Mon-Fri:</strong> {formatString(propertyInfo.office_hours?.general)}</p>
      <p><strong>Sat:</strong> {formatString(propertyInfo.office_hours?.saturday)}</p>
      <p><strong>Sun:</strong> {formatString(propertyInfo.office_hours?.sunday)}</p>
      {insiderInfoOfficeHours?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoOfficeHours.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}

      <hr />

      <h6 className="tx-16 text-dark"># Contact</h6>
      <p><strong>Phone:</strong> {propertyInfo.contact?.phone}</p>
      <p><strong>Email:</strong> {propertyInfo.contact?.email}</p>
      {insiderInfoContact?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoContact.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}

      <hr />

      <h6 className="tx-16 text-dark"># Location</h6>
      <p><strong>Address:</strong> {propertyInfo.location?.address}</p>
      <p><strong>Cross Streets:</strong> {propertyInfo.location?.cross_streets}</p>
      <p><strong>Submarket:</strong> {propertyInfo.location?.sub_market}</p>
      <p><strong>Neighborhood:</strong> {propertyInfo.location?.neighborhood}</p>
      {insiderInfoLocation?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoLocation.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}

      <hr />

      <h6 className="tx-16 text-dark"># About</h6>
      <p><strong>Year Built:</strong> {propertyInfo.about?.year_built ? propertyInfo.about.year_built : ''} {!!formatYear && `(${formatYear} years old)`}</p>
      <p><strong>Total Units:</strong> {propertyInfo.about?.total_unit_count}</p>
      <p><strong>About:</strong> {propertyInfo.about?.about}
      </p>
      {insiderInfoAbout?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoAbout.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}

      {insiderInfoOther?.length > 0 ?
        <>
          <hr />
          <h6 className="tx-16 text-dark"># Other Insider Info</h6>
          {insiderInfoOther?.map(li => (
            <div>- {li}</div>
          ))}
        </> : null
      }
    </Content>
  );
};

export default PropertyInfo;
