import React, { FC, useState } from 'react';
import cn from 'classnames';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

interface ProspectSettingProps {
  activeFilter: string,
  setActiveFilter: (filter: string) => void,
}

const CallProspectFilter: FC<ProspectSettingProps> = ({ activeFilter, setActiveFilter }) : JSX.Element => {
  const [isMenuDropdownOpen, setIsMenuDropdownOpen] = useState(false);

  const onClick = (filter) => {
    setActiveFilter(filter);
    setIsMenuDropdownOpen(false);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={isMenuDropdownOpen} toggle={() => setIsMenuDropdownOpen(!isMenuDropdownOpen)} style={{ paddingLeft: '5px' }}>
        <DropdownToggle tag="a" className="btn"><i className="ri-list-settings-line" /></DropdownToggle>
        <DropdownMenu right>
          <DropdownItem className={cn({ active: activeFilter === 'All' }, 'dropdown-item')} onClick={() => onClick('All')} tag="a">
            <i className="ri-team-fill" /> All Prospects
          </DropdownItem>
          <DropdownItem className={cn({ active: activeFilter === 'My' }, 'dropdown-item')} onClick={() => onClick('My')} tag="a">
            <i className="ri-contacts-book-fill" /> My Prospects
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>);
};

export default CallProspectFilter;
