export default {
  LOGIN: '/api/v1/token/',
  SSO_LOGIN: '/api/v1/keycloak-token/',
  CHECK_SSO: '/api/v1/check-sso/',
  KC_REFRESH_TOKEN: '/api/v1/keycloak-refresh-token/',
  BACKCHANNEL_LOGOUT: '/api/v1/backchannel-logout/',
  REFRESH: '/api/v1/token/refresh/',
  PROPERTY: '/api/v1/properties/',
  PROPERTY_BASE: '/api/v1/properties_base/',
  PROPERTY_LITE: '/api/v1/properties_lite/',
  SOURCE: '/api/v1/prospect_sources/',
  SOURCE_DETAILS: '/api/v1/prospect_sources/:id/',
  LEAD: '/api/v1/leads/',
  LEAD_NAMES: '/api/v1/leads/names/',
  LEAD_FOCUS_DATA: '/api/v1/leads/focus_data/',
  PROPERTY_LEADS_NAMES: '/api/v1/leads/property_leads_names/',
  LEAD_DELETE: '/api/v1/leads/bulk_delete/',
  LEAD_DETAILS: '/api/v1/leads/:id/',
  LEAD_UPDATE: '/api/v1/leads/bulk_update/',
  LEADS_FILTER: '/api/v1/leads_filters/',
  LEADS_FILTER_DETAILS: '/api/v1/leads_filters/:id/',
  SET_ACTIVE_FILTER: '/api/v1/active_filter/set_active_filter/',
  GET_ACTIVE_FILTER: '/api/v1/active_filter/active_filter/',
  TASKS: '/api/v1/tasks/',
  TASKS_BULK_SAVE: '/api/v1/tasks/bulk_save/',
  TASK_DETAILS: '/api/v1/tasks/:id/',
  NOTES: '/api/v1/notes/',
  NOTE_DETAILS: '/api/v1/notes/:id/',
  CURRENT_USER: '/api/v1/users/current_user/',
  EMAIL_TEMPLATES: '/api/v1/email_templates/',
  EMAIL_TEMPLATE_DETAILS: '/api/v1/email_templates/:id/',
  SMS_TEMPLATES: '/api/v1/sms_templates/',
  SMS_TEMPLATE_DETAILS: '/api/v1/sms_templates/:id/',
  USER_DETAILS: '/api/v1/users/:id/',
  USER_ACTIVITY: '/api/v1/users/:id/activity/',
  NOTIFICATIONS: '/api/v1/notifications/',
  NOTIFICATION_DETAILS: '/api/v1/notifications/:id/',
  NOTIFICATION_CLEAR_ALL: '/api/v1/notifications/clear_all/',
  NOTIFICATION_READ_ALL: '/api/v1/notifications/read_all/',
  ASSIGN_LEAD_OWNERS: '/api/v1/assign_lead_owners/',
  CURRENT_ASSIGN_LEAD_OWNER: '/api/v1/assign_lead_owners/current_assigned_owner/',
  ASSIGN_LEAD_OWNERS_DETAILS: '/api/v1/assign_lead_owners/:id/',
  ASSIGNMENT_RULES: {
    GET_ALL: '/api/v1/assignment_rules/',
    AGENTS_BY_ROLE: '/api/v1/assignment_rules/agents_by_role/',
    SAVE: '/api/v1/assignment_rules/save/',
  },
  AGENTS_ASSIGNMENT_RULES: '/api/v1/assignment_rules/agents/',
  NYLAS_AUTH: '/api/v1/nylas_authorize/',
  EMAIL_LABELS: '/api/v1/email_labels/',
  NYLAS_SEND_MESSAGE: '/api/v1/email_messages/send_message/',
  NYLAS_ARCHIVE_MESSAGES: '/api/v1/email_messages/archive_messages/',
  PROPERTY_NYLAS_SYNC_SETTINGS_UPDATE: '/api/v1/properties/:id/nylas_sync_settings/',
  PROPERTY_RESET_NYLAS_SETTINGS: '/api/v1/properties/:id/reset_nylas_settings/',
  EMAIL_MESSAGES: '/api/v1/email_messages/',
  EMAIL_MESSAGE_DETAILS: '/api/v1/email_messages/:id/',
  CURRENT_PROPERTY: '/api/v1/properties/current_property/',
  ACCESSED_PROPERTIES: '/api/v1/users/:id/accessed_properties/',
  ACCESSED_PROPERTIES_FLOOR_PLANS: '/api/v1/users/:id/accessed_properties/floor_plans/',
  PROPERTIES_ID: '/api/v1/properties/:id/',
  PROPERTY_DETAILS: '/api/v1/properties/:id/details/',
  COMMUNITY_DETAILS: '/api/v1/property/:id/community_details/',
  SEND_BULK_EMAIL: '/api/v1/email_messages/bulk_email/',
  FILTERED_LEADS_COUNT: '/api/v1/leads/filtered_leads_count/',
  COLUMNS: '/api/v1/columns/',
  COLUMNS_UPDATE: '/api/v1/columns/bulk_update/',
  COLUMNS_CREATE: '/api/v1/columns/bulk_create/',
  ROOMMATES: '/api/v1/leads/:lead_id/roommates/',
  ROOMMATES_SAVE: '/api/v1/leads/:lead_id/roommates/bulk_save/',
  ROOMMATE_DETAILS: '/api/v1/leads/:lead_id/roommates/:id/',
  PORTFOLIO: '/api/v1/portfolios/',
  REPORTS: {
    CORPORATE_PPC: '/api/v1/report/corporate_ppc/',
    STANDALONE_PPC: '/api/v1/report/standalone_ppc/',
    OVERVIEW_REPORTS: '/api/v1/report/overview/',
    MARKETING_REPORTS: '/api/v1/report/marketing/',
    AGENT_AND_OFFICE_REPORTS: '/api/v1/report/agent_and_office/',
    CREDIT_BUILDER_REPORTS: '/api/v1/report/credit_builder/',
    REFRESH_REPORTS: '/api/v1/report/refresh/:id/',
    DOWNLOAD_REPORTS: '/api/v1/report/download/:id/',
    OPERATIONS_REPORTS: '/api/v1/reports/operation/',
    LEAD_SOURCE_DRILLDOWN: '/api/v1/reports/source_drilldown/',
    LEAD_LOST_DRILLDOWN: '/api/v1/reports/lost_drilldown/',
    RAW_DATA: '/api/v1/report/raw_data/:id/',
    WORKFORCE_REPORTS: '/api/v1/report/workforce/',
  },
  TEST_TASK_NOTIFICATIONS: '/api/v1/tasks/test_task_overdue_notification/',
  CALLS: '/api/v1/calls/',
  CALL_DETAILS: '/api/v1/calls/:id/',
  ARCHIVE_CALLS: '/api/v1/calls/archive_calls/',
  CALL_NOTES: '/api/v1/calls/summary/',
  CHAT_REPORTS: '/api/v1/hobbes/evaluation/',
  CHAT_REPORT_DETAILS: '/api/v1/hobbes/evaluation/:id/',
  CHAT_REPORT_EVALUATION: '/api/v1/hobbes/chat_report/:id/chat_conversation/',
  CHAT_REPORT_EVALUATION_SUBMIT: '/api/v1/hobbes/evaluation/:id/submit/',
  CHAT_REPORT_MESSAGE: '/api/v1/hobbes/chat_report_conversation/:id/chat_message/',
  COMPETITOR: '/api/v1/competitors/',
  COMPETITOR_DETAILS: '/api/v1/competitors/:id/',
  SPENDS_UPDATE: '/api/v1/prospect_sources/update_spends/',
  SURVEY: '/api/v1/surveys/',
  SURVEYS_UPDATE: '/api/v1/surveys/update_surveys/',
  USERS: '/api/v1/users/',
  RESCOREUSERS: '/api/v1/users/rescores_users/',
  TEAM_MATES: '/api/v1/users/team_members/',
  MERGE_LEADS: '/api/v1/leads/merge/',
  BUSINESS_HOURS: '/api/v1/business_hours/',
  BUSINESS_HOURS_ALL: '/api/v1/business_hours/:userId/all/',
  BUSINESS_HOURS_UPDATE: '/api/v1/business_hours/bulk_update/',
  BUSINESS_HOURS_CREATE: '/api/v1/business_hours/bulk_create/',
  SMS_CONTACTS: '/api/v1/sms_contacts/',
  SMS_CONTACT_DETAILS: '/api/v1/sms_contacts/:id/',
  SMS_CONVERSATIONS_READALL: '/api/v1/leads/:id/sms/read_all/',
  SMS_MESSAGE: '/api/v1/leads/:id/sms/',
  CALL_SCORING_QUESTIONS: '/api/v1/call_scoring_questions/',
  USERS_CALL_ROUTING: 'api/v1/users/:id/call_routings/',
  AGENT_WEEKLY_CALL_ROUTING: 'api/v1/users/:id/agent_weekly_call_routings/',

  AVAILABLE_AGENTS: '/api/v1/communications/voice/agents/availables/',
  CALL_CONTACTS: '/api/v1/communications/voice/contacts/',
  VOICE_TOKEN: '/api/v1/communications/voice/agents/token/',
  TRANSFER_CALL_TO_AGENT: '/api/v1/communications/voice/agents/transfer_call_to_agent/',
  TRANSFER_CALL_TO_PROPERTY_TEAM: '/api/v1/communications/voice/agents/transfer_call_to_property_team/',
  CALL_FROM_DESKTOP_PHONE: '/api/v1/communications/voice/outbound/call_from_desktop/',
  CALL_FROM_PROPERTY_OFFICE_LINE: '/api/v1/communications/voice/outbound/call_to_property_office_line/',
  HANGUP_DESKTOP_CALL: '/api/v1/communications/voice/agents/hangup_desktop_call/',
  UNAVAILABLE_TO_RECEIVE_CALLS: '/api/v1/communications/voice/agents/unavailable_to_receive_calls/',
  AGENT_STATUS: '/api/v1/communications/voice/agents/agent_status/',

  SCORED_CALLS: '/api/v1/scored_calls/',
  SCORED_CALL_DETAILS: '/api/v1/scored_calls/:id/',
  REQUIRE_RESCORE_CALL: '/api/v1/scored_calls/:id/require_rescore/',
  PROPERTIES_AVAILABLE_TO_RESCORE: '/api/v1/properties/available_to_rescore/',
  CALL_RESCORES_META: '/api/v1/scored_calls/rescore_calls_meta/',
  SHARE_LEAD: '/api/v1/leads/share/',
  CALENDARS: '/api/v1/calendars/',
  PROSPECTS: '/api/v1/prospects/',
  PROSPECT_DETAILS: '/api/v1/prospects/:id/',
  PROSPECT_CONVERSATIONS: '/api/v1/prospects/:id/conversations/',
  PROSPECT_CONVERSATIONS_READALL: '/api/v1/prospects/:id/read_all/',
  REQUET_TO_JOIN_CONVERSATION: '/api/v1/prospects/:id/request_to_join_conversation/',
  PROSPECT_DISMISS_NEW_MESSAGE: '/api/v1/prospects/:id/show_modal_message/',
  LEAD_LEVEL_PROSPECT: '/api/v1/leads/:id/prospects/',
  AGENT_REQUEST_DETAILS: '/api/v1/agent_requests/:id/',
  BULK_CLEAR_NOTIFICATION: '/api/v1/notifications/bulk_clear/',
  RESET_PASSWORD_SEND_EMAIL: '/api/v1/password_reset/',
  RESET_PASSWORD: '/api/v1/password_reset/confirm/',
  PROPERTY_SCORE_STATE: '/api/v1/properties/submit_calls_score_state/',
  TYPING_STATE: '/api/v1/prospects/:id/send_typing_state/',
  LEADS_COMMUNICATION: '/api/v1/leads/:lead_id/communications/',
  UNENROLL_LEAD: '/api/v1/leads/:lead_id/nurture_unenrollment/',
  TOUR_AVAILABLE_TIME: '/api/v1/tour_available_time/',
  CHAT_TEMPLATES: '/api/v1/chat_templates/',
  CHAT_TEMPLATE_DETAILS: '/api/v1/chat_templates/:id/',
  TARGET_NUMBERS: '/api/v1/target_numbers/',
  TARGET_NUMBER_DETAILS: '/api/v1/target_numbers/:id/',
  PROPERTY_TARGET_NUMBER: '/api/v1/target_numbers/property_target_number/',
  TARGET_NUMBERS_SITE_ONLY: '/api/v1/site_only_target_numbers/',
  TARGET_NUMBER_SITE_ONLY_DETAILS: '/api/v1/site_only_target_numbers/:id/',
  CALL_SCHEDULES: '/api/v1/call_schedules/',
  CALL_SCHEDULES_SAVE: '/api/v1/call_schedules/bulk_save/',
  PMS_LOGS: '/api/v1/leads/:lead_id/pms_logs/',
  APARTMENTS: '/api/v1/leasing/units/',
  HOLIDAYS: '/api/v1/holidays/',
  HOLIDAYS_DETAILS: '/api/v1/holidays/:id/',
  PROFILE_PROPERTIES: '/api/v1/profile/properties/',
  PROFILE_DETAILS: '/api/v1/profile/property/:id/',
  LEAD_FROM_PROFILE: '/api/v1/profile/property/:id/lead/',
  TOUR_FROM_PROFILE: '/api/v1/profile/property/:id/tour/',
  ASSIST_BOT_CONVERSATION: '/api/v1/profile/property/:id/assist_bot/',
  CLEAR_ASSIST_BOT_CONVERSATION: '/api/v1/profile/property/:id/assist_bot/clear/',
  AGENT_CONVERSATIONS: '/api/v1/agent_question/conversations/',
  SET_PROPERTY_TO_GET_QUESTIONS: '/api/v1/agent_question/users/:id/set_property/',
  DISABLE_CHAT: '/api/v1/agent_question/users/:id/disable_chat/',
  HOBBES_AGENT_QUESTIONS: '/api/v1/agent_question/questions/get/',
  SAVE_QUESTIONS_ANSWERS: '/api/v1/agent_question/process_answer/',
  DISCARD_QUESTION: '/api/v1/agent_question/questions/:id/discard/',
  PROPERTY_USERS: '/api/v1/agent_question/properties/:id/users/',
  QUESTION_ANSWERS: '/api/v1/agent_question/questions/:id/answers/',
  CONFIRM_QUESTION: '/api/v1/agent_question/questions/:id/confirm/',
  QUESTION_FIELD_VALUES: '/api/v1/agent_question/questions/:id/field_values/',
  RESET_QUESTION: '/api/v1/agent_question/questions/:id/reset/',
  UPDATE_QUESTIONS: '/api/v1/agent_question/questions/update/',
  HOBBES_AGENT_QUESTION: '/api/v1/agent_question/questions/:id/',
  PROCESS_QUESTIONS_ANSWERS: '/api/v1/agent_question/conversations/process_answers/',
  PULL_QUESTION: '/api/v1/agent_question/conversations/pull_question/',
  QUESTION_OPTION: '/api/v1/agent_question/question_options/',
  LEAD_LEVEL_PRICE_QUOTES: '/api/v1/leads/:lead_id/historic_price_quotes/',
  PRICE_QUOTES: '/api/v1/historic_price_quotes/',
  PRICE_QUOTES_DETAILS: '/api/v1/historic_price_quotes/:id/',
  LEAD_LEVEL_PRICE_QUOTES_DETAILS: '/api/v1/leads/:lead_id/historic_price_quotes/:id/',
  DO_NOT_DISTURB: '/api/v1/users/do_not_disturb/',
  NURTURE_AUTOMATIONS: '/api/v1/nurture_automations/',
  NURTURE_AUTOMATIONS_ID: '/api/v1/nurture_automations/:id/',
  NURTURE_STEPS_LIST: '/api/v1/nurture_automations/:id/steps/',
  NURTURE_STEPS: '/api/v1/nurture_step/',
  NURTURE_STEPS_ID: '/api/v1/nurture_step/:id/',
  NURTURE_INSIGHTS: '/api/v1/nurture_automations/:id/insights/',
  REQUESTS: '/api/v1/support/requests/',
  REQUEST_DETAILS: '/api/v1/support/requests/:id/',
  REQUEST_RANK_REARRENGE: '/api/v1/support/requests/rank_rearrenge/',
  REQUEST_COMMENTS: '/api/v1/support/requests/:id/issue_comments/',
  REQUEST_CREATE_ISSUE_COMMENT: '/api/v1/support/requests/:id/create_issue_comment/',
  PIPELINE: {
    PROSPECTS: '/api/v1/pipeline/prospects/',
    APPLICATIONS: '/api/v1/pipeline/applications/',
    LEASES: '/api/v1/pipeline/leases/',
    ALL_SEARCH: '/api/v1/pipeline/all_search/',
    FILTERS: '/api/v1/pipeline/filters/',
    COLUMNS: '/api/v1/pipeline/columns/',
    COLUMNS_DETAILS: '/api/v1/pipeline/columns/:id/',
    FILTERS_DETAILS: '/api/v1/pipeline/filters/:id/',
    SEARCH_OBJECT: '/api/v1/pipeline/search_object/',
  },
  OBJECT_COMMUNICATION: '/api/v1/object_communications/',
  UPDATE_RECENT_CHAT_EVALUATION: '/api/v1/hobbes/update_recent_evaluation_report/',
  LEAVING_REASONS: '/api/v1/leaving_reasons/',
  SET_CALL_ON_HOLD: '/api/v1/calls/on_hold/',
  END_CONFERENCE: '/api/v1/conferences/end_conference/',
  LEAD_TRANSFER_TO_APPLICATION: '/api/v1/leads/:id/transfer_to_application/',
  AMENITY: '/api/v1/amenity/',
  INSIDER_QUESTIONS_WITH_ANSWERS: '/api/v1/hobbes/insider_questions_with_answers/',
  INSIDER_QUESTIONS_PERCENTAGE_BY_STATUS: '/api/v1/hobbes/insider_questions_with_answers/percentage_by_status/',
  CREATE_INSIDER_QUESTION_ANSWER: '/api/v1/hobbes/insider_info_answer/',
  UPDATE_INSIDER_QUESTION_ANSWER: '/api/v1/hobbes/insider_info_answer/:id/',
  CONFIRM_INSIDER_QUESTION_ANSWER: '/api/v1/hobbes/insider_info_answer/:id/confirm/',
  DENY_INSIDER_QUESTION_ANSWER: '/api/v1/hobbes/insider_info_answer/:id/deny/',
  SUGGEST_EDIT_INSIDER_QUESTION: '/api/v1/hobbes/insider_questions/:id/suggest_edit/',
  TOGGLE_DEFAULT_INSIDER_QUESTION_ANSWER: '/api/v1/hobbes/insider_info_answer/:id/toggle_default/',
};
