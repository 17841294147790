import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ManageRequestProps } from 'src/interfaces';
import { ActionType, Action, LeadData, CreateLeadType } from './action-types';

export default {
  getLeads: (param: ManageRequestProps, token = null): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEAD_REQUEST,
        ActionType.GET_LEAD_SUCCESS,
        ActionType.GET_LEAD_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.LEAD, { params: param, cancelToken: token }),
    },
  }),
  getLeadById: (id: number, params?: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEAD_BY_ID_REQUEST,
        ActionType.GET_LEAD_BY_ID_SUCCESS,
        ActionType.GET_LEAD_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.LEAD_DETAILS, id), { params }),
    },
  }),
  updateLeadById: (id: number, params: LeadData, property?: number): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_LEAD_REQUEST,
        ActionType.UPDATE_LEAD_SUCCESS,
        ActionType.UPDATE_LEAD_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.LEAD_DETAILS, id), params, { params: { property } }),
    },
  }),
  deleteLeads: (ids: number[]): Action => ({
    CALL_API: {
      types: [
        ActionType.DELETE_LEADS_REQUEST,
        ActionType.DELETE_LEADS_SUCCESS,
        ActionType.DELETE_LEADS_FAILURE,
      ],
      promise: client => client.delete(paths.api.v1.LEAD_DELETE, { data: ids }),
    },
  }),
  clearLeads: (): Action => ({
    type: ActionType.CLEAR_LEADS,
  }),
  updateLeads: (property: number, params: LeadData): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_LEADS_REQUEST,
        ActionType.UPDATE_LEADS_SUCCESS,
        ActionType.UPDATE_LEADS_FAILURE,
      ],
      promise: client => client.put(paths.api.v1.LEAD_UPDATE, params, { params: { property } }),
    },
  }),
  createLead: (data: CreateLeadType): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_LEAD_REQUEST,
        ActionType.CREATE_LEAD_SUCCESS,
        ActionType.CREATE_LEAD_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.LEAD, data),
    },
  }),
  getLeadNames: (): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEAD_NAMES_REQUEST,
        ActionType.GET_LEAD_NAMES_SUCCESS,
        ActionType.GET_LEAD_NAMES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.LEAD_NAMES),
    },
  }),
  getPropertyLeadsNames: (param: { property: number }): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEAD_NAMES_REQUEST,
        ActionType.GET_LEAD_NAMES_SUCCESS,
        ActionType.GET_LEAD_NAMES_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.PROPERTY_LEADS_NAMES, { params: param }),
    },
  }),
  testResman: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.TEST_RESMAN_REQUEST,
        ActionType.TEST_RESMAN_SUCCESS,
        ActionType.TEST_RESMAN_FAILURE,
      ],
      promise: client => client.get(`${build(paths.api.v1.LEAD_DETAILS, id)}run_test_sync/`),
    },
  }),
  getFilteredLeadsCount: (leadsFilter: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_FILTERED_LEADS_COUNT_REQUEST,
        ActionType.GET_FILTERED_LEADS_COUNT_SUCCESS,
        ActionType.GET_FILTERED_LEADS_COUNT_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.FILTERED_LEADS_COUNT, leadsFilter),
    },
  }),
  mergeLeads: (data: { primary_lead: number, leads: number[] }): Action => ({
    CALL_API: {
      types: [
        ActionType.MERGE_LEADS_REQUEST,
        ActionType.MERGE_LEADS_SUCCESS,
        ActionType.MERGE_LEADS_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.MERGE_LEADS, data),
    },
  }),
  shareLead: (data: { lead: number, properties: number[] }): Action => ({
    CALL_API: {
      types: [
        ActionType.SHARE_LEAD_REQUEST,
        ActionType.SHARE_LEAD_SUCCESS,
        ActionType.SHARE_LEAD_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.SHARE_LEAD, data),
    },
  }),
  transferLead: (data: { lead: number, properties: number[] }): Action => ({
    CALL_API: {
      types: [
        ActionType.TRANSFER_LEAD_REQUEST,
        ActionType.TRANSFER_LEAD_SUCCESS,
        ActionType.TRANSFER_LEAD_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.SHARE_LEAD, data),
    },
  }),
  getPMSSyncStatusById: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_PMS_SYNC_STATUS_BY_ID_REQUEST,
        ActionType.GET_PMS_SYNC_STATUS_BY_ID_SUCCESS,
        ActionType.GET_PMS_SYNC_STATUS_BY_ID_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.LEAD_DETAILS, id)),
    },
  }),

  getLeadForProspect: (id: number, params?: ManageRequestProps): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_LEAD_FOR_PROSPECT_REQUEST,
        ActionType.GET_LEAD_FOR_PROSPECT_SUCCESS,
        ActionType.GET_LEAD_FOR_PROSPECT_FAILURE,
      ],
      promise: client => client.get(build(paths.api.v1.LEAD_DETAILS, id), { params }),
    },
  }),

  unenrollLead: (id: number): Action => ({
    CALL_API: {
      types: [
        ActionType.UNENROLL_LEAD_REQUEST,
        ActionType.UNENROLL_LEAD_SUCCESS,
        ActionType.UNENROLL_LEAD_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.UNENROLL_LEAD, id)),
    },
  }),

  transferToApplication: (id: number, data: { applicationId: string, applicantType: string, isEmailInvite: boolean }): Action => ({
    CALL_API: {
      types: [
        ActionType.TRANSFER_TO_APPLICATION_REQUEST,
        ActionType.TRANSFER_TO_APPLICATION_SUCCESS,
        ActionType.TRANSFER_TO_APPLICATION_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.LEAD_TRANSFER_TO_APPLICATION, id), data),
    },
  }),
};
