import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import actions from 'dwell/store/actions';

const SyncStatusPoll: FC = () => {
  const dispatch = useDispatch();
  const { getPMSSyncStatusById } = actions.lead;
  const { pmsSyncData } = useSelector(state => state.lead);
  const { lead } = useSelector(state => state.resident.resident);
  const [isPolling, setIsPolling] = useState(false);
  const timer = useRef({});

  const { pathname } = useLocation();

  const stopPolling = () => {
    clearInterval(timer.current as NodeJS.Timeout);
    setIsPolling(false);
  };

  useEffect(() => {
    if (isPolling) {
      stopPolling();
    }
  }, [pathname]);

  useEffect(() => () => stopPolling(), []);

  const pollSyncStatus = () => {
    if (!lead) return;
    timer.current = setInterval(() => {
      dispatch(getPMSSyncStatusById(lead)).then((response) => {
        if (response?.result) {
          const { result: { data: { pms_sync_status: pmsSyncStatus } } } = response;
          if (pmsSyncStatus !== 'SYNCING') {
            clearInterval(timer.current as NodeJS.Timeout);
            setIsPolling(false);
          }
        }
      });
    }, 5000);
  };

  useEffect(() => {
    if (pmsSyncData.pms_sync_status === 'SYNCING' && !isPolling) {
      setIsPolling(true);
      pollSyncStatus();
    }
  }, [pmsSyncData]);

  return <></>;
};

export default SyncStatusPoll;
