import React, { FC, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import { debounce } from 'lodash';
import actions from 'dwell/actions';
import { PageSection } from 'dwell/store/property_profile/action-types';
import { selectSelectedProperties } from 'dwell/store/property/reducers';
import { PropertyProps } from 'dwell/store/property/action-types';
import {
  Back,
  Close,
  Dropdown,
  DropdownItem,
  DropdownItemContainer,
  DropdownLink,
  DropdownMenu,
  DropdownMenuHeader,
  DropdownSearch,
  Header as HeaderStyle,
  SubTitle,
} from './styles';

const pageSectionMap = {
  [PageSection.ASSIST_BOT]: 'Answer Assist',
  [PageSection.PROPERTY_INFO]: 'Property Info',
  [PageSection.LEASING_CRITERIA]: 'Leasing & Rental Criteria',
  [PageSection.AVAILABLE_UNITS]: 'Available Units',
  // [PageSection.UNITS_BY_FLOOR_PLAN]: 'U',
  [PageSection.AMENITIES]: 'Amenities',
  [PageSection.PET_INFO]: 'Pet Info',
  [PageSection.TEAM]: 'Team',
  [PageSection.SISTER_COMMUNITIES]: 'Sister Communities',
  [PageSection.NEIGHBORHOOD]: 'Neighborhood',
  [PageSection.GALLERY]: 'Gallery',
  [PageSection.ADD_NEW_LEAD]: 'Add New Lead',
  [PageSection.ADD_NEW_TOUR]: 'Add New Tour',
};

const Header: FC = () => {
  const { setSection, setCurrentProperty, getProperties, openProfile } = actions.propertyProfile;
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  const [command, setCommand] = useState(null);
  const section = useSelector(state => state.propertyProfile.section);
  const previousSection = useSelector(state => state.propertyProfile.previousSection);
  const properties = useSelector(state => state.propertyProfile.properties);
  const currentProperty = useSelector(state => state.propertyProfile.currentProperty);
  const selectedProperties: PropertyProps[] = useSelector(selectSelectedProperties);
  const activeUnit = useSelector(state => state.propertyProfile.activeUnit);
  const floorPlans = useSelector(state => state.propertyProfile.floorPlans);
  const dropdownMenuRef = useRef(null);
  const dropdownLinkRef = useRef(null);
  const floorPlan = useMemo(() => floorPlans.find(plan => plan.id === activeUnit?.plan?.id), [activeUnit, floorPlans]);
  const [isOpen, open] = useState(false);
  const comm = [17, 91, 93, 224];
  const activeProperties = useMemo(() => properties.filter(p => selectedProperties.map(sp => sp.id).includes(p.id)), [properties, selectedProperties]);
  const notActiveProperties = useMemo(() => properties.filter(p => !selectedProperties.map(sp => sp.id).includes(p.id)), [properties, selectedProperties]);

  const loadProperties = () => dispatch(getProperties(keyword));
  const handleDebounce = debounce(loadProperties, 500);

  useEffect(() => {
    if (keyword === null) return;
    if (!keyword) {
      loadProperties();
      return;
    }
    handleDebounce();
  }, [keyword]);

  useEffect(() => {
    if (!currentProperty.id && selectedProperties.length) {
      const property = properties.find(p => p.id === selectedProperties[0].id);
      if (property) {
        dispatch(setCurrentProperty(property));
      }
    }
  }, [selectedProperties, properties]);

  const mouseDown = (e) => {
    if (!dropdownMenuRef?.current?.contains(e.target) && !dropdownLinkRef?.current?.contains(e.target)) {
      open(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', mouseDown);
    return () => document.removeEventListener('mousedown', mouseDown);
  }, []);

  const keyDown = (e) => {
    let shouldPreventDefault = false;
    if (comm.includes(e.keyCode)) {
      setCommand(e.keyCode);
    } else if (command && e.keyCode === 77) {
      const element = document.querySelector('#property-menu-link') as HTMLAnchorElement;
      if (element) {
        shouldPreventDefault = true;
        element.click();
      }
    }
    if (shouldPreventDefault) e.preventDefault();
  };

  const keyUp = (e) => {
    e.preventDefault();
    if (comm.includes(e.keyCode)) {
      setCommand(null);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDown);
    document.addEventListener('keyup', keyUp);
    return () => {
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener('keyup', keyUp);
    };
  });

  const handleKeyDown = () => {
    handleDebounce.cancel();
    return true;
  };

  const clickProperty = (property) => {
    dispatch(setCurrentProperty(property));
    open(false);
  };

  const back = () => {
    if (previousSection === PageSection.AVAILABLE_UNITS && section === PageSection.UNITS_BY_FLOOR_PLAN) {
      dispatch(setSection(PageSection.AVAILABLE_UNITS));
    } else if (previousSection === PageSection.ASSIST_BOT && section === PageSection.UNITS_BY_FLOOR_PLAN) {
      dispatch(setSection(PageSection.ASSIST_BOT));
    } else {
      dispatch(setSection(PageSection.MAIN_MENU));
    }
  };

  return (
    <HeaderStyle id="pp-header">
      {section === PageSection.MAIN_MENU ? (
        <Dropdown>
          <DropdownLink id="property-menu-link" onClick={() => open(!isOpen)} ref={dropdownLinkRef}>
            <i className="ri-layout-grid-fill" />
            <span id="activeProperty">{currentProperty.name}</span>
          </DropdownLink>
          <div ref={dropdownMenuRef}>
            {isOpen && (
              <DropdownMenu id="dropdown-menu">
                <DropdownMenuHeader id="dropdown-menu-header">
                  <i className="ri-community-line" /> Property Switcher
                </DropdownMenuHeader>
                <DropdownSearch>
                  <i className="ri-search-line" />
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search properties..."
                    value={keyword}
                    onChange={({ target: { value } }) => setKeyword(value)}
                    onKeyDown={handleKeyDown}
                    autoFocus
                  />
                </DropdownSearch>
                <DropdownItemContainer>
                  {activeProperties.map(property => (
                    <DropdownItem
                      active={property.id === currentProperty.id}
                      key={property.id}
                      onClick={() => clickProperty(property)}
                    >
                      <span>{property.name}</span>
                    </DropdownItem>
                  ))}
                  <hr className="mg-0" />
                  {notActiveProperties.map(property => (
                    <DropdownItem
                      active={property.id === currentProperty.id}
                      key={property.id}
                      onClick={() => clickProperty(property)}
                    >
                      <span>{property.name}</span>
                    </DropdownItem>
                  ))}
                </DropdownItemContainer>
              </DropdownMenu>
            )}
          </div>
        </Dropdown>
      ) : (
        <>
          <Back onClick={back} id="back_to_main_menu">
            <i className="ri-arrow-left-s-line" />
          </Back>
          <SubTitle>
            <h6>
              {section === PageSection.UNITS_BY_FLOOR_PLAN ? `${floorPlan?.plan} Units` : pageSectionMap[section]}
            </h6>
            <span>{currentProperty.name}</span>
          </SubTitle>
        </>
      )}
      <Close onClick={() => dispatch(openProfile(false))} id="profile_close">
        <i className="ri-close-fill" />
      </Close>
    </HeaderStyle>
  );
};

export default Header;
