/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, FC, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner, Button } from 'reactstrap';
import { isEmpty, get, sortBy } from 'lodash';
import { Mention, MentionsInput } from 'react-mentions';
import CKEditor from 'ckeditor4-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import actions from 'dwell/actions';
import FileUpload from 'dwell/components/file_upload';
import { UploadIconButton } from 'dwell/views/lead/layout/styles';
import { PrimaryButton, WhiteButton, Avatar } from 'styles/common';
import { emailVariables } from 'dwell/constants';
import { getAvatarColor } from 'dwell/views/chat/common/utils';
import { ApplicationDetailsProps } from 'application/store/application/action-types';
import { OrderedDropdown } from 'dwell/components';
import {
  checkVariables,
  parseVariables,
  getPreview,
  replaceBodyEmptyVariables,
  replaceSubjectEmptyVariables,
  formatNewPlaceholderVariables,
} from 'src/dwell/components/email/composer/_utils';

import { getAvailablePlaceholders, Placeholder, TemplatePlaceholders } from 'dwell/components/settings/templates/_utils';
import { Attachment, EmailPopout as EmailPopoutProps } from 'dwell/store/email_popout/action-types';
import { priceQuoteSelection, PriceQuote } from 'dwell/store/price_quote/action-types';
import { selectCurrentPriceQuote } from 'dwell/store/price_quote/reducers';
import { selectPopout } from 'dwell/store/email_popout/reducers';
import emailPopoutActions from 'src/dwell/store/email_popout/action-creator';
import { convertFileSize } from '../utils';
import {
  EmailItemContainer,
  EmailItemHeader,
  EmailItemHeaderLink,
  EmailItemBody,
  EmailItemRecipient,
  EmailItemFooter,
  EmailFile,
  AlertEmailPreview,
} from './styles';

type emailFormat = {
  name: string;
  email: string;
};

const EmailPopOut: FC<EmailPopoutProps> = ({ popoutId, lead, property, isReply, emailMessage, minimized }) => {
  CKEditor.editorUrl = `${window.location.origin}/static/ckeditor/ckeditor.js`;

  const popout = useSelector(selectPopout(popoutId));
  const isSendingEmail = useSelector(state => state.nylas.isSendingEmail);
  const currentPriceQuote: PriceQuote = useSelector(selectCurrentPriceQuote);

  const { body, subject, cc, isPreview, files, showingCcInput, selectedTemplate, emailTemplates, subjectVariables, isSettedFromPriceQuote } = popout;
  const templatePlaceholders: TemplatePlaceholders = property.template_placeholders;
  const leaseInfo = get(emailMessage, 'lease_info', {}) as ApplicationDetailsProps;

  const placeholders: string[] = templatePlaceholders.email.map((placeholder: Placeholder) => placeholder.field);
  const availablePlaceholders = getAvailablePlaceholders(emailVariables, placeholders);

  const dispatch = useDispatch();
  const { getEmailTemplates } = actions.emailTemplate;
  const { sendMessage } = actions.nylas;
  const {
    closeEmailPopout,
    toggleMinimizeEmailPopout,
    setEmailPopoutTextVar,
    setEmailPopoutIsPreview,
    setEmailPopoutShowingCcInput,
    setEmailPopoutAttachment,
    removeEmailPopoutAttachment,
    clearEmailPopoutattachments,
    setEmailPopoutTemplates,
    setEmailPopoutSelectedTemplate,
    setEmailPopoutSubjectVariables,
  } = emailPopoutActions;
  const { setProtoPriceQuote, updatePriceQuoteById, clearCurrentPriceQuote } = actions.priceQuote;

  const setBody = (_body: string) => {
    dispatch(setEmailPopoutTextVar(popoutId, 'body', _body));
  };

  const setSubject = (_subject: string) => {
    dispatch(setEmailPopoutTextVar(popoutId, 'subject', _subject));
  };

  const setCc = (_cc: string) => {
    dispatch(setEmailPopoutTextVar(popoutId, 'cc', _cc));
  };

  const checkedBodyVariables = checkVariables(parseVariables(body), lead, property, null, currentPriceQuote, leaseInfo, cc);
  const checkedSubjectVariables = checkVariables(subjectVariables, lead, property, null, {}, leaseInfo);

  useEffect(() => {
    // get templates if there are not loaded yet or the current property is different from the property which opened the popout
    if (isEmpty(emailTemplates)) {
      dispatch(getEmailTemplates()).then(({ result: { data } }) => dispatch(setEmailPopoutTemplates(popoutId, data.results)));
    }
    if (isReply) {
      setSubject(`Re: ${emailMessage?.subject}`);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(subjectVariables)) {
      setTimeout(() => replaceSubjectEmptyVariables(checkedSubjectVariables, false), 100);
    }
  }, [subjectVariables]);

  useEffect(() => {
    // Not sure why but two instance of CKEditor are created, so we need to make them readOnly when we enter in
    // preview mode.
    if (!window.CKEDITOR) return;
    if (!window.CKEDITOR.instances) return;
    Object.keys(window.CKEDITOR.instances).forEach((instanceName) => {
      const instance = window.CKEDITOR.instances[instanceName];
      if (instance) {
        let retryCount = 0;
        const delayedSetReadOnly = () => {
          if (instance.editable() === undefined && retryCount < 10) {
            setTimeout(delayedSetReadOnly, retryCount * 100);
          } else {
            instance.setReadOnly(isPreview);
          }
          retryCount += 1;
        };
        setTimeout(delayedSetReadOnly, 50);
      }
    });
  }, [isPreview, window.CKEDITOR]);

  const filteredEmailTemplates = useMemo(() => !isEmpty(emailTemplates) && sortBy(emailTemplates, 'name'), [emailTemplates]);

  const dataCallback = (matchInfo, callback, type) => {
    const newData = Object.keys(emailVariables.VARIABLES)
      .map(key => (type === 'subject'
        ? { id: key, display: `${emailVariables.VARIABLES[key].name}` }
        : { id: key, title: emailVariables.VARIABLES[key].name, name: key }));
    callback(newData);
  };

  const handleTemplateChange = (templateId) => {
    const template = emailTemplates.find(t => t.id === Number(templateId));
    const newVariables = checkVariables(parseVariables(template.text), lead, property, null, currentPriceQuote, leaseInfo);
    setBody(replaceBodyEmptyVariables(template.text, newVariables, false));
    setSubject(template.subject);
    dispatch(setEmailPopoutSelectedTemplate(popoutId, template));
    dispatch(setEmailPopoutSubjectVariables(popoutId, template.subject_variables));
  };

  const handleSubjectChange = (event, newValue, newPlainTextValue, mentions) => {
    const newSubjectVariables = Object.keys(emailVariables.VARIABLES).filter(key => mentions.map(item => item.id).includes(emailVariables.VARIABLES[key].name));
    setSubject(newValue);
    dispatch(setEmailPopoutSubjectVariables(popoutId, newSubjectVariables));
  };

  const handleCcChange = ({ target: { value } }) => {
    setCc(value);
  };

  const handleFileAdded = (_files) => {
    dispatch(setEmailPopoutAttachment(popoutId, _files as Attachment[]));
  };

  const removeFile = (fileName: string) => {
    dispatch(removeEmailPopoutAttachment(popoutId, fileName));
  };

  const resetTemplate = () => {
    setBody('');
    setCc('');
    setSubject('');
    dispatch(clearEmailPopoutattachments(popoutId));
    dispatch(setEmailPopoutShowingCcInput(popoutId, false));
    dispatch(setEmailPopoutIsPreview(popoutId, false));
    dispatch(setEmailPopoutSubjectVariables(popoutId, []));
    dispatch(setEmailPopoutSelectedTemplate(popoutId, null));
  };

  const handleEditorChange = (evt) => {
    if (!isPreview) {
      let changedBody: string = evt.editor.getData();
      changedBody = formatNewPlaceholderVariables(changedBody);
      const newVariables = checkVariables(parseVariables(changedBody), lead, property, null, currentPriceQuote, leaseInfo);
      setBody(replaceBodyEmptyVariables(changedBody, newVariables, false));
    }
  };

  const closePopOut = () => {
    if (isSettedFromPriceQuote) {
      dispatch(setProtoPriceQuote({} as priceQuoteSelection));
      dispatch(clearCurrentPriceQuote());
    }
    dispatch(closeEmailPopout(popoutId));
  };

  const sendEmail = (formData) => {
    dispatch(sendMessage(formData)).then(() => {
      closePopOut();
    });
  };

  const isCCValid = (value: unknown): value is emailFormat[] =>
    Array.isArray(value) &&
    value.every(
      item =>
        typeof item === 'object' &&
            item !== null &&
            'name' in item &&
            'email' in item &&
            typeof item.name === 'string' &&
            typeof item.email === 'string',
    );

  const handleSend = () => {
    const parseCC = (_cc: unknown) => {
      if (!_cc) return [];

      if (typeof _cc === 'string') {
        return _cc.replace(/ /g, '').split(',').map(email => ({ email, name: '' }));
      }

      if (Array.isArray(_cc)) {
        return isCCValid(_cc) ? _cc : _cc.map(email => ({ email, name: '' }));
      }

      return [];
    };

    const getReceiverObject = () => {
      if (!isEmpty(emailMessage)) {
        return property.shared_email !== emailMessage.sender_email
          ? { email: emailMessage.sender_email, name: emailMessage.sender_name }
          : { email: emailMessage.receiver_email, name: emailMessage.receiver_name };
      }
      return { email: lead.email, name: `${lead.first_name} ${lead.last_name}` };
    };

    const createFormData = (payload) => {
      const formData = new FormData();
      files.forEach(file => formData.append('files', file));
      formData.append('message_data', JSON.stringify(payload));
      return formData;
    };

    const copies = parseCC(cc).filter(item => item.email !== property.shared_email);

    const sender = { email: property.shared_email, name: `The ${property.name} Team` };
    const receiverObj = getReceiverObject();

    const payload = {
      property: property.id,
      body,
      subject,
      sender,
      cc: copies,
      receiver: receiverObj,
      lead: lead?.id,
      send_followup_email: false,
      templateId: selectedTemplate?.id,
      message_id: emailMessage?.id,
      type: emailMessage?.type,
      lease: emailMessage?.lease,
      leasing_user: emailMessage?.leasing_user,
    };

    const formData = createFormData(payload);

    if (isSettedFromPriceQuote && lead?.id) {
      dispatch(updatePriceQuoteById(currentPriceQuote.id, { is_email_sent: true }, lead.id))
        .then(() => sendEmail(formData));
    } else {
      sendEmail(formData);
    }
  };

  let parsedSubject = subject;
  let parsedBody = body;
  if (isPreview) {
    parsedBody = getPreview(body, checkedBodyVariables, true);
    parsedSubject = getPreview(subject, checkedSubjectVariables, false);
  }

  let to = '';
  let avatarColor = '';
  let avatarInitials = '';
  let minimizedName = '';
  if (isEmpty(emailMessage) || lead) {
    to = `${lead?.first_name?.[0] || ''}${lead?.last_name?.[0] || ''}`;
    avatarColor = getAvatarColor(lead.first_name, lead.id);
    avatarInitials = `${lead.first_name[0]}${lead.last_name[0]}`;
    minimizedName = `${lead.first_name} ${lead.last_name}`;
  } else {
    to = `${emailMessage.sender_name} (${emailMessage.sender_email})`;
    avatarColor = 'bg-dark';
    const names = emailMessage?.sender_name?.split(' ') || [];
    avatarInitials = names.length >= 2 ? `${names[0][0]}${names[1][0]}` : `${names[0]}${names[1]}`;
    minimizedName = emailMessage.sender_name;
  }

  return (
    <div className="d-flex align-items-end flex-row-reverse">
      <EmailItemContainer className={minimized ? 'minimize' : ''}>
        <EmailItemHeader className="qc-item-header">
          <div className="media">
            <Avatar hideOnlineIcon className={avatarColor}>
              {avatarInitials}
            </Avatar>
            <div className="media-body" onClick={() => dispatch(toggleMinimizeEmailPopout(popoutId))}>
              <h6 className="mb-0">{minimizedName}</h6>
              <span>{property.name}</span>
            </div>
            <EmailItemHeaderLink onClick={() => closePopOut()}><i className="ri-close-fill" /></EmailItemHeaderLink>
          </div>
        </EmailItemHeader>
        <EmailItemBody className="qc-item-body">
          <EmailItemRecipient>
            <label>To:</label>
            <input type="text" className="form-control" value={to} disabled />
            <Button color="white" style={{ color: '#0168fa' }} onClick={() => dispatch(setEmailPopoutShowingCcInput(popoutId, true))} >Cc</Button>
          </EmailItemRecipient>
          {showingCcInput && (
            <EmailItemRecipient>
              <label>Cc:</label>
              <input
                type="text"
                className="form-control"
                value={cc}
                placeholder="email1@email.com, email2@email.com, ..."
                onChange={handleCcChange}
              />
              <Button color="white" onClick={() => dispatch(setEmailPopoutShowingCcInput(popoutId, false))} >
                <FontAwesomeIcon icon={faTimesCircle} />
              </Button>
            </EmailItemRecipient>
          )}
          <EmailItemRecipient>
            <label>From:</label>
            <input type="text" className="form-control" value={`The ${property.name} Team (${property.shared_email})`} disabled />
          </EmailItemRecipient>
          <EmailItemRecipient>
            <MentionsInput
              singleLine
              value={parsedSubject}
              onChange={handleSubjectChange}
              placeholder="Subject"
              className="popout-subject"
              disabled={isPreview}
            >
              <Mention
                appendSpaceOnAdd
                trigger="["
                markup="[=__display__=]"
                displayTransform={(id, display) => `[=${display}=]`}
                data={(matchInfo, callback) => dataCallback(matchInfo, callback, 'subject')}
                className="subject-variable"
                renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (<div className={`${focused ? 'focused' : ''}`}>{highlightedDisplay}</div>)}
              />
            </MentionsInput>
          </EmailItemRecipient>
          <div className="compose-editor">  {/* We use this as qc-email-body and qc-email-toolbar */}
            <CKEditor
              key={`popout-ckeditor-${popoutId}`}
              onBeforeLoad={() => { window.CKEDITOR.disableAutoInline = true; }}
              id={`popout-ckeditor-${popoutId}`}
              name={`popout-ckeditor-${popoutId}`}
              className="editor"
              data={parsedBody}
              onChange={handleEditorChange}
              config={{
                extraPlugins: 'autogrow',
                extraAllowedContent: 'span(*)',
                scayt_autoStartup: true,
                startupFocus: true,
                placeholder: 'Write some message...',
                placeholder_select: {
                  placeholders: Object.values(availablePlaceholders).map(item => item.name),
                  format: '<span><span class="email-placeholder">[=%placeholder%=]</span>&nbsp;</span>',
                },
                mentions: [{
                  feed: (matchInfo, callback) => dataCallback(matchInfo, callback, 'body'),
                  minChars: 0,
                  marker: '[',
                  itemTemplate: '<li data-id="{id}"><div class="item-title">{title}</div></li>',
                  outputTemplate: '<span><span class="email-placeholder">[={title}=]</span>&nbsp;</span>',
                }],
              }}
            />
            <div>
              {files.map((file, index) => (
                <EmailFile key={index}>
                  <span>{`${file.name} (${convertFileSize(file.size)})`}</span>
                  <a className="attachment-remove">
                    <i className="ri-close-circle-fill" onClick={() => removeFile(file.preview)} />
                  </a>
                </EmailFile>
              ))}
            </div>
          </div>
        </EmailItemBody>
        <EmailItemFooter className="qc-item-footer">
          {<OrderedDropdown
            toggleLabel={selectedTemplate ? selectedTemplate.name : 'Insert Template'}
            selectedValue={selectedTemplate}
            onChange={(_name, value) => handleTemplateChange(value)}
            className="template-selector"
            disabled={isPreview || isSettedFromPriceQuote}
            choices={!isEmpty(filteredEmailTemplates) ? filteredEmailTemplates.map(template => ({ name: template.name, value: template.id })) : []}
          />}
          <FileUpload
            onDropAccepted={e => handleFileAdded(e)}
            title="Upload File"
            dropzoneContainer={() => <UploadIconButton id="upload-file" className="btn mr-1" disabled={isPreview || isSendingEmail}><i className="ri-upload-2-fill" /></UploadIconButton>}
            dropzoneClassname="file-upload"
            disabled={isPreview || isSendingEmail}
          />
          <WhiteButton className="btn btn-icon" onClick={resetTemplate} disabled={isPreview || isSendingEmail || isSettedFromPriceQuote}><i className="ri-close-line" /></WhiteButton>
          {isSendingEmail && <Spinner size="sm" className="mg-l-auto" color="primary" />}
          <WhiteButton
            onClick={() => dispatch(setEmailPopoutIsPreview(popoutId, !isPreview))}
            className={isSendingEmail ? 'btn' : 'btn mg-l-auto'}
            disabled={isSendingEmail}
          >{isPreview ? 'Back to edit mail' : 'Preview'}
          </WhiteButton>
          <PrimaryButton
            color="primary"
            onClick={handleSend}
            className="btn"
            disabled={body.length === 0 || subject.length === 0 || isSendingEmail || Object.values(checkedBodyVariables).some(v => !v)
              || Object.values(checkedSubjectVariables).some(v => !v) || (!emailMessage?.type && (lead === null || lead === undefined))}
          >Send
          </PrimaryButton>
        </EmailItemFooter>
        {isPreview && !minimized && (
          <AlertEmailPreview className="alert alert-primary">
            <i className="ri-eye-fill" />
            <span>You're previewing this email. This is how your recipient will see this email message.</span>
          </AlertEmailPreview>
        )}
      </EmailItemContainer>
    </div>
  );
};

export default EmailPopOut;
