import produce from 'immer';
import { CommonStateType } from 'dwell/store/types';
import { Action as SectionAction, ActionType as SectionActionType } from 'src/corporate/store/section/action-types';
import { Action, ActionType, Page, PageSettings } from './action-types';

export interface PageState extends CommonStateType {
  navPage: 'EDIT_SITE' | 'SITE_SETTINGS' | 'MEDIA_LIBRARY';
  toolbarNavTab: 'PAGES' | 'SECTIONS';
  showPanelSidebar: boolean;
  showPageSettingsPanel: boolean;
  pages: Page[];
  pageSelectedId: number;
  addingPage: boolean;
  renamingPageIndex: number;
  saveButtonEnabled: boolean;
  pageSettingsValues: PageSettings;
  showUnsavedChangesModal: boolean;
  redoButtonEnabled: boolean,
  undoButtonEnabled: boolean,
}

export const initialState: PageState = {
  navPage: 'EDIT_SITE',
  toolbarNavTab: 'PAGES',
  showPanelSidebar: true,
  pages: [],
  pageSelectedId: null,
  addingPage: false,
  renamingPageIndex: null,
  isLoaded: false,
  saveButtonEnabled: false,
  showPageSettingsPanel: false,
  pageSettingsValues: null,
  showUnsavedChangesModal: false,
  redoButtonEnabled: false,
  undoButtonEnabled: false,
};

export const selectToolbarNavTab = (state: { page: PageState }): 'PAGES' | 'SECTIONS' => state.page.toolbarNavTab;
export const selectShowPanelSidebar = (state: { page: PageState }): boolean => state.page.showPanelSidebar;
export const selectShowPageSettingsPanel = (state: { page: PageState }): boolean => state.page.showPageSettingsPanel;
export const selectPages = (state: { page: PageState }): Page[] => state.page.pages;
export const selectPageSelectedId = (state: { page: PageState }): number => state.page.pageSelectedId;
export const selectAddingPage = (state: { page: PageState }): boolean => state.page.addingPage;
export const selectRenamingPageIndex = (state: { page: PageState }): number => state.page.renamingPageIndex;
export const selectSaveButtonEnabled = (state: { page: PageState }): boolean => state.page.saveButtonEnabled;
export const selectNavPage = (state: { page: PageState }): 'EDIT_SITE' | 'SITE_SETTINGS' | 'MEDIA_LIBRARY' => state.page.navPage;
export const selectShowUnsavedChangesModal = (state: { page: PageState }): boolean => state.page.showUnsavedChangesModal;
export const selectRedoButtonEnabled = (state: { page: PageState }): boolean => state.page.redoButtonEnabled;
export const selectUndoButtonEnabled = (state: { page: PageState }): boolean => state.page.undoButtonEnabled;

export default produce((state: PageState = initialState, action: Action|SectionAction): PageState => {
  switch (action.type) {
    case ActionType.SET_NAV_PAGE:
      state.saveButtonEnabled = false;
      state.navPage = action.navPage;
      break;
    case ActionType.GET_SITE_PAGE_DATA_REQUEST:
    case ActionType.GET_SUBSITE_PAGE_DATA_REQUEST:
      state.isLoaded = false;
      break;
    case ActionType.GET_SITE_PAGE_DATA_SUCCESS:
    case ActionType.GET_SUBSITE_PAGE_DATA_SUCCESS:
      state.pages = action.result.data;
      state.isLoaded = true;
      break;

    case ActionType.CORPORATE_SET_PAGE:
      state.pageSelectedId = action.pageId;
      break;

    case ActionType.SET_TOOLBAR_NAV_TAB:
      state.toolbarNavTab = action.nav;
      break;

    case ActionType.TOGGLE_PANEL_SIDEBAR:
      state.showPanelSidebar = !state.showPanelSidebar;
      break;

    case ActionType.ADDING_PAGE:
      state.addingPage = true;
      break;

    case ActionType.ADDING_PAGE_FINISHED:
      state.addingPage = false;
      break;

    case ActionType.RENAME_PAGE:
      state.renamingPageIndex = action.index;
      break;

    case ActionType.RENAME_PAGE_FINISHED:
      state.renamingPageIndex = null;
      break;

    case ActionType.HIDE_SITE_PAGE_SUCCESS: {
      const updatedPage = action.result.data;
      state.pages = state.pages.map((p) => {
        if (p.id === updatedPage.id) {
          return updatedPage;
        }
        return p;
      });
      break;
    }

    case ActionType.HIDE_SUBSITE_PAGE_SUCCESS: {
      const updatedPage = action.result.data;
      state.pages = state.pages.map((p) => {
        if (p.id === updatedPage.id) {
          return updatedPage;
        }
        return p;
      });
      break;
    }

    case ActionType.DUPLICATE_PAGE:
      break;

    case ActionType.DELETE_PAGE:
      state.pages.splice(action.index, 1);
      break;

    case ActionType.SET_ENABLE_SAVE_CHANGES_BUTTON:
      state.saveButtonEnabled = action.enable;
      break;

    case ActionType.PAGE_SECTIONS_UPDATED: {
      const pageIndex = state.pages.findIndex(p => p.id === state.pageSelectedId);
      state.pages[pageIndex].sections = action.sections;
      break;
    }

    case ActionType.PUBLISH_CORPORATE_SITE_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.PUBLISH_CORPORATE_SITE_SUCCESS:
      state.isSubmitting = false;
      state.pages = action.result.data;
      break;
    case ActionType.PUBLISH_CORPORATE_SITE_FAILURE:
      state.isSubmitting = false;
      break;

    case ActionType.TOGGLE_PAGE_SETTINGS_PANEL:
      state.showPageSettingsPanel = action.show;
      break;
    case ActionType.UPDATE_SITE_PAGE_SETTINGS_SUCCESS:
      state.pages = state.pages.map((page) => {
        if (page.id === action.result.data.id) {
          return action.result.data;
        }
        return page;
      });
      break;
    case ActionType.UPDATE_SUB_SITE_PAGE_SETTINGS_SUCCESS:
      state.pages = state.pages.map((page) => {
        if (page.id === action.result.data.id) {
          return action.result.data;
        }
        return page;
      });
      break;
    case ActionType.SET_SHOW_UNSAVED_CHANGES_MODAL:
      state.showUnsavedChangesModal = action.show;
      break;
    case ActionType.ENABLE_REDO_CHANGES_BUTTON:
      state.redoButtonEnabled = action.enable;
      break;
    case ActionType.ENABLE_UNDO_CHANGES_BUTTON:
      state.undoButtonEnabled = action.enable;
      break;
    case SectionActionType.ADD_CORPORATE_SECTION_SUCCESS:
    case SectionActionType.ADD_SUBSITE_SECTION_SUCCESS: {
      const pageIndex = state.pages.findIndex(p => p.id === action.result.data.id);
      state.pages[pageIndex].sections = action.result.data.sections;
      break;
    }

    case SectionActionType.CHANGE_ORDER_CORPORATE_SECTION_SUCCESS:
    case SectionActionType.CHANGE_ORDER_SUBSITE_SECTION_SUCCESS: {
      const pageIndex = state.pages.findIndex(p => p.id === action.result.data.id);
      state.pages[pageIndex].sections = action.result.data.sections;
      state.pages[pageIndex].sections_order = action.result.data.sections_order;
      break;
    }

    case SectionActionType.DELETE_CORPORATE_SECTION_SUCCESS:
    case SectionActionType.DELETE_SUBSITE_SECTION_SUCCESS: {
      const pageIndex = state.pages.findIndex(p => p.id === action.result.data.id);
      state.pages[pageIndex].sections = action.result.data.sections;
      break;
    }
    case SectionActionType.UPDATE_SUBSITE_SECTION_SUCCESS: {
      state.pages = state.pages.map((page) => {
        if (page.id === action.result.data.id) {
          return action.result.data;
        }
        return page;
      });
      break;
    }
    case SectionActionType.UPDATE_CORPORATE_SECTION_SUCCESS: {
      state.pages = state.pages.map((page) => {
        if (page.id === action.result.data.id) {
          return action.result.data;
        }
        return page;
      });
      break;
    }
  }
  return state;
});
