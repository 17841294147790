import { Call as TwilioCall, Device as TwilioDevice } from '@twilio/voice-sdk';
import { PusherAction } from 'dwell/store/pusher/action-types';
import { PropertyProps } from 'dwell/store/property/action-types';
import BackgroundAudioProcessor from 'dwell/store/voice/audio-processor';
import { LeadResidentSearch } from 'dwell/store/pipeline/action-types';

export enum AgentPhoneType {
  BROWSER_PHONE = 'browser',
  DESKTOP_PHONE = 'desktop',
}

export enum AgentStatusChoices {
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available',
}

export enum CallDirectionType {
  INBOUND = 'inbound',
  OUTBOUND = 'outbound',
}

export enum CallWindowBodyComponent {
  NOTES = 'NOTES',
  SUMMARY = 'SUMMARY',
  TRANSFER_CALL = 'TRANSFER_CALL',
  AUDIO_SETTINGS = 'AUDIO_SETTINGS',
  KEYPAD = 'KEYPAD',
  NETWORK_QUALITY = 'NETWORK_QUALITY',
  EMPTY = 'EMPTY',
  REASON_FOR_CALL = 'REASON_FOR_CALL'
}

export enum CallWindowMenuOptions {
  ADD_NEW_LEAD = 'ADD_NEW_LEAD',
  LINK_TO_EXISTING_LEAD = 'LINK_TO_EXISTING_LEAD',
  VIEW_PROSPECT = 'VIEW_PROSPECT',
}

export enum CallTopic {
  LEASING = 'Leasing Team',
  RESIDENT = 'Resident Team',
}

export enum OutboundCallSatus {
  ANSWERED = 'Answered',
  NO_ANSWER = 'No Answer'
}

export interface PusherOutboundCallStatus {
  status: keyof typeof OutboundCallSatus,
}

export const CallDirectionDisplay = {
  INBOUND: 'Inbound',
  OUTBOUND: 'Outbound',
};

export const TOPIC_VALUE = {
  GENERAL: 'General',
  LEASING_TEAM: 'Leasing',
  RESIDENT_TEAM: 'Resident Help',
};

export type TransferReasonType = {
  label: string;
  value: string;
}

export const TRANSFER_REASONS: TransferReasonType[] = [
  { label: 'Asked to be transferred', value: 'ASKED_TO_BE_TRANSFERRED' },
  { label: 'Asked to speak to specific person', value: 'ASKED_TO_SPEAK_TO_SPECIFIC_PERSON' },
  { label: 'Agent could not answer question', value: 'AGENT_COULD_NOT_ANSWER_QUESTION' },
];

export enum ActionType {
  GET_CALL_CONTACTS_REQUEST = 'GET_CALL_CONTACTS_REQUEST',
  GET_CALL_CONTACTS_SUCCESS = 'GET_CALL_CONTACTS_SUCCESS',
  GET_CALL_CONTACTS_FAILURE = 'GET_CALL_CONTACTS_FAILURE',
  CLEAR_CALL_CONTACTS = 'CLEAR_CALL_CONTACTS',

  GET_AVAILABLE_AGENTS_REQUEST = 'GET_AVAILABLE_AGENTS_REQUEST',
  GET_AVAILABLE_AGENTS_SUCCESS = 'GET_AVAILABLE_AGENTS_SUCCESS',
  GET_AVAILABLE_AGENTS_FAILURE = 'GET_AVAILABLE_AGENTS_FAILURE',

  GET_VOICE_TOKEN_REQUEST = 'GET_VOICE_TOKEN_REQUEST',
  GET_VOICE_TOKEN_SUCCESS = 'GET_VOICE_TOKEN_SUCCESS',
  GET_VOICE_TOKEN_FAILURE = 'GET_VOICE_TOKEN_FAILURE',

  SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_REQUEST = 'SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_REQUEST',
  SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_SUCCESS = 'SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_SUCCESS',
  SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_FAILURE = 'SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_FAILURE',

  CREATE_DEVICE = 'CREATE_DEVICE',
  CLEAR_DEVICE = 'CLEAR_DEVICE',
  REFRESH_DEVICE_TOKEN = 'REFRESH_DEVICE_TOKEN',

  TRANSFER_CALL_TO_AGENT_REQUEST = 'TRANSFER_CALL_TO_AGENT_REQUEST',
  TRANSFER_CALL_TO_AGENT_SUCCESS = 'TRANSFER_CALL_TO_AGENT_SUCCESS',
  TRANSFER_CALL_TO_AGENT_FAILURE = 'TRANSFER_CALL_TO_AGENT_FAILURE',

  TRANSFER_CALL_TO_PROPERTY_TEAM_REQUEST = 'TRANSFER_CALL_TO_PROPERTY_TEAM_REQUEST',
  TRANSFER_CALL_TO_PROPERTY_TEAM_SUCCESS = 'TRANSFER_CALL_TO_PROPERTY_TEAM_SUCCESS',
  TRANSFER_CALL_TO_PROPERTY_TEAM_FAILURE = 'TRANSFER_CALL_TO_PROPERTY_TEAM_FAILURE',

  SET_TRANSFER_REASON = 'SET_TRANSFER_REASON',

  SET_INCOMING_CALL = 'SET_INCOMING_CALL',

  SHOW_INCOMING_CALL_NOTIFICATION = 'SHOW_INCOMING_CALL_NOTIFICATION',
  TIME_SHOWING_INCOMING_CALL_NOTIFICATION = 'TIME_SHOWING_INCOMING_CALL_NOTIFICATION',
  CLOSE_CALL_NOTIFICATION = 'CLOSE_CALL_NOTIFICATION',

  INCOMING_CALL_ACCEPTED = 'INCOMING_CALL_ACCEPTED',

  CALL_CONTACT_FROM_BROWSER_PHONE = 'CALL_CONTACT_FROM_BROWSER_PHONE',

  SET_CALLING_FROM_DESKTOP_PROSPECT_NAME = 'SET_CALLING_FROM_DESKTOP_PROSPECT_NAME',

  CALL_CONTACT_FROM_DESKTOP_PHONE_REQUEST = 'CALL_CONTACT_FROM_DESKTOP_PHONE_REQUEST',
  CALL_CONTACT_FROM_DESKTOP_PHONE_SUCCESS = 'CALL_CONTACT_FROM_DESKTOP_PHONE_SUCCESS',
  CALL_CONTACT_FROM_DESKTOP_PHONE_FAILURE = 'CALL_CONTACT_FROM_DESKTOP_PHONE_FAILURE',

  CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_REQUEST = 'CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_REQUEST',
  CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_SUCCESS = 'CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_SUCCESS',
  CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_FAILURE = 'CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_FAILURE',

  HANGUP_DESKTOP_CALL_REQUEST = 'HANGUP_DESKTOP_CALL_REQUEST',
  HANGUP_DESKTOP_CALL_SUCCESS = 'HANGUP_DESKTOP_CALL_SUCCESS',
  HANGUP_DESKTOP_CALL_FAILURE = 'HANGUP_DESKTOP_CALL_FAILURE',

  COLLAPSE_CALL_WINDOW = 'COLLAPSE_CALL_WINDOW',
  SHOW_CALL_WINDOW = 'SHOW_CALL_WINDOW',

  CLEAR_IN_PROGRESS_CALL = 'CLEAR_IN_PROGRESS_CALL',

  UPDATE_IN_PROGRESS_CALL = 'UPDATE_IN_PROGRESS_CALL',

  SET_CALL_WINDOW_BODY_COMPONENT = 'SET_CALL_WINDOW_BODY_COMPONENT',
  TOGGLE_MUTE = 'TOGGLE_MUTE',
  SET_CALL_TIME = 'SET_CALL_TIME',
  SET_CALL_NOTES = 'SET_CALL_NOTES',
  SET_CALL_FINISHED = 'SET_CALL_FINISHED',
  SET_OUTBOUND_CALL_STATUS = 'SET_OUTBOUND_CALL_STATUS',

  PUSHER_DESKTOP_PHONE_CALL_ANSWERED = 'PUSHER_DESKTOP_PHONE_CALL_ANSWERED',
  PUSHER_DESKTOP_PHONE_CALL_COMPLETED = 'PUSHER_DESKTOP_PHONE_CALL_COMPLETED',
  PUSHER_BROWSER_PHONE_CALL_ANSWERED = 'PUSHER_BROWSER_PHONE_CALL_ANSWERED',

  SET_HAS_MICROPHONE_ACCESS = 'SET_HAS_MICROPHONE_ACCESS',
  SET_SHOW_ASK_FOR_MICROPHONE_ACCESS = 'SET_SHOW_ASK_FOR_MICROPHONE_ACCESS',

  CREATE_DEVICE_WITHOUT_NOISE_CANCELLING = 'CREATE_DEVICE_WITHOUT_NOISE_CANCELLING',
  CLEAR_IN_PROGRESS_CALL_WITHOUT_NOISE_CANCELLING = 'CLEAR_IN_PROGRESS_CALL_NOT_NOISE_CANCEL',

  SHOW_DIAL_WINDOW = 'SHOW_DIAL_WINDOW',
  HIDE_DIAL_WINDOW = 'HIDE_DIAL_WINDOW',

  SET_DIALED_PHONE_NUMBER = 'SET_DIALED_PHONE_NUMBER',
  SET_DIALED_PROPERTY = 'SET_DIALED_PROPERTY',
  SET_DIALED_CONTACT = 'SET_DIALED_CONTACT',

  ON_HOLD_REQUEST = 'ON_HOLD_REQUEST',
  ON_HOLD_SUCCESS = 'ON_HOLD_SUCCESS',
  ON_HOLD_FAILURE = 'ON_HOLD_FAILURE',

  END_CONFERENCE_REQUEST = 'END_CONFERENCE_REQUEST',
  END_CONFERENCE_SUCCESS = 'END_CONFERENCE_SUCCESS',
  END_CONFERENCE_FAILURE = 'END_CONFERENCE_FAILURE',
}

export interface InProgressCall {
  direction: CallDirectionType,
  collapsed: boolean,
  prospectName: string,
  prospectType: string,
  leadId?: number,
  leaseId?: number,
  leasingUserId?: number,
  applicationId?: number,
  propertyId: number,
  propertyExternalId: string,
  propertyName: string,
  sourceName?: string,
  topic?: string,
  phoneType: AgentPhoneType,
  call?: TwilioCall,
  callSid?: string,
  conferenceName?: string,
  callId?: number,
  areConferenceFeatureesEnabled?: boolean,
  participantLabel?: string,
}

export interface CallContact {
  id: number,
  name: string,
  property: number,
  phone_number: string,
  property_external_id: string,
  property_name: string,
  owner: number,
}

export interface Agent {
  id: number,
  name: string,
  role: string,
  office: string,
}

export interface PusherConferenceStarted {
  conference_name: string,
  call_sid: number,
  conference_sid: string,
}

export interface PropertyTeam {
  id: number,
  name: string,
  role: string,
}

export interface VoiceState {
  device: TwilioDevice,
  callContacts: CallContact[],
  allContactsCount: number,
  downloadedCount: number,
  loading: boolean,
  availableAgents: Agent[],
  propertyTeam: PropertyTeam,
  loadingAvailableAgents: boolean,
  errorMessage: string,
  voiceToken: string,
  transfering: boolean,
  callTransferred: boolean,
  transferReason: { label: string, value: string },
  transferEventId: number,
  showingIncomingCallNotification: boolean,
  timeShowingIncomingCallNotification: number,
  inProgressCall: InProgressCall,
  incomingCall: TwilioCall, // To handle inbound notification before agent accepts/reject the call
  callTime: number,
  callConnected: boolean, // To know when the call time should start
  notes: string,
  bodyComponent: CallWindowBodyComponent,
  muted: boolean,
  callFinished: boolean, // Agent hung up the inProgressCall but we need to see CallSummary
  outboundCallStatus: OutboundCallSatus, // To know if lead answered the call
  callingFromDesktopProspectName: string,
  desktopPhoneCallCompleted: boolean, // to handle once the call on desktop phone end
  desktopPhoneCallStarted: boolean,
  hasMicrophoneAccess: boolean,
  showAskForMicrophoneAccess: boolean,
  audioProcessor: BackgroundAudioProcessor,
  openDialWindow: boolean,
  dialedPhoneNumber: string,
  dialedProperty: PropertyProps,
  dialedContact: LeadResidentSearch,
  conferenceName: string,
  callSid: number,
  conferenceSid: string,
  onHold: boolean,
  onHoldLoading: boolean,
}

interface TransferCallBaseParams {
  CallSid: string,
  direction: string,
  topic: CallTopic,
  ConferenceSid: string
}

export interface TransferCallToAgentParams extends TransferCallBaseParams {
  agent_id: number
}

export interface TransferCallToPropertyTeamParams extends TransferCallBaseParams {
  property: number,
}

export interface LeadCallInfo {
  prospectName: string,
  prospectType: string,
  leadId: number,
}

export interface InboundCallParams {
  prospectName: string,
  prospectType: string,
  leadId?: number,
  propertyId: number,
  propertyExternalId: string,
  propertyName: string,
  sourceName: string,
  topic: string,
  call: TwilioCall,
}

export interface OutboundCallParams {
  prospectName: string,
  propertyName: string,
  leadId?: number,
  applicationId?: number,
  leaseId?: number,
  leasingUserId?: number,
  propertyId: number,
  propertyExternalId: string,
  phoneType: AgentPhoneType,
  call: TwilioCall,
  prospectType?: string,
}

export interface DesktopPhoneCall {
  caller_name: string,
  property_name: string,
  property_id: number,
  property_external_id: string,
  lead_id?: number,
  lease_id?: number,
  application_id?: number,
  leasing_user_id?: number,
  caller_type: string,
  source_name: string,
  topic: string,
  original_call_sid: string,
  direction: CallDirectionType,
  participant_label?: string,
  conference_sid?: string,
}

export interface CallFromDesktopPhoneParams {
  prospect_phone: string,
  property_id: number,
  lead_id?: number,
  lease_id?: number,
  application_id?: number,
  leasing_user_id?: number,
}

export interface CallCustomParams {
  propertyId: number,
  leadId: number,
}

export interface InboundCallCustomParams extends CallCustomParams {
  prospectName: string,
  prospectType: string,
  propertyName: string,
  propertyExternalId: string,
  leaseId?: number,
  leasingUserId?: number,
  applicationId?: number,
  sourceName: string,
  topic: string,
  originalCallSid: string,
  areConferenceFeaturesEnabled: boolean,
}

export interface OutboundCallCustomParams extends CallCustomParams {
  to: string,
}

interface CallContactsAction {
  type: ActionType.GET_CALL_CONTACTS_REQUEST | ActionType.GET_CALL_CONTACTS_SUCCESS | ActionType.GET_CALL_CONTACTS_FAILURE;
  result: { data: { results: CallContact[], count: number } };
  error: { response: { status: string } };
}

interface ClearCallContactsActions {
  type: ActionType.CLEAR_CALL_CONTACTS,
}

interface AvailableAgentsAction {
  type: ActionType.GET_AVAILABLE_AGENTS_REQUEST | ActionType.GET_AVAILABLE_AGENTS_SUCCESS | ActionType.GET_AVAILABLE_AGENTS_FAILURE;
  result: { data: { agents: Agent[], property_team: PropertyTeam } };
  error: { response: { status: string } };
}

interface VoiceTokenAction {
  type: ActionType.GET_VOICE_TOKEN_REQUEST | ActionType.GET_VOICE_TOKEN_SUCCESS | ActionType.GET_VOICE_TOKEN_FAILURE;
  result: { data: { voice_token: string } };
  error: { response: { status: string } };
}

interface CreateDeviceAction {
  type: ActionType.CREATE_DEVICE,
}

interface CreateDeviceWithoutNoiseSuppressionAction {
  type: ActionType.CREATE_DEVICE_WITHOUT_NOISE_CANCELLING,
}

interface SetAsUnavailableToReceiveCallsAction {
  type: ActionType.SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_REQUEST |
  ActionType.SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_SUCCESS |
  ActionType.SET_AS_UNAVAILABLE_TO_RECEIVE_CALLS_FAILURE,
  result: unknown;
  error: { response: { status: string } };
}

interface ClearDeviceAction {
  type: ActionType.CLEAR_DEVICE,
}

interface RefreshDeviceTokenAction {
  type: ActionType.REFRESH_DEVICE_TOKEN,
}

interface TransferCallToAgentAction {
  type: ActionType.TRANSFER_CALL_TO_AGENT_REQUEST | ActionType.TRANSFER_CALL_TO_AGENT_SUCCESS | ActionType.TRANSFER_CALL_TO_AGENT_FAILURE;
  result: { data: { transfer_event_id: number } };
  error: { response: { status: string } };
}

interface TransferCallToPropertyTeamAction {
  type: ActionType.TRANSFER_CALL_TO_PROPERTY_TEAM_REQUEST | ActionType.TRANSFER_CALL_TO_PROPERTY_TEAM_SUCCESS | ActionType.TRANSFER_CALL_TO_PROPERTY_TEAM_FAILURE;
  result: { data: { transfer_event_id: number } };
  error: { response: { status: string } };
}

interface SetTransferReasonAction {
  type: ActionType.SET_TRANSFER_REASON;
  reason: TransferReasonType;
}

interface SetIncomingCal {
  type: ActionType.SET_INCOMING_CALL,
  call: TwilioCall,
}

interface ShowIncomingCallNotification {
  type: ActionType.SHOW_INCOMING_CALL_NOTIFICATION
}

interface SetTimeShowingIncomingCallNotification {
  type: ActionType.TIME_SHOWING_INCOMING_CALL_NOTIFICATION,
  time: number,
}

interface IncomingCallAcceptedAction {
  type: ActionType.INCOMING_CALL_ACCEPTED,
  payload: InProgressCall,
}

interface CloseCallNotificationAction {
  type: ActionType.CLOSE_CALL_NOTIFICATION
}

interface CallContactFromBrowserPhoneAction {
  type: ActionType.CALL_CONTACT_FROM_BROWSER_PHONE,
  payload: InProgressCall,
}

interface CallContactFromDesktopPhoneAction {
  type: ActionType.CALL_CONTACT_FROM_DESKTOP_PHONE_REQUEST | ActionType.CALL_CONTACT_FROM_DESKTOP_PHONE_SUCCESS | ActionType.CALL_CONTACT_FROM_DESKTOP_PHONE_FAILURE;
  result: unknown;
  error: { response: { status: string } };
}

interface CallContactFromPropertyOfficeLineAction {
  type:
  ActionType.CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_REQUEST | ActionType.CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_SUCCESS| ActionType.CALL_CONTACT_FROM_PROPERTY_OFFICE_LINE_FAILURE;
  result: unknown;
  error: { response: { status: string } };
}

interface SetCallingFromDesktopProspectNameAction {
  type: ActionType.SET_CALLING_FROM_DESKTOP_PROSPECT_NAME,
  prospectName: string,
}

interface CollapseCallWindowAction {
  type: ActionType.COLLAPSE_CALL_WINDOW
}

interface ShowCallWindowAction {
  type: ActionType.SHOW_CALL_WINDOW
}

interface ShowDialWindowAction {
  type: ActionType.SHOW_DIAL_WINDOW
}

interface HideDialWindowAction {
  type: ActionType.HIDE_DIAL_WINDOW
}

interface ClearInProgressCallAction {
  type: ActionType.CLEAR_IN_PROGRESS_CALL
}

interface ClearInProgressCallWithoutNoiseSuppressionAction {
  type: ActionType.CLEAR_IN_PROGRESS_CALL_WITHOUT_NOISE_CANCELLING
}

interface UpdateInProgressCallAction {
  type: ActionType.UPDATE_IN_PROGRESS_CALL,
  leadInfo: LeadCallInfo,
}

interface SetCallWindowBodyComponentAction {
  type: ActionType.SET_CALL_WINDOW_BODY_COMPONENT,
  body: CallWindowBodyComponent,
}

interface SetCallFinishedAction {
  type: ActionType.SET_CALL_FINISHED,
}

interface ToggleMuteAction {
  type: ActionType.TOGGLE_MUTE,
  muted: boolean,
}
interface SetCallTimeAction {
  type: ActionType.SET_CALL_TIME,
  time: number,
}

interface SetCallNotesAction {
  type: ActionType.SET_CALL_NOTES,
  notes: string,
}

interface SetOutboundCallStatusAction {
  type: ActionType.SET_OUTBOUND_CALL_STATUS,
  status: OutboundCallSatus,
}

interface SetMicrophoneEnabledAction {
  type: ActionType.SET_HAS_MICROPHONE_ACCESS,
  enabled: boolean,
}

interface SetShowAskForMicrophoneAccess {
  type: ActionType.SET_SHOW_ASK_FOR_MICROPHONE_ACCESS,
  show: boolean,
}

interface SetDialedPhoneNumber {
  type: ActionType.SET_DIALED_PHONE_NUMBER,
  phone: string,
}

interface SetDialedProperty {
  type: ActionType.SET_DIALED_PROPERTY,
  property: PropertyProps,
}

interface SetDialedContact {
  type: ActionType.SET_DIALED_CONTACT,
  contact: LeadResidentSearch,
}

interface OnHoldAction {
  type: ActionType.ON_HOLD_REQUEST | ActionType.ON_HOLD_SUCCESS | ActionType.ON_HOLD_FAILURE;
  result: { data: { on_hold: boolean } };
  error: { response: { status: string } };
}

interface EndConferenceAction {
  type: ActionType.END_CONFERENCE_REQUEST | ActionType.END_CONFERENCE_SUCCESS | ActionType.END_CONFERENCE_FAILURE;
  result: unknown;
  error: { response: { status: string } };
}

export type Action =
  CallContactsAction |
  ClearCallContactsActions |
  AvailableAgentsAction |
  VoiceTokenAction |
  CreateDeviceAction |
  ClearDeviceAction |
  RefreshDeviceTokenAction |
  SetAsUnavailableToReceiveCallsAction |
  TransferCallToAgentAction |
  TransferCallToPropertyTeamAction |
  SetTransferReasonAction |
  SetIncomingCal |
  ShowIncomingCallNotification |
  SetTimeShowingIncomingCallNotification |
  IncomingCallAcceptedAction |
  CloseCallNotificationAction |
  CallContactFromBrowserPhoneAction |
  CallContactFromDesktopPhoneAction |
  CallContactFromPropertyOfficeLineAction |
  SetCallingFromDesktopProspectNameAction |
  CollapseCallWindowAction |
  ClearInProgressCallAction |
  UpdateInProgressCallAction |
  ShowCallWindowAction |
  ShowDialWindowAction |
  HideDialWindowAction |
  SetCallTimeAction |
  SetCallNotesAction |
  SetCallWindowBodyComponentAction |
  SetCallFinishedAction |
  SetOutboundCallStatusAction |
  ToggleMuteAction |
  SetMicrophoneEnabledAction |
  SetShowAskForMicrophoneAccess |
  SetDialedPhoneNumber |
  SetDialedProperty |
  SetDialedContact |
  PusherAction |
  ClearInProgressCallWithoutNoiseSuppressionAction |
  CreateDeviceWithoutNoiseSuppressionAction |
  OnHoldAction |
  EndConferenceAction;
