import { SiteType } from '../../iframe';

export const dynamicValues = [
  // Lease Default Fields
  'approved_security_deposit',
  'approved_non_refundable_premium_fee',
  'pet_rent',
  'pet_fee',
  'pet_deposit',
  'pet_non_refundable_deposit',
  'unauthorized_pet_fee',
  'sales_tax',
  'valet_waste',
  'facilities_fee',
  'non_refundable_administration_fee',
  'application_fee',
  'guarantor_application_fee',
  'corporate_application_fee',
  'document_update_change',
  'replacement_key_fee',
  'month_to_month_fee',
  'month_to_month_fee_mode',
  'early_termination_fee',
  'early_termination_fee_mode',
  'apartment_transfer_fee',
  'late_charges',
  'late_charges_mode',
  'late_charges_after_days',
  'late_charges_per_day',
  'lease_breach_fee',
  'dishonored_funds_charge',
  'insurance_coverage_minimum',
  'insurance_violation_fee',
  'storage_unit_late_fee',
  'storage_unit_late_fee_mode',
  'storage_unit_late_fee_after_days',
  'electric_company_website',
  'electric_company_phone_number',
  'electric_company_name',
  'gas_company_website',
  'gas_company_phone_number',
  'gas_company_name',
  'special_provisions',
  'community_manager_name',
  'management_office_phone',
  'management_office_address',
  'is_default_setting',
  'no_lease_first_violation_fee',
  'no_lease_subsequent_violation_fee',
  'pet_waste_first_violation_fee',
  'pet_waste_subsequent_violation_fee',
  'trash_left_out_fee',
  'trash_container_replacement_fee',
  'facilities_late_fee',
  'unlock_after_hours_fee',
  'fob_replacement_fee',
  'towing_company_website',
  'towing_company_phone_number',
  'towing_company_name',
  'management_fax_number',
  'lease_document',

  // Property Fields
  'property.name',
  'property.external_id',
  'property.site_plan_embed',

  // Community Details Fields
  'property.community_details.phone',
  'property.community_details.city',
  'property.community_details.country',
  'property.community_details.postal_code',
  'property.community_details.state',
  'property.community_details.street',
];

export const SITE_TYPES: {
  [key: string]: SiteType
} = { SUBSITE: 'subsite', CORPORATE: 'corporate', STANDALONE: 'standalone' };

export const OBJECT_FIT_OPTIONS = [
  { label: 'Fill', value: 'cover', description: 'Fills your entire container so there is no gaps, but some of the picture might be outside the edges of your monitor ("cropped out of view")' },
  { label: 'Fit', value: 'contain', description: 'Fits the entire picture in the container so you can see it all, but if the picture is not the rigth size there may be gaps.' },
  { label: 'Stretch', value: 'fill', description: 'Forces the picture to fit the entire container by stretching/squeezing it to your container shape. The image might appear distorted.' },
];
