import { PromiseActionType } from 'dwell/store/types';
import { PusherAction } from 'dwell/store/pusher/action-types';
import { GetLeadByIdAction } from 'dwell/store/lead/action-types';
import { PropertyProps } from '../property/action-types';

export enum ActionType {
  GET_CALLS_REQUEST = 'GET_CALLS_REQUEST',
  GET_CALLS_SUCCESS = 'GET_CALLS_SUCCESS',
  GET_CALLS_FAILURE = 'GET_CALLS_FAILURE',
  GET_CALL_BY_ID_REQUEST = 'GET_CALL_BY_ID_REQUEST',
  GET_CALL_BY_ID_SUCCESS = 'GET_CALL_BY_ID_SUCCESS',
  GET_CALL_BY_ID_FAILURE = 'GET_CALL_BY_ID_FAILURE',
  UPDATE_CALL_REQUEST = 'UPDATE_CALL_REQUEST',
  UPDATE_CALL_SUCCESS = 'UPDATE_CALL_SUCCESS',
  UPDATE_CALL_FAILURE = 'UPDATE_CALL_FAILURE',
  ARCHIVE_CALLS_REQUEST = 'ARCHIVE_CALLS_REQUEST',
  ARCHIVE_CALLS_SUCCESS = 'ARCHIVE_CALLS_SUCCESS',
  ARCHIVE_CALLS_FAILURE = 'ARCHIVE_CALLS_FAILURE',
  ARCHIVE_CALL_REQUEST = 'ARCHIVE_CALL_REQUEST',
  ARCHIVE_CALL_SUCCESS = 'ARCHIVE_CALL_SUCCESS',
  ARCHIVE_CALL_FAILURE = 'ARCHIVE_CALL_FAILURE',
  SAVE_CALL_NOTES_REQUEST = 'SAVE_CALL_NOTES_REQUEST',
  SAVE_CALL_NOTES_SUCCESS = 'SAVE_CALL_NOTES_SUCCESS',
  SAVE_CALL_NOTES_FAILURE = 'SAVE_CALL_NOTES_FAILURE',
  UNARCHIVE_CALL_FAILURE = 'UNARCHIVE_CALL_FAILURE',
  UNARCHIVE_CALL_REQUEST = 'UNARCHIVE_CALL_REQUEST',
  UNARCHIVE_CALL_SUCCESS = 'UNARCHIVE_CALL_SUCCESS',
}

export interface Event {
  to_location: string,
  from_location: string,
  to_agent: string,
  from_agent: string,
  transfer_reason: string,
  is_from_hobbes: boolean,
}

export interface Leg {
  id: number,
  agent: string,
  call_result: string,
  duration: number,
  from_number: string,
  to_number: string,
}

interface VapiTranscriptBlock {
  text: string;
  type: string;
  data: {
    start: number;
    end: number;
    speaker: string;
  };
}

export interface VapiTranscript {
  blocks: VapiTranscriptBlock[];
  entityMap: Record<string, unknown>;
}

export interface CallProps {
  id: number;
  score?: string;
  prev_score?: string;
  rescore_reason?: string;
  recording?: string;
  transcription?: string;
  is_test_number: boolean;
  source?: string;
  prospect_phone_number?: string;
  duration: number;
  call_id: string;
  is_transcribed: boolean;
  date: string;
  is_archived: boolean;
  is_removed: boolean;
  call_start_time: string;
  tracking_number: string;
  target_number: string;
  call_result: string;
  call_category: string;
  recording_url: string;
  resman_event_id: string;
  topic: string;
  answer_location: string;
  name?: string;
  lead: number;
  property: number;
  expanded?: boolean,
  transfer_events: Event[],
  legs: Leg[],
  handled_by?: string;
  direction?: string;
  is_from_hobbes?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deepgram_transcription?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  amazon_format_transcription?: any;
  vapi_transcript?: VapiTranscript;
  property_basic_info?: PropertyProps;
  known_caller?: boolean;
}

export interface CallSummary {
  call_sid?: string,
  notes: string,
  lead_id?: number,
  lease_id?: number,
  application_id?: number,
  transfer_reason?: string,
  transfer_event_id?: number,
  call_category?: 'PROSPECT' | 'RESIDENT' | 'OTHER',
  current_resident?: number,
  leasing_user?: number,
}

interface GetCallsAction {
  type: ActionType.GET_CALLS_REQUEST | ActionType.GET_CALLS_SUCCESS | ActionType.GET_CALLS_FAILURE;

  result: {
    data: { results: CallProps[]; count: number };
  };
  error: {
    response: { status: string };
  };
}

interface GetCallByIdAction {
  type: ActionType.GET_CALL_BY_ID_REQUEST | ActionType.GET_CALL_BY_ID_SUCCESS | ActionType.GET_CALL_BY_ID_FAILURE;

  result: {
    data: CallProps;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateCallByIdAction {
  type: ActionType.UPDATE_CALL_REQUEST | ActionType.UPDATE_CALL_SUCCESS | ActionType.UPDATE_CALL_FAILURE;

  result: {
    data: CallProps;
  };
  error: {
    response: { status: string };
  };
}

interface ArchiveCallsAction {
  type: ActionType.ARCHIVE_CALL_REQUEST | ActionType.ARCHIVE_CALL_SUCCESS | ActionType.ARCHIVE_CALL_FAILURE;

  result: {
    data: CallProps;
  };
  error: {
    response: { status: string };
  };
}

interface ArchiveCallAction {
  type: ActionType.ARCHIVE_CALLS_REQUEST | ActionType.ARCHIVE_CALLS_SUCCESS | ActionType.ARCHIVE_CALLS_FAILURE;

  error: {
    response: { status: string };
  };
}

interface saveCallSummaryAction {
  type: ActionType.SAVE_CALL_NOTES_REQUEST | ActionType.SAVE_CALL_NOTES_SUCCESS | ActionType.SAVE_CALL_NOTES_FAILURE;

  result: {
    data: CallProps;
  };
  error: {
    response: { status: string };
  };
}

interface UnarchiveCallAction {
  type: ActionType.UNARCHIVE_CALL_REQUEST | ActionType.UNARCHIVE_CALL_SUCCESS | ActionType.UNARCHIVE_CALL_FAILURE;

  result: {
    data: CallProps;
  };
  error: {
    response: { status: string };
  };
}

export type Action = PromiseActionType | GetCallsAction | GetCallByIdAction | UpdateCallByIdAction | ArchiveCallsAction | ArchiveCallAction | PusherAction | GetLeadByIdAction | saveCallSummaryAction | UnarchiveCallAction;
