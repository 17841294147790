import { PromiseActionType } from 'dwell/store/types';

export enum RequestPriorityTypes {
  LOWEST = 'LOWEST',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  HIGHEST = 'HIGHEST',
}

export const RequestPrioritiesOptions = {
  [RequestPriorityTypes.LOWEST]: 'Lowest',
  [RequestPriorityTypes.LOW]: 'Low',
  [RequestPriorityTypes.MEDIUM]: 'Medium',
  [RequestPriorityTypes.HIGH]: 'High',
  [RequestPriorityTypes.HIGHEST]: 'High',
} as const;

export type RequestPriorityType = keyof typeof RequestPrioritiesOptions;

export enum RequestTypes {
  SUPPORT_REQUEST = 'SUPPORT',
  FEATURE_REQUEST = 'FEATURE',
}

export const RequestOptions = {
  [RequestTypes.SUPPORT_REQUEST]: 'Support',
  [RequestTypes.FEATURE_REQUEST]: 'Feature',
} as const;

export type RequestOptionsType = keyof typeof RequestOptions;

export enum RequestStatusTypes {
  // OLD STATUSES
  OPEN = 'OPEN',
  ALL_OPEN = 'ALL_OPEN',
  DECLINED = 'DECLINED',
  SUPPORT_IN_PROGRESS = 'SUPPORT_IN_PROGRESS',
  ENG_IN_PROGRESS = 'ENG_IN_PROGRESS',
  AWAITING_THIRD_PARTY = 'AWAITING_THIRD_PARTY',
  AWAITING_CUSTOMER = 'AWAITING_CUSTOMER',
  CUSTOMER_REPLIED = 'CUSTOMER_REPLIED',
  AWAITING_ENG = 'AWAITING_ENG',

  // CRM Statuses
  ALL = 'ALL',
  AWAITING_PRIORITIZATION = 'AWAITING_PRIORITIZATION',
  PRIORITIZED = 'PRIORITIZED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ARCHIVED = 'ARCHIVED',
}

export enum RequestSortTypes {
  REQUEST_SORT_STARRED = 'starred',
  REQUEST_SORT_RANK = 'rank',
  REQUEST_SORT_JIRA_KEY = 'jira_key',
  REQUEST_SORT_SUMMARY = 'summary',
  REQUEST_SORT_STATUS = 'status',
  REQUEST_SORT_REQUESTER_NAME = 'requester',
  REQUEST_SORT_JIRA_CREATED_ON = 'jira_created_on',
}

export const RequestStatusOptions = {
  [RequestStatusTypes.ALL]: 'All',
  [RequestStatusTypes.ALL_OPEN]: 'All Open Requests',

  [RequestStatusTypes.OPEN]: 'Awaiting Prioritization',
  [RequestStatusTypes.AWAITING_PRIORITIZATION]: 'Awaiting Prioritization',
  [RequestStatusTypes.AWAITING_THIRD_PARTY]: 'Awaiting Prioritization',
  [RequestStatusTypes.AWAITING_CUSTOMER]: 'Awaiting Prioritization',
  [RequestStatusTypes.AWAITING_ENG]: 'Awaiting Prioritization',

  [RequestStatusTypes.PRIORITIZED]: 'Prioritized',

  [RequestStatusTypes.DONE]: 'Done',
  [RequestStatusTypes.ARCHIVED]: 'Archived',
  [RequestStatusTypes.DECLINED]: 'Archived',

  [RequestStatusTypes.IN_PROGRESS]: 'In Progress',
  [RequestStatusTypes.SUPPORT_IN_PROGRESS]: 'In Progress',
  [RequestStatusTypes.ENG_IN_PROGRESS]: 'In Progress',
  [RequestStatusTypes.CUSTOMER_REPLIED]: 'In Progress',
} as const;

export type RequestStatusOptionsType = keyof typeof RequestStatusOptions;

export enum RequesterFilterTypes {
  REQUESTER_MY_REQUESTS = 'MY_REQUESTS',
  REQUESTER_ALL_REQUESTS = 'ALL_REQUESTS',
  REQUESTER_PARTICIPANT = 'PARTICIPANT',
}

export const RequesterFilterOptions = {
  [RequesterFilterTypes.REQUESTER_MY_REQUESTS]: 'My Requests',
  [RequesterFilterTypes.REQUESTER_ALL_REQUESTS]: 'All Requests',
  [RequesterFilterTypes.REQUESTER_PARTICIPANT]: 'Where I am a participant',
} as const;

export type RequesterFilterOptionsType = keyof typeof RequesterFilterOptions;

export const RequestSortOptions = {
  '': 'None',
  [RequestSortTypes.REQUEST_SORT_STARRED]: 'Starred',
  [RequestSortTypes.REQUEST_SORT_RANK]: 'Rank',
  [RequestSortTypes.REQUEST_SORT_JIRA_KEY]: 'Reference',
  [RequestSortTypes.REQUEST_SORT_SUMMARY]: 'Summary',
  [RequestSortTypes.REQUEST_SORT_STATUS]: 'Status',
  [RequestSortTypes.REQUEST_SORT_REQUESTER_NAME]: 'Requester',
  [RequestSortTypes.REQUEST_SORT_JIRA_CREATED_ON]: 'Date Created',
} as const;

export type RequestSortOptionsType = keyof typeof RequestSortOptions;

export const RequestSortOrderOptions = {
  '': 'None',
  asc: 'Ascending',
  desc: 'Descending',
} as const;

export type RequestSortOrderOptionsType = keyof typeof RequestSortOrderOptions;

export interface AttachmentInfo {
  id: number;
  name: string;
  url: string;
  size: number;
}

export interface Comment {
  id: number;
  author: string;
  avatar: string;
  created: string;
  updated: string;
  body: string;
}

export interface Request {
  id: number;
  created: string;
  updated: string;
  type: RequestOptionsType;
  jira_key: string;
  summary: string;
  description: string;
  status: RequestStatusOptionsType;
  requester: number;
  requester_name: string;
  attachments_info: AttachmentInfo[];
  priority: RequestPriorityType;
  rank: number;
  jira_created_on: string;
  comments: Comment[];
}

export interface GetRequestsParams {
  offset?: number;
  limit?: number;
  page?: number;
  search?: string;
  type: RequestOptionsType;
  status: RequestStatusOptionsType;
  requester: RequesterFilterOptionsType;
  priority: RequestPriorityType|null;
  order: RequestSortOrderOptionsType;
  field: RequestSortOptionsType;
}

export interface UpdateRequestParams {
  priority?: RequestPriorityType;
  starred?: boolean;
  readed_by_user_ids?: number[];
}

export interface SupportPortalState {
  requests: Request[];
  requestsCount: number;
  requestsLoaded: boolean;
  requestComments: Comment[];
  requestCommentsLoaded: boolean;
  requestIssueDescriptionParsed: string;
  newCommentLoading: boolean;
  errorMessage: string;
}

export interface SupportPortalRow {
  id: number,
  type: string,
  jira_key: string,
  summary: string,
  status: RequestStatusTypes,
  requester: number,
  requester_name: string,
  created: string,
  rank: number,
  starred: boolean,
  readed_by_user_ids: number[],
}

export interface SupportPortalColumn {
  dataField: string;
  formatter: (cell: SupportPortalRow[keyof SupportPortalRow], row: SupportPortalRow) => JSX.Element;
  headerFormatter?: () => JSX.Element;
}

export enum ActionType {
  GET_REQUESTS_REQUEST = 'GET_REQUESTS_REQUEST',
  GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS',
  GET_REQUESTS_FAILURE = 'GET_REQUESTS_FAILURE',

  CREATE_REQUEST_REQUEST = 'CREATE_REQUEST_REQUEST',
  CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS',
  CREATE_REQUEST_FAILURE = 'CREATE_REQUEST_FAILURE',

  DELETE_REQUEST_REQUEST = 'DELETE_REQUEST_REQUEST',
  DELETE_REQUEST_SUCCESS = 'DELETE_REQUEST_SUCCESS',
  DELETE_REQUEST_FAILURE = 'DELETE_REQUEST_FAILURE',

  REARRANGE_RANK_REQUEST = 'REARRANGE_RANK_REQUEST',
  REARRANGE_RANK_SUCCESS = 'REARRANGE_RANK_SUCCESS',
  REARRANGE_RANK_FAILURE = 'REARRANGE_RANK_FAILURE',

  UPDATE_REQUEST_REQUEST = 'UPDATE_REQUEST_REQUEST',
  UPDATE_REQUEST_SUCCESS = 'UPDATE_REQUEST_SUCCESS',
  UPDATE_REQUEST_FAILURE = 'UPDATE_REQUEST_FAILURE',

  UPDATE_REQUEST_READED_BY_USER_IDS_REQUEST = 'UPDATE_REQUEST_READED_BY_USER_IDS_REQUEST',
  UPDATE_REQUEST_READED_BY_USER_IDS_SUCCESS = 'UPDATE_REQUEST_READED_BY_USER_IDS_SUCCESS',
  UPDATE_REQUEST_READED_BY_USER_IDS_FAILURE = 'UPDATE_REQUEST_READED_BY_USER_IDS_FAILURE',

  GET_REQUEST_COMMENTS_REQUEST = 'GET_REQUEST_COMMENTS_REQUEST',
  GET_REQUEST_COMMENTS_SUCCESS = 'GET_REQUEST_COMMENTS_SUCCESS',
  GET_REQUEST_COMMENTS_FAILURE = 'GET_REQUEST_COMMENTS_FAILURE',

  CREATE_ISSUE_COMMENT_REQUEST = 'CREATE_ISSUE_COMMENT_REQUEST',
  CREATE_ISSUE_COMMENT_SUCCESS = 'CREATE_ISSUE_COMMENT_SUCCESS',
  CREATE_ISSUE_COMMENT_FAILURE = 'CREATE_ISSUE_COMMENT_FAILURE',

  SET_SORT = 'SET_SORT',

  REQUESTS_REORDERED = 'REQUESTS_REORDERED',
}

interface GetRequestsAction {
  type: ActionType.GET_REQUESTS_REQUEST | ActionType.GET_REQUESTS_SUCCESS | ActionType.GET_REQUESTS_FAILURE;
  result: {
    data: { results: Request[], count: number }
  };
  error: {
    response: { status: string };
  };
}

interface CreateRequestAction {
  type: ActionType.CREATE_REQUEST_REQUEST | ActionType.CREATE_REQUEST_SUCCESS | ActionType.CREATE_REQUEST_FAILURE;
  result: { data: Request };
  error: {
    response: { status: string };
  };
}

interface DeleteRequestAction {
  type: ActionType.DELETE_REQUEST_REQUEST | ActionType.DELETE_REQUEST_SUCCESS | ActionType.DELETE_REQUEST_FAILURE;
  result: { data: Request };
  error: {
    response: { status: string };
  }
}

interface RearrangeRankAction {
  type: ActionType.REARRANGE_RANK_REQUEST | ActionType.REARRANGE_RANK_SUCCESS | ActionType.REARRANGE_RANK_FAILURE;
  result: { data: { new_id: number, new_rank: number, old_id: number, old_rank: number } };
  error: {
    response: { status: string };
  }
}

interface UpdateRequestAction {
  type: ActionType.UPDATE_REQUEST_REQUEST | ActionType.UPDATE_REQUEST_SUCCESS | ActionType.UPDATE_REQUEST_FAILURE;
  result: { data: Request };
  error: {
    response: { status: string };
  }
}

interface GetRequestCommentsAction {
  type: ActionType.GET_REQUEST_COMMENTS_REQUEST | ActionType.GET_REQUEST_COMMENTS_SUCCESS | ActionType.GET_REQUEST_COMMENTS_FAILURE;
  result: { data: { comments: Comment[], issue_description_parsed: string } };
  error: {
    response: { status: string };
  }
}

interface CreateIssueCommentAction {
  type: ActionType.CREATE_ISSUE_COMMENT_REQUEST | ActionType.CREATE_ISSUE_COMMENT_SUCCESS | ActionType.CREATE_ISSUE_COMMENT_FAILURE;
  result: { data: {comment: Comment} };
  error: {
    response: { status: string };
  }
}

interface UpdateRequestReadedByUserIdsAction {
  type: ActionType.UPDATE_REQUEST_READED_BY_USER_IDS_REQUEST | ActionType.UPDATE_REQUEST_READED_BY_USER_IDS_SUCCESS | ActionType.UPDATE_REQUEST_READED_BY_USER_IDS_FAILURE;
  result: { data: Request };
  error: { response: { status: string } };
}

export type Action =
  PromiseActionType |
  GetRequestsAction |
  CreateRequestAction |
  DeleteRequestAction |
  RearrangeRankAction |
  UpdateRequestAction |
  GetRequestCommentsAction |
  CreateIssueCommentAction |
  UpdateRequestReadedByUserIdsAction;
