import { Headers } from 'src/interfaces';

export const getLeadId = (pathname: string): number | null => {
  try {
    const segments = pathname.split('/');
    const leadId = parseInt(segments[segments.indexOf('leads') + 1], 10);
    if (Number.isNaN(leadId)) return null;
    return leadId;
  } catch (error) {
    return null;
  }
};

/**
 * This is a workaround to not cache listing of tasks.
 * We noticed that even if tasks were completed or deleted in the automation or manually, the updates were not showing up for about a minute, causing confusion.
 * I used this header to avoid caching each time a task is deleted or completed.
 * It should not cause conflicts, as, as far as I know, leads are linked to a single property, and when shared, a new lead is created.
 * Check method_decorators in backend/api/views/task.py.
 */
export const getIgnoreCacheHeaders = (leadId: number): Headers => {
  const timestamp = new Date().getTime();
  return { 'X-SelectedProperties': `lead_${leadId}_tasks_${timestamp}` };
};
