import assignLeadOwners from './assignment_rules/action-creators';
import assistBot from './assist_bot/action-creators';
import authentication from './authentication/action-creators';
import businessHours from './business_hours/action-creators';
import calendar from './calendars/action-creators';
import callScoringQuestions from './call_scoring_questions/action-creators';
import communityDetails from './community_details/action-creators';
import emailPopout from './email_popout/action-creator';
import focusBar from './focus_bar/action-creators';
import holiday from './holidays/action-creators';
import lead from './lead/action-creators';
import notification from './notification/action-creators';
import propertiesSelector from './properties_selector/action-creators';
import property from './property/action-creators';
import propertyProfile from './property_profile/action-creators';
import prospectChat from './chat/action-creators';
import pusher from './pusher/action-creators';
import rescoreUsers from './rescore_users/action-creators';
import scoredCalls from './scored_calls/action-creators';
import smsMessage from './sms/action-creators';
import task from './task/action-creators';
import user from './user/action-creators';
import report from './report/action-creators';
import portfolio from './portfolio/action-creators';
import agentQuestion from './agent_question/action-creators';
import priceQuote from './price_quote/action-creators';
import call from './call/action-creators';
import emailMessage from './email_message/action-creators';
import nylas from './nylas/action-creators';
import note from './note/action-creators';
import nurture from './nurture_automations/action-creators';
import nurtureStep from './nurture_step/action-creators';
import pipeline from './pipeline/action-creators';
import communication from './communication/action-creators';
import apartment from './apartment/action-creators';
import callSchedule from './call_schedule/action-creators';
import chatTemplate from './chat_template/action-creators';
import chatEvaluation from './chat_evaluation/action-creators';
import columnsSettings from './columns_settings/action-creators';
import competitor from './competitor/action-creators';
import emailLabel from './email_label/action-creators';
import emailTemplate from './email_template/action-creators';
import leadsFilter from './leads_filter/action-creators';
import pmsLogs from './pms_logs/action-creators';
import prospectSource from './prospect_source/action-creators';
import smsTemplate from './sms_template/action-creators';
import roommate from './roommate/action-creators';
import survey from './survey/action-creators';
import resetPassword from './reset_password/action-creators';
import targetNumber from './target_number/action-creators';
import leavingReasons from './leaving_reasons/action-creators';
import insiderInfo from './insider_info/action-creators';
import userActivity from './user_activity/action-creators';

export default {
  assignLeadOwners,
  assistBot,
  authentication,
  businessHours,
  calendar,
  callScoringQuestions,
  communityDetails,
  emailPopout,
  focusBar,
  holiday,
  lead,
  notification,
  propertiesSelector,
  property,
  propertyProfile,
  prospectChat,
  pusher,
  rescoreUsers,
  scoredCalls,
  smsMessage,
  task,
  user,
  report,
  portfolio,
  agentQuestion,
  priceQuote,
  call,
  emailMessage,
  nylas,
  note,
  nurture,
  nurtureStep,
  pipeline,
  communication,
  apartment,
  callSchedule,
  chatTemplate,
  chatEvaluation,
  columnsSettings,
  competitor,
  emailLabel,
  emailTemplate,
  leadsFilter,
  pmsLogs,
  prospectSource,
  smsTemplate,
  roommate,
  survey,
  resetPassword,
  targetNumber,
  leavingReasons,
  insiderInfo,
  userActivity,
};
