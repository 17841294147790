import React, { FC, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircleDivider } from 'styles/common';
import { currencyFormat } from 'compete/constants';
import actions from 'dwell/actions';
import { InsiderInfoDetails } from 'dwell/store/property_profile/action-types';
import { unitTypes } from 'site/constants';
import { formatUnitFilter, formatAvailableDate } from '../available_units/utils';
import ImagePreview, { ImageType } from '../gallery/preview';
import { CardApartment, Content, ImageIcon, NavLine, NavLink, NavWrapper, UnitsSection } from './styles';
import { ImagePreview as ImagePreviewStyle } from '../styles';
import { findMatchingInsiderInfoDetails } from './utils';

const UnitsByFloorPlan: FC = () => {
  const insiderInfo = useSelector(state => state.propertyProfile.insiderInfoDetails) as InsiderInfoDetails[];
  const [activeIndex, setActiveIndex] = useState(0);
  const [command, setCommand] = useState(null);
  const dispatch = useDispatch();
  const floorPlans = useSelector(state => state.propertyProfile.floorPlans);
  const activeUnit = useSelector(state => state.propertyProfile.activeUnit);
  const depositActions = useSelector(state => state.propertyProfile.leasingCriteria.deposit_actions);
  const isGalleryPreviewOpen = useSelector(state => state.propertyProfile.isGalleryPreviewOpen);
  const { openGalleryFullView } = actions.propertyProfile;
  const floorPlan = useMemo(() => floorPlans.find(plan => plan.id === activeUnit.plan.id), [activeUnit, floorPlans]);
  const units = floorPlan?.units || [];
  const unit = useMemo(() => units?.find((u, index) => index === activeIndex), [activeIndex, units]) || {};

  const insiderInfoUnit = findMatchingInsiderInfoDetails(
    insiderInfo, unit.id, unit.floor_plan,
    unitTypes.find(({ bedrooms }) => bedrooms === floorPlan.bedrooms)?.value,
  );

  const focusElement = (index) => {
    const element = document.querySelector(`#unit-item-${index}`) as HTMLAnchorElement;
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  };

  useEffect(() => {
    const index = units.findIndex(u => u.id === activeUnit.id);
    if (index === -1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
      setTimeout(() => focusElement(index), 500);
    }
  }, [units, activeUnit.id]);

  const comm = [17, 91, 93, 224];
  const keyDown = (e) => {
    if (comm.includes(e.keyCode)) {
      setCommand(e.keyCode);
    }

    // Arrow Left
    if (command && e.keyCode === 37) {
      const newIndex = activeIndex <= 0 ? activeIndex : activeIndex - 1;
      setActiveIndex(newIndex);
      focusElement(newIndex);
    }

    // Arrow Right
    if (e.keyCode === 39) {
      const newIndex = activeIndex >= units.length - 1 ? activeIndex : activeIndex + 1;
      setActiveIndex(newIndex);
      focusElement(newIndex);
    }

    return true;
  };

  const keyUp = (e) => {
    if (comm.includes(e.keyCode)) {
      setCommand(null);
    }
    return true;
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDown);
    document.addEventListener('keyup', keyUp);
    return () => {
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener('keyup', keyUp);
    };
  });

  const galleryData = useMemo(() => {
    const data = [] as ImageType[];
    floorPlan.images.forEach((image) => {
      data.push({
        image,
        plan: floorPlan.plan,
        bedrooms: floorPlan.bedrooms,
        bathrooms: floorPlan.bathrooms,
        square_footage: floorPlan.square_footage,
        isPlan: true,
      } as ImageType);
    });
    return data;
  }, []);

  const image = (floorPlan.images || [])[0];

  return (
    <Content>
      <UnitsSection>
        <NavWrapper>
          <NavLine>
            {units.map((u, index) => (
              <NavLink
                id={`unit-item-${index}`}
                key={index}
                active={index === activeIndex}
                onClick={() => setActiveIndex(index)}
              >
                {u.unit}
              </NavLink>
            ))}
          </NavLine>
        </NavWrapper>
        <CardApartment>
          <div className="card-header">
            <h6>Apartment {unit.unit} | {floorPlan.plan}</h6>
            <div className="d-flex align-items-center">
              {formatUnitFilter(floorPlan.bedrooms?.toString())} <CircleDivider /> {floorPlan.bathrooms} Bath
              <CircleDivider /> {floorPlan.square_footage} SqFt
            </div>
            {image && (
              <ImagePreviewStyle onClick={() => dispatch(openGalleryFullView(true))}>
                <img src={image} className="img-fluid" alt="plan" data-testid="plan-image" />
                <ImageIcon><i className="ri-code-view" /></ImageIcon>
              </ImagePreviewStyle>
            )}
          </div>
          <div className="card-body">
            <h6 className="tx-16 text-dark"># Rent &amp; Deposit</h6>
            <p><strong>Rent starting at:</strong> ${currencyFormat(unit.market_rent)}</p>
            <p>
              <strong>Deposit starting at:</strong> ${currencyFormat(depositActions.refundable_approved_application)}
              (or ${currencyFormat(depositActions.non_refundable_approved_application)} with ePremium)
            </p>
            <p><strong>Available date:</strong> {formatAvailableDate(unit.available_date)}</p>
            <hr />
            <h6 className="tx-16 text-dark"># Location</h6>
            <p data-testid="unit_number"><strong>Unit number:</strong> {unit.unit}</p>
            <p data-testid="building_name"><strong>Located in building:</strong> {unit.building_name}</p>
            <p data-testid="floor_level"><strong>On floor:</strong> {unit.floor_level}</p>
            {unit.description && (
              <>
                <hr />
                <h6 className="tx-16 text-dark"># Features</h6>
                <ul>
                  {unit.description.split('\n').map((d, index) => d && <li key={index}>{d}</li>)}
                </ul>
              </>
            )}
            {floorPlan.promotion && (
              <>
                <hr />
                <h6 className="tx-16 text-dark"># Promotion</h6>
                {floorPlan.promotion.promotion_html && (
                  <p className="mg-b-5-f">
                    <strong>Special Offer:</strong> <div dangerouslySetInnerHTML={{ __html: floorPlan.promotion.promotion_html }} />
                  </p>
                )}
                {floorPlan.promotion.restriction && (
                  <p><strong>Restrictions:</strong> {floorPlan.promotion.restriction}</p>
                )}
              </>
            )}
            {insiderInfoUnit?.length > 0 &&
            <>
              <hr />
              <h6 className="tx-16 text-dark"># Insider Info</h6>
              {insiderInfoUnit.map(li => (
                <div>- {li}</div>
              ))}
            </>
            }
          </div>
        </CardApartment>
      </UnitsSection>
      {isGalleryPreviewOpen &&
        <ImagePreview images={galleryData} activeIndex={0} onClose={() => dispatch(openGalleryFullView(false))} showIndicator={false} />}
    </Content>
  );
};

export default UnitsByFloorPlan;
