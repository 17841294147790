import { chatVariables, smsVariables, emailVariables } from 'dwell/constants';

export interface Placeholder {
  field: string,
  name: string
}

export interface TemplatePlaceholders {
  email: Placeholder[],
  chat: Placeholder[],
  sms: Placeholder[],
  bulk_email: Placeholder[]
}

type VariablesType =
  | typeof chatVariables
  | typeof smsVariables
  | typeof emailVariables;

type VariableField =
  | 'applicant_name'
  | 'applicant_first_name'
  | 'co_applicant_name'
  | 'co_applicant_first_name'
  | 'guarantor_name'
  | 'guarantor_first_name'
  | 'lead_full_name'
  | 'lead_first_name'
  | 'lead_owner'
  | 'lead_owner_first_name'
  | 'lease_agreement_name'
  | 'lease_agreement_sign_hyperlink'
  | 'lease_agreement_sign_link'
  | 'lease_owner'
  | 'lease_end_date'
  | 'lease_base_rent'
  | 'lease_required_notice_to_vacate_days'
  | 'lease_month_to_month_fee'
  | 'lease_remove_people_names'
  | 'lease_new_people_names'
  | 'lease_pay_change_fee_link'
  | 'lease_pay_change_fee_hyperlink'
  | 'property_address'
  | 'property_name'
  | 'property_email'
  | 'property_phone_number'
  | 'property_website'
  | 'property_website_link'
  | 'property_description'
  | 'tour_time'
  | 'tour_link'
  | 'tour_type'
  | 'virtual_tour_link'
  | 'unit_number'
  | 'unit_type'
  | 'unit_type_lowest_price'
  | 'unit_type_highest_price'
  | 'floor_plan'
  | 'floor_plan_lowest_price'
  | 'floor_plan_highest_price'
  | 'unit_number_price'
  | 'property_business_hours';

interface VariableInfo {
  name: string
}

export type TemplateVariables = Partial<Record<VariableField, VariableInfo>>;

// It is Partial because not all of our possible variables could have a dummy
export const dummyValues: Partial<Record<VariableField, string>> = {
  applicant_name: 'John Smith',
  applicant_first_name: 'John',
  co_applicant_name: 'Alice Smith',
  co_applicant_first_name: 'Alice',
  guarantor_name: 'Larry Mctester',
  guarantor_first_name: 'Larry',
  lead_full_name: 'Ben Johnson',
  lead_first_name: 'Ben',
  lead_owner: 'Christina Peterson',
  lead_owner_first_name: 'Christina',
  lease_agreement_name: 'Lease Agreement',
  lease_agreement_sign_hyperlink: '<a href="https://sanpropertia.com/lease-application/document-agreement/lease" target="_blank">here</a>',
  lease_agreement_sign_link: 'https://sanpropertia.com/lease-application/document-agreement/lease',
  lease_owner: 'Christina Peterson',
  lease_end_date: 'Aug 24, 2022',
  lease_base_rent: '$1,000',
  lease_required_notice_to_vacate_days: '60',
  lease_month_to_month_fee: '$100',
  lease_remove_people_names: 'John Smith, Alice Smith',
  lease_new_people_names: 'Alice Smith, Larry Mctester',
  lease_pay_change_fee_link: 'https://sanpropertia.com/pay-change-fee',
  lease_pay_change_fee_hyperlink: '<a href="https://sanpropertia.com/pay-change-fee" target="_blank">here</a>',
  property_address: '500 South Eagle Ave, Scottsdale, AZ, 12345',
  property_name: 'San Propertia',
  property_email: 'sanpropertia@union.com',
  property_phone_number: '234-567-8901',
  property_website: 'https://sanpropertia.com',
  property_website_link: '<a href="https://sanpropertia.com?chat_open" target="_blank">here</a>',
  property_description: `San Propertia is a beautiful community located in the heart of downtown Scottsdale.
    We offer luxury amenities like cabana-lined swimming pools and modern interior amenities across our wonderful 1 bed, 2 bed, and 3 bed homes.
  `,
  tour_time: 'Aug 24, 2022 1:30 PM',
  tour_link: 'https://sanpropertia.com?chat_open',
  tour_type: 'In-Person Tour',
  virtual_tour_link: 'https://sanpropertia.com/virtual-tour',
  unit_number: '2059',
  unit_type: 'Studio',
  unit_type_lowest_price: '$1,000',
  unit_type_highest_price: '$1,500',
  floor_plan: 'A1',
  floor_plan_lowest_price: '$1,000',
  floor_plan_highest_price: '$1,500',
  unit_number_price: '$1,000',
  property_business_hours: 'Monday – Saturday, 9:00 a.m. – 5:00 p.m',
};

export const getPreviewWithDummies = (text: string, variablesInText: string[], variables: TemplateVariables, isBody = false, isSMS = false): string => {
  let preview = text;
  (variablesInText || []).forEach((variable) => {
    if (dummyValues[variable]) {
      let pattern = isBody ?
        `<span class="email-placeholder">\\[=${variables[variable].name}=\\]<\\/span>`
        : `\\[=${variables[variable].name}=\\]`;
      pattern = isSMS ? `\\[=${variables[variable].name}=\\]` : pattern;
      preview = preview.replace(
        new RegExp(pattern, 'g'),
        dummyValues[variable],
      );
    }
  });
  return preview;
};

export const getAvailablePlaceholders = (templateVariables: VariablesType, placeholders: string[]): TemplateVariables => (
  Object.keys(templateVariables.VARIABLES)
    .filter(field => placeholders.includes(field))
    .reduce((obj, key) => ({ ...obj, [key]: templateVariables.VARIABLES[key] }), {})
);
