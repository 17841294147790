import produce from 'immer';
import { preFillState } from 'dwell/store/utils';
import { Action, ActionType, CompanyPolicyProps, InsiderQuestionCategory, InsiderQuestion } from './action-types';
import { CommonStateType } from '../types';

export interface HobbesState extends CommonStateType {
  companyPolicy: CompanyPolicyProps;
  isCategoryLoaded: boolean;
  amenityCategories: { id: number; name: string }[];
  areCompanyPoliciesLoaded: boolean;
  areInsiderQuestionsLoaded: boolean;
  answeredQuestionsPercentage: number;
  insiderQuestions: InsiderQuestion[];
  insiderQuestionsCount: number;
  insiderQuestionCategories: InsiderQuestionCategory[];
}

const initialState: HobbesState = {
  isSubmitting: false,
  errorMessage: null,
  companyPolicy: {} as CompanyPolicyProps,
  isCategoryLoaded: false,
  amenityCategories: [],
  areCompanyPoliciesLoaded: false,
  areInsiderQuestionsLoaded: false,
  answeredQuestionsPercentage: 0,
  insiderQuestions: [] as InsiderQuestion[],
  insiderQuestionsCount: 0,
  insiderQuestionCategories: [] as InsiderQuestionCategory[],
};

export const selectInsiderQuestionCategories = (state: {hobbes: HobbesState}): InsiderQuestionCategory[] => state.hobbes.insiderQuestionCategories;
export const selectAnsweredQuestionPercentage = (state: {hobbes: HobbesState}): number => state.hobbes.answeredQuestionsPercentage;
export const selectInsiderQuestions = (state: {hobbes: HobbesState}): InsiderQuestion[] => state.hobbes.insiderQuestions;
export const selectAreInsiderQuestionsLoaded = (state: {hobbes: HobbesState}): boolean => state.hobbes.areInsiderQuestionsLoaded;
export const selectInsiderQuestionsCount = (state: {hobbes: HobbesState}): number => state.hobbes.insiderQuestionsCount;

export default produce((state: HobbesState = initialState, action: Action): HobbesState => {
  preFillState(state, action, Object.values(ActionType));
  switch (action.type) {
    case ActionType.SAVE_COMPANY_POLICIES_SUCCESS:
      state.companyPolicy = action.result.data;
      break;

    case ActionType.GET_AMENITY_CATEGORY_REQUEST:
      state.isCategoryLoaded = false;
      break;
    case ActionType.GET_AMENITY_CATEGORY_SUCCESS:
      state.isCategoryLoaded = true;
      state.amenityCategories = action.result.data;
      break;
    case ActionType.GET_AMENITY_CATEGORY_FAILURE:
      state.isCategoryLoaded = false;
      break;

    case ActionType.GET_CUSTOMER_COMPANY_POLICIES_REQUEST:
      state.areCompanyPoliciesLoaded = false;
      break;
    case ActionType.GET_CUSTOMER_COMPANY_POLICIES_SUCCESS:
      state.companyPolicy = action.result.data;
      state.areCompanyPoliciesLoaded = true;
      break;
    case ActionType.GET_CUSTOMER_COMPANY_POLICIES_FAILURE:
      state.areCompanyPoliciesLoaded = true;
      break;

    case ActionType.GET_ANSWERED_QUESTIONS_PERCENTAGE_SUCCESS:
      state.answeredQuestionsPercentage = action.result.data;
      break;
    case ActionType.GET_INSIDER_QUESTIONS_REQUEST:
      state.areInsiderQuestionsLoaded = false;
      break;
    case ActionType.GET_INSIDER_QUESTIONS_SUCCESS:
      state.insiderQuestions = action.result.data.results;
      state.insiderQuestionsCount = action.result.data.count;
      state.areInsiderQuestionsLoaded = true;
      break;

    case ActionType.UPDATE_INSIDER_QUESTION_SUCCESS:
      state.areInsiderQuestionsLoaded = false;
      break;
    case ActionType.UPDATE_INSIDER_QUESTION_FAILURE:
      state.areInsiderQuestionsLoaded = true;
      break;

    case ActionType.CREATE_INSIDER_QUESTION_SUCCESS:
      state.areInsiderQuestionsLoaded = false;
      break;
    case ActionType.CREATE_INSIDER_QUESTION_FAILURE:
      state.areInsiderQuestionsLoaded = true;
      break;

    case ActionType.GET_INSIDER_QUESTION_CATEGORY_SUCCESS:
      state.insiderQuestionCategories = action.result.data.results;
      break;
  }

  return state;
});
