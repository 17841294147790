import React, { FC, useEffect, useState } from 'react';
import actions from 'dwell/actions';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Spinner } from 'reactstrap';
import he from 'he';
import {
  OptionsPanelDropdownItem,
  OptionsPanelDropdownMenu,
  OptionsPanelDropdownToggle,
  OptionsPanelIcon, OptionsPanelIconInner,
  ProspectMenuHeader, ProspectMenuItemBody, ProspectMenuBody,
  TemplateTitle, NoTemplatesContainer,
} from 'dwell/views/chat/multi_chat/styles';
import { selectCurrentProperty, selectSelectedProperties } from 'dwell/store/property/reducers';
import { TOUR_TYPES } from 'dwell/constants/tasks';
import { PropertyProps } from 'dwell/store/property/action-types';
import { Prospect as IProspect } from 'dwell/store/chat/action-types';
import { getTimezone } from 'src/utils';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

interface MessageOptionsPanelProps {
  sendMessage: (message: string, type?: string) => void,
  setNewMessage: (message: string) => void,
  setMessageType: (type: string) => void,
  prospect: IProspect,
  isSMS: boolean,
  isSingleChat?: boolean,
  setCharsCount?: React.Dispatch<React.SetStateAction<number>>;
}

interface MessageType {
  id: number;
  title: string;
  text: string;
}

const mockMessages = [
  { id: -1, title: 'Welcome', text: 'Welcome' },
  { id: -2, title: "I don't know", text: "I don't know" },
  { id: -3, title: 'Goodbye', text: 'Goodbye' },
];

const dataCaptureElements = [
  { title: 'Guest card', fields: 4 },
  { title: 'Name', fields: 2 },
  { title: 'Schedule a Tour', fields: 9 },
];

const MessageOptionsPanel: FC<MessageOptionsPanelProps> = ({ sendMessage, setNewMessage, setMessageType, prospect, isSMS }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [messages, setMessages] = useState<MessageType[]>(mockMessages);
  const [smsMessages, setSmsMessages] = useState<MessageType[]>([]);
  const [prospectLead, setProspectLead] = useState(null);
  const [property, setProperty] = useState<PropertyProps>({ name: '', city: '', domain: '', town: '', tracking_number: '', users: [], website_link: '', shared_email: '', adapted_logo: '' });
  const chatTemplates = useSelector(state => state.chatTemplate.chatTemplates);
  const smsTemplates = useSelector(state => state.smsTemplate.smsTemplates);
  const chatLoaded = useSelector(state => state.chatTemplate.isLoaded);
  const smsLoaded = useSelector(state => state.smsTemplate.isLoaded);
  const lead = useSelector(state => state.lead.leadProspect);

  const dispatch = useDispatch();
  const { getChatTemplates } = actions.chatTemplate;
  const { getSMSTemplates } = actions.smsTemplate;
  const { getLeadForProspect } = actions.lead;
  const { setDraftToActiveChat } = actions.prospectChat;
  const { getPropertyDetails } = actions.property;
  const selectedProperties = useSelector(selectSelectedProperties);
  const propertyDetails = useSelector(selectCurrentProperty);

  useEffect(() => {
    if (isDropdownOpen) {
      if (!isSMS) {
        dispatch(getChatTemplates(prospect.property));
      } else {
        dispatch(getSMSTemplates(prospect.property));
      }
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    const current = selectedProperties.find(p => p.id === prospect.property);
    if (current) {
      setProperty(current);
    } else if (!isEmpty(propertyDetails)) {
      setProperty(propertyDetails);
    } else {
      dispatch(getPropertyDetails(prospect.property));
    }
  }, [propertyDetails, selectedProperties]);

  useEffect(() => {
    if (!isSMS) {
      if (prospect.lead || prospect.guest_card) {
        dispatch(getLeadForProspect(prospect.lead || prospect.guest_card, { property: prospect.property }));
      }
    } else if (prospect.id) {
      dispatch(getLeadForProspect(prospect.id, { property: prospect.property }));
    }
  }, [prospect.lead, prospect.guest_card, isSMS]);

  useEffect(() => {
    if (lead && (prospect.lead === lead.id || prospect.guest_card === lead.id || prospect.id === lead.id)) {
      setProspectLead(lead);
    }
  }, [lead]);

  useEffect(() => {
    if (!isSMS) {
      const filteredChatTemplates = chatTemplates.filter(ct => ct.property === prospect.property);
      const newChatTemplates = filteredChatTemplates.map(template => ({ id: template.id, title: template.name, text: template.text }));
      setMessages(newChatTemplates);
    } else {
      const filteredSmsTemplates = smsTemplates.filter(st => st.property === prospect.property && !st.is_system_template);
      const newSmsTemplates = filteredSmsTemplates.map(template => ({ id: template.id, title: template.name, text: template.text }));
      setSmsMessages(newSmsTemplates);
    }
  }, [chatTemplates, smsTemplates, isSMS]);

  const handleSendMessageClick = (message) => {
    sendMessage(message, 'DATA_CAPTURE');
    setIsDropdownOpen(false);
  };

  const getRecentTour = () => {
    const tours = prospectLead.tasks.filter(t => t.tour_date);
    if (tours.length) {
      const sortedTours = tours.sort((a, b) => b.tour_date - a.tour_date);
      const recentTour = sortedTours.slice(-1).pop();
      return recentTour;
    }
    return null;
  };

  const handleSetMessageClick = (message) => {
    let parsedMessage = message;
    const newLine = /(<br \/>)|(\r\n|\r|\n|\\r\\n|\\r|\\n)/ig;
    parsedMessage = he.decode(parsedMessage.replaceAll(newLine, '&#10;'));
    const tagReg = /(<(\/|s)([^>]+)>)/ig;
    parsedMessage = he.decode(parsedMessage.replaceAll(tagReg, ''));
    const placeholderReg = /\[=(.*?)=\]/g;
    [...parsedMessage.matchAll(placeholderReg)].reduce((a, b) => a.concat(b[0]), [])
      .forEach((placeholder) => {
        switch (placeholder) {
          case '[=Tour time=]':
            if (!isEmpty(prospect) && prospect.tour_date) {
              const date = `${prospect.tour_date}`;
              parsedMessage = parsedMessage.replace(placeholder, date);
            } else if (!isEmpty(prospectLead) && prospectLead.tasks.length) {
              const recentTour = getRecentTour();
              if (recentTour && recentTour.tour_date) {
                const date = `${moment(recentTour.tour_date).tz(getTimezone()).format('dddd, MMMM DD, YYYY, [at] h:mm A')} (${getTimezone()})`;
                parsedMessage = parsedMessage.replace(placeholder, date);
              }
            }
            break;
          case '[=Tour type=]':
            if (!isEmpty(prospectLead) && prospectLead.tasks.length) {
              const recentTour = getRecentTour();
              if (recentTour && Object.keys(TOUR_TYPES).includes(recentTour.type)) {
                const type = TOUR_TYPES[recentTour.type];
                parsedMessage = parsedMessage.replace(placeholder, type);
              }
            }
            break;
          case '[=Lead owner=]':
            if (!isEmpty(prospectLead) && prospectLead.owner) {
              const owner = property.users.find(u => u.id === prospectLead.owner);
              parsedMessage = parsedMessage.replace(placeholder, `${owner.first_name} ${owner.last_name}`);
            } break;
          case '[=Lead owner first name=]':
            if (!isEmpty(prospectLead) && prospectLead.owner) {
              const owner = property.users.find(u => u.id === prospectLead.owner);
              parsedMessage = parsedMessage.replace(placeholder, owner.first_name);
            } break;
          case '[=Lead full name=]':
            if (!isEmpty(prospectLead) && prospectLead.first_name && prospectLead.last_name) {
              const name = `${prospectLead.first_name} ${prospectLead.last_name}`;
              parsedMessage = parsedMessage.replace(placeholder, name);
            } break;
          case '[=Lead first name=]':
            if (!isEmpty(prospectLead) && prospectLead.first_name) {
              const name = prospectLead.first_name;
              parsedMessage = parsedMessage.replace(placeholder, name);
            } break;
          case '[=Property name=]':
            if (property && property.name) {
              parsedMessage = parsedMessage.replace(placeholder, property.name);
            } break;
          case '[=Property logo=]':
            if (property && property.logo) {
              parsedMessage = parsedMessage.replace(placeholder, property.logo);
            } break;
          case '[=Property address=]':
            if (property && property.city) {
              parsedMessage = parsedMessage.replace(placeholder, `${property.city}, ${property.town}`);
            } break;
          case '[=Property email=]':
            if (property && property.shared_email) {
              parsedMessage = parsedMessage.replace(placeholder, property.shared_email);
            } break;
          case '[=Property phone number=]':
            if (property && property.tracking_number) {
              parsedMessage = parsedMessage.replace(placeholder, property.tracking_number);
            } break;
          case '[=Property website=]':
            if (property) {
              parsedMessage = parsedMessage.replace(placeholder, `https://${property.domain}`);
            } break;
          case '[=Property description=]':
            if (property) {
              parsedMessage = parsedMessage.replace(placeholder, property.description);
            } break;
          case '[=Virtual tour link=]':
            if (property) {
              parsedMessage = parsedMessage.replace(placeholder, `https://${property.domain}/virtual-tour`);
            } break;
          case '[=Tour link=]':
            if (property) {
              parsedMessage = parsedMessage.replace(placeholder, `https://${property.domain}/?chat_open`);
            } break;
          case '[=Applicant name=]': {
            if (!isEmpty(prospectLead) && prospectLead.lease_details?.applicant) {
              const { applicant } = prospectLead.lease_details;
              parsedMessage = parsedMessage.replace(placeholder, `${applicant.first_name} ${applicant.last_name}`);
            } break;
          }
          case '[=Applicant first name=]': {
            if (!isEmpty(prospectLead) && prospectLead.lease_details?.applicant) {
              const { applicant } = prospectLead.lease_details;
              parsedMessage = parsedMessage.replace(placeholder, applicant.first_name);
            } break;
          }
          case '[=Co-applicant name=]': {
            if (!isEmpty(prospectLead) && prospectLead.lease_details?.co_applicant) {
              const { co_applicant } = prospectLead.lease_details;
              parsedMessage = parsedMessage.replace(placeholder, `${co_applicant.first_name} ${co_applicant.last_name}`);
            } break;
          }
          case '[=Co-applicant first name=]': {
            if (!isEmpty(prospectLead) && prospectLead.lease_details?.co_applicant) {
              const { co_applicant } = prospectLead.lease_details;
              parsedMessage = parsedMessage.replace(placeholder, co_applicant.first_name);
            } break;
          }
          case '[=Guarantor name=]': {
            if (!isEmpty(prospectLead) && prospectLead.lease_details?.guarantor) {
              const { guarantor } = prospectLead.lease_details;
              parsedMessage = parsedMessage.replace(placeholder, `${guarantor.first_name} ${guarantor.last_name}`);
            } break;
          }
          case '[=Guarantor first name=]': {
            if (!isEmpty(prospectLead) && prospectLead.lease_details?.guarantor) {
              const { guarantor } = prospectLead.lease_details;
              parsedMessage = parsedMessage.replace(placeholder, guarantor.first_name);
            } break;
          }
          case '[=Unit number=]': {
            if (!isEmpty(prospectLead) && prospectLead.lease_details?.unit_number) {
              parsedMessage = parsedMessage.replace(placeholder, prospectLead.lease_details.unit_number);
            } else if (!isEmpty(prospectLead) && prospectLead.units_selected) {
              parsedMessage = parsedMessage.replace(placeholder, prospectLead.units_selected.join(', '));
            }
            break;
          }
        }
      });
    setMessageType('TEMPLATE');
    setNewMessage(parsedMessage);
    dispatch(setDraftToActiveChat(parsedMessage, prospect.id));
    setIsDropdownOpen(false);
  };

  let filteredCaptureElements = dataCaptureElements;
  if (prospect.name) {
    if (prospect.has_active_tour || prospect.tour_scheduling_in_progress) {
      filteredCaptureElements = filteredCaptureElements.filter(e => e.title !== 'Schedule a Tour');
    }
    if (prospect.has_guest_card) {
      filteredCaptureElements = filteredCaptureElements.filter(e => e.title !== 'Guest card');
    }
    filteredCaptureElements = filteredCaptureElements.filter(e => e.title !== 'Name');
  }

  return (
    <Dropdown isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(!isDropdownOpen)}>
      <OptionsPanelDropdownToggle
        tag="div"
        data-toggle="dropdown"
        aria-expanded={isDropdownOpen}
        className="toggle"
      >
        <OptionsPanelIcon className="ri-add-circle-fill" />
      </OptionsPanelDropdownToggle>
      <OptionsPanelDropdownMenu right>
        <div>
          {!isSMS && filteredCaptureElements.length ?
            <>
              <ProspectMenuHeader>
                <i className="ri-stack-line" />
                Data Capture Forms
              </ProspectMenuHeader>
              <ProspectMenuBody>
                {filteredCaptureElements.map((message, index) => (
                  <OptionsPanelDropdownItem key={index} onClick={() => handleSendMessageClick(message.title)}>
                    <span>
                      <OptionsPanelIconInner className="ri-arrow-left-down-line" />
                    </span>
                    <ProspectMenuItemBody>
                      <TemplateTitle>{message.title}</TemplateTitle>
                      {/* <span>{message.fields}</span> */}
                    </ProspectMenuItemBody>
                  </OptionsPanelDropdownItem>
                ))}
              </ProspectMenuBody>
            </> : null}
          {!isSMS ? (
            <React.Fragment>
              <ProspectMenuHeader>
                <i className="ri-stack-line" />
                Chat Templates
              </ProspectMenuHeader>
              <ProspectMenuBody>
                {chatLoaded && messages.map((message, index) => (
                  <OptionsPanelDropdownItem className="chat-ai-item" key={index} onClick={() => handleSetMessageClick(message.text)}>
                    <span>
                      <OptionsPanelIconInner className="ri-add-line" />
                    </span>
                    <ProspectMenuItemBody>
                      <TemplateTitle>{message.title}</TemplateTitle>
                    </ProspectMenuItemBody>
                  </OptionsPanelDropdownItem>
                ))}
                {!messages.length && chatLoaded && (
                  <NoTemplatesContainer>
                    <div>
                      Your team does not have chat templates setup yet.
                    </div>
                    <Link to={{
                      pathname: '/properties/settings/template/list',
                      state: { templatesTab: 'Chat' },
                    }}
                    >Go to Manage Templates
                    </Link>
                  </NoTemplatesContainer>)}
                {!chatLoaded && (
                  <Spinner size="sm" />
                )}
              </ProspectMenuBody>
            </React.Fragment>) : (
            <React.Fragment>
              <ProspectMenuHeader>
                <i className="ri-stack-line" />
                SMS Templates
              </ProspectMenuHeader>
              <ProspectMenuBody>
                {smsLoaded && smsMessages.map((message, index) => (
                  <OptionsPanelDropdownItem className="chat-ai-item" key={index} onClick={() => handleSetMessageClick(message.text)}>
                    <span>
                      <OptionsPanelIconInner className="ri-add-line" />
                    </span>
                    <ProspectMenuItemBody>
                      <TemplateTitle>{message.title}</TemplateTitle>
                    </ProspectMenuItemBody>
                  </OptionsPanelDropdownItem>
                ))}
                {!smsMessages.length && smsLoaded && (
                  <NoTemplatesContainer>
                    <div>
                      Your team does not have SMS templates setup yet.
                    </div>
                    <Link to={{
                      pathname: '/properties/settings/template/list',
                      state: { templatesTab: 'SMS' } }}
                    >Go to Manage Templates
                    </Link>
                  </NoTemplatesContainer>)}
                {!smsLoaded && (
                  <Spinner size="sm" />
                )}
              </ProspectMenuBody>
            </React.Fragment>)}
        </div>
      </OptionsPanelDropdownMenu>
    </Dropdown>);
};

export default MessageOptionsPanel;
