import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { InsiderInfoDetails } from 'dwell/store/property_profile/action-types';
import { Content } from '../styles';

const PetInfo: FC = () => {
  const insiderInfo = useSelector(state => state.propertyProfile.insiderInfoDetails) as InsiderInfoDetails[];

  const insiderInfoPetInfo = insiderInfo?.find(({ category }) => category === 'Pet Info')?.details;

  return (
    <Content>
      {insiderInfoPetInfo?.length > 0 ?
        <>
          <h6 className="tx-16 text-dark"># Insider Info</h6>
          {insiderInfoPetInfo?.map(li => (
            <div>- {li}</div>
          ))}
        </> : null
      }
    </Content>
  );
};

export default PetInfo;
