import produce from 'immer';

import { SupportPortalState, ActionType, Request, Action, Comment } from './action-types';

const initialState: SupportPortalState = {
  requests: [],
  requestsCount: 0,
  requestsLoaded: true,
  requestComments: [],
  requestCommentsLoaded: true,
  requestIssueDescriptionParsed: '',
  newCommentLoading: false,
  errorMessage: '',
};

export const selectSupportRequests = (state: { supportPortal: SupportPortalState }): Request[] => state.supportPortal.requests;
export const selectSupportRequestsCount = (state: { supportPortal: SupportPortalState }): number => state.supportPortal.requestsCount;
export const selectSupportRequestsLoaded = (state: { supportPortal: SupportPortalState }): boolean => state.supportPortal.requestsLoaded;
export const selectSupportRequestComments = (state: { supportPortal: SupportPortalState }): Comment[] => state.supportPortal.requestComments;
export const selectSupportRequestCommentsLoaded = (state: { supportPortal: SupportPortalState }): boolean => state.supportPortal.requestCommentsLoaded;
export const selectSupportRequestIssueDescriptionParsed = (state: { supportPortal: SupportPortalState }): string => state.supportPortal.requestIssueDescriptionParsed;
export const selectSupportnewCommentLoading = (state: { supportPortal: SupportPortalState }): boolean => state.supportPortal.newCommentLoading;

export default produce((state: SupportPortalState = initialState, action: Action): SupportPortalState => {
  switch (action.type) {
    case ActionType.GET_REQUESTS_REQUEST:
      state.requestsLoaded = false;
      break;
    case ActionType.GET_REQUESTS_SUCCESS:
      state.requests = action.result.data.results;
      state.requestsLoaded = true;
      state.requestsCount = action.result.data.count;
      break;
    case ActionType.GET_REQUESTS_FAILURE:
      state.requestsLoaded = true;
      state.errorMessage = action.error.response?.status;
      break;
    case ActionType.CREATE_REQUEST_SUCCESS: {
      const request: Request = action.result.data;
      // try to add it to the list if the new request fulfills the filter criteria
      if (state.requests.length === 0 || state.requests.every((r: Request) => r.type === request.type)) {
        state.requests = [request].concat(state.requests);
      }
      state.requestsCount += 1;
      break;
    }

    case ActionType.DELETE_REQUEST_SUCCESS: {
      const requestDeleted: Request = action.result.data;
      state.requests = state.requests.filter((request: Request) => request.id !== requestDeleted.id);
      state.requestsCount -= 1;
      break;
    }

    case ActionType.UPDATE_REQUEST_SUCCESS: {
      const requestUpdated: Request = action.result.data;
      const index = state.requests.findIndex((request: Request) => request.id === requestUpdated.id);
      state.requests[index] = requestUpdated;
      break;
    }

    case ActionType.GET_REQUEST_COMMENTS_SUCCESS: {
      const { result: { data } } = action;
      state.requestComments = data.comments;
      state.requestIssueDescriptionParsed = data.issue_description_parsed;
      state.requestCommentsLoaded = true;
      break;
    }
    case ActionType.GET_REQUEST_COMMENTS_REQUEST: {
      state.requestCommentsLoaded = false;
      break;
    }
    case ActionType.GET_REQUEST_COMMENTS_FAILURE: {
      state.requestComments = [];
      state.requestIssueDescriptionParsed = '';
      state.requestCommentsLoaded = true;
      break;
    }
    case ActionType.CREATE_ISSUE_COMMENT_REQUEST: {
      state.newCommentLoading = true;
      break;
    }
    case ActionType.CREATE_ISSUE_COMMENT_SUCCESS: {
      const { comment }: { comment: Comment } = action.result.data;
      if (comment) {
        state.requestComments = [comment].concat(state.requestComments);
        state.newCommentLoading = false;
      }
      break;
    }
    case ActionType.CREATE_ISSUE_COMMENT_FAILURE: {
      state.newCommentLoading = false;
      break;
    }
    case ActionType.UPDATE_REQUEST_READED_BY_USER_IDS_SUCCESS: {
      const { data: request }: { data: Request } = action.result;
      state.requests = state.requests.map((r: Request) => (r.id === request.id ? request : r));
      break;
    }
  }
  return state;
});
