import { fieldChoices, TOUR_TYPES } from 'dwell/constants';

export const TOUR_TYPES_LIST = Object.keys(TOUR_TYPES).filter(i => i !== 'VIRTUAL_TOUR');

export const DEFAULT_TASK_TYPES = ['CALL_BACK', 'FIRST_CONTACT', 'REPLY_BACK'];

export const FOLLOWUPS = Object.keys(fieldChoices.TASK_FILED_CHOICES.type)
  .filter(i => ![...TOUR_TYPES_LIST, ...DEFAULT_TASK_TYPES].includes(i));

export const ALL_TOUR_TYPES = Object.keys(TOUR_TYPES);

export const ALL_TYPES = [...FOLLOWUPS, ...TOUR_TYPES_LIST, ...DEFAULT_TASK_TYPES];
