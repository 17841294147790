import { paths } from 'dwell/constants';
import { build } from 'dwell/constants/paths';
import { ActionType, Action, InsiderQuestionWithAnswerParams } from './action-types';

export default {
  getQuestionsPercentageByStatus: (propertyId: number): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_QUESTIONS_PERCENTAGE_BY_STATUS_REQUEST,
        ActionType.GET_QUESTIONS_PERCENTAGE_BY_STATUS_SUCCESS,
        ActionType.GET_QUESTIONS_PERCENTAGE_BY_STATUS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.INSIDER_QUESTIONS_PERCENTAGE_BY_STATUS, { params: { propertyId } }),
    },
  }),
  getQuestionsWithAnswers: (params: InsiderQuestionWithAnswerParams): Action => ({
    CALL_API: {
      types: [
        ActionType.GET_QUESTIONS_WITH_ANSWERS_REQUEST,
        ActionType.GET_QUESTIONS_WITH_ANSWERS_SUCCESS,
        ActionType.GET_QUESTIONS_WITH_ANSWERS_FAILURE,
      ],
      promise: client => client.get(paths.api.v1.INSIDER_QUESTIONS_WITH_ANSWERS, { params }),
    },
  }),
  createInsiderQuestionAnswer: (payload): Action => ({
    CALL_API: {
      types: [
        ActionType.CREATE_ANSWER_REQUEST,
        ActionType.CREATE_ANSWER_SUCCESS,
        ActionType.CREATE_ANSWER_FAILURE,
      ],
      promise: client => client.post(paths.api.v1.CREATE_INSIDER_QUESTION_ANSWER, payload),
    },
  }),
  updateInsiderQuestionAnswer: (payload): Action => ({
    CALL_API: {
      types: [
        ActionType.UPDATE_ANSWER_REQUEST,
        ActionType.UPDATE_ANSWER_SUCCESS,
        ActionType.UPDATE_ANSWER_FAILURE,
      ],
      promise: client => client.put(build(paths.api.v1.UPDATE_INSIDER_QUESTION_ANSWER, payload.id), payload),
    },
  }),
  confirmInsiderQuestionAnswer: (payload): Action => ({
    CALL_API: {
      types: [
        ActionType.CONFIRM_QUESTION_ANSWER_REQUEST,
        ActionType.CONFIRM_QUESTION_ANSWER_SUCCESS,
        ActionType.CONFIRM_QUESTION_ANSWER_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.CONFIRM_INSIDER_QUESTION_ANSWER, payload.id), payload),
    },
  }),
  denyInsiderQuestionAnswer: (payload): Action => ({
    CALL_API: {
      types: [
        ActionType.DENY_QUESTION_ANSWER_REQUEST,
        ActionType.DENY_QUESTION_ANSWER_SUCCESS,
        ActionType.DENY_QUESTION_ANSWER_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.DENY_INSIDER_QUESTION_ANSWER, payload.id), payload),
    },
  }),
  suggestEditInsiderQuestion: (payload): Action => ({
    CALL_API: {
      types: [
        ActionType.SUGGEST_EDIT_QUESTION_REQUEST,
        ActionType.SUGGEST_EDIT_QUESTION_SUCCESS,
        ActionType.SUGGEST_EDIT_QUESTION_FAILURE,
      ],
      promise: client => client.post(build(paths.api.v1.SUGGEST_EDIT_INSIDER_QUESTION, payload.id), payload),
    },
  }),
  toggleIsDefaultInsiderQuestionAnswer: (payload): Action => ({
    CALL_API: {
      types: [
        ActionType.TOGGLE_DEFAULT_QUESTION_ANSWER_REQUEST,
        ActionType.TOGGLE_DEFAULT_QUESTION_ANSWER_SUCCESS,
        ActionType.TOGGLE_DEFAULT_QUESTION_ANSWER_FAILURE,
      ],
      promise: client => client.patch(build(paths.api.v1.TOGGLE_DEFAULT_INSIDER_QUESTION_ANSWER, payload.id), payload),
    },
  }),
};
