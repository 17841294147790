import React, { FC, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormGroup, Input, FormFeedback } from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import actions from 'dwell/actions';
import { fieldChoices } from 'dwell/constants';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { LeadTypes, PageSection } from 'dwell/store/property_profile/action-types';
import { LeadCreationDialog } from 'dwell/components';
import { ErrorLabel } from 'styles/common';
import { FormLabel } from './styles';
import { Content } from '../styles';

const AddLead: FC = () => {
  const { push } = useHistory();
  const [focused, setFocused] = useState(false);
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [fields, setFields] = useState({
    first_name: '',
    last_name: '',
    source: null,
    email: '',
    phone_number: '',
    floor_plan: [],
    move_in_date: null,
    owner: null,
    unit_type: null,
  } as LeadTypes);
  const [errors, setErrors] = useState({ source: false, firstName: false, lastName: false, email: false, phone: false });
  const { sources, floor_plans: floorPlans, users } = useSelector(state => state.propertyProfile.propertyInfo);
  const currentProperty = useSelector(state => state.propertyProfile.currentProperty);
  const { unitType } = fieldChoices.LEAD_FILED_CHOICES;
  const { createLead, setSection } = actions.propertyProfile;
  const dispatch = useDispatch();

  const unitTypeChoices = Object.keys(unitType).map(item => ({ value: item, label: unitType[item] }));
  const handleOnChange = ({ target: { value, id } }) => {
    setFields({ ...fields, [id]: value });
  };

  const formatOptionLabel = ({ label, value }) => (
    <div style={{ display: 'flex' }}>
      <div className="inputCheck">
        <input type="checkbox" defaultChecked={fields.floor_plan && fields.floor_plan.includes(value)} />
      </div>
      <div style={{ color: 'red !important' }}>{label}</div>
    </div>
  );

  const validate = () => (
    {
      source: !fields.source,
      firstName: !fields.first_name,
      lastName: !fields.last_name,
      phone: !fields.email && !fields.phone_number,
      email: !fields.phone_number && !fields.email,
    }
  );

  const handleSaveSuccess = ({ result: { data } }) => {
    setTimeout(() => {
      dispatch(setSection(PageSection.MAIN_MENU));
      push(`/${currentProperty.external_id}/leads/${data.id}`);
    }, 1000);
  };

  const handleSaveFailure = ({ response: { data } }) => {
    if (data.id) {
      setIsDuplicated(true);
      setErrorData(data);
    }
  };

  const handleSave = () => {
    const newErrors = validate();
    setErrors(newErrors);
    if (!Object.values(newErrors).filter(i => i).length) {
      dispatch(createLead(currentProperty.id, {
        ...fields,
        first_name: fields.first_name || '-',
        last_name: fields.last_name || '-',
      })).then(e => handleSaveSuccess(e)).catch(e => handleSaveFailure(e));
    }
  };

  const floorPlanChoices = useMemo(
    () => floorPlans?.map(item => ({ value: item.id, label: item.plan })),
    [floorPlans],
  );

  const convertedSourceChoices = useMemo(
    () => sources.map(source => ({ value: source.id, label: source.name })).sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)),
    [sources],
  );

  const ownersChoices = useMemo(
    () => users?.map(o => ({ value: o.id, label: o.email })),
    [users],
  );

  const defaultOwner = useMemo(
    () => users.find(u => u.is_assigned_owner)?.id,
    [users],
  );

  useEffect(() => {
    setFields({ ...fields, owner: defaultOwner });
  }, [defaultOwner]);

  useEffect(() => {
    if (Object.values(errors).filter(i => i).length) setErrors(validate());
  }, [fields.source, fields.first_name, fields.last_name, fields.email, fields.phone_number]);

  return (
    <Content>
      <FormGroup>
        <FormLabel>First Name</FormLabel>
        <Input
          type="text"
          name="first_name"
          id="first_name"
          data-testid="first_name"
          placeholder="Enter first name"
          value={fields.first_name || ''}
          invalid={errors.firstName}
          onChange={handleOnChange}
        />
        <FormFeedback>First Name is required.</FormFeedback>
      </FormGroup>
      <FormGroup>
        <FormLabel>Last Name</FormLabel>
        <Input
          type="text"
          name="last_name"
          id="last_name"
          data-testid="last_name"
          placeholder="Enter last name"
          value={fields.last_name || ''}
          invalid={errors.lastName}
          onChange={handleOnChange}
        />
        <FormFeedback>Last Name is required.</FormFeedback>
      </FormGroup>
      <FormGroup>
        <FormLabel>Email Address</FormLabel>
        <Input
          type="text"
          name="email"
          id="email"
          data-testid="email"
          placeholder="Enter email address"
          value={fields.email || ''}
          invalid={errors.email}
          onChange={handleOnChange}
        />
        <FormFeedback>Email is required.</FormFeedback>
      </FormGroup>
      <FormGroup data-testid="phone_number">
        <FormLabel>Phone Number</FormLabel>
        <PhoneInput
          country="us"
          onlyCountries={['us']}
          id="phone_number"
          placeholder="Enter phone number"
          inputClass="phone-number-input"
          value={fields.phone_number || ''}
          onChange={phone => handleOnChange({ target: { id: 'phone_number', value: phone } })}
          disableDropdown
          disableCountryCode
        />
        <FormFeedback>Phone Number is required.</FormFeedback>
        {errors.phone && <ErrorLabel>Phone Number is required</ErrorLabel>}
      </FormGroup>
      <FormGroup data-testid="move_in_date">
        <FormLabel>Move-In Date</FormLabel>
        <SingleDatePicker
          inputIconPosition="after"
          small
          block
          numberOfMonths={1}
          placeholder="Select date"
          isOutsideRange={() => false}
          date={fields.move_in_date ? moment(fields.move_in_date) : null}
          onDateChange={date => handleOnChange({ target: { id: 'move_in_date', value: date && date.format('YYYY-MM-DD') } })}
          focused={focused}
          onFocusChange={e => setFocused(e.focused)}
          openDirection="down"
          hideKeyboardShortcutsPanel
          isDayHighlighted={day => day.isSame(moment(), 'd')}
        />
      </FormGroup>
      <FormGroup data-testid="floor_plan">
        <FormLabel>Floor Plan(s)</FormLabel>
        <Select
          defaultValue={isEmpty(fields.floor_plan) ? null : floorPlanChoices.filter(option => fields.floor_plan.includes(option.value))}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          formatOptionLabel={formatOptionLabel}
          options={floorPlanChoices}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Select floor plan"
          onChange={selectedItems => handleOnChange({ target: { id: 'floor_plan', value: selectedItems && selectedItems.length ? selectedItems.map(item => item.value) : [] } })}
        />
      </FormGroup>
      <FormGroup data-testid="unit_type">
        <FormLabel>Unit Type</FormLabel>
        <Select
          defaultValue={unitTypeChoices.find(i => i.value === fields.unit_type)}
          options={unitTypeChoices}
          classNamePrefix="select"
          placeholder="Select unit type"
          onChange={item => handleOnChange({ target: { id: 'unit_type', value: item.value } })}
        />
      </FormGroup>
      <FormGroup data-testid="source">
        <FormLabel>Source</FormLabel>
        <Select
          defaultValue={convertedSourceChoices.find(i => i.value === fields.source)}
          options={convertedSourceChoices}
          classNamePrefix="select"
          placeholder="Select source"
          onChange={item => handleOnChange({ target: { id: 'source', value: item.value } })}
        />
        <Input invalid={errors.source} hidden />
        <FormFeedback>Source is required.</FormFeedback>
      </FormGroup>
      <FormGroup data-testid="owner">
        <FormLabel>Owner</FormLabel>
        <Select
          value={ownersChoices.find(i => i.value === fields.owner)}
          options={ownersChoices}
          classNamePrefix="select"
          placeholder="Select owner"
          onChange={item => handleOnChange({ target: { id: 'owner', value: item.value } })}
        />
      </FormGroup>
      <button className="btn btn-primary btn-block" data-testid="add-new-lead" onClick={handleSave}>Add New Lead</button>
      {isDuplicated && (
        <LeadCreationDialog
          handleClose={() => setIsDuplicated(false)}
          show={isDuplicated}
          newLeadData={{ firstName: fields.first_name, lastName: fields.last_name, email: fields.email, moveInDate: fields.move_in_date }}
          errorData={errorData}
          property={currentProperty}
        />
      )}
    </Content>
  );
};

export default AddLead;
