import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Button, CardBody, Col, InputGroup, InputGroupAddon, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import actions from 'dwell/actions';
import { selectProperty } from 'dwell/store/property/reducers';
import { getPropertyId } from 'src/utils';
import {
  CancelBtn,
  PropertyList,
  PropertyListAction,
  PropertyListCard,
  PropertyListCardHeader,
  PropertyListCardTitle,
  PropertyListCloseIcon, PropertyListFormSearch,
  PropertyListFormSearchInput, PropertyListItem,
  PropertyListLabel,
  SaveBtn,
} from 'dwell/components/leads/lead_share_modal/styles';
import ConfirmModal from './_confirmModal';

const compare = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

interface BasicPropertyProps {
  id: number;
  name: string
}

interface LeadShareModalProps {
  show: boolean,
  handleClose: () => void,
  lead: { id: number, first_name: string, last_name: string },
}

const LeadShareModal: FC<LeadShareModalProps> = ({ handleClose, show, lead }) => {
  const { push } = useHistory();

  const closeBtn = <button className="close" onClick={() => handleClose()}><i className="ri-close-line" /></button>;
  const [keywordProperty, setKeywordProperty] = useState('');
  const [keywordSelectedProperty, setKeywordSelectedProperty] = useState('');
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [properties, setProperties] = useState<BasicPropertyProps[]>([]);
  const [currentProperty, setCurrentProperty] = useState<BasicPropertyProps>({} as BasicPropertyProps);
  const propertyDetails = useSelector(selectProperty);
  const isSubmitting = useSelector(state => state.lead.isSubmitting);
  const dispatch = useDispatch();
  const { shareLead, transferLead } = actions.lead;

  const handleSave = () => {
    if (isSubmitting) return;
    if (selectedProperties.map(p => p.id).includes(currentProperty.id)) {
      dispatch(shareLead({
        lead: lead.id,
        properties: selectedProperties.map(p => p.id),
      })).then(() => {
        handleClose();
      });
    } else {
      dispatch(transferLead({
        lead: lead.id,
        properties: selectedProperties.map(p => p.id),
      })).then(() => {
        handleClose();
        push(`/${getPropertyId()}/leads`);
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(propertyDetails)) {
      setProperties(propertyDetails.customer_properties);
      setCurrentProperty(propertyDetails);
    }
  }, [propertyDetails]);

  useEffect(() => {
    setAllProperties(properties.filter(p => p.id === currentProperty.id)
      .concat(properties.filter(p => p.id !== currentProperty.id).sort(compare)));
    setSelectedProperties(properties.filter(p => p.id === currentProperty.id));
  }, [properties]);

  const changeSelectedProperties = (event) => {
    const { target: { id, checked } } = event;
    if (typeof id !== 'string') return;
    const parsedId = Number(id.split('id-')[1]);
    if (!checked && parsedId === currentProperty.id && !isOpenConfirmModal) {
      setIsOpenConfirmModal(true);
      return;
    }
    if (checked) {
      setSelectedProperties(selectedProperties.concat(allProperties.filter(p => p.id === parsedId)));
    } else {
      setSelectedProperties(selectedProperties.filter(p => p.id !== parsedId));
    }
  };

  const onConfirm = () => {
    changeSelectedProperties({ target: { id: currentProperty.id, checked: false } });
    setIsOpenConfirmModal(false);
  };

  useEffect(() => {
    if (allProperties.length) {
      setAllProperties(allProperties.filter(p => p.id === currentProperty.id)
        .concat(allProperties.filter(p => p.id !== currentProperty.id && selectedProperties.includes(p)))
        .concat(allProperties.filter(p => p.id !== currentProperty.id && !selectedProperties.includes(p)).sort(compare)));
    }
  }, [selectedProperties]);

  const selectAllProperties = () => {
    setSelectedProperties(allProperties);
  };

  const selectNoneProperties = () => {
    setSelectedProperties(allProperties.filter(p => p.id === currentProperty.id));
    setIsOpenConfirmModal(true);
  };

  return (
    <Modal
      isOpen={show}
      toggle={() => handleClose()}
      centered
      aria-labelledby="example-custom-modal-styling-title"
      className="lead-share-modal"
      size="lg"
    >
      <ModalHeader close={closeBtn}>
        Share Lead - {lead.first_name} {lead.last_name}
      </ModalHeader>
      <ModalBody>
        <p>Select the properties to share the lead with:</p>
        <Row>
          <Col xs={6}>
            <PropertyListCard>
              <PropertyListCardHeader>
                <InputGroup className="search-group">
                  <PropertyListFormSearch>
                    <i className="ri-search-line" />
                    <PropertyListFormSearchInput
                      name="properties-search"
                      value={keywordProperty}
                      onChange={({ target: { value } }) => setKeywordProperty(value)}
                      placeholder="Search..."
                    />
                  </PropertyListFormSearch>
                  {keywordProperty &&
                  <InputGroupAddon addonType="append">
                    <Button color="white" className="clear-btn" onClick={() => setKeywordProperty('')}>&times;</Button>
                  </InputGroupAddon>}
                </InputGroup>
              </PropertyListCardHeader>
              <CardBody>
                <PropertyListCardTitle className="d-flex align-items-center justify-content-end">
                  <PropertyListAction onClick={() => selectNoneProperties()} >Select None</PropertyListAction>
                  <PropertyListAction onClick={() => selectAllProperties()} >Select All</PropertyListAction>
                </PropertyListCardTitle>
                <PropertyList>
                  {allProperties.filter(property => property.name.toLowerCase().includes(keywordProperty.toLowerCase())).map((property, index) =>
                    (
                      <PropertyListItem className="d-flex align-items-center" key={index}>
                        <div className="custom-control custom-checkbox mr-sm-2">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`id-${property.id}`}
                            name={`id-${property.id}`}
                            checked={selectedProperties && selectedProperties.includes(property)}
                            onChange={e => changeSelectedProperties(e)}
                          />
                          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                          <PropertyListLabel
                            htmlFor={`id-${property.id}`}
                            className="ml-1 custom-control-label"
                          >{property.id === currentProperty.id ? `${property.name} (owner)` : property.name}
                          </PropertyListLabel>
                        </div>
                      </PropertyListItem>
                    ))}
                </PropertyList>
              </CardBody>
            </PropertyListCard>
          </Col>
          <Col xs={6}>
            <PropertyListCard>
              <PropertyListCardHeader>
                <InputGroup className="search-group">
                  <PropertyListFormSearch>
                    <i className="ri-search-line" />
                    <PropertyListFormSearchInput
                      name="selected-properties-search"
                      value={keywordSelectedProperty}
                      onChange={({ target: { value } }) => setKeywordSelectedProperty(value)}
                      placeholder="Search..."
                    />
                  </PropertyListFormSearch>
                  {keywordSelectedProperty &&
                  <InputGroupAddon addonType="append">
                    <Button color="white" className="clear-btn" onClick={() => setKeywordSelectedProperty('')}>&times;</Button>
                  </InputGroupAddon>}
                </InputGroup>
              </PropertyListCardHeader>
              <CardBody>
                <PropertyListCardTitle className="d-flex align-items-center justify-content-between">
                  <div>{selectedProperties.length} selected</div>
                  <PropertyListAction onClick={() => selectNoneProperties()} >Clear All</PropertyListAction>
                </PropertyListCardTitle>
                <PropertyList>
                  {selectedProperties.filter(property => property.name.toLowerCase().includes(keywordSelectedProperty.toLowerCase())).map(property => (
                    <PropertyListItem isSelected key={`selected-${property.id}`} className="d-flex align-items-center justify-content-between">
                      <PropertyListLabel key={property.id} id={property.id}>{property.name} {property.id === currentProperty.id && '(owner)'}</PropertyListLabel>
                      <button style={{ opacity: 1 }} className="close" onClick={() => changeSelectedProperties({ target: { id: property.id, checked: false } })}><PropertyListCloseIcon className="ri-close-line" /></button>
                    </PropertyListItem>))}
                </PropertyList>
              </CardBody>
            </PropertyListCard>
          </Col>
        </Row>
        <ConfirmModal lead={lead} property={currentProperty} onCancel={() => setIsOpenConfirmModal(false)} onConfirm={onConfirm} show={isOpenConfirmModal} />
      </ModalBody>
      <ModalFooter>
        <CancelBtn className="btn" color="secondary" onClick={() => handleClose()} >Cancel</CancelBtn>
        <SaveBtn className="btn" color="primary" onClick={handleSave} disabled={!selectedProperties.some(p => p.id !== currentProperty.id) || isSubmitting} >{!isSubmitting ? 'Save Changes' : 'Saving...'}</SaveBtn>
      </ModalFooter>
    </Modal>
  );
};

export default LeadShareModal;
