import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Call } from '@twilio/voice-sdk';

import { selectCurrentUser } from 'dwell/store/user/reducers';
import {
  selectShowingIncomingCallNotification,
  selectDesktopPhoneCallStarted,
  selectDevice,
} from 'dwell/store/voice/reducers';
import { selectIsSessionTimedOut } from 'dwell/store/authentication/reducers';
import voiceActions from 'dwell/store/voice/action-creator';

import { getCallParams } from './utils';
import InboundCallNotification from '../notifications/inbound';
import OutboundCallNotification from '../notifications/outbound';

const VoiceCentre: FC = () => {
  const dispatch = useDispatch();

  const showInboundNotification = useSelector(selectShowingIncomingCallNotification);
  const showOutboundNotification = useSelector(selectDesktopPhoneCallStarted);
  const device = useSelector(selectDevice);

  const currentUser = useSelector(selectCurrentUser);
  const isSessionTimedOut = useSelector(selectIsSessionTimedOut);

  const refreshToken = () => {
    const refresh = true;
    dispatch(voiceActions.getVoiceToken(refresh)).then(() => dispatch(voiceActions.refreshDeviceToken()));
  };

  const incomingCall = (callReceived: Call) => {
    callReceived.on('cancel', () => dispatch(voiceActions.closeCallNotification()));
    dispatch(voiceActions.setIncomingCall(callReceived));
    dispatch(voiceActions.showIncomingCallNotification());
  };

  const onAcceptCall = (callToAccept: Call) => {
    dispatch(voiceActions.closeCallNotification());
    dispatch(voiceActions.acceptIncomigCall({ ...getCallParams(callToAccept), call: callToAccept }));
    callToAccept.accept();
  };

  const onRejectCall = (callToReject: Call) => {
    dispatch(voiceActions.closeCallNotification());
    callToReject.reject();
  };

  const setAsUnavailable = () => {
    dispatch(voiceActions.setUserUnavailbleToReceiveCalls(currentUser.id));
  };

  useEffect(() => {
    if (device) {
      // Avoid adding multiple listeners for the same event
      if (device.listenerCount('incoming') === 0) {
        device.on('incoming', incomingCall);
      }
      if (device.listenerCount('tokenWillExpire') === 0) device.on('tokenWillExpire', refreshToken);
    }
  }, [device]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (currentUser && currentUser.has_voice_setup && !device) {
      // TODO: Remove or refactor after validation dispatch(voiceActions.getVoiceToken()).then(() => dispatch(voiceActions.createDevice()));
      if (currentUser.ai_noise_suppression_enabled) {
        dispatch(voiceActions.getVoiceToken()).then(() => dispatch(voiceActions.createDevice()));
      } else {
        dispatch(voiceActions.getVoiceToken()).then(() => dispatch(voiceActions.createDeviceWithoutNoiseCancel()));
      }
    }
  }, [currentUser.id]);

  useEffect(() => {
    if (!('Notification' in window)) return;
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    if (isSessionTimedOut) {
      setAsUnavailable();
    }
    if (!isSessionTimedOut && currentUser && currentUser.has_voice_setup && !device) {
      // TODO: Remove or refactor after validation dispatch(voiceActions.getVoiceToken()).then(() => dispatch(voiceActions.createDevice()));
      if (currentUser.ai_noise_suppression_enabled) {
        dispatch(voiceActions.getVoiceToken()).then(() => dispatch(voiceActions.createDevice()));
      } else {
        dispatch(voiceActions.getVoiceToken()).then(() => dispatch(voiceActions.createDeviceWithoutNoiseCancel()));
      }
    }
  }, [isSessionTimedOut]);

  return (
    <>
      {showInboundNotification && <InboundCallNotification acceptCall={onAcceptCall} rejectCall={onRejectCall} />}
      {showOutboundNotification && <OutboundCallNotification />}
    </>
  );
};

export default VoiceCentre;
