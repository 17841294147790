import { PromiseActionType } from 'dwell/store/types';

export interface Section {
  id: number;
  page: number;
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any;
}

export interface Page {
  id: number;
  page: string;
  // customer: number;
  sections: Section[];
  sections_order: number[];
  page_settings: PageSettings;
  hide: boolean;
}

export interface PageSettings {
  title_tag: string;
  meta_description: string;
  search_engines_index: boolean;
  no_index: boolean;
  no_follow: boolean;
  no_snippet: boolean;
  no_archive: boolean;
  no_image_index: boolean;
  canonical: string;
  site_name: string;
  type: string;
  url: string;
  html_tags: {
    tag: string;
    attributes: {
      key: string;
      value: string;
    }[];
  }[]
}

export enum ActionType {
  TOGGLE_PANEL_SIDEBAR = 'TOGGLE_PANEL_SIDEBAR',
  SET_TOOLBAR_NAV_TAB = 'SET_TOOLBAR_NAV_TAB',

  GET_SITE_PAGE_DATA_REQUEST = 'GET_SITE_PAGE_DATA_REQUEST',
  GET_SITE_PAGE_DATA_SUCCESS = 'GET_SITE_PAGE_DATA_SUCCESS',
  GET_SITE_PAGE_DATA_FAILURE = 'GET_SITE_PAGE_DATA_FAILURE',

  GET_SUBSITE_PAGE_DATA_REQUEST = 'GET_SUBSITE_PAGE_DATA_REQUEST',
  GET_SUBSITE_PAGE_DATA_SUCCESS = 'GET_SUBSITE_PAGE_DATA_SUCCESS',
  GET_SUBSITE_PAGE_DATA_FAILURE = 'GET_SUBSITE_PAGE_DATA_FAILURE',

  CORPORATE_SET_PAGE = 'CORPORATE_SET_PAGE',
  ADDING_PAGE = 'ADDING_PAGE',
  ADDING_PAGE_FINISHED = 'ADDING_PAGE_FINISHED',
  RENAME_PAGE = 'RENAME_PAGE',
  RENAME_PAGE_FINISHED = 'RENAME_PAGE_FINISHED',
  DUPLICATE_PAGE = 'DUPLICATE_PAGE',
  DELETE_PAGE = 'DELETE_PAGE',

  HIDE_SITE_PAGE_REQUEST = 'HIDE_SITE_PAGE_REQUEST',
  HIDE_SITE_PAGE_SUCCESS = 'HIDE_SITE_PAGE_SUCCESS',
  HIDE_SITE_PAGE_FAILURE = 'HIDE_SITE_PAGE_FAILURE',

  HIDE_SUBSITE_PAGE_REQUEST = 'HIDE_SUBSITE_PAGE_REQUEST',
  HIDE_SUBSITE_PAGE_SUCCESS = 'HIDE_SUBSITE_PAGE_SUCCESS',
  HIDE_SUBSITE_PAGE_FAILURE = 'HIDE_SUBSITE_PAGE_FAILURE',

  SET_ENABLE_SAVE_CHANGES_BUTTON = 'SET_ENABLE_SAVE_CHANGES_BUTTON',
  ENABLE_REDO_CHANGES_BUTTON = 'ENABLE_CORPORATE_REDO_CHANGES_BUTTON',
  ENABLE_UNDO_CHANGES_BUTTON = 'ENABLE_CORPORATE_UNDO_CHANGES_BUTTON',

  PUBLISH_CORPORATE_SITE_REQUEST = 'PUBLISH_CORPORATE_SITE_REQUEST',
  PUBLISH_CORPORATE_SITE_SUCCESS = 'PUBLISH_CORPORATE_SITE_SUCCESS',
  PUBLISH_CORPORATE_SITE_FAILURE = 'PUBLISH_CORPORATE_SITE_FAILURE',

  PUBLISH_SUBSITE_REQUEST = 'PUBLISH_SUBSITE_REQUEST',
  PUBLISH_SUBSITE_SUCCESS = 'PUBLISH_SUBSITE_SUCCESS',
  PUBLISH_SUBSITE_FAILURE = 'PUBLISH_SUBSITE_FAILURE',

  SET_NAV_PAGE = 'SET_NAV_PAGE',

  PAGE_SECTIONS_UPDATED = 'PAGE_SECTIONS_UPDATED',

  TOGGLE_PAGE_SETTINGS_PANEL = 'TOGGLE_PAGE_SETTINGS_PANEL',

  UPDATE_SITE_PAGE_SETTINGS_REQUEST = 'UPDATE_SITE_PAGE_SETTINGS_REQUEST',
  UPDATE_SITE_PAGE_SETTINGS_SUCCESS = 'UPDATE_SITE_PAGE_SETTINGS_SUCCESS',
  UPDATE_SITE_PAGE_SETTINGS_FAILURE = 'UPDATE_SITE_PAGE_SETTINGS_FAILURE',

  UPDATE_SUB_SITE_PAGE_SETTINGS_REQUEST = 'UPDATE_SUB_SITE_PAGE_SETTINGS_REQUEST',
  UPDATE_SUB_SITE_PAGE_SETTINGS_SUCCESS = 'UPDATE_SUB_SITE_PAGE_SETTINGS_SUCCESS',
  UPDATE_SUB_SITE_PAGE_SETTINGS_FAILURE = 'UPDATE_SUB_SITE_PAGE_SETTINGS_FAILURE',

  SET_SHOW_UNSAVED_CHANGES_MODAL = 'SHOW_UNSAVED_CHANGES_MODAL',
}

interface SetNavPageAction {
  type: ActionType.SET_NAV_PAGE;
  navPage: 'EDIT_SITE' | 'SITE_SETTINGS' | 'MEDIA_LIBRARY';
}

interface GetSitePageDataAction {
  type: ActionType.GET_SITE_PAGE_DATA_REQUEST | ActionType.GET_SITE_PAGE_DATA_SUCCESS | ActionType.GET_SITE_PAGE_DATA_FAILURE;
  result: {
    data: Page[];
  };
  error: {
    response: { status: string };
  };
}

interface GetSubsitePageDataAction {
  type: ActionType.GET_SUBSITE_PAGE_DATA_REQUEST | ActionType.GET_SUBSITE_PAGE_DATA_SUCCESS | ActionType.GET_SUBSITE_PAGE_DATA_FAILURE;
  result: {
    data: Page[];
  };
  error: {
    response: { status: string };
  };
}

interface TogglePanelSidebarAction {
  type: ActionType.TOGGLE_PANEL_SIDEBAR;
}

interface TogglePageSettingsAction {
  type: ActionType.TOGGLE_PAGE_SETTINGS_PANEL;
  show: boolean;
}

interface SetToolbarNavTabAction {
  type: ActionType.SET_TOOLBAR_NAV_TAB;
  nav: 'PAGES' | 'SECTIONS';
}

interface SetCorporatePageAction {
  type: ActionType.CORPORATE_SET_PAGE;
  pageId: number;
}

// interface SetSectionAction {
//   type: ActionType.SET_SECTION;
//   sectionId: number;
// }

// interface ToggleAddNewSectionPanelAction {
//   type: ActionType.TOGGLE_ADD_NEW_SECTION_PANEL;
// }

interface AddingPageAction {
  type: ActionType.ADDING_PAGE;
  name: string;
}

interface AddingPageFinishedAction {
  type: ActionType.ADDING_PAGE_FINISHED;
  name: string;
}

interface RenamePageAction {
  type: ActionType.RENAME_PAGE;
  index: number;
}

interface HideSitePageAction {
  type: ActionType.HIDE_SITE_PAGE_REQUEST | ActionType.HIDE_SITE_PAGE_SUCCESS | ActionType.HIDE_SITE_PAGE_FAILURE;
  result: {
    data: Page;
  };
  error: {
    response: { status: string };
  };
}

interface HideSubSitePageAction {
  type: ActionType.HIDE_SUBSITE_PAGE_REQUEST | ActionType.HIDE_SUBSITE_PAGE_SUCCESS | ActionType.HIDE_SUBSITE_PAGE_FAILURE;
  result: {
    data: Page;
  };
  error: {
    response: { status: string };
  };
}

interface RenamePageFinishedAction {
  type: ActionType.RENAME_PAGE_FINISHED;
  index: number;
  name: string;
}

interface DuplicatePageAction {
  type: ActionType.DUPLICATE_PAGE;
  index: number;
}

interface DeletePageAction {
  type: ActionType.DELETE_PAGE;
  index: number;
}

interface SetSaveButtonStateAction {
  type: ActionType.SET_ENABLE_SAVE_CHANGES_BUTTON;
  enable: boolean;
}

interface PublishCorporateSiteAction {
  type: ActionType.PUBLISH_CORPORATE_SITE_REQUEST | ActionType.PUBLISH_CORPORATE_SITE_SUCCESS | ActionType.PUBLISH_CORPORATE_SITE_FAILURE;
  result: { data: Page[] };
  error: { response: { status: string } };
}

interface PublishSubsiteAction {
  type: ActionType.PUBLISH_SUBSITE_REQUEST | ActionType.PUBLISH_SUBSITE_SUCCESS | ActionType.PUBLISH_SUBSITE_FAILURE;
  result: { data: Page[] };
  error: { response: { status: string } };
}

interface PageSectionsUpdatedAction {
  type: ActionType.PAGE_SECTIONS_UPDATED;
  sections: Section[];
}

interface UpdateSitePageSettingsAction {
  type: ActionType.UPDATE_SITE_PAGE_SETTINGS_REQUEST | ActionType.UPDATE_SITE_PAGE_SETTINGS_SUCCESS | ActionType.UPDATE_SITE_PAGE_SETTINGS_FAILURE;
  result: { data: Page }
  error: { response: { status: string } };
}

interface UpdateSubSitePageSettingsAction {
  type: ActionType.UPDATE_SUB_SITE_PAGE_SETTINGS_REQUEST | ActionType.UPDATE_SUB_SITE_PAGE_SETTINGS_SUCCESS | ActionType.UPDATE_SUB_SITE_PAGE_SETTINGS_FAILURE;
  result: { data: Page }
  error: { response: { status: string } };
}

interface SetShowUnsavedChangesModal {
  type: ActionType.SET_SHOW_UNSAVED_CHANGES_MODAL;
  show: boolean;
}

interface enableRedoChangesButton {
  type: ActionType.ENABLE_REDO_CHANGES_BUTTON,
  enable: boolean,
}

interface enableUndoChangesButton {
  type: ActionType.ENABLE_UNDO_CHANGES_BUTTON,
  enable: boolean,
}

export type Action =
  | SetNavPageAction
  | PromiseActionType
  | GetSitePageDataAction
  | GetSubsitePageDataAction
  | TogglePanelSidebarAction
  | SetToolbarNavTabAction
  | SetCorporatePageAction
  | AddingPageAction
  | AddingPageFinishedAction
  | RenamePageAction
  | RenamePageFinishedAction
  | DuplicatePageAction
  | DeletePageAction
  | HideSitePageAction
  | HideSubSitePageAction
  | PublishCorporateSiteAction
  | PublishSubsiteAction
  | PageSectionsUpdatedAction
  | SetSaveButtonStateAction
  | TogglePageSettingsAction
  | UpdateSitePageSettingsAction
  | UpdateSubSitePageSettingsAction
  | SetShowUnsavedChangesModal
  | enableRedoChangesButton
  | enableUndoChangesButton;
