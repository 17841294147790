import React, { useState, useEffect, FC } from 'react';
import { ModalBody, ModalHeader, ModalFooter, Button, FormGroup, Input } from 'reactstrap';
import { ModalWindow } from 'site/components/sortable_list/styles';
import { ModalFormLabel } from '../common';
import CheckBox from '../check_box';
import SelectCustom from '../select_custom';

interface Media {
  title?: string,
  location?: string,
  category?: string,
  altTag?: string,
}

interface EditProps {
  isModalOpen?: boolean,
  onModalToggle: () => void,
  onSubmit: (data: Media) => void,
  submitting?: boolean,
  isVideo?: boolean,
  indexToChange?: string,
  displayOnHomepage?: boolean,
  categories?: string[],
  data?: Media,
  onHomePageVideoChange?: (index: string) => void,
  isOnlyAltTagEdit?: boolean,
}

const EditMediaModal: FC<EditProps> = ({ isModalOpen, onModalToggle, onSubmit, submitting, data, categories, displayOnHomepage, indexToChange, onHomePageVideoChange, isVideo, isOnlyAltTagEdit }) => {
  const [editMediaState, updateMedia] = useState({
    title: data.title,
    location: data.location,
    category: data.category,
    altTag: data.altTag,
  });

  useEffect(() => {
    updateMedia({
      ...editMediaState,
      title: data.title,
      location: data.location,
      category: data.category,
      altTag: data.altTag,
    });
  }, [data]);

  const handleChange = (key, value) => {
    updateMedia({ ...editMediaState, [key]: value !== '-1' ? value : null });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { title, location, category, altTag } = editMediaState;

    onSubmit({ title, location, category, altTag });
    updateMedia({ ...editMediaState, title: '', location: '', category: '', altTag: '' });
    onModalToggle();
  };

  const { title, location, category, altTag } = editMediaState;

  return (
    <ModalWindow isOpen={isModalOpen} toggle={onModalToggle} keyboard={!submitting} centered>
      <ModalHeader toggle={onModalToggle}>
        Edit Media Details
      </ModalHeader>
      <form id="edit-media-form" onSubmit={handleSubmit}>
        <ModalBody>
          {!isOnlyAltTagEdit && (
            <FormGroup>
              <ModalFormLabel for="title">Title</ModalFormLabel>
              <Input
                type="text"
                id="title"
                placeholder="Title"
                value={title}
                onChange={e => handleChange('title', e.target.value)}
                disabled={submitting}
              />
            </FormGroup>)}
          {!isOnlyAltTagEdit && (
            <FormGroup>
              <ModalFormLabel for="videoDescription">Location</ModalFormLabel>
              <Input
                id="location"
                placeholder="Location"
                value={location}
                onChange={e => handleChange('location', e.target.value)}
                disabled={submitting}
              />
            </FormGroup>)}
          {!isOnlyAltTagEdit && (
            <>
              <ModalFormLabel>Category</ModalFormLabel>
              <SelectCustom
                value={category}
                options={categories}
                id="images"
                disabled={false}
                handleInputChange={e => handleChange('category', e.target.value)}
                placeholderDisplay
              />
            </>)}
          {isVideo && !isOnlyAltTagEdit &&
            <div className="mt-3">
              <CheckBox
                checked={!!displayOnHomepage}
                id={`video_home_page_check_${indexToChange}`}
                onChange={() => onHomePageVideoChange(indexToChange)}
                label="Display on homepage"
              />
            </div>
          }
          <FormGroup style={{ marginTop: `${isOnlyAltTagEdit ? '0px' : '16px'}` }}>
            <ModalFormLabel for="title">Alt Tag</ModalFormLabel>
            <Input
              style={{ resize: 'none' }}
              rows="2"
              type="textarea"
              id="altTag"
              placeholder="Alt Tag"
              value={altTag}
              onChange={e => handleChange('altTag', e.target.value)}
              disabled={submitting}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="white" type="button" onClick={onModalToggle} disabled={submitting}>
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={submitting}>
            Save changes
          </Button>
        </ModalFooter>
      </form>
    </ModalWindow>
  );
};

EditMediaModal.defaultProps = {
  isModalOpen: false,
  submitting: false,
  data: {
    title: '',
    location: '',
    category: '',
    altTag: '',
  },
  categories: null,
  displayOnHomepage: false,
  indexToChange: null,
  isVideo: false,
};

export default EditMediaModal;
