import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Avatar from 'dwell/components/avatar';
import { UserProps } from 'dwell/store/user/action-types';

interface CommentEditorProps {
  user?: UserProps;
  editorContent: string;
  setEditorContent: (content: string) => void;
  handleSave: () => void;
  handleCancel: () => void;
}

const CommentEditor: React.FC<CommentEditorProps> = ({
  user,
  editorContent,
  setEditorContent,
  handleSave,
  handleCancel,
}) => {
  // Local state
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // Quill modules
  const modules = {
    toolbar: [
      ['bold', 'italic'],
      ['link', 'blockquote', 'code-block', 'image'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  // handlers
  const onCancel = () => {
    handleCancel();
    setIsEditing(false);
  };

  const onSave = () => {
    handleSave();
    setIsEditing(false);
  };

  return (
    <div className="media mb-3">
      {!!user && (
        <Avatar
          name={`${user.first_name} ${user.last_name}`}
          className="mr-3"
        />
      )}
      <div className="media-body">
        {!isEditing ? (
          <input
            id="addComment"
            type="text"
            className="form-control"
            placeholder="Add a comment"
            onFocus={() => setIsEditing(true)}
          />
        ) : (
          <div className="sr-comment">
            <div id="editorComment">
              <ReactQuill
                theme="snow"
                modules={modules}
                placeholder="Write some comment..."
                value={editorContent}
                onChange={setEditorContent}
              />
            </div>
            <div className="d-flex mt-2">
              <button className="btn btn-primary mr-1" onClick={onSave}>Save</button>
              <button id="commentCancel" className="btn btn-white" onClick={onCancel}>Cancel</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentEditor;
