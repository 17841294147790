const EDIT = {
  id: 'edit',
  name: 'Edit',
  icon: 'ri-edit-2-line',
};

const DELETE = {
  id: 'delete',
  name: 'Delete',
  icon: 'ri-delete-bin-line',
};

const MOVE_UP = {
  id: 'move_up',
  name: 'Move Section Up',
  icon: 'ri-arrow-up-line',
};

const MOVE_DOWN = {
  id: 'move_down',
  name: 'Move Section Down',
  icon: 'ri-arrow-down-line',
};

const EDIT_DELETE = [EDIT, DELETE];
const MOVE_UP_DOWN = [MOVE_UP, MOVE_DOWN];

const SITE_PAGES_ITEM = [
  {
    id: 'page_settings',
    name: 'Page Settings',
    icon: 'ri-settings-6-line',
  },
  {
    id: 'hide',
    name: 'Hide Page',
    icon: 'ri-eye-off-line',
  },
  {
    id: 'rename',
    name: 'Rename',
    icon: 'ri-edit-2-line',
  },
  {
    id: 'duplicate',
    name: 'Duplicate',
    icon: 'ri-file-copy-line',
  },
  DELETE,
];

const PAGES_SECTION_FIXED_ITEM = [EDIT];

const PAGES_SECTION_ITEM = [...EDIT_DELETE, ...MOVE_UP_DOWN];

const PAGES_SECTION_MOVE_UP_EDIT_DELETE = [MOVE_UP, ...EDIT_DELETE];

const PAGES_SECTION_MOVE_DOWN_EDIT_DELETE = [MOVE_DOWN, ...EDIT_DELETE];

const MENU_OPTIONS = [
  { id: 'edit_menu', name: 'Edit Menu', icon: 'ri-pencil-line' },
  { id: 'delete_menu', name: 'Delete Menu', icon: 'ri-delete-bin-line' },
];

const BUTTON_OPTIONS = [
  { id: 'edit_button', name: 'Edit Button', icon: 'ri-pencil-line' },
];

const VERSION_OPTIONS = [
  { value: 'V1', label: 'V1' },
  { value: 'V2', label: 'V2' },
];

const SITE_PAGES = [
  { value: 'HOME', label: 'Home' },
  { value: 'ABOUT_US', label: 'About Us' },
  { value: 'BLOG', label: 'Blog' },
  { value: 'CAREERS', label: 'Careers' },
  { value: 'CONSULTING', label: 'Consulting' },
  { value: 'DEVELOPMENT', label: 'Development' },
  { value: 'LOGIN', label: 'Login' },
  { value: 'PROPERTY_MANAGEMENT', label: 'Property Management' },
  { value: 'FIND_YOUR_HOME', label: 'Find Your Home' },
];

const SUB_SITE_PAGES = [
  { value: 'HOME', label: 'Home' },
  { value: 'FLOOR_PLANS', label: 'Floor Plans' },
  { value: 'VIRTUAL_TOUR', label: 'Virtual Tour' },
  { value: 'PHOTOS', label: 'Photos' },
  { value: 'AMENITIES', label: 'Amenities' },
  { value: 'PET_FRIENDLY', label: 'Pet Friendly' },
  { value: 'NEIGHBORHOOD', label: 'Neighborhood' },
  { value: 'CONTACT_US', label: 'Contact Us' },

  // SITE PAGES (Needed to navigate to Site Pages from a Sub Site, used in Footer)
  { value: 'PROPERTY_MANAGEMENT', label: 'Property Management' },
  { value: 'FIND_YOUR_HOME', label: 'Find Your Home' },
  { value: 'ABOUT_US', label: 'About Us' },
  { value: 'CAREERS', label: 'Careers' },
];

const POPUP_OPTIONS = [
  { value: 'CONTACT_FORM', label: 'Contact Form' },
];

const BUTTON_CUSTOM_ACTIONS = [
  { value: 'SCHEDULE_A_TOUR', label: 'Schedule a Tour' },
  { value: 'GET_IN_TOUCH', label: 'Get in Touch' },
  { value: 'APPLY_NOW', label: 'Apply Now' },
  { value: 'TOUR_WITH_AN_AGENT', label: 'Tour With an Agent' },
  { value: 'VIRTUAL_TOUR', label: 'Virtual Tour' },
];

const THEME_OPTIONS = [
  { value: true, label: 'Light Theme' },
  { value: false, label: 'Dark Theme' },
];

const POINTS_OF_INTEREST = [
  { label: 'Restaurants', value: 'restaurants' },
  { label: 'Shops', value: 'shops' },
  { label: 'Schools', value: 'schools' },
  { label: 'Public Transit', value: 'public_transit' },
  { label: 'Airports', value: 'airports' },
  { label: 'Recreational Parks', value: 'recreational_parks' },
  { label: 'Hospitals', value: 'hospitals' },
];

export default {
  THEME_OPTIONS,
  SITE_PAGES_ITEM,
  PAGES_SECTION_FIXED_ITEM,
  PAGES_SECTION_ITEM,
  PAGES_SECTION_MOVE_UP_EDIT_DELETE,
  PAGES_SECTION_MOVE_DOWN_EDIT_DELETE,
  MENU_OPTIONS,
  BUTTON_OPTIONS,
  VERSION_OPTIONS,
  SITE_PAGES,
  SUB_SITE_PAGES,
  BUTTON_CUSTOM_ACTIONS,
  POPUP_OPTIONS,
  POINTS_OF_INTEREST,
};
