export default {
  LEASE_TAB_CHOICES: {
    paymentMethods: {
      CERTIFIED_CHECK: 'Certified check',
      BANK_CHECK: 'Bank check',
      E_CHECK: 'E-check',
      CREDIT_CARD: 'Credit card',
      DEBIT_CARD: 'Debit card',
    },
    concessionMonths: {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    },
    concessionTypes: {
      CONCESSION: 'Concession',
      VOUCHER: 'Voucher',
    },
    petTypes: {
      CAT: 'Cat',
      DOG: 'Dog',
      BIRD: 'Bird',
      OTHER: 'Other',
    },
    petSexes: {
      MALE: 'Male',
      FEMALE: 'Female',
      OTHER: 'Other',
    },
    parkingTypes: {
      CARPORT: 'Carport',
      UNCOVERED: 'Uncovered',
    },
    garageTypes: {
      GARAGE: 'Garage',
      DETACHED_GARAGE: 'Detached Garage',
    },
    garageTerms: {
      SAME_AS_LEASE_TERM: 'Same as lease term',
      MONTH_TO_MONTH: 'Month-to-month',
      OTHER: 'Other',
    },
    storageTerms: {
      SAME_AS_LEASE_TERM: 'Same as lease term',
      MONTH_TO_MONTH: 'Month-to-month',
      OTHER: 'Other',
    },
    gateKeyTypes: {
      GATE_ENTRY_KEY_FOB: 'Gate Entry Key Fob',
      GARAGE_DOOR_OPENER: 'Garage Door Opener',
      FITNESS_CENTER_KEY: 'Fitness Center Key',
    },
    securityDepositTypeChoices: {
      TRADITIONAL: 'Traditional',
      E_PREMIUM: 'ePremium',
      USD: 'Union Security Deposit',
    },
    leaseTypeChoices: {
      NEW_LEASE: 'New Lease',
      RENEWAL: 'Renewal',
      TRANSFER: 'Transfer',
      LEASE_CHANGE: 'Lease Change',
    },
  },
  applicationDecisionChoices: {
    AWAITING_PROPERTY_DECISION: 'Awaiting Property Decision',
    APPROVED: 'Approved',
    CONDITIONALLY_APPROVED: 'Conditionally Approved',
    DENIED: 'Declined',
    CANCELED: 'Canceled',
  },
  leasingProgressChoices: {
    SUBMIT_APPLICATION: 'Submit Application',
    PAY_APPLICATION_FEES: 'Pay Application Fees',
    REVIEW_LEASE_DETAILS: 'Review Lease Details ',
    GENERATE_DOCUMENTS: 'Generate Documents',
    SEND_DEPOSIT_AGREEMENT: 'Send Deposit Agreement',
    SIGN_DEPOSIT_AGREEMENT: 'Sign Deposit Agreement',
    COUNTERSIGN_DEPOSIT_AGREEMENT: 'Countersign Deposit Agreement',
    SCREEN_APPLICATION_GROUP: 'Screen Application Group',
    MAKE_APPLICATION_DECISION: 'Make Application Decision',
    SEND_LEASE: 'Send Lease',
    SIGN_LEASE: 'Sign Lease',
    COUNTERSIGN_LEASE: 'Countersign Lease',
  },
  applicantStepChoices: {
    SUBMIT_APPLICATION: { label: 'Submit Application', nonStateLabel: 'Not Submitted' },
    PAY_APPLICATION_FEE: { label: 'Pay Application Fee', nonStateLabel: 'Not Paid' },
    PASS_SCREENING: { label: 'Pass Screening', nonStateLabel: 'Not Passed' },
    LEASE_AGREEMENT: { label: 'Lease Agreement', nonStateLabel: 'Not Signed' },
    DEPOSIT_AGREEMENT: { label: 'Deposit Agreement', nonStateLabel: 'Not Signed' },
  },
  guarantorStepChoices: {
    SUBMIT_APPLICATION: { label: 'Submit Application', nonStateLabel: 'Not Submitted' },
    PAY_APPLICATION_FEE: { label: 'Pay Application Fee', nonStateLabel: 'Not Paid' },
    PASS_CREDIT_CHECK: { label: 'Pass Credit Check', nonStateLabel: 'Not Passed' },
    LEASE_AGREEMENT: { label: 'Guarantor Addendum', nonStateLabel: 'Not Signed' },
  },
  screeningStatusChoices: {
    ACCEPT: 'Accept',
    PENDING: 'Pending',
    CONDITIONAL: 'Conditional',
    DECLINE: 'Decline',
    UNABLE_TO_ASSESS: 'Unable to assess',
  },
  creditDecisionChoices: {
    CREDIT_ACCEPT: 'Accept',
    CREDIT_ACCEPT_WITH_CONDITIONS: 'Accept with Conditions',
    CREDIT_PENDING: 'Pending',
    CREDIT_DECLINE: 'Decline',
    CREDIT_UNABLE_TO_ASSESS: 'Unable to assess',
  },
  criminalDecisionChoices: {
    CRIMINAL_ACCEPT: 'Accept',
    CRIMINAL_PENDING: 'Pending',
    CRIMINAL_RECORDS_FOUND: 'Records found',
    CRIMINAL_UNABLE_TO_ASSESS: 'Unable to assess',
  },
};

export const phoneTypes = {
  MOBILE: 'Mobile',
  LANDLINE: 'Landline',
};

export const relationshipTypes = {
  SPOUSE: 'Spouse',
  FRIEND: 'Friend',
  PARTNER: 'Partner',
  PARENT: 'Parent',
  ADULT_CHILD: 'Adult Child',
  ADULT_RELATIVE: 'Adult Relative',
};

export const ownRentTypes = {
  RENT: 'Rent',
  OWN: 'Owned',
};

export const identificationTypes = {
  SSN: 'SSN',
  ITIN: 'ITIN',
};

export const countries = {
  UNITED_STATES: 'United States',
  CANADA: 'Canada',
};

export const USStates = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

const CanadaStates = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NT: 'Northwest Territories',
  NS: 'Nova Scotia',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

export const AllStates = [...Object.keys(USStates), ...Object.keys(CanadaStates)].sort().reduce(
  (acc, key) => ({ ...acc, [key]: key in CanadaStates ? CanadaStates[key] : USStates[key] }),
  {},
);

export const states = { UNITED_STATES: USStates, CANADA: CanadaStates, ALL: AllStates };

export const rentableItemTypeChoices = {
  PARKING: 'Parking',
  GARAGE: 'Garage',
  STORAGE_UNIT: 'Storage Unit',
  OTHER: 'Other',
};

export enum LeaseDocumentType {
  DEPOSIT_AGREEMENT = 'DEPOSIT_AGREEMENT',
  LEASE_AGREEMENT = 'LEASE_AGREEMENT',
  NOTICE = 'NOTICE',
  RENEW_LEASE_AGREEMENT = 'RENEW_LEASE_AGREEMENT',
  CREDIT_BUILDER_ADDENDUM = 'CREDIT_BUILDER_ADDENDUM',
  EXISTING_RESIDENT_CREDIT_BUILDER_ADDENDUM = 'EXISTING_RESIDENT_CREDIT_BUILDER_ADDENDUM',
  ROOMMATE_ADDENDUM = 'ROOMMATE_ADDENDUM',
}

export const NoticeTypeChoice = {
  STANDARD_LEASE: 'Standard Lease',
  LEASE_BREAK: 'Lease Break',
  MONTH_TO_MONTH_LEASE: 'Month-to-Month Lease',
};
