import React, { FC } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { InsiderInfoDetails, LeasingCriteriaTypes } from 'dwell/store/property_profile/action-types';
import { currencyFormat } from 'compete/constants';
import { Content } from '../styles';

const LeasingRentalCriteria: FC = () => {
  const insiderInfo = useSelector(state => state.propertyProfile.insiderInfoDetails) as InsiderInfoDetails[];
  const leasingCriteria = useSelector(state => state.propertyProfile.leasingCriteria) as LeasingCriteriaTypes;

  const insiderInfoRentalCriteria = insiderInfo?.find(({ category, subcategory }) => category === 'Leasing and Rental Criteria' && subcategory === 'Rental Criteria')?.details;
  const insiderInfoDepositOptions = insiderInfo?.find(({ category, subcategory }) => category === 'Leasing and Rental Criteria' && subcategory === 'Deposit Options')?.details;
  const insiderInfoApplicationFees = insiderInfo?.find(({ category, subcategory }) => category === 'Leasing and Rental Criteria' && subcategory === 'Application Fees')?.details;
  const insiderInfoPetFees = insiderInfo?.find(({ category, subcategory }) => category === 'Leasing and Rental Criteria' && subcategory === 'Pet Fees')?.details;
  const insiderInfoOtherRentItems = insiderInfo?.find(({ category, subcategory }) => category === 'Leasing and Rental Criteria' && subcategory === 'Other Rent Items')?.details;

  return !isEmpty(leasingCriteria) && (
    <Content>
      <h6 className="tx-16 text-dark"># Rental Criteria</h6>
      <p><strong>Rental History:</strong> 1 year of good rental history or proof of home ownership</p>
      <p><strong>Income:</strong> Household income of {leasingCriteria.rental_criteria?.income} times the rental amount</p>
      <p><strong>Credit/Background:</strong> Satisfactory credit status and pass criminal background screening</p>
      <p><strong>Applications:</strong> All applicants 18 years or older are required to complete an application</p>
      <p><strong>Identification:</strong> All applicants are require to show government issued ID</p>
      {insiderInfoRentalCriteria?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoRentalCriteria.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}

      <hr />

      <h6 className="tx-16 text-dark"># Deposit Options</h6>
      <p>
        <strong>Refundable:</strong> ${currencyFormat(leasingCriteria.deposit_actions?.refundable_approved_application)} deposit
        for approved or ${currencyFormat(leasingCriteria.deposit_actions?.refundable_conditionally_approved_application)} for conditionally approved + 3rd party
        renter’s insurance required
      </p>
      <p>
        <strong>Non-Refundable:</strong> ${currencyFormat(leasingCriteria.deposit_actions?.non_refundable_approved_application)} one-time fee for approved or
        ${currencyFormat(leasingCriteria.deposit_actions?.non_refundable_conditionally_approved_application)} for conditionally approved + must
        use ePremium as renter’s insurance provider
      </p>
      <p>
        <strong>Pet Deposit:</strong> An additional deposit is required for Pets. ${currencyFormat(leasingCriteria.deposit_actions?.refundable_pet_deposit)} for refundable option and
        ${currencyFormat(leasingCriteria.deposit_actions?.non_refundable_pet_deposit)} for Non-Refundable option.
      </p>
      {insiderInfoDepositOptions?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoDepositOptions.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}

      <hr />

      <h6 className="tx-16 text-dark"># Application Fees</h6>
      <p><strong>Applicants:</strong> ${currencyFormat(leasingCriteria.application_fees?.applicants_fee)} fee (per applicant)</p>
      <p><strong>Guarantors:</strong> ${currencyFormat(leasingCriteria.application_fees?.guarantors_fee)} fee (per guarantor)</p>
      <p><strong>Admin Fee:</strong> ${currencyFormat(leasingCriteria.application_fees?.admin_fee)} fee (one-time charge)</p>
      {insiderInfoApplicationFees?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoApplicationFees.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}
      <hr />

      <h6 className="tx-16 text-dark"># Pet Fees</h6>
      <p><strong>Pet Rent:</strong> ${currencyFormat(leasingCriteria.pet_fees?.rent)} (per pet, per month)</p>
      <p><strong>Pet Fee:</strong> ${currencyFormat(leasingCriteria.pet_fees?.fee)} fee (one-time charge)</p>
      {insiderInfoPetFees?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoPetFees.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}

      <hr />

      <h6 className="tx-16 text-dark"># Other Rent Items</h6>
      <p><strong>Valet Waste:</strong> ${currencyFormat(leasingCriteria.other_rent_items?.valet_waste)} (per month)</p>
      <p><strong>MT Plus:</strong> ${currencyFormat(leasingCriteria.other_rent_items?.mt_plus)} (per month)</p>
      {leasingCriteria.other_rent_items?.rentable_items.map(item => (
        <p><strong>{item.name}:</strong> ${currencyFormat(item.monthly_rent)} (per month)</p>
      ))}
      {insiderInfoOtherRentItems?.length > 0 && (
        <>
          <p><strong>Insider Info:</strong></p>
          {insiderInfoOtherRentItems.map(li => (
            <div>- {li}</div>
          ))}
        </>
      )}
    </Content>
  );
};

export default LeasingRentalCriteria;
