import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormFeedback, FormGroup, Input, Popover, PopoverBody, CustomInput } from 'reactstrap';
import Select from 'react-select';
import { Typeahead } from 'react-bootstrap-typeahead';
import { fieldChoices } from 'dwell/constants';
import { PageSection, TourTypes } from 'dwell/store/property_profile/action-types';
import actions from 'dwell/actions';
import { getLeadId } from 'dwell/views/lead/layout/utils';
import taskActions from 'dwell/store/task/action-creators';
import { AvailableTimesParams } from 'src/interfaces';
import { isEmpty } from 'lodash';
import { formatTimeByTimezone, convertToDateWithTimezone, replaceTimezone, dateFilter } from 'src/utils';
import { selectAreAvailableDateTimesLoaded } from 'dwell/store/task/reducers';
import { FormLabel, StyledDatePicker } from './styles';
import { Content } from '../styles';

const AddTour: FC = () => {
  const [fields, setFields] = useState({
    type: null,
    owner: null,
    lead: null,
    tour_date: null,
    units: [],
    description: null,
    tour_confirmation_reminder_enabled: false,
  } as TourTypes);
  const [errors, setErrors] = useState({ type: false, owner: false, lead: false, tour_date: false, units: false });
  const [isShowPopper, showPopper] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getAvailableTourTime } = actions.task;
  const { createTour, setSection } = actions.propertyProfile;
  const dispatch = useDispatch();
  const currentProperty = useSelector(state => state.propertyProfile.currentProperty);
  const plans = useSelector(state => state.propertyProfile.floorPlans);
  const units = useSelector(state => state.propertyProfile.units);
  const availableDateTimes = useSelector(state => state.task.availableDateTimes);
  const leads = useSelector(state => state.propertyProfile.leads);
  const { users } = useSelector(state => state.propertyProfile.propertyInfo);
  const areAvailableDateTimesLoaded = useSelector(selectAreAvailableDateTimesLoaded);

  const selfGuidedTourVendor = currentProperty?.self_guided_tour_vendor;

  const unitsChoices = useMemo(
    () => {
      let result = [];
      if (fields.type === 'SELF_GUIDED_TOUR') {
        result = units;
      } else {
        plans.forEach((plan) => {
          result = result.concat(plan.units);
        });
      }
      return result
        .filter((u) => {
          if (fields.type && fields.type === 'SELF_GUIDED_TOUR') {
            return u.can_be_toured;
          }
          return true;
        })
        .map(v => ({ value: v.id, label: v.unit }));
    },
    [plans, units, selfGuidedTourVendor, fields.type],
  );

  const disableSmartRent = useMemo(() => {
    const propertyDontHaveSelfGuidedVendor = !selfGuidedTourVendor;
    return propertyDontHaveSelfGuidedVendor || !unitsChoices.length;
  }, [selfGuidedTourVendor, unitsChoices]);

  const ownersChoices = useMemo(
    () => users?.map(o => ({ value: o.id, label: o.email })),
    [users],
  );

  const convertedTypeChoice = useMemo(
    () => currentProperty.tour_types.map(type => ({ value: type, label: fieldChoices.TASK_FILED_CHOICES.type[type] })),
    [currentProperty.tour_types],
  );

  const formattedDates = useMemo(() => {
    let tempDates = [];
    if (!fields.lead || !fields.owner) return [];
    if (availableDateTimes.length) {
      if (typeof availableDateTimes[0] !== 'string') {
        tempDates = availableDateTimes.map(i => i.times).reduce((a, b) => a.concat(b), []);
        if (fields.type === 'SELF_GUIDED_TOUR') tempDates = tempDates.filter(i => i.available);
        tempDates = tempDates.map(i => i.date_time);
        tempDates = tempDates.map(i => convertToDateWithTimezone(i));
        return tempDates;
      }
      return [];
    }
    return [];
  }, [availableDateTimes, fields.lead, fields.owner, fields.type]);

  const { dates, times } = useMemo(() => {
    let t: Array<Date> = [];
    if (!formattedDates.length) return { dates: [], times: t };
    t = formattedDates.filter((i) => {
      const correctTourDate = replaceTimezone(fields.tour_date);
      const correctItemDate = replaceTimezone(i);
      return dateFilter(correctTourDate, correctItemDate);
    });
    return { dates: formattedDates, times: t };
  }, [formattedDates, fields.tour_date]);

  const fetchAvailableTourTime = () => {
    const tourData = {
      type: fields.type,
      unit: fields.units,
      date: formatTimeByTimezone().format('YYYY-MM-DD'),
      property: currentProperty.id,
    } as AvailableTimesParams;
    dispatch(getAvailableTourTime(tourData));
  };

  useEffect(() => {
    if (!fields.lead || !fields.owner) return;
    if (fields.type && fields.type === 'SELF_GUIDED_TOUR' && !isEmpty(fields.units)) {
      fetchAvailableTourTime();
    } else if (fields.type && fields.type !== 'SELF_GUIDED_TOUR') {
      fetchAvailableTourTime();
    }
  }, [fields.units, fields.type, fields.lead, fields.owner]);

  useEffect(() => {
    if (fields.tour_date && !areAvailableDateTimesLoaded) {
      setFields({ ...fields, tour_date: null });
    }
  }, [fields.tour_date, areAvailableDateTimesLoaded]);

  useEffect(() => {
    if (!fields.tour_date || !times.length) return;
    const date = fields.tour_date instanceof Date ? fields.tour_date : new Date(fields.tour_date);
    if (!times.map(t => t.getTime()).includes(date.getTime())) {
      setFields({ ...fields, tour_date: times[0] });
    }
  }, [fields.tour_date, times]);

  const handleOnChange = (id, value) => {
    if (value instanceof Date) {
      const date = new Date(value.getTime());
      date.setMilliseconds(0);
      setFields({ ...fields, [id]: date });
    }
    setFields({ ...fields, [id]: value });
  };

  const validateDate = (value) => {
    if (!value) return false;
    const date = new Date(value.getTime());
    date.setMilliseconds(0);
    return times.length ? times.map(t => t.getTime()).includes(date.getTime()) : true;
  };

  const validate = () => (
    {
      type: !fields.type,
      owner: !fields.owner,
      tour_date: fields.type !== 'VIRTUAL_TOUR' ? !validateDate(fields.tour_date) : false,
      lead: !fields.lead,
      units: fields.type === 'SELF_GUIDED_TOUR' ? !Boolean(fields.units.length).valueOf() : false,
    }
  );

  useEffect(() => {
    if (Object.values(errors).filter(i => i).length) setErrors(validate());
  }, [fields.type, fields.owner, fields.lead, fields.tour_date, fields.units]);

  const handleSaveSuccess = () => {
    dispatch(setSection(PageSection.MAIN_MENU));
    setIsSubmitting(false);
    const leadId = getLeadId(window.location.pathname);
    if (leadId === fields.lead) {
      dispatch(taskActions.getObjectTasks(leadId, 'lead'));
    }
  };

  const handleSave = () => {
    const newErrors = validate();
    setErrors(newErrors);
    if (!Object.values(newErrors).filter(i => i).length) {
      setIsSubmitting(true);
      fields.tour_date = fields.type !== 'VIRTUAL_TOUR' ? replaceTimezone(fields.tour_date).format() : null;
      if (currentProperty && currentProperty.self_guided_tour_vendor && currentProperty.self_guided_tour_vendor === 'I_APTS' && fields.type === 'SELF_GUIDED_TOUR') {
        fields.status = 'PENDING';
      }
      dispatch(createTour(currentProperty.id, fields)).then(handleSaveSuccess);
    }
  };

  return (
    <Content>
      <FormGroup>
        <FormLabel>Tour Type</FormLabel>
        <Select
          defaultValue={convertedTypeChoice.find(i => i.value === fields.type)}
          options={convertedTypeChoice}
          classNamePrefix="select"
          placeholder="Select Tour Type"
          onChange={item => handleOnChange('type', item.value)}
          isOptionDisabled={({ value }) => (value === 'SELF_GUIDED_TOUR' && disableSmartRent)}
        />
        <Input invalid={errors.type} hidden />
        <FormFeedback>Please choose a tour type.</FormFeedback>
      </FormGroup>
      <FormGroup>
        <FormLabel>Linked To</FormLabel>
        <Typeahead
          id="lead"
          labelKey="name"
          options={leads}
          selected={(leads || []).filter(item => item.id === fields.lead)}
          onChange={e => handleOnChange('lead', e[0] ? e[0].id : null)}
          placeholder="Choose one"
        />
        <Input invalid={errors.lead} hidden />
        <FormFeedback>Please choose a lead to be linked.</FormFeedback>
      </FormGroup>
      <FormGroup>
        <FormLabel>Assigned To</FormLabel>
        <Select
          value={ownersChoices.find(i => i.value === fields.owner)}
          options={ownersChoices}
          classNamePrefix="select"
          placeholder="Select owner"
          onChange={item => handleOnChange('owner', item.value)}
        />
        <Input invalid={errors.owner} hidden />
        <FormFeedback>Owner is required.</FormFeedback>
      </FormGroup>
      <FormGroup>
        <FormLabel>Showing Unit(s)</FormLabel>
        <Select
          defaultValue={isEmpty(fields.units) ? null : unitsChoices.filter(option => fields.units.includes(option.value))}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          options={unitsChoices}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Select Unit(s)"
          onChange={selectedItems => handleOnChange('units', selectedItems.map(item => item.value))}
        />
        <Input invalid={errors.units} hidden />
        <FormFeedback>Please select at least one unit.</FormFeedback>
      </FormGroup>
      {fields.type !== 'VIRTUAL_TOUR' && (
        <FormGroup>
          <FormLabel>Tour Date</FormLabel>
          <StyledDatePicker
            showTimeSelect
            selected={fields.tour_date ? new Date(fields.tour_date) : null}
            onChange={date => handleOnChange('tour_date', date)}
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText={areAvailableDateTimesLoaded ? 'Select a date' : 'Loading...'}
            includeDates={dates}
            includeTimes={times}
            disabledKeyboardNavigation
            disabled={!areAvailableDateTimesLoaded}
          />
          <Input invalid={errors.tour_date} hidden />
          <FormFeedback>Please choose tour date.</FormFeedback>
        </FormGroup>)}
      <FormGroup>
        <FormLabel>Description</FormLabel>
        <textarea
          id="description"
          className="form-control"
          placeholder="Add Tour Details"
          spellCheck="false"
          onChange={({ target: { id, value } }) => handleOnChange(id, value)}
        />
      </FormGroup>
      <FormGroup className="d-flex align-items-center">
        <CustomInput
          type="checkbox"
          id="tour-confirmation-followup"
          label="Send tour confirmation followup"
          onChange={({ target: { checked } }) => handleOnChange('tour_confirmation_reminder_enabled', checked)}
          checked={fields.tour_confirmation_reminder_enabled}
        />
        <div
          id="profile-tour-popover"
          className="d-flex tx-16 lh-1 mg-l-5 op-6"
          onMouseEnter={() => showPopper(true)}
          onMouseLeave={() => showPopper(false)}
        >
          <i className="ri-information-line" />
        </div>
        <Popover className="reminder-checkbox-popover-info" placement="right" isOpen={isShowPopper} target="profile-tour-popover" toggle={() => showPopper(!isShowPopper)}>
          <PopoverBody>Union automatically sends a followup email to confirm tour 24 hours prior to scheduled tour time.</PopoverBody>
        </Popover>
      </FormGroup>
      <button className="btn btn-primary btn-block" onClick={handleSave} disabled={isSubmitting}>Add Tour</button>
    </Content>
  );
};

export default AddTour;
