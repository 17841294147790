interface EmailVariables {
  VARIABLES: {
    lead_full_name: {
      name: string,
      hide?: boolean,
    }
  }
}

const variables = {
  VARIABLES: {
    applicant_name: { name: 'Applicant name' },
    applicant_first_name: { name: 'Applicant first name' },
    co_applicant_name: { name: 'Co-applicant name' },
    co_applicant_first_name: { name: 'Co-applicant first name' },
    guarantor_name: { name: 'Guarantor name' },
    guarantor_first_name: { name: 'Guarantor first name' },
    lease_agreement_name: { name: 'Lease agreement name' },
    lease_agreement_sign_hyperlink: { name: 'Lease agreement sign hyperlink' },
    lease_agreement_sign_link: { name: 'Lease agreement sign link' },
    lease_owner: { name: 'Lease owner name' },
    lease_end_date: { name: 'Lease end date' },
    lease_base_rent: { name: 'Base rent' },
    lease_required_notice_to_vacate_days: { name: 'Required notice to vacate days' },
    lease_month_to_month_fee: { name: 'Month-to-month fee' },
    lease_remove_people_names: { name: 'Lease remove people names' },
    lease_new_people_names: { name: 'Lease new people names' },
    lease_pay_change_fee_link: { name: 'Lease pay change fee sign link' },
    lease_pay_change_fee_hyperlink: { name: 'Lease pay change fee hyperlink' },
    resident_first_name: { name: 'Resident first name' },
    lead_full_name: { name: 'Lead full name' },
    lead_first_name: { name: 'Lead first name' },
    lead_owner: { name: 'Lead owner' },
    lead_owner_first_name: { name: 'Lead owner first name' },
    property_address: { name: 'Property address' },
    property_name: { name: 'Property name' },
    property_email: { name: 'Property email' },
    property_phone_number: { name: 'Property phone number' },
    property_website: { name: 'Property website' },
    property_website_link: { name: 'Property website link' },
    property_description: { name: 'Property description' },
    tour_time: { name: 'Tour time' },
    tour_type: { name: 'Tour type' },
    virtual_tour_link: { name: 'Virtual tour link' },
    tour_link: { name: 'Tour link' },
    unit_number: { name: 'Unit number' },
    unit_type: { name: 'Unit type' },
    unit_type_lowest_price: { name: 'Unit type lowest price' },
    unit_type_highest_price: { name: 'Unit type highest price' },
    floor_plan: { name: 'Floor plan' },
    floor_plan_lowest_price: { name: 'Floor plan lowest price' },
    floor_plan_highest_price: { name: 'Floor plan highest price' },
    unit_number_price: { name: 'Unit number price' },
    property_business_hours: { name: 'Property business hours' },
  },
} as EmailVariables;

export default variables;
