import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  PING_USER_ACTIVITY_REQUEST = 'PING_USER_ACTIVITY_REQUEST',
  PING_USER_ACTIVITY_SUCCESS = 'PING_USER_ACTIVITY_SUCCESS',
  PING_USER_ACTIVITY_FAILURE = 'PING_USER_ACTIVITY_FAILURE',
}

export enum UserActivityType {
  ACTIVE = 'active',
  IDLE = 'idle',
  BREAK_TIME = 'break',
}

export interface PingUserActivityAction {
  type: ActionType.PING_USER_ACTIVITY_REQUEST
  | ActionType.PING_USER_ACTIVITY_SUCCESS
  | ActionType.PING_USER_ACTIVITY_FAILURE,
}

export type Action =
  PromiseActionType
  | PingUserActivityAction
