import { ApplicantProps } from 'leasing/store/types';

export const getStatusClassName = (status: string) : string => status.toLocaleString().replace('_', '-');

export const getApplicantName = (applicant: ApplicantProps, applicants: ApplicantProps[], lead = {} as { first_name: string, last_name: string }) : string => {
  const unnamedUsers = applicants.filter(item => !item.first_name && !item.last_name && !item.is_primary).map(item => item.id);
  if (!applicant.first_name && !applicant.last_name) {
    if (applicant.is_primary) {
      return `${lead.first_name} ${lead.last_name}`;
    }
    const index = unnamedUsers.findIndex(id => id === applicant.id);
    if (index === -1) {
      return `New User ${!unnamedUsers.length ? '' : unnamedUsers.length}`;
    }
    return `New User ${index === 0 ? '' : index + 1}`;
  }
  return `${applicant.first_name} ${applicant.last_name}`;
};

export const formatLeaseTerm = (value: number) : string => {
  if (!value) return '';
  if (value === 1) {
    return `${value} month`;
  }
  return `${value} months`;
};

export const formatPriceValue = (value: number): string => (`$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);

export const getLeaseTermChoices = (active_lease_terms: number[]): { [k: string]: string } =>
  (active_lease_terms?.length ? active_lease_terms.reduce((json, value) => ({ ...json, [value]: `${value} months` }), {}) : []);
