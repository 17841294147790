import { FormSearch, SearchIcon } from 'site/components/common';
import { DropdownToggle, DropdownMenu } from 'reactstrap';
import styled from 'styled-components';

export const CustomSearchIcon = styled(SearchIcon)`
    position: initial;
`;

export const LetterLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 600;
  font-size: 12px;
  color: #1e293b;

  &:not(:first-child) {
    margin-top: 10px;
  }

  &::after {
    content: "";
    display: block;
    flex: 1;
    border-botton: 1px solid ${props => props.theme.colors.colorbg02};
    margin-left: 5px;
  }
`;

export const CustomFormSearch = styled(FormSearch)`
  &:focus {
    border: none;
    background-color: transparent;
    box-shadow: none;
    outline: none;
  }
`;

export const FormSearchDiv = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  background-color: ${props => props.theme.colors.colorbg01};
  border: 1px solid rgba(0,0,0,0);
  border-radius: 6px;
  padding: 0 8px;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
  &:focus, &.focus {
    background-color: ${props => props.theme.colors.white};
    border-color: #d5dcf4;
    border-color: ${props => props.theme.input.borderColor};
  }
`;

export const DropdownLink = styled(DropdownToggle)`
  padding-left: 12px;
  padding-right: 28px;
  padding-bottom: 2px;
  align-items: center;
  display: flex;
  position: relative;
  padding-top: 0;

  &:focus {
    box-shadow: none;
  }

  &:after {
      content: '\\EBA8' ;
      font-family: 'remixicon';
      font-size: 11px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: rotate(90deg);
      line-height: 0;
      opacity: .5;
      border-top: 0;
  }
`;

export const CustomDropdownMenu = styled(DropdownMenu)`
  height: 215px;
  min-width: 340px;
`;
