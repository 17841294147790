import React, { FC, useEffect, useState } from 'react';
import 'react-dates/initialize';
import { Button, Col, Form, ModalBody, ModalHeader, Row, Input, Label, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye';
import { Mention, MentionsInput } from 'react-mentions';
import 'src/scss/pages/_placeholders.scss';
import { chatVariables, smsVariables, emailVariables } from 'dwell/constants';
import CKEditor from 'ckeditor4-react';
import { ChatTemplateProps } from 'dwell/store/chat_template/action-types';
import { SMSTemplateProps } from 'dwell/store/sms_template/action-types';
import { DetailResponse } from 'src/interfaces';
import { EmailTemplateProps } from 'dwell/store/email_template/action-types';
import actions from 'dwell/actions';
import { connect } from 'react-redux';
import { EmailTemplateModal, CustomTextEditor, EmailInputFormGroup, EmailTemplateModalFooter, ErrorsContainer } from 'dwell/components/settings/templates/styles';
import { SettingsPrimaryButton } from 'dwell/views/settings/styles';
import { isEmpty } from 'lodash';
import { toast, ToastOptions } from 'react-toastify';
import { toastOptions } from 'site/constants';
import { formatNewPlaceholderVariables } from 'src/dwell/components/email/composer/_utils';
import { getPreviewWithDummies } from './_utils';

interface CommonTemplateModalWindowProps {
  handleClose: () => void;
  show: boolean;
  templateType: 'Email' | 'Chat' | 'SMS';
  createEmailTemplate: (data: EmailTemplateProps, msg: () => void) => Promise<DetailResponse>;
  updateEmailTemplateById: (templateId: number, data: EmailTemplateProps, msg: () => void) => Promise<DetailResponse>;
  templateId: number;
  emailTemplate: EmailTemplateProps;
  createChatTemplate: (data: ChatTemplateProps, msg: () => void) => Promise<DetailResponse>;
  updateChatTemplateById: (templateId: number, data: ChatTemplateProps, msg: () => void) => Promise<DetailResponse>;
  createSMSTemplate: (data: SMSTemplateProps, msg: () => void) => Promise<DetailResponse>;
  updateSMSTemplateById: (templateId: number, data: SMSTemplateProps, msg: () => void) => Promise<DetailResponse>;
  propertyId: number;
  templates: string[];
}

const placeholdersByType = {
  SMS: smsVariables,
  Email: emailVariables,
  Chat: chatVariables,
};

const CommonTemplateModalWindow: FC<CommonTemplateModalWindowProps> = ({
  emailTemplate,
  templateType,
  handleClose,
  show,
  createEmailTemplate,
  templateId,
  updateEmailTemplateById,
  createChatTemplate,
  updateChatTemplateById,
  createSMSTemplate,
  updateSMSTemplateById,
  propertyId,
  templates,
}) => {
  const closeBtn = <button className="close" onClick={() => handleClose()} />;

  CKEditor.editorUrl = `${window.location.origin}/static/ckeditor/ckeditor.js`;

  const [text, setText] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [subjectVariables, setSubjectVariables] = useState<string[]>([]);
  const [isError, setIsError] = useState(false);
  const [isDuplicated, setIsDuplicated] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    if (!isEmpty(emailTemplate)) {
      setText(emailTemplate.text);
      setName(emailTemplate.name);
      if (templateType === 'Email') {
        setSubject(emailTemplate.subject);
      }
    }
  }, [emailTemplate]);

  useEffect(() => {
    if (window.CKEDITOR) {
      Object.keys(window.CKEDITOR.instances).forEach(instanceName => window.CKEDITOR.instances[instanceName].setReadOnly(isPreview));
    }
  }, [isPreview]);

  const onEditorChange = (evt) => {
    if (!isPreview) {
      let changedBody: string = evt.editor.getData();
      // when the user paste from somewhere the Template we need to format the placeholders if it is not an SMS template
      // SMS templates recognize the placeholders with format [=Placeholder=] but it changes to
      // <span class="email-placeholder">[=Placeholder=]</span> when we talk about other template types
      if (templateType !== 'SMS') {
        changedBody = formatNewPlaceholderVariables(changedBody);
      }
      setText(changedBody);
    }
  };

  const onNameChange = ({ target: { value } }) => {
    setName(value);
    setIsError(false);
  };

  const templateVariables = placeholdersByType[templateType];
  const availablePlaceholders = templateVariables.VARIABLES;

  const parseVariables = (data) => {
    const emailVars = data.match(/\[=(.*?)=\]/g);
    let variableTitles = [];
    if (emailVars) {
      // eslint-disable-next-line no-useless-escape
      variableTitles = emailVars.map(v => v.replace(/[\[=\]]/g, ''));
    }
    return Object.keys(templateVariables.VARIABLES).filter(key => variableTitles.includes(templateVariables.VARIABLES[key].name));
  };

  const handleCreate = () => {
    const variables = parseVariables(text);
    switch (templateType) {
      case 'Email':
        createEmailTemplate({ text, subject, name, variables, subject_variables: subjectVariables }, () => toast.success('Email template created', toastOptions as ToastOptions)).then(() => {
          handleClose();
        });
        break;
      case 'SMS':
        createSMSTemplate({ text, name, variables }, () => toast.success('SMS template created', toastOptions as ToastOptions)).then(() => {
          handleClose();
        });
        break;
      case 'Chat':
        createChatTemplate({ text, name, variables, property: propertyId }, () => toast.success('Chat template created', toastOptions as ToastOptions)).then(() => {
          handleClose();
        });
        break;
    }
  };

  const handleUpdate = () => {
    const variables = parseVariables(text);
    switch (templateType) {
      case 'Email':
        updateEmailTemplateById(templateId, { text, subject, name, variables, subject_variables: subjectVariables }, () => toast.success('Email template updated', toastOptions as ToastOptions)).then(() => {
          handleClose();
        });
        break;
      case 'SMS':
        updateSMSTemplateById(templateId, { text, name, variables }, () => toast.success('SMS template updated', toastOptions as ToastOptions)).then(() => {
          handleClose();
        });
        break;
      case 'Chat':
        updateChatTemplateById(templateId, { text, name, variables }, () => toast.success('Chat template updated', toastOptions as ToastOptions)).then(() => {
          handleClose();
        });
        break;
    }
  };

  const handleSave = () => {
    if (name.trim()) {
      if (emailTemplate.id) {
        handleUpdate();
      } else if (!templates.includes(name.trim().toLowerCase())) {
        handleCreate();
      } else {
        setIsDuplicated(true);
        setIsError(true);
      }
    } else {
      setIsError(true);
    }
  };

  const handleCKEediorConfig = () => {
    let SettingsConfig = 'Cut,Copy,Paste,Undo,Redo,Anchor,Underline,Strike,Subscript,Superscript,CreatePlaceholder';
    if (['Chat', 'SMS'].includes(templateType)) {
      SettingsConfig += ',Bold,Italic,Link,Unlink,Indent,Outdent,BulletedList,NumberedList,Scayt';
    }
    return SettingsConfig;
  };
  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setSubject(newValue);
    setSubjectVariables(Object.keys(availablePlaceholders).filter(key => mentions.map(item => item.id).includes(availablePlaceholders[key].name)));
  };

  const dataSubjectCallback = (matchInfo, callback) => {
    const data = Object.keys(availablePlaceholders).map((key, i) => ({ id: i, display: `${availablePlaceholders[key].name}` }));
    callback(data);
  };

  const dataCallback = (matchInfo, callback) => {
    const data = Object.keys(availablePlaceholders).map((key, i) => ({ id: i, title: availablePlaceholders[key].name }));
    callback(data);
  };

  const disableAutoInline = () => {
    const { CKEDITOR }: CKEditor = window;
    CKEDITOR.disableAutoInline = true;
  };

  let parsedBody = text;
  let parsedSubject = subject;

  if (isPreview) {
    const isSMS = templateType === 'SMS';
    parsedBody = getPreviewWithDummies(text, parseVariables(text), templateVariables.VARIABLES, true, isSMS);
    parsedSubject = getPreviewWithDummies(subject, parseVariables(subject), templateVariables.VARIABLES, false, isSMS);
  }

  return (
    <EmailTemplateModal isOpen={show} toggle={() => handleClose()} centered>
      <ModalHeader close={closeBtn}> {emailTemplate.id ? `Edit ${templateType} Template` : `Add ${templateType} Template`}</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs="12">
            <Form>
              <EmailInputFormGroup className="p-0" isError={isError}>
                <Input className={`template-name h-41 ${emailTemplate.id ? 'font-weight-bold' : ''}`} style={{ padding: '0 15px', paddingBottom: '2px' }} placeholder="Template title" value={name} onChange={e => onNameChange(e)} />
              </EmailInputFormGroup>
              {isDuplicated && <ErrorsContainer>The template name already exists</ErrorsContainer>}
              {templateType === 'Email' && (
                <EmailInputFormGroup style={{ paddingTop: 0, paddingBottom: '6px' }}>
                  {emailTemplate.id && <Label style={{ margin: '5px 0 0 15px' }}>Subject:</Label>}
                  <MentionsInput singleLine value={parsedSubject} onChange={handleChange} placeholder="Subject" className="subject">
                    <Mention
                      appendSpaceOnAdd
                      trigger="["
                      markup="[=__display__=]"
                      displayTransform={(id, display) => `[=${display}=]`}
                      data={dataSubjectCallback}
                      className="subject-variable"
                      renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => <div className={`${focused ? 'focused' : ''}`}>{highlightedDisplay}</div>}
                    />
                  </MentionsInput>
                </EmailInputFormGroup>
              )}
              <EmailInputFormGroup>
                <CustomTextEditor>
                  <div className="templates-editor">
                    <CKEditor
                      id="editor-default"
                      editorName="editor"
                      onBeforeLoad={disableAutoInline}
                      className="editor"
                      data={parsedBody}
                      onChange={onEditorChange}
                      config={{
                        removeButtons: handleCKEediorConfig(),
                        height: 300,
                        extraAllowedContent: 'span(*)',
                        scayt_autoStartup: true,
                        placeholder_select: {
                          placeholders: Object.values(availablePlaceholders).map(item => item.name),
                          // without '&nbsp;' we cant continue editing a template after a placeholder inserted
                          format: templateType !== 'SMS' ? '<span><span class="email-placeholder">[=%placeholder%=]</span>&nbsp;</span>' : '[=%placeholder%=]&nbsp;',
                        },
                        mentions: [
                          {
                            feed: (matchInfo, callback) => dataCallback(matchInfo, callback),
                            minChars: 0,
                            marker: '[',
                            itemTemplate: '<li data-id="{id}"><div class="item-title">{title}</div></li>',
                            outputTemplate: templateType !== 'SMS' ? '<span><span class="email-placeholder">[={title}=]</span>&nbsp;</span>' : '[={title}=]&nbsp;',
                          },
                        ],
                      }}
                    />
                  </div>
                </CustomTextEditor>
              </EmailInputFormGroup>
            </Form>
          </Col>
        </Row>
      </ModalBody>
      <EmailTemplateModalFooter>
        <div>
          <Button onClick={() => setIsPreview(!isPreview)}>{isPreview ? 'Back to edit' : 'Preview'}</Button>
        </div>
        <div className="d-flex ml-auto">
          <Button className="btn-secondary mr-2" onClick={() => handleClose()}>
            Cancel
          </Button>
          <SettingsPrimaryButton disabled={!(text.length && name.length) || isPreview} className="btn btn-primary m-0" onClick={() => handleSave()}>
            {emailTemplate.id ? 'Save changes' : 'Add template'}{' '}
          </SettingsPrimaryButton>
        </div>
      </EmailTemplateModalFooter>
      {isPreview && <Alert style={{ marginBottom: 0 }} className="mt-2" color="info"><FontAwesomeIcon className="mr-1" icon={faEye} />You&apos;re previewing this template. This is what your recipient will see when they read your message.</Alert>}
    </EmailTemplateModal>
  );
};

export default connect(null, {
  ...actions.emailTemplate,
  ...actions.chatTemplate,
  ...actions.smsTemplate,
})(CommonTemplateModalWindow);
