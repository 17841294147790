import React, { FC, useState } from 'react';
import { SelectButton, SelectToggle, SelectMenu, SelectItem } from 'styles/common';

interface DropdownProps {
  id: string,
  value: string | number,
  onChange: (id: string, value: string) => void,
  name?: string,
  className?: string,
  choices?: unknown,
  needFocus?: boolean,
  invalid?: boolean,
}

const Dropdown: FC<DropdownProps> = ({ id, value, name, className, choices, onChange, invalid }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SelectButton isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} invalid={invalid} className={className}>
      <SelectToggle caret name={name}>
        {choices[value]}
      </SelectToggle>
      <SelectMenu>
        {Object.keys(choices).map((field, key) => (
          <SelectItem key={key} onClick={() => onChange(id, field)}>
            {choices[field]}
          </SelectItem>
        ))}
      </SelectMenu>
    </SelectButton>
  );
};

export default Dropdown;
