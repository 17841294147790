import * as moment from 'moment';
import { PromiseActionType } from 'dwell/store/types';

export enum ActionType {
  GET_CORPORATE_PPC_REPORTS_REQUEST = 'GET_CORPORATE_PPC_REPORTS_REQUEST',
  GET_CORPORATE_PPC_REPORTS_SUCCESS = 'GET_CORPORATE_PPC_REPORTS_SUCCESS',
  GET_CORPORATE_PPC_REPORTS_FAILURE = 'GET_CORPORATE_PPC_REPORTS_FAILURE',

  GET_STANDALONE_PPC_REPORTS_REQUEST = 'GET_STANDALONE_PPC_REPORTS_REQUEST',
  GET_STANDALONE_PPC_REPORTS_SUCCESS = 'GET_STANDALONE_PPC_REPORTS_SUCCESS',
  GET_STANDALONE_PPC_REPORTS_FAILURE = 'GET_STANDALONE_PPC_REPORTS_FAILURE',

  GET_OVERVIEW_REPORTS_REQUEST = 'GET_OVERVIEW_REPORTS_REQUEST',
  GET_OVERVIEW_REPORTS_SUCCESS = 'GET_OVERVIEW_REPORTS_SUCCESS',
  GET_OVERVIEW_REPORTS_FAILURE = 'GET_OVERVIEW_REPORTS_FAILURE',

  GET_MARKETING_REPORTS_REQUEST = 'GET_MARKETING_REPORTS_REQUEST',
  GET_MARKETING_REPORTS_SUCCESS = 'GET_MARKETING_REPORTS_SUCCESS',
  GET_MARKETING_REPORTS_FAILURE = 'GET_MARKETING_REPORTS_FAILURE',

  GET_AGENT_AND_OFFICE_REPORTS_REQUEST = 'GET_AGENT_AND_OFFICE_REPORTS_REQUEST',
  GET_AGENT_AND_OFFICE_REPORTS_SUCCESS = 'GET_AGENT_AND_OFFICE_REPORTS_SUCCESS',
  GET_AGENT_AND_OFFICE_REPORTS_FAILURE = 'GET_AGENT_AND_OFFICE_REPORTS_FAILURE',

  GET_CREDIT_BUILDER_REPORTS_REQUEST = 'GET_CREDIT_BUILDER_REPORTS_REQUEST',
  GET_CREDIT_BUILDER_REPORTS_SUCCESS = 'GET_CREDIT_BUILDER_REPORTS_SUCCESS',
  GET_CREDIT_BUILDER_REPORTS_FAILURE = 'GET_CREDIT_BUILDER_REPORTS_FAILURE',

  GET_WORKFORCE_REPORTS_REQUEST = 'GET_WORKFORCE_REPORTS_REQUEST',
  GET_WORKFORCE_REPORTS_SUCCESS = 'GET_WORKFORCE_REPORTS_SUCCESS',
  GET_WORKFORCE_REPORTS_FAILURE = 'GET_WORKFORCE_REPORTS_FAILURE',

  REQUIRE_RESCORE_CALL_REQUEST = 'REQUIRE_RESCORE_CALL_REQUEST',
  REQUIRE_RESCORE_CALL_SUCCESS = 'REQUIRE_RESCORE_CALL_SUCCESS',
  REQUIRE_RESCORE_CALL_FAILURE = 'REQUIRE_RESCORE_CALL_FAILURE',

  GET_PROPERTIES_AVAILABLE_TO_RESCORE_REQUEST = 'GET_PROPERTIES_AVAILABLE_TO_RESCORE_REQUEST',
  GET_PROPERTIES_AVAILABLE_TO_RESCORE_SUCCESS = 'GET_PROPERTIES_AVAILABLE_TO_RESCORE_SUCCESS',
  GET_PROPERTIES_AVAILABLE_TO_RESCORE_FAILURE = 'GET_PROPERTIES_AVAILABLE_TO_RESCORE_FAILURE',

  REFRESH_REPORT_REQUEST = 'REFRESH_REPORT_REQUEST',
  REFRESH_REPORT_SUCCESS = 'REFRESH_REPORT_SUCCESS',
  REFRESH_REPORT_FAILURE = 'REFRESH_REPORT_FAILURE',

  GET_RAW_DATA_REPORTS_REQUEST = 'GET_RAW_DATA_REPORTS_REQUEST',
  GET_RAW_DATA_REPORTS_SUCCESS = 'GET_RAW_DATA_REPORTS_SUCCESS',
  GET_RAW_DATA_REPORTS_FAILURE = 'GET_RAW_DATA_REPORTS_FAILURE',

  DOWNLOAD_REPORT_REQUEST = 'REFRESH_REPORT_REQUEST',
  DOWNLOAD_REPORT_SUCCESS = 'REFRESH_REPORT_SUCCESS',
  DOWNLOAD_REPORT_FAILURE = 'REFRESH_REPORT_FAILURE',

  CLEAR_REPORTS = 'CLEAR_REPORTS',
  SET_DATE_PERIOD = 'SET_DATE_PERIOD',
  SET_ATTRIBUTION = 'SET_ATTRIBUTION',
  SET_COMPARE_VALUE = 'SET_COMPARE_VALUE',
  SET_QUERY_PROPERTIES = 'SET_QUERY_PROPERTIES',
  SET_PORTFOLIO = 'SET_PORTFOLIO',
  SET_REPORT_TYPE = 'SET_REPORT_TYPE',
  SET_CUSTOM_DATE = 'SET_CUSTOM_DATE',
  SET_SHOW_PAID_ONLY = 'SET_SHOW_PAID_ONLY',
  SET_PROPERTY_NOT_AVAILABLE_TO_RESCORE = 'SET_PROPERTY_NOT_AVAILABLE_TO_RESCORE',
  SET_RELOAD_STATE = 'SET_RELOAD_STATE',
  SET_PROPERTIES_LABEL = 'SET_PROPERTIES_LABEL',
  SET_AGENT_FILTER = 'SET_AGENT_FILTER',
}

export interface PPCChannel {
  campaign_id: number,
  campaign_name: string,
  channel_type: string,
  clicks: number,
  conv_rate: number,
  conversions: number,
  cost: number,
  cost_micros: number,
  cpa: number,
  cpc: number,
  ctr: number,
  impressions: number,
}

export interface PPCGraphData {
  cpa: number,
  cpc: number,
  period: string,
  total_clicks: number,
  total_conversions: number,
  total_cost: number,
  total_impressions: number,
}

export interface PPCKeyword {
  clicks: number,
  impressions: number,
  match_type: string,
  text: string,
}

export interface PPCMetrics {
  clicks: number,
  conv_rate: number,
  conversions: number,
  cost: number,
  cpa: number,
  cpc: number,
  ctr: number,
  impressions: number,
}

export interface PPCReport {
  id?: number,
  generated_date?: string,
  start_date?: string,
  end_date?: string,
  status?: string,
  channels: PPCChannel[],
  graph_data: PPCGraphData[],
  keywords: PPCKeyword[],
  metrics: PPCMetrics,
  previous_metrics: PPCMetrics,
}

export interface ToursReport {
  total_tours: number,
  tours_data: {
    [tour_type: string]: { name: string, value: number, percent: number },
  },
  total_leases: number,
  leases_data: {
    [tour_type: string]: { name: string, value: number, percent: number },
  },
}

export interface LeadToLeaseReport {
  leads: number, leases: number, tours: number, leased_rate: number, lead_to_tour: number, tour_to_lease: number,
  property_id?: number, property?: number
}

export interface ActivityReport {
  activities: number, emails: number, tasks: number, notes: number, calls: number, agent_chats: number,
}

interface ScoredCall {
  id: number;
  lead_id: string,
  property: number,
  property_name: string,
  lead_name: string,
  source: string,
  prospect_phone_number: string,
  agent_name: string,
  duration: number,
  date: string,
  score: number,
  yes_questions: string[],
  omitted_questions: string[],
  external_id: string,
  answered_by_hobbes: boolean,
}

interface ScoringQuestion {
  id: string,
  question: string,
  weight: number,
}

export interface CallsReport {
  prospect_calls: number,
  call_answered: { percents: number, calls: number },
  call_missed: { percents: number, calls: number },
  call_busy: { percents: number, calls: number },
  call_failed: { percents: number, calls: number },
  average_call_time: number,
  average_call_score: number,
  introduction: number,
  qualifying: number,
  amenities: number,
  closing: number,
  overall: number,
  scored_calls: ScoredCall[],
  scoring_questions: ScoringQuestion[],
  agents: { agent: string, properties: string[], score: number }[],
}

export interface EngagementReport {
  average_response_time_business: number,
  average_response_time_non_business: number,
  average_sign_lease_time: number,
  average_followups_number: number,
  followups_2_hours: number[] | number, // It can be used as column too
  followups_24_hours: number[] | number,
  followups_48_hours: number[] | number,
  followups_more_48_hours: number[] | number,
  no_followups: number[] | number,
  average_response_time: React.ReactText,
}

export interface OverviewReport {
  id: number,
  status: string,
  pending: boolean,
  start_date: string,
  end_date: string,
  generated_date: string,

  overall: {
    lead_to_lease: LeadToLeaseReport,
    activity: ActivityReport,
    calls: CallsReport,
    engagement: EngagementReport,
    tours: ToursReport,
  };
  grouped: {
    lead_to_lease: { [property_id: number]: LeadToLeaseReport },
    activity: { [property_id: number]: ActivityReport },
    calls: { [property_id: number]: { prospect_calls: number } },
    engagement: { [property_id: number]: EngagementReport },
    tours: { [property_id: number]: ToursReport },
  };
  audition: {
    'leads': {
      first_name: string, last_name: string, email: string, source__name: string, acquisition_date: string,
      last_followup_date: string
    }[],
    'tours': {
      first_name: string, last_name: string, email: string, source__name: string, tour_completed_date: string,
      tour_type: string, floor_plan_type: string
    }[],
    'leases': {
      first_name: string, last_name: string, email: string, source__name: string, acquisition_date: string,
      closed_status_date: string,
    }[],
    'responses': {
      acquisition_date: string, last_followup_date: string, response_time_business: number, first_followup_date: string,
      response_time_overall: number
    }[],
  };
  chart_values: {
    leads: { value: number, label: string }[],
    tours: { value: number, label: string }[],
    leases: { value: number, label: string }[],
    prospect_calls: { value: number, label: string }[],
    average_call_time: { value: number, label: string }[],
    average_call_score: { value: number, label: string }[],
    average_response_time_business: { value: number, label: string }[],
    average_response_time_non_business: { value: number, label: string }[],
    average_sign_lease_time: { value: number, label: string }[],
    average_followups_number: { value: number, label: string }[],
  };
  compare_values: {
    lead_to_lease: {
      leads: number | string, leases: number | string, tours: number | string, leased_rate: number | string,
      lead_to_tour: number | string, tour_to_lease: number | string,
    },
    activity: {
      calls: number | string, agent_chats: number | string, emails: number | string, tasks: number | string,
      notes: number | string, activities: number | string
    },
    calls: {
      prospect_calls: number | string, average_call_time: string | number, amenities: string | number,
      average_call_score: string | number, closing: string | number, introduction: string | number,
      overall: string | number, qualifying: string | number,
    },
    engagement: {
      average_response_time_business: number | string, average_response_time_non_business: number | string,
      average_sign_lease_time: number | string, average_followups_number: number | string, followups_2_hours: number[],
      followups_24_hours: number[], followups_48_hours: number[], followups_more_48_hours: number[], no_followups: number[],
    },
    tours: {
      total_tours: number | string, total_leases: number | string
    }
  };
  source_calls: { source: string, calls: number, percents: number }[],
}

export interface LeadSourceReport {
  index: number,
  id: number,
  leads: number,
  leases: number,
  tours: number,
  calls: number,
  spends: { date: string, price: number}[],
  property?: string,
  property_id: number,
  leased_rate: number,
  tour_completed_rate: number,
  source: string,

  // custom columns in front-end
  rowspan?: number,
  type?: string,
  spend?: number,
  cost_per_tour?: number,
  cost_per_lease?: number,
  cost_per_lead?: number,
}

export interface LeadLostReport {
  index?: number,
  property?: number,
  lost_leads: number,
  [lost_reason: string]: { name: string, value: number } | number,
}

export interface MarketingReport {
  id: number,
  status: string,
  pending: boolean,
  start_date: string,
  end_date: string,
  generated_date: string,

  overall: {
    lead_source: LeadSourceReport[],
    lead_lost: LeadLostReport,
  },
  grouped: {
    lead_source: LeadSourceReport[],
    lead_lost: LeadLostReport[],
  },
  compare_values: {
    lead_lost: number | string,
    lead_source: {
      [property_id: string]: {
        leads: number[],
        leases: number[],
        tours: number[],
        calls: number[]
      }
    }
  }
}

export interface OfficeMeta {
  pods: { name: string, properties: string[] }[],
  properties: string[]
}

export interface AgentReport {
  id: number | string,
  name: string,
  office: string,
  office_meta: OfficeMeta,
  lead_to_tour_conversion_rate: number,
  lead_to_lease_conversion_rate: number,
  worked_leads: number,
  scheduled_tours: number,
  completed_tours: number,
  leases_signed: number,
  total_inbound_calls: number,
  answered_inbound_calls: number,
  missed_inbound_calls: number,
  declined_inbound_calls: number,
  abandoned_inbound_calls: number,
  prompt_1_inbound_calls: number,
  prompt_3_inbound_calls: number,
  prompt_1_resident_calls: number,
  prompt_3_resident_calls: number,
  prompt_1_prospect_calls: number,
  prompt_3_prospect_calls: number,
  total_inbound_calls_answered: number,
  inbound_resident_calls_answered: number,
  inbound_prospect_calls_answered: number,
  overall_call_answer_rate: number,
  resident_call_answer_rate: number,
  prospect_call_answer_rate: number,
  call_transferred_to_onsite: number,
  call_transferred_to_onsite_rate: number,
  inbound_call_score: number,
  outbound_call_score: number,
  total_outbound_call_made: number,
  resident_outbound_call_made: number,
  prospect_outbound_call_made: number,
  emails_sent: number,
  texts_sent: number,
}

export interface OfficeReport {
  id: number | string,
  office: string,
  office_meta: OfficeMeta,
  all_leads: number,
  lead_to_tour_conversion_rate: number,
  lead_to_lease_conversion_rate: number,
  worked_leads: number,
  unique_worked_leads: number,
  leases_from_ion_always: number,
  tours_from_ion_sometime: number,
  leases_from_ion_sometime: number,
  scheduled_tours: number,
  completed_tours: number,
  leases_signed: number,
  prompt_1_resident_calls: number,
  prompt_1_prospect_calls: number,
  prompt_3_resident_calls: number,
  prompt_3_prospect_calls: number,
  total_inbound_calls: number,
  inbound_resident_calls: number,
  inbound_prospect_calls: number,
  total_inbound_calls_answered: number,
  inbound_resident_calls_answered: number,
  inbound_prospect_calls_answered: number,
  overall_call_answer_rate: number,
  resident_call_answer_rate: number,
  prospect_call_answer_rate: number,
  call_transferred_to_onsite: number,
  call_transferred_to_onsite_rate: number,
  inbound_call_score: number,
  outbound_call_score: number,
  total_outbound_call_made: number,
  emails_sent: number,
  texts_sent: number,
}

export interface AgentOfficeReport {
  id: number,
  status: string,
  pending: boolean,
  start_date: string,
  end_date: string,
  generated_date: string,

  overall: {
    agent: AgentReport[],
    office: OfficeReport[],
  },
  grouped: {
    agent: AgentReport[],
    office: OfficeReport[],
  },
  compare_values: {
    agent: {
      [agent_id: string]: {
        lead_to_tour_conversion_rate: number,
        lead_to_lease_conversion_rate: number,
        worked_leads: number,
        scheduled_tours: number,
        completed_tours: number,
        leases_signed: number,
        total_inbound_calls: number,
        answered_inbound_calls: number,
        missed_inbound_calls: number,
        declined_inbound_calls: number,
        abandoned_inbound_calls: number,
        prompt_1_inbound_calls: number,
        prompt_3_inbound_calls: number,
        prompt_1_resident_calls: number,
        prompt_3_resident_calls: number,
        prompt_1_prospect_calls: number,
        prompt_3_prospect_calls: number,
        total_inbound_calls_answered: number,
        inbound_resident_calls_answered: number,
        inbound_prospect_calls_answered: number,
        overall_call_answer_rate: number,
        resident_call_answer_rate: number,
        prospect_call_answer_rate: number,
        call_transferred_to_onsite: number,
        call_transferred_to_onsite_rate: number,
        inbound_call_score: number,
        outbound_call_score: number,
        total_outbound_call_made: number,
        resident_outbound_call_made: number,
        prospect_outbound_call_made: number,
        emails_sent: number,
        texts_sent: number,
      }
    },
    office: {
      [office: string]: {
        lead_to_tour_conversion_rate: number,
        lead_to_lease_conversion_rate: number,

        unique_worked_leads: number,
        total_worked_leads: number,
        leases_from_ion_always: number,
        tours_from_ion_sometime: number,
        leases_from_ion_sometime: number,
        completed_tours: number,
        scheduled_tours: number,
        leases: number,

        prompt_1_resident_calls: number,
        prompt_1_prospect_calls: number,

        prompt_3_resident_calls: number,
        prompt_3_prospect_calls: number,

        total_inbound_calls: number,
        inbound_resident_calls: number,
        inbound_prospect_calls: number,

        total_inbound_calls_answered: number,
        inbound_resident_calls_answered: number,
        inbound_prospect_calls_answered: number,

        overall_call_answer_rate: number,
        resident_call_answer_rate: number,
        prospect_call_answer_rate: number,

        call_transferred_to_onsite: number,
        call_transferred_to_onsite_rate: number,

        outbound_call_score: number,
        inbound_call_score: number,

        outbound_call_made: number,
        emails_sent: number,
        texts_sent: number,
      }
    }
  }
}

export interface WorkforceAgent {
  agent_id: number,
  active_leads: number,
  assigned_leads: number,
  assigned_tasks: number,
  availability: number,
  avg_absolute_response_time: number,
  avg_absolute_sms_response_time: number,
  avg_available_time: number,
  avg_break_time: number,
  avg_idle_time: number,
  avg_response_time: number,
  avg_sms_response_time: number,
  call_handling_time: number,
  chat_conversations: number,
  chat_handling_time: number,
  completed_tasks: number,
  handled_interactions_rate: number,
  handling_time: number,
  interactions: number,
  leads_w_completed_tours: number
  leads_w_scheduled_tours: number
  leases_signed: number,
  name: string,
  prospect_sms: number,
  resident_sms: number,
  response_rate: number,
  sms_conversations: number,
  sms_received: number,
  sms_response_time: number,
  sms_responses: number,
  tours_completed: number,
  tours_scheduled: number,
  type: string,
  unworked_leads: number,
  worked_leads: number,
}

export interface WorkforceComparison {
  availability_rate: number,
  available_time: number,
  avg_absolute_response_time: number,
  avg_absolute_sms_response_time: number,
  avg_agent_response_time: number,
  avg_available_time: number,
  avg_response_time: number,
  avg_sms_response_time: number,
  break_time: number,
  call_volume_trends: number,
  chat_conversations: number,
  handling_time: number,
  leases_signed: number,
  live_chat_transfers: number,
  outbound_calls: number,
  prospect_sms: number,
  resident_sms: number,
  response_rate: number,
  sessions_handled: number,
  sms_received: number,
  sms_responses: number,
  total_active_leads: number,
  total_unworked_leads: number,
  total_worked_leads: number,
  tours_completed: number,
  tours_scheduled: number,
  transferred_inbound_calls: number,
}

export interface WorkforceGraph {
  period: string,
  active_leads: number,
  assigned_leads: number,
  call_handling_time: number,
  chat_conversations: number,
  chat_handling_time: number,
  completed_tours: number,
  leases_signed: number,
  outbound_calls: number,
  response_rate: number,
  scheduled_tours: number,
  sms_received: number,
  sms_responses: number,
  transferred_inbound_calls: number,
  unworked_leads: number,
  worked_leads: number,
}

export interface WorkforceReport {
  agents: WorkforceAgent[],
  comparison: WorkforceComparison,
  data: {
    answered_outbound_calls: number,
    availability_rate: number,
    avg_absolute_response_time: number,
    avg_absolute_sms_response_time: number,
    avg_available_time: number,
    avg_response_time: number,
    avg_sms_response_time: number,
    break_time: number,
    call_volume_trends: number,
    chat_conversations: number,
    chat_prospects: number,
    handling_time: number,
    inbound_calls: number,
    leads_w_completed_tours: number
    leads_w_scheduled_tours: number
    leases_signed: number,
    outbound_calls: number,
    outbound_calls_answer_rate: number,
    prospect_sms: number,
    resident_sms: number,
    response_rate: number,
    sms_received: number,
    sms_responses: number,
    total_sms: number,
    tours_completed: number,
    tours_scheduled: number,
    transferred_inbound_calls: number,
    transferred_inbound_calls_rate: number,
  },
  datePeriod?: string,
  dateRange?: string,
  graphs: WorkforceGraph[],
}

interface SummaryType {
  cumulative: {
    enrollments: { renewals: number, new_residents: number, current: number },
    cancellations: { renewals: number, new_residents: number, current: number },
    net_enrollments: { renewals: number, new_residents: number, current: number },
  },
  monthly: {
    enrollments: { renewals: number, new_residents: number, current: number },
    cancellations: { renewals: number, new_residents: number, current: number },
    net_enrollments: { renewals: number, new_residents: number, current: number },
  },
  revenue: {
    cumulative: {
      total: number,
      property: number,
      pmc: number,
    },
    cumulative_net: {
      total: number,
      property: number,
      pmc: number,
    },
    monthly: {
      total: number,
      property: number,
      pmc: number,
    },
    monthly_net: {
      total: number,
      property: number,
      pmc: number,
    },
  }
}

interface EnrollmentType {
  property: string;
  unit: string;
  first_name: string;
  last_name: string;
  email: string;
  enrollment_date: string;
  new_resident: string;
  existing_resident: string;
}

interface PaymentType {
  property: string;
  unit: string;
  first_name: string;
  last_name: string;
  email: string;
  enrollment_date: string;
  new_resident: string;
  existing_resident: string;
  payment_date: string;
  late_date: string;
  paid_before_late_date: string;
  payment_amount: string;
  rent_amount: string;
  paid_entire_rent: string;
  paid_on_time: string;
}

interface CancellationType {
  property: string;
  unit: string;
  first_name: string;
  last_name: string;
  email: string;
  enrollment_date: string;
  new_resident: string;
  existing_resident: string;
}

export interface CreditBuilderReport {
  id: number,
  status: string,
  pending: boolean,
  start_date: string,
  end_date: string,
  generated_date: string,

  overall: {
    summary: SummaryType,
    enrollments: EnrollmentType[],
    net_enrollments: EnrollmentType[],
    billings: EnrollmentType[],
    cancellations: CancellationType[],
    payments: PaymentType[],
  },
}

export interface ReportParam {
  date_period: string,
  custom_start_date: string,
  custom_end_date: string,
  compare_value: string,
  attribution: string,
  properties: number[],
  portfolio: number,
  show_paid_only?: boolean,
}

export interface RawData {
  category: string;
  data: {
    [key: string]: number | string;
  }[]
}

interface GetCorporatePPCReportsAction {
  type: ActionType.GET_CORPORATE_PPC_REPORTS_REQUEST
  | ActionType.GET_CORPORATE_PPC_REPORTS_SUCCESS
  | ActionType.GET_CORPORATE_PPC_REPORTS_FAILURE,
  result: { data: PPCReport },
  error: { response: { status: string } },
}

interface GetStandalonePPCReportsAction {
  type: ActionType.GET_STANDALONE_PPC_REPORTS_REQUEST
  | ActionType.GET_STANDALONE_PPC_REPORTS_SUCCESS
  | ActionType.GET_STANDALONE_PPC_REPORTS_FAILURE,
  result: { data: PPCReport },
  error: { response: { status: string } },
}

interface GetOverviewReportsAction {
  type: ActionType.GET_OVERVIEW_REPORTS_REQUEST | ActionType.GET_OVERVIEW_REPORTS_SUCCESS | ActionType.GET_OVERVIEW_REPORTS_FAILURE;

  result: {
    data: OverviewReport;
  };
  error: {
    response: { status: string };
  };
}

interface GetMarketingReportsAction {
  type: ActionType.GET_MARKETING_REPORTS_REQUEST | ActionType.GET_MARKETING_REPORTS_SUCCESS | ActionType.GET_MARKETING_REPORTS_FAILURE;

  result: {
    data: MarketingReport;
  };
  error: {
    response: { status: string };
  };
}

interface GetAgentAndOfficeReportsAction {
  type: ActionType.GET_AGENT_AND_OFFICE_REPORTS_REQUEST | ActionType.GET_AGENT_AND_OFFICE_REPORTS_SUCCESS | ActionType.GET_AGENT_AND_OFFICE_REPORTS_FAILURE;

  result: {
    data: AgentOfficeReport;
  };
  error: {
    response: { status: string };
  };
}

interface GetCreditBuilderReportsAction {
  type: ActionType.GET_CREDIT_BUILDER_REPORTS_REQUEST | ActionType.GET_CREDIT_BUILDER_REPORTS_SUCCESS | ActionType.GET_CREDIT_BUILDER_REPORTS_FAILURE;

  result: {
    data: CreditBuilderReport;
  };
  error: {
    response: { status: string };
  };
}

interface GetWorkforceReportAction {
  type: ActionType.GET_WORKFORCE_REPORTS_REQUEST
  | ActionType.GET_WORKFORCE_REPORTS_SUCCESS
  | ActionType.GET_WORKFORCE_REPORTS_FAILURE,
  result: { data: WorkforceReport },
  error: { response: { status: string } },
}

interface RequireRescoreCallAction {
  type: ActionType.REQUIRE_RESCORE_CALL_REQUEST | ActionType.REQUIRE_RESCORE_CALL_SUCCESS | ActionType.REQUIRE_RESCORE_CALL_FAILURE;

  result: {
    data: OverviewReport;
  };
  error: {
    response: { status: string };
  };
}

interface GetPropertiesAvailableToRescoreAction {
  type: ActionType.GET_PROPERTIES_AVAILABLE_TO_RESCORE_REQUEST | ActionType.GET_PROPERTIES_AVAILABLE_TO_RESCORE_SUCCESS | ActionType.GET_PROPERTIES_AVAILABLE_TO_RESCORE_FAILURE;

  result: {
    data: number[];
  };
  error: {
    response: { status: string };
  };
}

interface RefreshReportsAction {
  type: ActionType.REFRESH_REPORT_REQUEST | ActionType.REFRESH_REPORT_SUCCESS | ActionType.REFRESH_REPORT_FAILURE;

  result: {
    data: {
      success: boolean;
    }
  };
  error: {
    response: { status: string };
  };
}

interface RawDataReportAction {
  type: ActionType.GET_RAW_DATA_REPORTS_REQUEST | ActionType.GET_RAW_DATA_REPORTS_SUCCESS | ActionType.GET_RAW_DATA_REPORTS_FAILURE;

  result: {
    data: RawData;
  };
  error: {
    response: { status: string };
  };
}

interface SetPropertyNotAvailableToRescoreAction {
  type: ActionType.SET_PROPERTY_NOT_AVAILABLE_TO_RESCORE;
  id: number;
}

interface SetReloadStateAction {
  type: ActionType.SET_RELOAD_STATE;
  needReload: boolean,
}

interface ClearReportsAction {
  type: ActionType.CLEAR_REPORTS;
}

interface SetDatePeriodAction {
  type: ActionType.SET_DATE_PERIOD;
  datePeriod: string;
}

interface SetAttributionAction {
  type: ActionType.SET_ATTRIBUTION;
  attribution: string;
}

interface SetCompareValueAction {
  type: ActionType.SET_COMPARE_VALUE;
  compareValue: string;
}

interface SetQueryPropertiesAction {
  type: ActionType.SET_QUERY_PROPERTIES;
  properties: number[];
}

interface SetPortfolioAction {
  type: ActionType.SET_PORTFOLIO;
  portfolio: number;
}

interface SetReportTypeAction {
  type: ActionType.SET_REPORT_TYPE;
  reportType: string;
}

interface SetCustomDateAction {
  type: ActionType.SET_CUSTOM_DATE;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface SetShowPaidOnlyAction {
  type: ActionType.SET_SHOW_PAID_ONLY;
  showPaidOnly: boolean;
}

interface SetPropertiesLabelAction {
  type: ActionType.SET_PROPERTIES_LABEL;
  label: string;
}

interface SetAgentFilterAction {
  type: ActionType.SET_AGENT_FILTER;
  agentFilter: number;
}

export type Action =
  | PromiseActionType
  | GetCorporatePPCReportsAction
  | GetStandalonePPCReportsAction
  | GetOverviewReportsAction
  | GetMarketingReportsAction
  | GetCreditBuilderReportsAction
  | RequireRescoreCallAction
  | GetPropertiesAvailableToRescoreAction
  | SetPropertyNotAvailableToRescoreAction
  | RefreshReportsAction
  | RawDataReportAction
  | SetReloadStateAction
  | SetDatePeriodAction
  | SetAttributionAction
  | SetCompareValueAction
  | SetQueryPropertiesAction
  | SetPortfolioAction
  | SetReportTypeAction
  | SetCustomDateAction
  | ClearReportsAction
  | SetShowPaidOnlyAction
  | SetPropertiesLabelAction
  | GetAgentAndOfficeReportsAction
  | SetAgentFilterAction
  | GetWorkforceReportAction;
