import { PromiseActionType } from 'dwell/store/types';
import { PaginationResponse } from 'src/interfaces';

export enum ActionType {
  SAVE_COMPANY_POLICIES_REQUEST = 'SAVE_COMPANY_POLICIES_REQUEST',
  SAVE_COMPANY_POLICIES_SUCCESS = 'SAVE_COMPANY_POLICIES_SUCCESS',
  SAVE_COMPANY_POLICIES_FAILURE = 'SAVE_COMPANY_POLICIES_FAILURE',

  GET_AMENITY_CATEGORY_REQUEST = 'GET_AMENITY_CATEGORY_REQUEST',
  GET_AMENITY_CATEGORY_SUCCESS = 'GET_AMENITY_CATEGORY_SUCCESS',
  GET_AMENITY_CATEGORY_FAILURE = 'GET_AMENITY_CATEGORY_FAILURE',

  GET_PROPERTY_DETAILS_SUCCESS = 'GET_PROPERTY_DETAILS_SUCCESS',

  GET_CUSTOMER_COMPANY_POLICIES_REQUEST = 'GET_CUSTOMER_COMPANY_POLICIES_REQUEST',
  GET_CUSTOMER_COMPANY_POLICIES_SUCCESS = 'GET_CUSTOMER_COMPANY_POLICIES_SUCCESS',
  GET_CUSTOMER_COMPANY_POLICIES_FAILURE = 'GET_CUSTOMER_COMPANY_POLICIES_FAILURE',

  GET_ANSWERED_QUESTIONS_PERCENTAGE_REQUEST = 'GET_ANSWERED_QUESTIONS_PERCENTAGE_REQUEST',
  GET_ANSWERED_QUESTIONS_PERCENTAGE_SUCCESS = 'GET_ANSWERED_QUESTIONS_PERCENTAGE_SUCCESS',
  GET_ANSWERED_QUESTIONS_PERCENTAGE_FAILURE = 'GET_ANSWERED_QUESTIONS_PERCENTAGE_FAILURE',

  GET_INSIDER_QUESTIONS_REQUEST = 'GET_INSIDER_QUESTIONS_REQUEST',
  GET_INSIDER_QUESTIONS_SUCCESS = 'GET_INSIDER_QUESTIONS_SUCCESS',
  GET_INSIDER_QUESTIONS_FAILURE = 'GET_INSIDER_QUESTIONS_FAILURE',

  UPDATE_INSIDER_QUESTION_REQUEST = 'UPDATE_INSIDER_QUESTION_REQUEST',
  UPDATE_INSIDER_QUESTION_SUCCESS = 'UPDATE_INSIDER_QUESTION_SUCCESS',
  UPDATE_INSIDER_QUESTION_FAILURE = 'UPDATE_INSIDER_QUESTION_FAILURE',

  DELETE_INSIDER_QUESTION_REQUEST = 'DELETE_INSIDER_QUESTION_REQUEST',
  DELETE_INSIDER_QUESTION_SUCCESS = 'DELETE_INSIDER_QUESTION_SUCCESS',
  DELETE_INSIDER_QUESTION_FAILURE = 'DELETE_INSIDER_QUESTION_FAILURE',

  CREATE_INSIDER_QUESTION_REQUEST = 'CREATE_INSIDER_QUESTION_REQUEST',
  CREATE_INSIDER_QUESTION_SUCCESS = 'CREATE_INSIDER_QUESTION_SUCCESS',
  CREATE_INSIDER_QUESTION_FAILURE = 'CREATE_INSIDER_QUESTION_FAILURE',

  GET_INSIDER_QUESTION_CATEGORY_REQUEST = 'GET_INSIDER_QUESTION_CATEGORY_REQUEST',
  GET_INSIDER_QUESTION_CATEGORY_SUCCESS = 'GET_INSIDER_QUESTION_CATEGORY_SUCCESS',
  GET_INSIDER_QUESTION_CATEGORY_FAILURE = 'GET_INSIDER_QUESTION_CATEGORY_FAILURE',
}

export interface CompanyPolicyProps {
  basic_qualification_requirements: string;
  accept_section_eight: boolean;
  section_eight_disclaimer: string;
  accept_unemployment_as_income: boolean;
  unemployment_income_disclaimer: string;
  accept_applicant_without_ssn: boolean;
  ssn_disclaimer: string;
  accept_applicant_with_misdemeanors_or_felonies: boolean;
  misdemeanor_or_felony_disclaimer: string;
  is_hard_inquiry_on_credit_report: boolean;
  screening_process_time: string;
  is_valet_waste_service_optional: boolean;
  is_alley_waste_service_optional: boolean;
  application_refund_policy: string;
  package_policy: string;
  lease_break_policy: string;
  transfer_policy: string;
}

export interface InsiderQuestionCategory {
  id: number;
  name: string;
  subcategories?: InsiderQuestionSubCategory[];
}

export interface InsiderQuestionSubCategory {
  id: number;
  name: string;
}

export interface InsiderQuestion {
  properties: number[];
  label: string;
  order?: number;
  category: InsiderQuestionCategory;
  subcategory: InsiderQuestionSubCategory;
  applicable_unit_types: string[];
  applicable_floor_plans: string[];
  applicable_units: string[];
  id: number;
}

export interface Unit {
  id: string;
  unit: string;
  market_rent: number;
  effective_rent: number;
  floor_plan: number;
  available_date: string;
  is_waitlist: boolean;
  description: string | null;
}

export interface FloorPlanWithUnits {
  promotions: string[];
  units: Unit[];
  bathrooms: number;
  bedrooms: number;
  id: string;
  images: string[];
  max_rent: number;
  min_rent: number;
  plan: string;
  square_footage: number;
}

interface SaveCompanyPoliciesAction {
  type: ActionType.SAVE_COMPANY_POLICIES_REQUEST | ActionType.SAVE_COMPANY_POLICIES_SUCCESS | ActionType.SAVE_COMPANY_POLICIES_FAILURE;

  result: {
    data: CompanyPolicyProps;
  };
  error: {
    response: { status: string };
  };
}

interface GetAmenityCategoriesAction {
  type: ActionType.GET_AMENITY_CATEGORY_REQUEST | ActionType.GET_AMENITY_CATEGORY_SUCCESS | ActionType.GET_AMENITY_CATEGORY_FAILURE;

  result: {
    data: { id: number; name: string }[];
  };
  error: {
    response: { status: string };
  };
}

interface GetPropertyDetailsAction {
  type: ActionType.GET_PROPERTY_DETAILS_SUCCESS;

  result: {
    data: { company_polices: CompanyPolicyProps };
  };
  error: {
    response: { status: string };
  };
}

interface GetCustomerCompanyPoliciesAction {
  type: ActionType.GET_CUSTOMER_COMPANY_POLICIES_REQUEST | ActionType.GET_CUSTOMER_COMPANY_POLICIES_SUCCESS | ActionType.GET_CUSTOMER_COMPANY_POLICIES_FAILURE;

  result: {
    data: CompanyPolicyProps;
  };
  error: {
    response: { status: string };
  };
}

interface GetAnsweredQuestionsPErcentageAction {
  type: ActionType.GET_ANSWERED_QUESTIONS_PERCENTAGE_REQUEST | ActionType.GET_ANSWERED_QUESTIONS_PERCENTAGE_SUCCESS | ActionType.GET_ANSWERED_QUESTIONS_PERCENTAGE_FAILURE;
  result: { data: number }
  error: {
    response: { status: string };
  };
}

interface GetInsiderQuestionsAction extends PaginationResponse<InsiderQuestion> {
  type: ActionType.GET_INSIDER_QUESTIONS_REQUEST | ActionType.GET_INSIDER_QUESTIONS_SUCCESS | ActionType.GET_INSIDER_QUESTIONS_FAILURE;

  error: {
    response: { status: string };
  };
}

interface CreateInsiderQuestionAction {
  type: ActionType.CREATE_INSIDER_QUESTION_REQUEST | ActionType.CREATE_INSIDER_QUESTION_SUCCESS | ActionType.CREATE_INSIDER_QUESTION_FAILURE;
}

interface PartialUpdateInsiderQuestionAction {
  type: ActionType.UPDATE_INSIDER_QUESTION_REQUEST | ActionType.UPDATE_INSIDER_QUESTION_SUCCESS | ActionType.UPDATE_INSIDER_QUESTION_FAILURE;

  result: {
    data: InsiderQuestion;
  };
  error: {
    response: { status: string };
  };
}

interface UpdateInsiderQuestionAction {
  type: ActionType.UPDATE_INSIDER_QUESTION_REQUEST | ActionType.UPDATE_INSIDER_QUESTION_SUCCESS | ActionType.UPDATE_INSIDER_QUESTION_FAILURE;

  result: {
    data: InsiderQuestion;
  };
  error: {
    response: { status: string };
  };
}

interface GetInsiderQuestionCategoryAction {
  type: ActionType.GET_INSIDER_QUESTION_CATEGORY_REQUEST | ActionType.GET_INSIDER_QUESTION_CATEGORY_SUCCESS | ActionType.GET_INSIDER_QUESTION_CATEGORY_FAILURE;

  result: {
    data: { results: InsiderQuestionCategory[]; count: number };
  };
  error: {
    response: { status: string };
  };
}

export type Action =
  | PromiseActionType
  | SaveCompanyPoliciesAction
  | GetAmenityCategoriesAction
  | GetPropertyDetailsAction
  | GetCustomerCompanyPoliciesAction
  | GetAnsweredQuestionsPErcentageAction
  | GetInsiderQuestionsAction
  | CreateInsiderQuestionAction
  | UpdateInsiderQuestionAction
  | PartialUpdateInsiderQuestionAction
  | GetInsiderQuestionCategoryAction;
