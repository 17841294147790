import React, { FC, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from 'reactstrap';
import TimeAgo from 'react-timeago';
import Skeleton from 'react-loading-skeleton';
import { LineSkeleton, formatTimeByTimezone } from 'src/utils';
import moment from 'moment';
import {
  MediaAvatar, MediaBody, MediaLead, MediaInfo, MediaLabel, MediaValue, ContentNavBar, NavLeadOption, KeyInfoCreditBuilderWrapper,
} from 'dwell/views/lead/layout/key_info/style';
import residentFieldChoices from 'resident/constants/field_choices';
import { selectLeaseDetails } from 'resident/store/lease/reducers';
import actions from 'resident/store/actions';
import { OrderedOwnerDropdown } from 'dwell/components';
import SyncStatusPoll from './_sync_status_poll';
import { PMSSyncStatus } from './styles';
import ColumnLabel from './_column_label';

interface KeyInfoProps {
  isCreditBuilder?: boolean;
}

const KeyInfo: FC<KeyInfoProps> = ({ isCreditBuilder = false }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const leaseDetails = useSelector(selectLeaseDetails);
  const currentProperty = useSelector(state => state.property.property);
  const dispatch = useDispatch();
  const { updateLease } = actions.leaseDetails;
  const { stage: stageChoices, status: statusChoices } = residentFieldChoices.RESIDENT_FILED_CHOICES;
  const {
    last_followup_date: lastFollowupDate, last_activity_date: lastActivityDate, unit, stage, lease_status: status,
    pms_sync_date: pmsSyncDate, pms_sync_status: pmsSyncStatus, last_unit: lastUnit,
  } = leaseDetails;

  const isLoaded = !!leaseDetails.id;
  const getTimeAgo = date => (date ? <TimeAgo title={formatTimeByTimezone(date).format('YYYY-MM-DD HH:mm')} date={date} live={false} /> : <>&nbsp;</>);

  const onChangeOwner = (field, value) => {
    dispatch(updateLease(leaseDetails.id, { [field]: value }));
  };

  const syncStatus = useMemo(() => {
    if (!pmsSyncStatus) return <br />;

    let syncStatusIcon = 'ri-indeterminate-circle-line';
    if (pmsSyncStatus === 'SUCCESS') syncStatusIcon = 'ri-checkbox-circle-line';
    if (pmsSyncStatus === 'SYNCING') syncStatusIcon = 'ri-refresh-line';
    if (pmsSyncStatus === 'FAILURE') syncStatusIcon = 'ri-information-line';

    return (
      <PMSSyncStatus status={pmsSyncStatus}>
        <span id="pms_sync">
          <i className={syncStatusIcon} />
          <span>
            {pmsSyncStatus === 'NOT_STARTED' && 'Not Started'}
            {pmsSyncStatus === 'SYNCING' && 'Syncing ...'}
            {['SUCCESS', 'FAILURE'].includes(pmsSyncStatus) && (
              <React.Fragment>
                {pmsSyncStatus === 'SUCCESS' ? 'Synced' : 'Not synced'} ({getTimeAgo(pmsSyncDate)})
                <Tooltip placement="top" isOpen={tooltipOpen} target="pms_sync" toggle={() => setTooltipOpen(!tooltipOpen)}>
                  {formatTimeByTimezone(pmsSyncDate).format('lll')}
                </Tooltip>
              </React.Fragment>
            )}
          </span>
        </span>
      </PMSSyncStatus>
    );
  }, [pmsSyncStatus, pmsSyncDate, isCreditBuilder]);

  const stageLabel = useMemo(() => {
    const value = stageChoices[stage];
    return <ColumnLabel value={value} />;
  }, [stage]);

  const statusLabel = useMemo(() => {
    const value = statusChoices[status];
    return <ColumnLabel value={value} />;
  }, [status]);

  const columns = [
    { field: 'Last Sync Date', value: syncStatus },
    ...(isCreditBuilder
      ? []
      : [
        { field: 'Last Followup Date', value: lastFollowupDate ? moment(lastFollowupDate)?.format('LL') : '' },
        { field: 'Last Activity Date', value: lastActivityDate ? moment(lastActivityDate)?.format('LL') : '' },
        { field: 'Stage', value: stageLabel },
        { field: 'Status', value: statusLabel },
      ]),
  ];

  const content = (
    <React.Fragment>
      <MediaLead>
        {isLoaded ? (
          <MediaAvatar><span><i className={`tx-20 pd-l-2 ${isCreditBuilder ? 'ri-key-2-line' : 'ri-home-7-fill'}`} /></span></MediaAvatar>
        ) : (
          <Skeleton circle height={38} width={38} />
        )}
        <MediaBody>
          <div className="d-flex align-items-end">
            <div>
              <MediaLabel>Lease</MediaLabel>
              {isLoaded ? <strong>{unit || lastUnit}</strong> : <LineSkeleton width={100} height={12} />}
            </div>
          </div>
        </MediaBody>
        {columns.map(c => (
          <MediaInfo $isCreditBuilder={isCreditBuilder}>
            <MediaLabel>{isLoaded ? c.field : <LineSkeleton height={8} width={150} />}</MediaLabel>
            <MediaValue>
              {isLoaded ? <>{c.value || <br />}</> : <LineSkeleton height={9} width={100} />}
            </MediaValue>
          </MediaInfo>
        ))}
      </MediaLead>
      {!isCreditBuilder && (
        <ContentNavBar>
          <NavLeadOption>
            <OrderedOwnerDropdown availableOwners={currentProperty.users} selectedOwner={leaseDetails.owner} onChange={onChangeOwner} />
          </NavLeadOption>
        </ContentNavBar>
      )
      }
      <SyncStatusPoll />
    </React.Fragment>
  );

  return isCreditBuilder ? <KeyInfoCreditBuilderWrapper>{content}</KeyInfoCreditBuilderWrapper> : content;
};

export default KeyInfo;
