import React, { useEffect, useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormFeedback, FormGroup, Input, ModalBody, ModalHeader } from 'reactstrap';
import { isEmpty, noop } from 'lodash';
import actions from 'dwell/actions';
import userManager from 'dwell/store/authentication/user_manager';
import { SSOCheckAction as SSOCheckResponse } from 'dwell/store/authentication/action-types';
import { paths, IS_RECENTLY_LOGGED, USER_TO_LOGIN, USER_TO_SWITCH } from 'dwell/constants';
import { getColorFromString } from 'site/common/getColor';
import { getPropertyId } from 'src/utils';
import { SSOStatus } from 'src/interfaces';
import { ModalWindow, ModalSubtitle, MediaBody, Avatar, Media, PasswordContainer, LinkForgot, ContentLabel, AvailableAccounts, EmptyList } from './styles';

interface ReloginFlowProps {
  show: boolean;
}

const ReloginFlowModal: FC<ReloginFlowProps> = ({ show }) => {
  const dispatch = useDispatch();
  const {
    login,
    logout,
    checkSSO,
    resetLoginState,
    backchannelLogout,
  } = actions.authentication;
  const currentUser = useSelector(state => state.user.currentUser);
  const teamUsers = useSelector(state => state.user.teamUsers);
  const isFormInvalid = useSelector(state => state.authentication.isFormInvalid);
  const [selectedUser, setSelectedUser] = useState(currentUser);
  const [password, setPassword] = useState('');
  const [reloginStep, setStep] = useState(1);
  const [ssoStatus, setSSOStatus] = useState<SSOStatus | null>(null);

  const { push } = useHistory();

  useEffect(() => {
    if (isEmpty(currentUser)) {
      if (ssoStatus && ssoStatus.isSSO) dispatch(backchannelLogout());
      else dispatch(logout());
    }
  }, [currentUser]);

  const localCheckSSO = async () => {
    try {
      const ssoCheckResponse: SSOCheckResponse = await dispatch(checkSSO(selectedUser.email));
      setSSOStatus(ssoCheckResponse.result.data);
    } catch (error) {
      noop();
    }
  };

  useEffect(() => {
    localCheckSSO();
  }, []);

  const handlePasswordChange = (value) => {
    if (isFormInvalid) {
      resetLoginState();
    }
    setPassword(value);
  };

  useEffect(() => {
    handlePasswordChange('');
  }, [selectedUser]);

  const loginUser = (user) => {
    dispatch(login({ email: user.email, password })).then((response) => {
      if (response) {
        localStorage.setItem(IS_RECENTLY_LOGGED, 'true');
        if (user.email !== currentUser.email) {
          const externalId = getPropertyId();
          if (externalId) {
            push(`/${externalId}/leads`);
          } else {
            push(paths.client.BASE);
          }
          window.location.reload();
        }
      }
    });
  };

  const handleLogin = () => {
    if (ssoStatus && ssoStatus.isSSO) {
      sessionStorage.setItem(USER_TO_LOGIN, selectedUser.email.toLowerCase());
      userManager.signinRedirect({
        login_hint: selectedUser.email,
        extraQueryParams: {
          kc_idp_hint: ssoStatus.ssoProvider,
          prompt: 'login',
        },
      });
    } else {
      loginUser(selectedUser);
    }
  };

  const handleKeyPress = ({ key }) => {
    if (key === 'Enter') {
      handleLogin();
    }
  };

  const changeAccount = async (user) => {
    if (ssoStatus && ssoStatus.isSSO) {
      sessionStorage.setItem(USER_TO_SWITCH, user.email.toLowerCase());
      await userManager.signinRedirect({
        login_hint: user.email,
        extraQueryParams: {
          kc_idp_hint: ssoStatus.ssoProvider,
          prompt: 'login',
        },
      });
    } else {
      setSelectedUser(user);
      setStep(3);
    }
  };

  const UserAvatar = ({ user }) => (
    <React.Fragment>
      {user.avatar || !(user.first_name && user.last_name) ? (
        <Avatar>
          <img src={user.avatar || '/static/images/default-avatar.gif'} alt="avatar" />
        </Avatar>
      ) : (
        <Avatar style={{ backgroundColor: getColorFromString(user.last_name) }}>
          {user.first_name[0]}
          {user.last_name[0]}
        </Avatar>
      )}
    </React.Fragment>
  );

  const switchAccount = reloginStep === 3;

  const getHeaderLabel = () => {
    if ([2, 3].includes(reloginStep)) {
      return 'Switch Accounts';
    } else if (!ssoStatus || !ssoStatus.isSSO) {
      return 'Enter Password';
    }
    return 'Login';
  };

  return (
    <ModalWindow isOpen={show} centered>
      <ModalHeader>{getHeaderLabel()}</ModalHeader>
      <ModalBody>
        {[1, 3].includes(reloginStep) && (
          <ModalSubtitle className="mb-30">
            {!switchAccount
              ? `Due to inactivity, your Union session has ended.
            Please log back in below`
              : 'Enter account password:'}
          </ModalSubtitle>
        )}
        {!isEmpty(selectedUser) && reloginStep !== 2 ? (
          <React.Fragment>
            <div>
              <Media active>
                <UserAvatar user={selectedUser} />
                <MediaBody>
                  <h6>
                    {selectedUser.first_name} {selectedUser.last_name}
                  </h6>
                  <p>{selectedUser.email}</p>
                </MediaBody>
              </Media>
              <PasswordContainer className="mt-4">
                {(!ssoStatus || !ssoStatus.isSSO) && (
                  <FormGroup className="mb-4">
                    <Input
                      type="password"
                      id="password"
                      placeholder="Enter account password"
                      value={password}
                      onChange={({ target: { value } }) => handlePasswordChange(value)}
                      onKeyPress={handleKeyPress}
                      invalid={isFormInvalid}
                    />
                    <FormFeedback>
                      The password you entered is invalid. Please try again.
                    </FormFeedback>
                    {!switchAccount && (
                      <div className="text-right" style={{ marginTop: '-2px' }}>
                        <LinkForgot onClick={() => push(paths.client.SEND_PASSWORD_RESET_EMAIL)}>
                          Forgot password?
                        </LinkForgot>
                      </div>
                    )}
                  </FormGroup>
                )}
                <div className="d-flex">
                  <button className="btn btn-primary flex-fill justify-content-center" onClick={() => handleLogin()}>
                    {switchAccount ? 'Switch Account' : 'Login'}
                  </button>
                  <button className="btn btn-white flex-fill justify-content-center ml-10" onClick={() => setStep(2)}>
                    {switchAccount ? 'Cancel' : 'Switch Account'}
                  </button>
                </div>
              </PasswordContainer>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Media
              className="mb-4"
              active
              isClick
              onClick={() => {
                setStep(1);
                setSelectedUser(currentUser);
              }}
            >
              <UserAvatar user={currentUser} />
              <MediaBody>
                <h6>
                  {currentUser.first_name} {currentUser.last_name}
                </h6>
                <p>{currentUser.email}</p>
                <i>Logged out due to inactivity</i>
              </MediaBody>
            </Media>
            <ContentLabel>Switch account to:</ContentLabel>
            <AvailableAccounts>
              {isEmpty(teamUsers.filter(user => user.id !== currentUser.id)) ? (
                <EmptyList>
                  <p>There are no available accounts to switch to.</p>
                </EmptyList>
              ) : (
                teamUsers
                  .filter(user => user.id !== currentUser.id)
                  .map((user, index) => (
                    <Media isSwitch key={index} onClick={() => changeAccount(user)}>
                      <UserAvatar user={user} />
                      <MediaBody>
                        <h6>
                          {user.first_name} {user.last_name}
                        </h6>
                        <p>{user.email}</p>
                      </MediaBody>
                    </Media>
                  ))
              )}
            </AvailableAccounts>
          </React.Fragment>
        )}
      </ModalBody>
    </ModalWindow>
  );
};

export default ReloginFlowModal;
