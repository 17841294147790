import { InsiderInfoDetails } from 'dwell/store/property_profile/action-types';

const findMatchingInsiderInfoDetails = (insiderInfo: InsiderInfoDetails[], unit: number, floorPlan: number, unitType?: string): string[] | undefined => {
  let filteredInsiderInfo = insiderInfo?.filter(({ applicable_unit_types }) => applicable_unit_types.includes(unitType));

  if (filteredInsiderInfo?.length === 0) {
    filteredInsiderInfo = insiderInfo?.filter(({ applicable_unit_types }) => !applicable_unit_types?.length);
  }

  const { details } = filteredInsiderInfo?.find(
    ({ applicable_unit, applicable_floor_plan }) => (
      applicable_unit === unit && applicable_floor_plan === floorPlan
    )) ||
    filteredInsiderInfo?.find(({ applicable_unit, applicable_floor_plan }) => (
      applicable_unit === unit && !applicable_floor_plan
    )) ||
    filteredInsiderInfo?.find(({ applicable_unit, applicable_floor_plan }) => (
      !applicable_unit && applicable_floor_plan === floorPlan
    )) ||
    filteredInsiderInfo?.find(({ applicable_unit, applicable_floor_plan }) => (
      !applicable_unit && !applicable_floor_plan
    )) || {};

  return details;
};

export { findMatchingInsiderInfoDetails };
