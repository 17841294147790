import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import actions from 'dwell/store/focus_bar/action-creators';
import emailPopOutActions from 'dwell/store/email_popout/action-creator';
import { FocusBarState } from 'dwell/store/focus_bar/action-types';
import { selectFocusBarData } from 'dwell/store/focus_bar/reducers';
import useTimeout from 'src/hooks/useTimeout';

import { Action, Bar } from './styles';

const FocusBar: FC = () => {
  const [countCall, setCallCount] = useState(0);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { isLoaded, from, index, items, leadQuery, title } = useSelector(selectFocusBarData) as FocusBarState;
  const isLeadLoaded = useSelector(state => state.lead.isLoaded);
  const { circleEmailPopout } = emailPopOutActions;

  const timeout = useTimeout(
    (clear: () => void) => {
      if (isLoaded && !isEmpty(leadQuery) && countCall < 10) {
        setCallCount(countCall + 1);
        const after = items.reduce((acc, i) => (i.lead > acc ? i.lead : acc), 0);
        dispatch(actions.getLeadData({ ...leadQuery, after, append: true, backupLeads: [], from, title }))
          .finally(clear);
      }
    },
    600000,
    [isLoaded, items],
  );

  const onPrevious = () => {
    const newIndex = index - 1;
    if (newIndex < 0 || !isLeadLoaded) return;
    dispatch(actions.setBarIndex(newIndex));
    dispatch(circleEmailPopout(`lead-${items[index].lead}`));
    push(`/${items[index - 1].property}/leads/${items[newIndex].lead}/overview`);
  };

  const onNext = () => {
    const newIndex = index + 1;
    if (newIndex === items.length || !isLeadLoaded) return;
    dispatch(actions.setBarIndex(newIndex));
    dispatch(circleEmailPopout(`lead-${items[index].lead}`));
    push(`/${items[index + 1].property}/leads/${items[newIndex].lead}/overview`);
  };

  const onClose = () => {
    dispatch(actions.resetFocusBar());
    push(from);
  };

  const onKeyUp = (e: KeyboardEvent) => {
    if (['INPUT', 'TEXTAREA'].includes(document?.activeElement?.tagName)) return;
    if (!e.key) return;
    switch (e.key.toLocaleLowerCase()) {
      case 'escape':
        push(from);
        break;
      case 'u':
        onNext();
        break;
      case 'i':
        onPrevious();
        break;
    }
  };

  useEffect(() => () => {
    if (timeout.current) clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    if (isLoaded) document.addEventListener('keyup', onKeyUp);

    return () => document.removeEventListener('keyup', onKeyUp);
  });

  if (!isLoaded) return null;

  return (
    <Bar id="focus_bar">
      <span className="mg-r-auto">
        {title}&nbsp; {index + 1} /
        <span> {items.length}</span>
      </span>

      <div>
        <Action
          className={cn(['ri-arrow-up-s-line', 'with-border', { disabled: index === 0 || !isLeadLoaded }])}
          onClick={onPrevious}
          id="move-up"
        />
        <UncontrolledTooltip placement="bottom" target="move-up">
          Move Up (I)
        </UncontrolledTooltip>

        <Action
          className={cn(['ri-arrow-down-s-line', 'with-border', { disabled: index === items.length - 1 || !isLeadLoaded }])}
          onClick={onNext}
          id="move-down"
        />
        <UncontrolledTooltip placement="bottom" target="move-down">
          Move Down (U)
        </UncontrolledTooltip>

        <Action className="ri-close-line" onClick={onClose} />
      </div>
    </Bar>
  );
};

export default FocusBar;
