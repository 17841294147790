import moment from 'moment';
import React from 'react';
import { CHAT_MESSAGE_STATUSES } from 'dwell/constants/chat_evaluations';
import { OfficeMeta } from 'dwell/store/report/action-types';
import { isObject } from 'lodash';
import {
  ComparePeriodLabel, ReportCompareValue, ReportValue, TableCompare,
} from 'dwell/views/reports/blocks_styles';
import { ChatReportStats } from 'dwell/store/chat_evaluation/action-types';
import { TableColumn } from 'src/interfaces';
import { getPropertyId, LineSkeleton } from 'src/utils';
import { TourDataType } from 'dwell/constants/report_types';
import { currencyFormat } from 'compete/constants';

interface Spend {
  date: string,
  price: number,
}

interface CommonSetting {
  theme: string,
  usePlotGradientColor: string,
  plotGradientColor: string,
  plotFillAngle: string,
  plotFillAlpha: string,
  plotFillRatio: string,
  showPlotBorder: string,
  drawFullAreaBorder: string,
  plotBorderColor: string,
  anchorAlpha: string,
  anchorBgColor: string,
  anchorBorderColor: string,
  plotFillColor: string,
  plotBorderAlpha: string,
  maxLabelHeight: number,
  drawCrossLine: number,
  crossLineAlpha: number,
  labelDisplay: string,
}

interface Source {
  label: string,
  showLabel?: string,
}

interface Agent {
  agent: string,
  score: number,
  properties: string,
}

interface CallScoringDrilldown {
  property: string,
  average_call_score: number,
  introduction: number,
  qualifying: number,
  amenities: number,
  closing: number,
  overall: number,
}

interface CallScoringData {
  lead_id: string,
  property: number,
  property_name: string,
  lead_name: string,
  source: string,
  prospect_phone_number: string,
  agent_name: string,
  duration: number,
  date: string,
  score: number,
  yes_questions: string[],
  omitted_questions: string[],
  answered_by_hobbes: boolean,
}

interface DateRange {
  property: string,
  startDate: string,
  endDate: string,
}

interface Questions {
  id: string,
  question: string,
  weight: number,
}

interface CreditBuilderResidentMetrics {
  new_residents: number,
  current: number,
  renewals: number,
}

interface CreditBuilderRevenueMetrics {
  pmc: number,
  total: number,
  property: number,
}

interface CreditBuilderSymmaryData {
  monthly: {
    enrollments: CreditBuilderResidentMetrics,
    cancellations: CreditBuilderResidentMetrics,
    net_enrollments: CreditBuilderResidentMetrics,
  },
  revenue: {
    monthly: CreditBuilderRevenueMetrics,
    cumulative: CreditBuilderRevenueMetrics,
    monthly_net: CreditBuilderRevenueMetrics,
    cumulative_net: CreditBuilderRevenueMetrics,
  },
  cumulative: {
    enrollments: CreditBuilderResidentMetrics,
    cancellations: CreditBuilderResidentMetrics,
    net_enrollments: CreditBuilderResidentMetrics,
  },
}

interface CreditBuilderLaunchDateRelation {
  name: string,
  launchDate: string,
}

export const addLabelSetting = (source: Source[]): Source[] => source.map((item, index) => ({ ...item, showLabel: [0, source.length - 1].includes(index) ? '1' : '0' }));
export const tooltipHeader = (label: string): string => `<div style="background-color:#0168fa;color:#fff;border-radius: 3px 3px 0 0;padding: 8px;margin: -6px -6px 5px;text-align: center;">${label}</div>`;

export const chartCommonSetting = (color: string): CommonSetting => ({
  theme: 'fusion',
  usePlotGradientColor: '1',
  plotGradientColor: '#ffffff',
  plotFillAngle: '90',
  plotFillAlpha: '20',
  plotFillRatio: '10,100',
  showPlotBorder: '1',
  drawFullAreaBorder: '0',
  plotBorderColor: color,
  anchorAlpha: '100',
  anchorBgColor: color,
  anchorBorderColor: color,
  plotFillColor: color,
  plotBorderAlpha: '100',
  maxLabelHeight: 90,
  drawCrossLine: 1,
  crossLineAlpha: 70,
  labelDisplay: 'none',
});

export const getTotalSpends = (spends: Spend[], startDate: string, endDate: string): number => {
  const start = moment.utc(startDate).startOf('day');
  const end = moment.utc(endDate).startOf('day');
  let totalSpends = 0;
  spends.forEach((spend) => {
    const date = moment.utc(spend.date);
    if (start.isSame(date, 'month') || end.isSame(date, 'month')) {
      if (start.isSame(end, 'month')) {
        totalSpends += spend.price * ((end.diff(start, 'days') + 1) / date.daysInMonth());
      } else {
        if (date.isSame(start, 'month')) {
          totalSpends += spend.price * ((date.endOf('month').diff(start, 'days') + 1) / date.daysInMonth());
        }
        if (date.isSame(end, 'month')) {
          totalSpends += spend.price * ((end.diff(date.startOf('month'), 'days') + 1) / date.daysInMonth());
        }
      }
    }
    if (date.isBetween(start, end, 'month', '()')) {
      totalSpends += spend.price;
    }
  });
  return totalSpends;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const sortColumns = (sortOrder: string, sortField: string, tableData: any): any => tableData.sort((a, b) => {
  const aField = ['call_answered', 'call_failed', 'call_missed', 'call_busy'].includes(sortField) ? a[sortField].percents : a[sortField];
  const bField = ['call_answered', 'call_failed', 'call_missed', 'call_busy'].includes(sortField) ? b[sortField].percents : b[sortField];
  if (aField > bField) {
    return sortOrder === 'asc' ? 1 : -1;
  } else if (bField > aField) {
    return sortOrder === 'asc' ? -1 : 1;
  }
  return 0;
});

export const getCompareColor = (value: string | number, isEngagement = false, isNeutral = false): string => {
  if (value === 'n/a') return '#f3505c';
  if (isNeutral) return '#0168fa';
  if (value as number < 0) return isEngagement ? '#24ba7b' : '#f3505c';
  if (value as number > 0) return isEngagement ? '#f3505c' : '#24ba7b';
  return '#929eb9';
};

export const getCompareIcon = (value: string | number): JSX.Element => {
  if (value as number < 0) return <i className="ri-arrow-down-line" />;
  if (value as number > 0) return <i className="ri-arrow-up-line" />;
  return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const getCompareValue = (value: any): string => {
  if (value) {
    return ['n/a', 0].includes(value) ? value : value[0];
  } return '';
};

export const formatNumber = (value: number, isPercent = false): string => {
  let result = (`${value.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);
  if (isPercent) {
    result = result.toString().replace(/(\.\d*?)0+$/, '$1');
  }
  if (result.endsWith('.')) {
    result = result.slice(0, -1);
  }
  return isPercent ? `${result}%` : result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const formatCompareValue = (value: any): string => {
  if (['n/a', 0].includes(value)) {
    return value;
  }
  if (value === null) {
    return '...';
  }
  return `${formatNumber(Math.abs(value), true)}`;
};

export const formatPriceValue = (value: number): string => (`$${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`);

// eslint-disable-next-line no-restricted-globals
export const isNumeric = (value: string | number): boolean => value != null && value !== '' && !isNaN(Number(value.toString()));

export const formatToOneDecimal = (value: number): number | string => (value % 1 === 0 ? value : value.toFixed(1));

export const formatStringOrOneDecimal = (value: number | string): number | string => (isNumeric(value) ? (Number(value) / 60).toFixed(1) : value);

export const defaultLeadSourceColumns = (): TableColumn[] => ([
  {
    dataField: 'source',
    text: 'Source',
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'type',
    text: 'Type',
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'leads',
    text: 'Leads',
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'tours',
    text: 'Tours',
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'leases',
    text: 'Leases',
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'calls',
    text: 'Calls',
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'leased_rate',
    text: 'Leased rate (%)',
    headerStyle: () => ({ width: 'calc(80% / 11)' }),
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'tour_completed_rate',
    text: 'Tour completed rate (%)',
    headerStyle: () => ({ width: 'calc(80% / 11)' }),
    formatter: () => (<LineSkeleton />),
    sort: true,
  },
  {
    dataField: 'spend',
    text: 'Spend',
    headerStyle: () => ({ width: 'calc(80% / 11)' }),
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'cost_per_lead',
    text: 'Cost per lead',
    headerStyle: () => ({ width: 'calc(80% / 11)' }),
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'cost_per_tour',
    text: 'Cost per tour',
    headerStyle: () => ({ width: 'calc(80% / 11)' }),
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'cost_per_lease',
    text: 'Cost per lease',
    headerStyle: () => ({ width: 'calc(80% / 11)' }),
    formatter: () => (<LineSkeleton />),
  },
]);

export const defaultAgentColumns = (): TableColumn[] => ([
  {
    dataField: 'office',
    text: 'Office',
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'all_leads',
    text: 'All Leads',
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'worked_leads',
    text: 'Worked Leads',
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'tours_scheduled',
    text: 'Tours Scheduled',
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'tours_completed',
    text: 'Tours Completed',
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'tours_completed_rate',
    text: 'Tours Completed Rate %',
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'leases_signed',
    text: 'Leases Signed',
    headerStyle: () => ({ width: 'calc(80% / 11)' }),
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'leases_signed_rate',
    text: 'Lead to Signed Lease Rate %',
    headerStyle: () => ({ width: 'calc(80% / 11)' }),
    formatter: () => (<LineSkeleton />),
    sort: true,
  },
  {
    dataField: 'inbound_calls_answered',
    text: 'Inbound Calls Answered',
    headerStyle: () => ({ width: 'calc(80% / 11)' }),
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'outbound_calls_made',
    text: 'Outbound Calls Made',
    headerStyle: () => ({ width: 'calc(80% / 11)' }),
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'avg_call_score',
    text: 'Avg. Call Score',
    headerStyle: () => ({ width: 'calc(80% / 11)' }),
    formatter: () => (<LineSkeleton />),
  },
  {
    dataField: 'emails_sent',
    text: 'Emails Sent',
    headerStyle: () => ({ width: 'calc(80% / 11)' }),
    formatter: () => (<LineSkeleton />),
  },
]);

export const exportDataToXls = async (id: string, ...dataForXls: { data: unknown[], sheet: string, header?: string[], callback?: (dataTable: unknown, row: unknown, index: number) => void }[]): Promise<void> => {
  const XLSX = await import('xlsx');
  const wb = { Sheets: {}, SheetNames: [] };

  // eslint-disable-next-line no-restricted-syntax
  for (const { data, sheet, header, callback = null } of dataForXls) {
    let dataTable;
    if (header) {
      dataTable = XLSX.utils.json_to_sheet(data, { header });
    } else {
      dataTable = XLSX.utils.json_to_sheet(data);
    }
    if (callback) {
      data.forEach((row, index) => callback(dataTable, row, index));
    }

    const shortSheetName = sheet.substr(0, 31);
    wb.Sheets[shortSheetName] = dataTable;
    wb.SheetNames.push(shortSheetName);
  }

  const xlsBlob = new Blob(
    [XLSX.write(wb, { bookType: 'xls', type: 'array' })],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' },
  );

  const link = document.createElement('a');
  link.setAttribute('href', window.URL.createObjectURL(xlsBlob));
  link.setAttribute('download', `${id}.xls`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const exportAgentCallScoringDataToXls = (data: Agent[]): void => {
  const reportData = data.map(row => ({
    'Property Agent': row.agent,
    Properties: row.properties,
    Score: `${row.score}%`,
  }));

  exportDataToXls(
    `agents_call-scoring-data_${moment(new Date()).format('MM-DD-YYYY')}`,
    { data: reportData, sheet: 'AVG. CALL SCORE BY AGENT' },
  );
};

const getTotalsFromCreditBuilderResidentsMetrics = (metrics: CreditBuilderResidentMetrics): number => {
  let total = metrics.new_residents + metrics.current + metrics.renewals;
  if (!Number.isInteger(total)) total = Number(total.toFixed(1));
  return total;
};

const percentageFormat = (value: number, total: number): string => (total ? `${Math.round((value / total) * 100)}%` : '0%');

const completeCurrencyFormat = (value: number): string => `$${currencyFormat(value)}`;

export const exportCreditBuilderSummaryToXls = async (reportName: string, summary: CreditBuilderSymmaryData, startDate: string, endDate: string, propertiesLaunchDateRelation: CreditBuilderLaunchDateRelation[]): Promise<void> => {
  const XLSX = await import('xlsx');
  const wb = { Sheets: {}, SheetNames: [] };
  const { cumulative, monthly, revenue } = summary;

  const totalCumulativeEnrollments = getTotalsFromCreditBuilderResidentsMetrics(cumulative.enrollments);
  const totalAverageNewMonthlyEnrollments = getTotalsFromCreditBuilderResidentsMetrics(monthly.enrollments);
  const totalCumulativeRevenue = revenue.cumulative.total;

  const totalCumulativeCancellations = getTotalsFromCreditBuilderResidentsMetrics(cumulative.cancellations);
  const totalAverageMonthlyCancellations = getTotalsFromCreditBuilderResidentsMetrics(monthly.cancellations);
  const totalNetRevenue = revenue.cumulative_net.total;

  const totalCumulativeNetEnrollments = getTotalsFromCreditBuilderResidentsMetrics(cumulative.net_enrollments);
  const totalNewMonthlyNetEnrollments = getTotalsFromCreditBuilderResidentsMetrics(monthly.net_enrollments);
  const totalAverageNewMonthlyRevenue = revenue.monthly.total;

  const totalAverageNetNewMonthlyRevenue = revenue.monthly_net.total;

  /* eslint-disable max-len */
  const data = [
    ['', 'From', 'To', '# Days'],
    ['Date Range', moment(startDate).format('MM/DD/YYYY'), moment(endDate).format('MM/DD/YYYY'), moment(endDate).diff(moment(startDate), 'days') + 1], // +1 to include the end date
    [],
    ['Cumulative Total Enrollments', totalCumulativeEnrollments, '100%', '', 'Average New Monthly Enrollments', totalAverageNewMonthlyEnrollments, '100%', '', 'Cumulative Total Revenue', completeCurrencyFormat(totalCumulativeRevenue), '100%'],
    ['New Residents', cumulative.enrollments.new_residents, percentageFormat(cumulative.enrollments.new_residents, totalCumulativeEnrollments), '', 'New Residents', monthly.enrollments.new_residents, percentageFormat(monthly.enrollments.new_residents, totalAverageNewMonthlyEnrollments), '', 'Property', completeCurrencyFormat(revenue.cumulative.property), percentageFormat(revenue.cumulative.property, totalCumulativeRevenue)],
    ['Existing Residents', cumulative.enrollments.current, percentageFormat(cumulative.enrollments.current, totalCumulativeEnrollments), '', 'Existing Residents', monthly.enrollments.current, percentageFormat(monthly.enrollments.current, totalAverageNewMonthlyEnrollments), '', 'PMC', completeCurrencyFormat(revenue.cumulative.pmc), percentageFormat(revenue.cumulative.pmc, totalCumulativeRevenue)],
    ['Renewal Residents', cumulative.enrollments.renewals, percentageFormat(cumulative.enrollments.renewals, totalCumulativeEnrollments), '', 'Renewal Residents', monthly.enrollments.renewals, percentageFormat(monthly.enrollments.renewals, totalAverageNewMonthlyEnrollments)],
    [''],
    ['Cumulative Total Cancellations', totalCumulativeCancellations, '100%', '', 'Average New Monthly Cancellations', totalAverageMonthlyCancellations, '100%', '', 'Net Total Revenue', completeCurrencyFormat(revenue.cumulative_net.total), '100%'],
    ['New Residents', cumulative.cancellations.new_residents, percentageFormat(cumulative.cancellations.new_residents, totalCumulativeCancellations), '', 'New Residents', monthly.cancellations.new_residents, percentageFormat(monthly.cancellations.new_residents, totalAverageMonthlyCancellations), '', 'Property', completeCurrencyFormat(revenue.cumulative_net.property), percentageFormat(revenue.cumulative_net.property, totalNetRevenue)],
    ['Existing Residents', cumulative.cancellations.current, percentageFormat(cumulative.cancellations.current, totalCumulativeCancellations), '', 'Existing Residents', monthly.cancellations.current, percentageFormat(monthly.cancellations.current, totalAverageMonthlyCancellations), '', 'PMC', completeCurrencyFormat(revenue.cumulative_net.pmc), percentageFormat(revenue.cumulative_net.pmc, totalNetRevenue)],
    ['Renewal Residents', cumulative.cancellations.renewals, percentageFormat(cumulative.cancellations.renewals, totalCumulativeCancellations), '', 'Renewal Residents', monthly.cancellations.renewals, percentageFormat(monthly.cancellations.renewals, totalAverageMonthlyCancellations)],
    [''],
    ['Cumulative Net Enrollments', totalCumulativeNetEnrollments, '100%', '', 'Average New Monthly Net Enrollments', totalNewMonthlyNetEnrollments, '100%', '', 'Average New Monthly Revenue', completeCurrencyFormat(totalAverageNewMonthlyRevenue), '100%'],
    ['New Residents', cumulative.net_enrollments.new_residents, percentageFormat(cumulative.net_enrollments.new_residents, totalCumulativeNetEnrollments), '', 'New Residents', monthly.net_enrollments.new_residents, percentageFormat(monthly.net_enrollments.new_residents, totalNewMonthlyNetEnrollments), '', 'Property', completeCurrencyFormat(revenue.monthly.property), percentageFormat(revenue.monthly.property, totalAverageNewMonthlyRevenue)],
    ['Existing Residents', cumulative.net_enrollments.current, percentageFormat(cumulative.net_enrollments.current, totalCumulativeNetEnrollments), '', 'Existing Residents', monthly.net_enrollments.current, percentageFormat(monthly.net_enrollments.current, totalNewMonthlyNetEnrollments), '', 'PMC', completeCurrencyFormat(revenue.monthly.pmc), percentageFormat(revenue.monthly.pmc, totalAverageNewMonthlyRevenue)],
    ['Renewal Residents', cumulative.net_enrollments.renewals, percentageFormat(cumulative.net_enrollments.renewals, totalCumulativeNetEnrollments), '', 'Renewal Residents', monthly.net_enrollments.renewals, percentageFormat(monthly.net_enrollments.renewals, totalNewMonthlyNetEnrollments)],
    [''],
    ['', '', '', '', '', '', '', '', 'Average Net New Monthly Revenue', completeCurrencyFormat(totalAverageNetNewMonthlyRevenue), '100%'],
    ['', '', '', '', '', '', '', '', 'Property', completeCurrencyFormat(revenue.monthly_net.property), percentageFormat(revenue.monthly_net.property, totalAverageNetNewMonthlyRevenue)],
    ['', '', '', '', '', '', '', '', 'PMC', completeCurrencyFormat(revenue.monthly_net.pmc), percentageFormat(revenue.monthly_net.pmc, totalAverageNetNewMonthlyRevenue)],
    [''],
    ['Properties Included', 'Launch Date'],
    ...propertiesLaunchDateRelation.map(({ name, launchDate }) => [name, moment(launchDate).format('MM/DD/YYYY')]),
  ];
  /* eslint-enable max-len */

  const worksheet = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, worksheet, 'Sheet1');

  const xlsBlob = new Blob(
    [XLSX.write(wb, { bookType: 'xls', type: 'array' })],
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' },
  );

  const link = document.createElement('a');
  link.setAttribute('href', window.URL.createObjectURL(xlsBlob));
  link.setAttribute('download', `${reportName}.xls`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const exportCallScoringDrilldownToXls = (data: CallScoringDrilldown[]): void => {
  const reportData = data.map(row => ({
    Property: row.property,
    'Avg. Call Score': `${row.average_call_score}%`,
    'Introduction and Lead Information': `${row.introduction}%`,
    'Qualifying Questions': `${row.qualifying}%`,
    'Amenities and Benefits': `${row.amenities}%`,
    Closing: `${row.closing}%`,
    'Overall Impression': `${row.overall}%`,
  }));

  exportDataToXls(
    `call-scoring-overall-drilldown_${moment(new Date()).format('MM-DD-YYYY')}`,
    { data: reportData, sheet: 'Call Scoring (Overall)' },
  );
};

export const exportCallScoringDataToXls = (data: CallScoringData[], scoringQuestions: Questions[], filteringData: DateRange[], type = 'property'): void => {
  let reportData = data.map(row => ({
    Property: row.property_name,
    Name: row.lead_name,
    'Call Source': row.source,
    'Phone Number': row.prospect_phone_number,
    'Property Agent': row.answered_by_hobbes ? 'Hobbes' : row.agent_name,
    'Call Duration': moment.utc(row.duration * 1000).format('m:ss'),
    Date: moment(row.date).local().format('lll'),
    Score: `${row.score}%`,
  }));
  if (type === 'property') {
    reportData = reportData.map((item) => {
      const e = { ...item };
      delete e.Property;
      return e;
    });
  } else {
    reportData = reportData.map((item) => {
      const e = { ...item };
      delete e['Call Status'];
      return e;
    });
  }
  scoringQuestions.forEach((item, index) => {
    reportData = reportData.map((row, reportIndex) => ({
      ...row,
      // eslint-disable-next-line no-nested-ternary
      [`Q${index + 1} - ${item.question.replace(/#/g, '')}`]: (data[reportIndex].yes_questions || []).includes(item.id) ? 'Yes' : ((data[reportIndex].omitted_questions || []).includes(item.id) ? 'Omitted' : 'No'),
      [`Q${index + 1} - Weight`]: item.weight,
    }));
  });
  let filteredData = filteringData.map(row => ({
    Property: row.property,
    'Start Date': moment(row.startDate).local().format('lll'),
    'End Date': moment(row.endDate).local().format('lll'),
    'Report Creation Date': moment(new Date()).local().format('lll'),
  }));
  if (type !== 'property') {
    filteredData = filteredData.map((item) => {
      const e = { ...item };
      delete e.Property;
      return e;
    });
  }

  const callbackMapReportData = (dataTable, row, index) => {
    if (row.lead_name) {
      // eslint-disable-next-line no-param-reassign
      dataTable[`A${index + 2}`].l = { Target: `${window.location.origin}/${getPropertyId()}/leads/${row.lead_id}` };
    }
  };

  exportDataToXls(
    `call-scoring-data_${moment(new Date()).format('MM-DD-YYYY')}_${getPropertyId()}`,
    { data: reportData, sheet: 'Data', callback: callbackMapReportData },
    { data: filteredData, sheet: 'Filtering criteria' },
  );
};

export const exportChatsEvaluationData = (report: ChatReportStats): void => {
  const reportData = [{
    'Total Conversations': report.conversations.total,
    'Avg conversation score': report.conversations.avg,
    'Total Responses': report.responses.total,
  }];

  CHAT_MESSAGE_STATUSES.forEach(({ status, label }) => {
    reportData[0][label] = report.responses[status].count;
  });

  exportDataToXls(
    `chats_monthly_session_${moment(new Date()).format('MM-DD-YYYY')}_${getPropertyId()}`,
    {
      data: reportData,
      sheet: moment(report.session_date).format('[Data for] MMMM YYYY [Session]'),
    },
  );
};

export const convertTime = (responseMinutes: number): JSX.Element => {
  const hours = Math.floor(responseMinutes / 60);
  const minutes = Math.floor(responseMinutes % 60);
  return (
    <ReportValue>
      {hours > 0 && <>{hours}<small>{hours === 1 ? 'hr' : 'hrs'}{' '}</small></>}
      {minutes}<small>{minutes === 1 ? 'min' : 'mins'}</small>
    </ReportValue>);
};

export const formatNumberWithCommas = (x: number): string => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const renderCompareComp = (cell: string, compareValue: number[] | string): JSX.Element => (
  <React.Fragment>
    <div className="percent-bar">
      <div>{cell}</div>
      {compareValue !== undefined && (
        <TableCompare compareValue>
          <ReportCompareValue color={getCompareColor(getCompareValue(compareValue))}>
            {formatCompareValue(getCompareValue(compareValue))} {getCompareIcon(getCompareValue(compareValue))}
            {Array.isArray(compareValue) && <ComparePeriodLabel>{`(${compareValue[1]})`}</ComparePeriodLabel>}
          </ReportCompareValue>
        </TableCompare>
      )}
    </div>
  </React.Fragment>
);

export const getOffice = (cell: string, row: { office_meta: OfficeMeta }): JSX.Element | string => (cell === 'On-Site' ? (
  <select className="custom-select mn-wd-150" defaultValue="on_site">
    <option value="on_site" disabled>On-Site</option>
    {row.office_meta.pods.map(pod => (
      <optgroup label={pod.name} disabled>
        {pod.properties.map(p => <option value={p}>{p}</option>)}
      </optgroup>
    ))}
    {row.office_meta.properties.map(p => <option value={p} disabled>{p}</option>)}
  </select>
) : 'Virtual');

export const formatOfficeMeta = (officeMeta: OfficeMeta): string => {
  let result = 'On-Site\n';
  if (!isObject(officeMeta)) return result;
  officeMeta.pods.forEach((pod) => {
    result += `${pod.name}\n`;
    pod.properties.forEach((p) => {
      result += ` - ${p}\n`;
    });
  });
  officeMeta.properties.forEach((p) => {
    result += ` - ${p}\n`;
  });
  return result;
};

export const cbTypeNames = ['CB_ENROLLMENT_REPORTS', 'CB_CANCELLATION_REPORTS', 'CB_PAYMENT_REPORTS', 'CB_NET_ENROLLMENT_REPORTS', 'CB_BILLING_REPORTS', 'CB_SUMMARY_REPORTS'];

export const PPCNames = ['CORPORATE_PPC', 'STANDALONE_PPC', 'WORKFORCE_REPORTS'];

export const defaultReportTypes = ['OVERVIEW_REPORTS', 'MARKETING_REPORTS', 'AGENT_AND_OFFICE_REPORTS'];

export const filterData = (data: TourDataType, allowedTourTypes: string[]): TourDataType => {
  if (allowedTourTypes) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (allowedTourTypes.includes(key)) {
        acc[key] = value;
      }
      return acc;
    }, {}) as TourDataType;
  }
  return data;
};
