import produce from 'immer';
import { unionBy } from 'lodash';
import { ActionType as PusherActionType, PusherAction } from 'dwell/store/pusher/action-types';
import { OwnerCategory, PageCategory, GroupCategory } from 'dwell/views/tasks/category/utils';
import moment from 'moment';
import { ActionType as LeadActionType } from '../lead/action-types';
import { ActionType as PropertyActionType, PropertyProps } from '../property/action-types';
import { Action, ActionType, AvailableDateTimeProps, TaskProps } from './action-types';
import { CommonStateType } from '../types';

export interface TaskFilters {
  owner: OwnerCategory | number;
  page: PageCategory;
  search: string;
  category: GroupCategory;
  upcoming: string | moment.Moment;
}

export interface LoadMoreCount {
  TOURS: number;
  CALL_BACK: number;
  FIRST_CONTACT: number;
  REPLY_BACK: number;
  FOLLOWUPS: number;
}

export interface DisabledLoadMore {
  TOURS: boolean;
  CALL_BACK: boolean;
  FIRST_CONTACT: boolean;
  REPLY_BACK: boolean;
  FOLLOWUPS: boolean;
}

export interface TaskState extends CommonStateType {
  isCompletedTasksLoaded: boolean;
  tasks: TaskProps[];
  objectTasks: TaskProps[];
  task: TaskProps;
  count: number;
  availableDateTimes: AvailableDateTimeProps[] | string[];
  selectedProperties: PropertyProps[];
  areAvailableDateTimesLoaded: boolean;
  taskFilters: TaskFilters;
  completedTasks: TaskProps[];
  loadMoreCount: LoadMoreCount;
  disabledLoadMore: DisabledLoadMore;
  allTasks: TaskProps[];
}

const initialState: TaskState = {
  isCompletedTasksLoaded: true,
  isSubmitting: false,
  errorMessage: null,
  isLoaded: false,
  tasks: [],
  objectTasks: [],
  task: {} as TaskProps,
  count: 0,
  availableDateTimes: [],
  selectedProperties: [],
  areAvailableDateTimesLoaded: true,
  completedTasks: [],
  loadMoreCount: {
    TOURS: 1,
    CALL_BACK: 1,
    FIRST_CONTACT: 1,
    REPLY_BACK: 1,
    FOLLOWUPS: 1,
  },
  disabledLoadMore: {
    TOURS: false,
    CALL_BACK: false,
    FIRST_CONTACT: false,
    REPLY_BACK: false,
    FOLLOWUPS: false,
  },
  allTasks: [],
  taskFilters: {
    owner: OwnerCategory.All,
    page: PageCategory.Today,
    category: GroupCategory.All,
    search: '',
    upcoming: moment(),
  },
};

export const selectAvailableDateTimes = (state: { task: TaskState }): (AvailableDateTimeProps[] | string[]) =>
  state.task.availableDateTimes;
export const selectAreAvailableDateTimesLoaded = (state: { task: TaskState }): boolean =>
  state.task.areAvailableDateTimesLoaded;
export const selectTasks = (state: { task: TaskState }): TaskProps[] => state.task.tasks;
export const selectTaskFilters = (state: { task: TaskState }): Partial<TaskFilters> => state.task.taskFilters;
export const selectLoadMoreCount = (state: { task: TaskState }): LoadMoreCount => state.task.loadMoreCount;
export const selectDisabledLoadMore = (state: { task: TaskState }): DisabledLoadMore => state.task.disabledLoadMore;
export const TASK_FILTERS_KEY = 'TASK_FILTERS';

export default produce((state: TaskState = initialState, action: Action): TaskState => {
  const pusherAction = action as PusherAction;
  if (pusherAction.type.startsWith('PUSHER_') && pusherAction.pusherModel !== 'task') {
    return state;
  }

  const isCompletedPage = state.taskFilters.page === PageCategory.Completed;

  switch (action.type) {
    case ActionType.BULK_SAVE_TASKS_SUCCESS:
      if (isCompletedPage) {
        state.completedTasks = unionBy(action.result.data, state.completedTasks, 'id');
      } else {
        state.allTasks = unionBy(action.result.data, state.allTasks, 'id');
      }
      break;

    case ActionType.GET_TASKS_REQUEST:
      if (action.payload.append && state.count) {
        state.isLoaded = true;
      } else {
        state.isLoaded = action.payload.page > 1 && action.payload.paginated;
      }
      if (action.payload.status === 'COMPLETED') {
        state.isCompletedTasksLoaded = false;
      }
      break;
    case ActionType.GET_TASKS_SUCCESS:
      state.isLoaded = true;
      if (action.payload.append || action.result.data.previous) {
        if (isCompletedPage) {
          state.completedTasks = unionBy(state.completedTasks, action.result.data.results, 'id');
          if (action.payload.group) {
            const noMoreTasks = action.result.data.count === 0;
            state.loadMoreCount[action.payload.group] += 1;
            state.disabledLoadMore[action.payload.group] = noMoreTasks;
          }
        } else {
          state.allTasks = unionBy(state.allTasks, action.result.data.results, 'id');
        }
      } else if (isCompletedPage) {
        state.completedTasks = action.result.data.results;
      } else {
        state.allTasks = action.result.data.results;
      }
      if (action.payload.status === 'COMPLETED') {
        state.isCompletedTasksLoaded = true;
      } else {
        state.count = action.result.data.count;
      }
      break;
    case ActionType.GET_TASKS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isLoaded = true;
      break;

    case ActionType.GET_OBJECT_TASKS_REQUEST:
      state.isLoaded = false;
      state.objectTasks = [];
      break;
    case ActionType.GET_OBJECT_TASKS_SUCCESS:
      state.isLoaded = true;
      state.objectTasks = action.result.data.results;
      break;
    case ActionType.GET_OBJECT_TASKS_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isLoaded = true;
      break;

    case ActionType.UPDATE_TASK_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.UPDATE_TASK_SUCCESS: {
      state.isSubmitting = false;
      if (isCompletedPage) {
        state.completedTasks = state.completedTasks.map(task => (task.id === action.result.data.id ? action.result.data : task));
      } else {
        state.allTasks = state.allTasks.map(task => (task.id === action.result.data.id ? action.result.data : task));
      }
      state.objectTasks = state.objectTasks.map(task => (task.id === action.result.data.id ? action.result.data : task));
      break;
    }
    case ActionType.UPDATE_TASK_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.COMPLETE_TASK_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.COMPLETE_TASK_SUCCESS: {
      state.isSubmitting = false;
      if (isCompletedPage) {
        state.completedTasks = state.completedTasks.map(task => (task.id === action.result.data.id ? { ...task, ...action.result.data, became_completed: true } : task));
      } else {
        state.allTasks = state.allTasks.map(task => (task.id === action.result.data.id ? { ...task, ...action.result.data, became_completed: true } : task));
      }
      state.objectTasks = state.objectTasks.map(task => (task.id === action.result.data.id ? action.result.data : task));
      break;
    }
    case ActionType.COMPLETE_TASK_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.MARK_NO_SHOW_TASK_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.MARK_NO_SHOW_TASK_SUCCESS: {
      state.isSubmitting = false;
      if (isCompletedPage) {
        state.completedTasks = state.completedTasks.filter(task => task.id !== action.result.data.id);
      } else {
        state.allTasks = state.allTasks.filter(task => task.id !== action.result.data.id);
      }
      state.objectTasks = state.objectTasks.filter(task => task.id !== action.result.data.id);
      break;
    }
    case ActionType.MARK_NO_SHOW_TASK_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case LeadActionType.CREATE_LEAD_REQUEST:
      state.isSubmitting = true;
      break;
    case LeadActionType.CREATE_LEAD_SUCCESS:
      state.isSubmitting = false;
      break;
    case LeadActionType.CREATE_LEAD_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case LeadActionType.UPDATE_LEAD_SUCCESS: {
      const leadUpdated = action.result.data;
      if (['CLOSED', 'LOST', 'DELETED', 'TEST'].includes(leadUpdated.status) || leadUpdated.stage === 'LEASE_SIGNED') {
        state.objectTasks = [];
      }
      break;
    }

    case ActionType.CREATE_TASK_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.CREATE_TASK_SUCCESS: {
      state.isSubmitting = false;
      const newTask = action.result.data;
      const assignedToMe = newTask.owner === action.payload.currentUserId;
      if (state.taskFilters.owner !== OwnerCategory.All && newTask.owner !== state.taskFilters.owner && !assignedToMe) {
        break;
      }
      if (isCompletedPage) {
        state.completedTasks = unionBy([newTask], state.completedTasks, 'id');
      } else {
        state.allTasks = unionBy([newTask], state.allTasks, 'id');
      }
      break;
    }
    case ActionType.CREATE_TASK_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case ActionType.DELETE_TASK_REQUEST:
      state.isSubmitting = true;
      break;
    case ActionType.DELETE_TASK_SUCCESS: {
      state.isSubmitting = false;
      if (isCompletedPage) {
        state.completedTasks = state.completedTasks.filter(task => task.id !== action.result.data.id);
      } else {
        state.allTasks = state.allTasks.filter(task => task.id !== action.result.data.id);
      }
      state.objectTasks = state.objectTasks.filter(task => task.id !== action.result.data.id);
      break;
    }
    case ActionType.DELETE_TASK_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.isSubmitting = false;
      break;

    case PusherActionType.PUSHER_CREATE_RECORD: {
      const row = action.row as TaskProps;
      const { leadId } = action;
      if (state.selectedProperties.map(p => p.id).includes(row.property)) {
        state.tasks = [row].concat(state.tasks.filter(t => t.id !== row.id));
        // We only show task in the lead detail if the task is for the lead]
        if (leadId === row.lead) state.objectTasks = [row].concat(state.objectTasks.filter(t => t.id !== row.id));
      }
      break;
    }
    case PusherActionType.PUSHER_UPDATE_RECORD: {
      const row = action.row as TaskProps;
      const { leadId } = action;
      state.tasks = state.tasks.map(t => (t.id === row.id ? {
        ...row,
        became_completed: t.status !== 'COMPLETED' && row.status === 'COMPLETED' ? true : t.became_completed,
      } : t));
      if (leadId === row.lead) state.objectTasks = state.objectTasks.map(task => (task.id === row.id ? row : task));
      if (row.status === 'NO_SHOW') {
        state.tasks = state.tasks.filter(t => t.id !== row.id);
        // We only show task in the lead detail if the task is for the lead
        if (leadId === row.lead) state.objectTasks = [row].concat(state.objectTasks.filter(t => t.id !== row.id));
      }
      state.task = state.task.id === row.id ? row : state.task;
      break;
    }
    case PusherActionType.PUSHER_DELETE_RECORD: {
      const row = action.row as TaskProps;
      const { leadId } = action;
      state.tasks = state.tasks.filter(t => t.id.toString() !== row.id.toString());
      // We only show task in the lead detail if the task is for the lead
      if (leadId === row.lead) state.objectTasks = state.objectTasks.filter(t => t.id.toString() !== row.id.toString());
      break;
    }

    case ActionType.GET_AVAILABLE_TOUR_TIMES_REQUEST:
      state.areAvailableDateTimesLoaded = false;
      break;
    case ActionType.GET_AVAILABLE_TOUR_TIMES_SUCCESS:
      state.availableDateTimes = action.result.data.times || [];
      state.areAvailableDateTimesLoaded = true;
      break;
    case ActionType.GET_AVAILABLE_TOUR_TIMES_FAILURE:
      state.errorMessage = action.error.response?.status;
      state.areAvailableDateTimesLoaded = true;
      break;

    case ActionType.SET_CURRENT_TASK:
      state.task = action.result.data;
      break;

    case PropertyActionType.SET_SELECTED_PROPERTIES:
      state.selectedProperties = action.properties;
      break;
    case PropertyActionType.GET_SELECTED_PROPERTIES_SUCCESS:
      state.selectedProperties = action.result.data;
      break;
    case PropertyActionType.GET_SELECTED_PROPERTIES_FLOOR_PLANS_SUCCESS:
      state.selectedProperties = action.result.data;
      break;
    case ActionType.CLEAR_AVAILABLE_TOUR_TIMES:
      state.availableDateTimes = [];
      break;
    case ActionType.APPLY_TASK_FILTERS:
      state.taskFilters = { ...state.taskFilters, ...action.filters };
      localStorage.setItem(TASK_FILTERS_KEY, JSON.stringify(state.taskFilters));
      break;
    case ActionType.RESET_LOAD_MORE:
      state.loadMoreCount = {
        TOURS: 1,
        CALL_BACK: 1,
        FIRST_CONTACT: 1,
        REPLY_BACK: 1,
        FOLLOWUPS: 1,
      };
      state.disabledLoadMore = {
        TOURS: false,
        CALL_BACK: false,
        FIRST_CONTACT: false,
        REPLY_BACK: false,
        FOLLOWUPS: false,
      };
      break;
  }

  // Update the proxy tasks list
  state.tasks = isCompletedPage ? state.completedTasks : state.allTasks;

  return state;
});
