import { PromiseActionType } from 'dwell/store/types';
import { AnswerAssistTopicType } from 'dwell/store/assist_bot/action-types';

export enum ActionType {
  GET_PROFILE_PROPERTIES_REQUEST = 'GET_PROFILE_PROPERTIES_REQUEST',
  GET_PROFILE_PROPERTIES_SUCCESS = 'GET_PROFILE_PROPERTIES_SUCCESS',
  GET_PROFILE_PROPERTIES_FAILURE = 'GET_PROFILE_PROPERTIES_FAILURE',

  GET_PROFILE_DETAILS_REQUEST = 'GET_PROFILE_DETAILS_REQUEST',
  GET_PROFILE_DETAILS_SUCCESS = 'GET_PROFILE_DETAILS_SUCCESS',
  GET_PROFILE_DETAILS_FAILURE = 'GET_PROFILE_DETAILS_FAILURE',

  CREATE_LEAD_FROM_PROFILE_REQUEST = 'CREATE_LEAD_FROM_PROFILE_REQUEST',
  CREATE_LEAD_FROM_PROFILE_SUCCESS = 'CREATE_LEAD_FROM_PROFILE_SUCCESS',
  CREATE_LEAD_FROM_PROFILE_FAILURE = 'CREATE_LEAD_FROM_PROFILE_FAILURE',

  CREATE_TOUR_FROM_PROFILE_REQUEST = 'CREATE_TOUR_FROM_PROFILE_REQUEST',
  CREATE_TOUR_FROM_PROFILE_SUCCESS = 'CREATE_TOUR_FROM_PROFILE_SUCCESS',
  CREATE_TOUR_FROM_PROFILE_FAILURE = 'CREATE_TOUR_FROM_PROFILE_FAILURE',

  SET_SECTION = 'SET_SECTION',
  SET_PREVIOUS_SECTION = 'SET_PREVIOUS_SECTION',
  OPEN_PROFILE = 'OPEN_PROFILE',
  SET_PROPERTY = 'SET_PROPERTY',
  SET_ACTIVE_UNIT = 'SET_ACTIVE_UNIT',
  OPEN_GALLERY_PREVIEW = 'OPEN_GALLERY_PREVIEW',
  SET_UNIT_TYPE = 'SET_UNIT_TYPE',
  SET_EXPANDED_UNITS = 'SET_EXPANDED_UNITS',
}

export enum PageSection {
  MAIN_MENU = 'MAIN_MENU',
  ASSIST_BOT = 'ASSIST_BOT',
  PROPERTY_INFO = 'PROPERTY_INFO',
  LEASING_CRITERIA = 'LEASING_CRITERIA',
  AVAILABLE_UNITS = 'AVAILABLE_UNITS',
  UNITS_BY_FLOOR_PLAN = 'UNITS_BY_FLOOR_PLAN',
  AMENITIES = 'Amenities',
  PET_INFO = 'PET_INFO',
  TEAM = 'Team',
  SISTER_COMMUNITIES = 'SISTER_COMMUNITIES',
  NEIGHBORHOOD = 'NEIGHBORHOOD',
  GALLERY = 'GALLERY',
  ADD_NEW_LEAD = 'ADD_NEW_LEAD',
  ADD_NEW_TOUR = 'ADD_NEW_TOUR',
}

export interface LeadTypes {
  first_name: string,
  last_name: string,
  email: string,
  phone_number: string,
  move_in_date: string,
  floor_plan: number[],
  source: number,
  owner: number,
  unit_type: string,
}

export interface TourTypes {
  type: string,
  owner: string,
  lead: number,
  units: number[],
  tour_date: string | Date,
  description: string,
  tour_confirmation_reminder_enabled: boolean,
  status?: string,
}

export interface LeadNameTypes {
  id: number,
  name: string,
}

export interface PropertyTypes {
  id: number,
  name: string,
  external_id: string,
}

export interface ProspectSourceTypes {
  id: number,
  name: string,
}

export interface UsersTypes {
  id: number,
  first_name: string,
  last_name: string,
}

export interface FloorPlansTypes {
  id: number,
  plan: string,
}

export interface PropertyInfoTypes {
  office_hours: { general: string, saturday: string, sunday: string },
  contact: { email: string, phone: string },
  location: { address: string, cross_streets: string, sub_market: string, neighborhood: string },
  about: { year_built: string, total_unit_count: string, about: string },
  sources: ProspectSourceTypes[],
  users: UsersTypes[],
  floor_plans: FloorPlansTypes[],
}

export interface LeasingCriteriaTypes {
  rental_criteria: { income: number },
  deposit_actions: {
    refundable_approved_application: number, refundable_conditionally_approved_application: number,
    non_refundable_approved_application: number, non_refundable_conditionally_approved_application: number,
    refundable_pet_deposit: number, non_refundable_pet_deposit: number
  },
  application_fees: { applicants_fee: number, guarantors_fee: number, admin_fee: number },
  pet_fees: { fee: number, rent: number },
  other_rent_items: { valet_waste: number, mt_plus: number, rentable_items: { name: string, monthly_rent: number}[]},
}

export interface UnitTypes {
  unit: string,
  description: string,
  status: string,
  market_rent: number,
  bed_rooms: number,
  bath_rooms: number,
  available_date: string,
  plan?: string,
  building_name: string,
  floor_level: string,
}

export interface FloorPlanTypes {
  plan: string,
  units: UnitTypes[],
  bedrooms: number,
  bathrooms: number,
  square_footage: number,
  images: string[],
  promotion: PromotionTypes,
}

export interface PromotionTypes {
  name: string,
  promotion_html: string,
  restriction: string,
  floor_plans: string[],
  unit_types: string[],
  lease_duration_modifier: string,
  lease_duration: number,
}

export interface TeamTypes {
  id: number,
  first_name: string,
  last_name: string,
  avatar: string,
  role: string,
  is_online: boolean,
  office: string,
}

export interface AmenitiesTypes {
  community: { name: string, category: string }[],
  apartment: { name: string, category: string }[],
  hours: {
    clubhouse: string, fitness_center: string, pool: string, community_quiet_hours: string,
    moving_and_transfer: string
  },
  parking: { residents: string, guests: string },
  utilities: string[],
}

export interface NeighborhoodTypes {
  locations: { [key: string]: { name: string, address: string, phone: string, website: string, mile: number }[] },
}

export interface GalleryTypes {
  community_map: string,
  floor_plans: FloorPlanTypes[]
}

export interface SisterCommunitiesTypes {
  community_address: string,
  distance_from_property: number,
  name: string,
  phone_number: string,
  unit_types: {[unit_type: string]: { count: number, starting_at: number }},
  domain: string,
}

export interface InsiderInfoDetails {
  details: string[];
  category: string;
  subcategory: string;
  applicable_unit_types?: string[];
  applicable_floor_plan?: number;
  applicable_unit?: number;
}

export interface ProfileTypes {
  property_info: PropertyInfoTypes,
  leasing_criteria: LeasingCriteriaTypes,
  floor_plans: FloorPlanTypes[],
  units: UnitTypes[],
  promotion: PromotionTypes[],
  amenities: AmenitiesTypes,
  teams: TeamTypes[],
  neighborhood: NeighborhoodTypes,
  gallery: GalleryTypes,
  leads: LeadNameTypes[],
  sister_communities: SisterCommunitiesTypes[],
  answer_assist_topics: AnswerAssistTopicType[],
  insider_info_details: InsiderInfoDetails[]
}

export interface ExpandedUnits {
  [key: string]: boolean,
}

interface GetPropertiesAction {
  type: ActionType.GET_PROFILE_PROPERTIES_REQUEST
  | ActionType.GET_PROFILE_PROPERTIES_SUCCESS
  | ActionType.GET_PROFILE_PROPERTIES_FAILURE;

  result: {
    data: { results: PropertyTypes[] }
  };
  error: {
    response: { status: string }
  };
}

interface GetProfileDetailsAction {
  type: ActionType.GET_PROFILE_DETAILS_REQUEST
  | ActionType.GET_PROFILE_DETAILS_SUCCESS
  | ActionType.GET_PROFILE_DETAILS_FAILURE;

  result: {
    data: ProfileTypes
  };
  error: {
    response: { status: string }
  };
}

interface CreateLeadAction {
  type: ActionType.CREATE_LEAD_FROM_PROFILE_REQUEST
  | ActionType.CREATE_LEAD_FROM_PROFILE_SUCCESS
  | ActionType.CREATE_LEAD_FROM_PROFILE_FAILURE;

  result: {
    data: LeadTypes
  };
  error: {
    response: { status: string }
  };
}

interface SetSectionAction {
  type: ActionType.SET_SECTION;
  section: PageSection,
}

interface SetPreviousSectionAction {
  type: ActionType.SET_PREVIOUS_SECTION;
  section: PageSection,
}

interface SetExpandedUnitsAction {
  type: ActionType.SET_EXPANDED_UNITS;
  expandedUnits: ExpandedUnits,
}

interface OpenProfileAction {
  type: ActionType.OPEN_PROFILE;
  isOpen: boolean,
}

interface SetPropertyAction {
  type: ActionType.SET_PROPERTY;
  property: PropertyTypes,
}

interface SetActiveUnitAction {
  type: ActionType.SET_ACTIVE_UNIT;
  unit: UnitTypes,
}

interface OpenGalleryPreviewAction {
  type: ActionType.OPEN_GALLERY_PREVIEW;
  isOpen: boolean,
}

interface SetUnitTypeAction {
  type: ActionType.SET_UNIT_TYPE;
  unitType: string,
}

export type Action =
  | PromiseActionType
  | GetPropertiesAction
  | GetProfileDetailsAction
  | SetPropertyAction
  | SetActiveUnitAction
  | SetSectionAction
  | OpenProfileAction
  | OpenGalleryPreviewAction
  | CreateLeadAction
  | SetUnitTypeAction
  | SetPreviousSectionAction
  | SetExpandedUnitsAction;
