import React, { FC, useEffect, useState, useMemo } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { isIonAgent } from 'dwell/views/calls/communications/voice_centre/utils';
import { selectSelectedProperties, selectSomeSelectedPropertiesHaveVoiceSetup, selectIsTheFirstPropertyCBPlatform } from 'dwell/store/property/reducers';
import { selectCurrentUser, selectIsChatReviewerOrCallScorer } from 'dwell/store/user/reducers';
import voiceActions from 'dwell/store/voice/action-creator';
import actions from 'dwell/actions/index';
import { UserProps } from 'dwell/store/user/action-types';
import { Action as AuthAction, AuthenticationProps, SSOCheckAction } from 'dwell/store/authentication/action-types';
import { Action as VoiceAction } from 'dwell/store/voice/action-types';
import { build, client } from 'dwell/constants/paths';
import AccountSwitcherModal from 'containers/account_switcher';
import ReloginFlowModal from 'containers/re_login_flow';
import Profile from 'dwell/views/profile';
import { getShortName, LAST_ACTIVITY_DATE, LOGGED_ACCOUNT, IS_RECENTLY_LOGGED } from 'dwell/constants';
import { platformTypes } from 'site/constants';
import { checkIfAllPropertiesAreInactive } from 'src/utils';
import { canAccessSupportPortal } from 'dwell/views/support_portal/utils';
import { SSOStatus } from 'src/interfaces';
import DoNotDisturbSwitch from './do_not_disturb';
import AvailableToChatSwitch from './do_not_disturb/available_to_chat';
import PusherManager from '../../pusher';
import {
  UserAccount,
  accountDropdownMenuStyles,
  AccountSwitchButton,
  UserAvatar,
  UserAvatarEmpty,
  UserSettingsAvatar,
  UserSettingsAvatarEmpty,
  UserSettingsEmail,
  UserSettingsHeader,
  UserSettingsIcon,
  UserSettingsItem,
  UserSettingsName,
  Divider,
} from './styles';

const DefaultHeaderDropdown: FC = () => {
  const { push } = useHistory();

  const dispatch = useDispatch();
  const { logout, sessionTimeout, backchannelLogout, checkSSO } = actions.authentication;
  const { getCurrentUser, updateUserAvailableStatus } = actions.user;
  const currentUser: UserProps = useSelector(selectCurrentUser);
  const [ssoStatus, setSSOStatus] = useState<SSOStatus | null>(null);
  const isChatReviewerOrCallScorer = useSelector(selectIsChatReviewerOrCallScorer);
  const isSessionTimedOut: boolean = useSelector(state => state.authentication.isSessionTimedOut);
  const isTheFirstPropertyCBPlatform = useSelector(selectIsTheFirstPropertyCBPlatform);
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const [isShowingProfile, setIsShowProfile] = useState(false);
  const selectedProperties = useSelector(selectSelectedProperties);
  const somePropertiesHaveVoice = useSelector(selectSomeSelectedPropertiesHaveVoiceSetup);
  const supportPortalEnabled = selectedProperties.some(property => property.support_portal_enabled) && canAccessSupportPortal(currentUser);

  const handleGetCurrentUser = (shouldRetry = false) => {
    dispatch(getCurrentUser()).catch(() => {
      if (shouldRetry) setTimeout(handleGetCurrentUser, 1000);
    });
  };

  const unavailableToReceiveCallsBeforeAction = (action: () => VoiceAction | AuthAction) => {
    // If user has voice support we must set "unavailable" on Twilio before
    // login out or the session timed out
    if (currentUser && currentUser.has_voice_setup) {
      dispatch(voiceActions.setUserUnavailbleToReceiveCalls(currentUser.id)).then(() => dispatch(action()));
    } else {
      dispatch(action());
    }
  };

  useEffect(() => {
    const loggedAccount = JSON.parse(localStorage.getItem(LOGGED_ACCOUNT)) || {};
    if (loggedAccount.access) {
      handleGetCurrentUser(true);
    }
  }, []);

  const handleCheckSSO = async (email: string) => {
    const response: SSOCheckAction | null = await dispatch(checkSSO(email));
    if (response.result && response.result.data) {
      const sso: SSOStatus = response.result.data;
      setSSOStatus(sso);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const loggedAccount = localStorage.getItem(LOGGED_ACCOUNT);
      const parsedLoggedAccount: AuthenticationProps | null = loggedAccount ? JSON.parse(loggedAccount) : null;
      const lastActivityDate = localStorage.getItem(LAST_ACTIVITY_DATE) || '';
      if (lastActivityDate && parsedLoggedAccount && parsedLoggedAccount.access) {
        if (
          moment(lastActivityDate)
            .add(1, 'hour')
            .diff(moment()) < 0
        ) {
          if (ssoStatus && ssoStatus.isSSO) {
            dispatch(backchannelLogout()).then(() => {
              unavailableToReceiveCallsBeforeAction(sessionTimeout);
            }).catch(() => {
              unavailableToReceiveCallsBeforeAction(sessionTimeout);
            });
          } else {
            unavailableToReceiveCallsBeforeAction(sessionTimeout);
          }
        }
      }
    }, 15 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      const { id } = currentUser;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const {
        crmApp: { config },
      } = window;
      if (config.pusherKey && config.pusherCluster) {
        const pusher = new PusherManager(config.pusherKey, config.pusherCluster);
        pusher.initializePusher(['notification', 'agentrequest', 'typing', 'user'], id);
      }
      const isRecentlyLogged = localStorage.getItem(IS_RECENTLY_LOGGED) || '';
      if (!currentUser.is_available && isRecentlyLogged && !isChatReviewerOrCallScorer) {
        dispatch(updateUserAvailableStatus(currentUser.id, { is_available: true })).then(() => {
          localStorage.removeItem(IS_RECENTLY_LOGGED);
          handleCheckSSO(currentUser.email);
        });
      } else {
        localStorage.removeItem(IS_RECENTLY_LOGGED);
        handleCheckSSO(currentUser.email);
      }
    }
  }, [currentUser]);

  const onSettingsClick = () => {
    if (isTheFirstPropertyCBPlatform) push(build(client.SETTINGS.LIST_TEMPLATE, 'properties'));
    else push(build(client.SETTINGS.ASSIGNMENT_RULES, 'properties'));
  };

  const setUserAsUnavailableAndLogout = () => {
    if (ssoStatus && ssoStatus.isSSO) {
      dispatch(backchannelLogout(true)).then(() => {
        unavailableToReceiveCallsBeforeAction(logout);
      }).catch(() => {
        unavailableToReceiveCallsBeforeAction(logout);
      });
    } else {
      unavailableToReceiveCallsBeforeAction(logout);
    }
  };

  const toggle = () => {
    setDropDownOpen(!dropdownOpen);
  };

  const navigateCompete = () => {
    push(build(client.COMPETE.HOME, selectedProperties[0].external_id));
  };

  const navigateSupportPortal = () => {
    push(client.SUPPORT_PORTAL.VIEW);
  };

  const { first_name: firstName, last_name: lastName, avatar, email, id: userID } = currentUser;
  const arePropertiesInactive = useMemo(() => checkIfAllPropertiesAreInactive(selectedProperties), [selectedProperties]);
  const areMultipleOrSingleNotSiteOnly = (selectedProperties.length > 1 || (selectedProperties.length === 1 && !platformTypes.isSiteOnly(selectedProperties[0].platforms))) && !arePropertiesInactive;
  const isOneAndNotSiteOnly = selectedProperties.length === 1 && !platformTypes.isSiteOnly(selectedProperties[0].platforms);
  const showDoNotDisturbSwitcher: boolean = (
    !isTheFirstPropertyCBPlatform &&
    !isChatReviewerOrCallScorer &&
    somePropertiesHaveVoice &&
    currentUser &&
    (currentUser.has_voice_setup || isIonAgent(currentUser.role, currentUser?.browser_phone)) &&
    !arePropertiesInactive
  );

  return (
    <>
      {isShowingModal && <AccountSwitcherModal show={isShowingModal} currentUser={currentUser} handleClose={() => setIsShowingModal(!isShowingModal)} />}
      {isSessionTimedOut && <ReloginFlowModal show={isSessionTimedOut} />}
      <Profile show={isShowingProfile} handleClose={() => setIsShowProfile(false)} />
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <UserAccount>
          <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
            {avatar || !(firstName && lastName) ? (
              <UserAvatarEmpty>
                <UserAvatar src={avatar || '/static/images/default-avatar.gif'} alt="avatar" />
              </UserAvatarEmpty>
            ) : (
              <UserAvatarEmpty>{getShortName(`${firstName} ${lastName}`)}</UserAvatarEmpty>
            )}
          </DropdownToggle>
        </UserAccount>
        <DropdownMenu
          modifiers={{
            setWidth: {
              enabled: true,
              fn: data => ({ ...data, styles: { ...data.styles, ...accountDropdownMenuStyles } }),
            },
          }}
          right
        >
          <UserSettingsHeader>
            {avatar || !(firstName && lastName) ? (
              <UserSettingsAvatarEmpty>
                <UserSettingsAvatar src={avatar || '/static/images/default-avatar.gif'} alt="avatar" />
              </UserSettingsAvatarEmpty>
            ) : (
              <UserSettingsAvatarEmpty>{getShortName(`${firstName} ${lastName}`)}</UserSettingsAvatarEmpty>
            )}
            <UserSettingsName>
              {firstName} {lastName}
            </UserSettingsName>
            <UserSettingsEmail>{email}</UserSettingsEmail>
            <UserSettingsEmail>Agent ID: {userID}</UserSettingsEmail>
            {!currentUser.is_call_scorer && (
              <AccountSwitchButton
                onClick={() => {
                  setIsShowingModal(true);
                  setDropDownOpen(false);
                }}
              >
                Switch account
              </AccountSwitchButton>
            )}
          </UserSettingsHeader>
          {showDoNotDisturbSwitcher && <DoNotDisturbSwitch />}
          {!isTheFirstPropertyCBPlatform && !isChatReviewerOrCallScorer && !arePropertiesInactive && <AvailableToChatSwitch />}
          {!currentUser.is_call_scorer && (
            <React.Fragment>
              <UserSettingsItem
                onClick={() => {
                  setIsShowProfile(true);
                  setDropDownOpen(false);
                }}
              >
                <UserSettingsIcon className="ri-user-settings-line" /> My Profile
              </UserSettingsItem>
              {(areMultipleOrSingleNotSiteOnly) && (
                <UserSettingsItem onClick={onSettingsClick}>
                  <UserSettingsIcon className="ri-settings-4-line" /> Settings
                </UserSettingsItem>
              )}
              {areMultipleOrSingleNotSiteOnly && !isTheFirstPropertyCBPlatform && <Divider />}
              {!isTheFirstPropertyCBPlatform && isOneAndNotSiteOnly && !arePropertiesInactive && (
                <UserSettingsItem onClick={navigateCompete}>
                  <UserSettingsIcon className="ri-bar-chart-2-line" /> Compete
                </UserSettingsItem>
              )}
              {isOneAndNotSiteOnly && !isTheFirstPropertyCBPlatform && !arePropertiesInactive && <Divider />}
              {(areMultipleOrSingleNotSiteOnly) && !isTheFirstPropertyCBPlatform && (
                <>
                  {supportPortalEnabled && (
                    <UserSettingsItem onClick={navigateSupportPortal}>
                      <UserSettingsIcon className="ri-question-line" /> Support Portal
                    </UserSettingsItem>
                  )}
                  <UserSettingsItem onClick={() => window.open('https://www.notion.so/Help-Support-ac701af2ceaf4fedb1163dd5af6800e5')}>
                    <UserSettingsIcon className="ri-folder-unknow-line" /> Help Guides
                  </UserSettingsItem>
                </>
              )}
            </React.Fragment>
          )}
          <UserSettingsItem onClick={setUserAsUnavailableAndLogout}>
            <UserSettingsIcon className="ri-logout-box-r-line" /> Logout
          </UserSettingsItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default DefaultHeaderDropdown;
