import React, { FC, Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LineSkeleton } from 'src/utils';
import actions from 'application/store/actions';
import { selectApplicationDetails, selectApplicationIsLoaded } from 'application/store/application/reducers';
import {
  StatusDropdownMenu, StageDropdown, DropdownLink, StatusDropdownItem,
} from 'dwell/views/lead/layout/key_info/style';
import ApplicationCancelDialog from 'dwell/components/leads/lead_lost_dialog';

interface StatusDropdownProps {
  choices: { [key: string]: { title: string, text: string } },
  value: string,
}

const StatusDropdown: FC<StatusDropdownProps> = ({ choices, value }) => {
  const dispatch = useDispatch();
  const [isOpen, open] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const { updateApplication } = actions.application;
  const { setReloadState } = actions.application;
  const application = useSelector(selectApplicationDetails);
  const isLoaded = useSelector(selectApplicationIsLoaded);

  const onSave = (application_status) => {
    if (application_status === 'CANCELED') {
      setShowCancelModal(true);
    } else {
      dispatch(updateApplication(application.id, { application_status, is_single_field_save: true }))
        .then(() => dispatch(setReloadState(true)));
    }
  };

  const handleSaveStatus = (lost_reason) => {
    setShowCancelModal(false);
    dispatch(updateApplication(application.id, { application_status: 'CANCELED', lost_reason, is_single_field_save: true }))
      .then(() => dispatch(setReloadState(true)));
  };

  return (
    <>
      {isLoaded ?
        <Fragment>
          <StageDropdown isOpen={isOpen} toggle={() => open(!isOpen)}>
            <DropdownLink caret>
              {value ? choices[value].title : 'Select status'}
            </DropdownLink>
            <StatusDropdownMenu right>
              {Object.keys(choices).map((key, index) => (
                <React.Fragment key={`status${index}`}>
                  <StatusDropdownItem onClick={() => onSave(key)} selected={value === key} >
                    <strong>
                      {choices[key].title}
                      {value === key && <i className="ri-check-line" />}
                    </strong>
                    <span>
                      {choices[key].text}
                    </span>
                  </StatusDropdownItem>
                </React.Fragment>))}
            </StatusDropdownMenu>
          </StageDropdown>
          <ApplicationCancelDialog
            show={showCancelModal}
            handleClose={() => setShowCancelModal(false)}
            handleSave={handleSaveStatus}
            textLabel="cancel"
          />
        </Fragment> : <LineSkeleton width={80} height={38} style={{ marginLeft: '8px' }} />}
    </>
  );
};

export default StatusDropdown;
